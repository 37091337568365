const patientOutcomeAndDisposition = (radiology) => ({
  label: "Patient Outcome and Disposition",
  render: { type: "Title" },
  items: [patientOutcome(), patientDisposition(radiology)],
});

const patientOutcome = () => ({
  name: "patientOutcome",
  render: {
    type: "InlineRadios",
    options: ["Acceptable", "Adverse", "AMA", "Unknown"],
  },
});

const patientDisposition = (radiology) => ({
  name: "patientDisposition",
  render: {
    type: "InlineRadios",
    options: [
      "Death",
      "Admitted",
      "Readmitted",
      "Transferred",
      "Discharged",
      "AMA",
      "No Track",
    ],
    hide: radiology,
  },
  itemsDependOn: [{ name: "patientDisposition", is: "Discharged" }],
  items: [patientDispositionLocation()],
});

const patientDispositionLocation = () => ({
  name: "patientDispositionLocation",
  render: {
    type: "InlineRadios",
    options: [
      "Home",
      "Nursing Home",
      "Swing Bed",
      "Home with Home Health",
      "Home with Hospice",
      "Skilled Nursing Facility",
      "Other",
      "LTAC",
      "Rehabilitation Hospital",
      "Assisted Living/ Skilled Nursing Facility with Hospice",
      "Law Enforcement",
    ],
  },
  itemsDependOn: [{ name: "patientDispositionLocation", is: "Other" }],
  items: [additionalDetails()],
});

const additionalDetails = () => ({
  name: "patDisLocAdditionalDetails",
  render: { type: "TextBox", required: true },
});

export default patientOutcomeAndDisposition;
