import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityReasonForReviewChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Reason for Review"}
        xAxisLabel={"Reason"}
        yAxisLabel={"Number of Records"}
        figureText={
          "Records reviewed by Reason for Review for all RPPR© facilities"
        }
      />
    </>
  );
};

export default AllFacilityReasonForReviewChart;
