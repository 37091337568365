import * as React from "react";
//ui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form } from "react-final-form";
import { Select, TextField, Checkboxes } from "mui-rff";
import { MenuItem } from "@mui/material";
//data
import { facilityUser } from "src/utilities/rbacFunctions";
import { MidlevelActions } from "src/redux/reducers/PersonnelManagement/Midlevel";
import { useSelector } from "react-redux";
import { selectContentByID } from "src/redux/selectors";
import { degrees } from "./initialValues";
//validation
import { midlevelProviderValidationSchema as validationSchema } from "../validationSchema";
import { makeValidate } from "mui-rff";

const validateForm = makeValidate(validationSchema);

const UpdateMidlevelDialog = (props) => {
  const isFacilityUser = facilityUser(props.roles);
  const Midlevel = useSelector(selectContentByID("Midlevel", props.midlevelID));
  const render = Midlevel !== undefined;

  const initialValues = {
    firstName: Midlevel.FirstName,
    lastName: Midlevel.LastName,
    email: Midlevel.EmailAddress,
    degree: Midlevel.Degree,
    active: Midlevel.Active,
  };

  if (isFacilityUser) {
    initialValues.unlink = false;
  }

  const updateMidlevel = (values) => {
    const castValues = validationSchema.cast(values);
    const payload = {
      ID: props.midlevelID,
      FirstName: castValues.firstName,
      LastName: castValues.lastName,
      EmailAddress: castValues.email,
      Degree: castValues.degree,
      Active: castValues.active,
    };

    if (isFacilityUser) {
      payload.Unlink = castValues.unlink;
    }

    props.dispatch(MidlevelActions.Update(payload));

    if (payload.Unlink) {
      props.dispatch(
        MidlevelActions.RemoveFromFacility({
          FacilityID: props.facilityID,
          MidlevelID: props.midlevelID,
          RemoveMidlevel: isFacilityUser,
        })
      );
    }

    props.setDialogVisible();
  };

  return (
    <>
      {render && (
        <Form
          onSubmit={updateMidlevel}
          initialValues={initialValues}
          validate={validateForm}
          render={({ handleSubmit }) => (
            <>
              <form>
                <Dialog
                  fullWidth
                  maxWidth={"sm"}
                  open={props.dialogVisible}
                  onClose={() => {
                    props.setDialogVisible();
                  }}
                >
                  <DialogTitle>Update Midlevel</DialogTitle>
                  <DialogContent>
                    <div className={"row py-2"}>
                      <div className={"col"}>
                        <TextField
                          label="First Name *"
                          name="firstName"
                          variant={"outlined"}
                        />
                      </div>
                      <div className={"col"}>
                        <TextField
                          label="Last Name *"
                          name="lastName"
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="E-Mail"
                          name="email"
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <Select
                          id="create-provider-degree-select"
                          name={"degree"}
                          label={"Degree*"}
                          labelId="create-provider-degree-select-label"
                          variant={"outlined"}
                          displayEmpty
                        >
                          <MenuItem disabled>Select a Degree</MenuItem>
                          {degrees.map((option) => (
                            <MenuItem
                              key={`create-provider-degree-select-option-${option.name}-${option.value}`}
                              value={option.value}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    {!isFacilityUser && (
                      <div className={"row pt-2"}>
                        <div className={"col"}>
                          <Checkboxes
                            size={"small"}
                            name={"active"}
                            data={{ label: "Active", value: true }}
                            color={"primary"}
                          />
                        </div>
                      </div>
                    )}
                    {isFacilityUser && (
                      <div className={"row pt-2"}>
                        <div className={"col"}>
                          <Checkboxes
                            size={"small"}
                            name={"unlink"}
                            data={{
                              label: "Unlink from Facility",
                              value: true,
                            }}
                            color={"primary"}
                          />
                        </div>
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color={"secondary"}
                      variant={"contained"}
                      onClick={() => {
                        props.setDialogVisible();
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      color={"primary"}
                      variant={"contained"}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </>
          )}
        />
      )}
    </>
  );
};

export default UpdateMidlevelDialog;
