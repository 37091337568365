import * as yup from "yup";

const validationSchema = yup.object({
  reviewType: yup.string().required().nullable(),
  committeeID: yup.number().required().nullable(),
  subcommittee: yup.string().required().nullable(),
  meetingDate: yup.date().required().nullable(),
});

export default validationSchema;
