import React from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { canCreateUser } from "src/utilities/rbacFunctions";
import {
  selectContent,
  selectFacilities,
  selectUsers,
} from "src/redux/selectors";
import { UserActions } from "src/redux/reducers/PersonnelManagement/User";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import CreateUserDialog from "./CreateUserDialog";
import UpdateUserDialog from "./UpdateUserDialog";
import TablePageCard from "src/components/individual/TablePageCard";
import { Button } from "@mui/material";

const columns = [
  {
    name: "ID",
    label: "ID",
  },
  {
    name: "firstName",
    label: "First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
  },
  {
    name: "username",
    label: "Username",
  },
  {
    name: "emailAddress",
    label: "E-Mail Address",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
  },
  {
    name: "permission",
    label: "Permission",
  },
  {
    name: "facility",
    label: "Facility",
  },
  {
    name: "active",
    label: "Active",
  },
  {
    name: "actions",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      download: false,
      print: false,
    },
  },
];

const options = {
  filterType: "multiselect",
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
};

const User = (props) => {
  const [createUserDialogVisible, setCreateUserDialogVisible] =
    React.useState(false);
  const [updateUserDialogVisible, setUpdateUserDialogVisible] =
    React.useState(false);
  const [userID, setUserID] = React.useState(null);
  const Users = useSelector(selectUsers);
  const Facilities = useSelector(selectFacilities);
  const Permissions = useSelector(selectContent("Permission"));
  const Loaded = useSelector((state) => state.Loaded);

  useEffectOnce(() => {
    Loaded.User === false && props.dispatch(UserActions.BatchAdd());
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
  });

  const data = Users.map((user, i) => {
    const candidatePermission = Permissions.find(
      (permission) => permission.ID === user.PermissionID
    );
    const candidateFacility = Facilities.find(
      (facility) => facility.ID === user.FacilityID
    );
    return {
      ID: user.ID,
      firstName: user.FirstName ? user.FirstName : "-",
      lastName: user.LastName ? user.LastName : "-",
      username: user.UserName,
      emailAddress: user.EmailAddress,
      phoneNumber:
        user.PhoneNumber !== undefined && user.PhoneNumber !== null
          ? `${
              user.AreaCode !== undefined &&
              user.AreaCode !== null &&
              `(${user.AreaCode})`
            } ${user.PhoneNumber} ${
              user.Extension !== undefined && user.Extension !== null
                ? `ext. ${user.Extension}`
                : ""
            }`
          : "-",
      permission:
        candidatePermission !== undefined && candidatePermission !== null
          ? candidatePermission.Level
          : "-",
      facility:
        candidateFacility !== undefined && candidateFacility !== null
          ? candidateFacility.FullName
          : "-",
      active: user.Active ? "Active" : "Inactive",
      actions: (
        <Button
          variant={"contained"}
          color={"primary"}
          size={"small"}
          onClick={() => {
            setUserID(user.ID);
            setUpdateUserDialogVisible(true);
          }}
        >
          Edit
        </Button>
      ),
    };
  });

  return (
    <>
      {createUserDialogVisible && (
        <CreateUserDialog
          {...props}
          dialogVisible={createUserDialogVisible}
          setDialogVisible={() => {
            setCreateUserDialogVisible(false);
          }}
        />
      )}
      {updateUserDialogVisible && (
        <UpdateUserDialog
          {...props}
          dialogVisible={updateUserDialogVisible}
          userID={userID}
          setDialogVisible={() => {
            setUpdateUserDialogVisible(false);
          }}
        />
      )}
      <TablePageCard
        title={"Users"}
        table={{ columns, data, options }}
        button={{
          isRendered: canCreateUser(props.roles),
          label: "New User",
          handleClick: () => setCreateUserDialogVisible(true),
        }}
      />
    </>
  );
};

export default User;
