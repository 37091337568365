import patientSafetyFindings from "./patientSafetyFindings";
import otherPatientSafetyIssues from "./otherPatientSafetyIssues";
import humanFactorsCommunication from "./humanFactorsCommunication";
import otherDocumentationIssues from "./otherDocumentationIssues";
import humanFactorsTraining from "./humanFactorsTraining";
import rulesPolicyProtocol from "./rulesPolicyProtocol";
import additionalBarriers from "./additionalBarriers";
import systemIssues from "./systemIssues";
import sentinelEvent from "./sentinelEvent";
import healthLiteracyReview from "./healthLiteracyReview";
import patientOutcomeAndDisposition from "./patientOutcomeAndDisposition";
import issuesForArchiToTrend from "./issuesForArchiToTrend";
import medicalManagement from "./medicalManagement";
import Constants from "src/utilities/constants";

const createChecklistStructure = (committeeID, recordVersion = 2) => {
  const disableFirstQuestion =
    !Constants.HAIR_REMOVAL_COMMITTEE_CHECK.includes(committeeID);
  const isRadiology = committeeID === Constants.COMMITTEE_ID_RADIOLOGY;
  const isLegacyVersion = versions[recordVersion] === "Legacy";
  return [
    patientSafetyFindings(disableFirstQuestion),
    otherPatientSafetyIssues(),
    humanFactorsCommunication(),
    otherDocumentationIssues(isLegacyVersion),
    humanFactorsTraining(isLegacyVersion),
    rulesPolicyProtocol(isLegacyVersion),
    additionalBarriers(isLegacyVersion),
    systemIssues(isLegacyVersion),
    sentinelEvent(),
    healthLiteracyReview(isRadiology),
    issuesForArchiToTrend(),
    medicalManagement(isLegacyVersion),
    patientOutcomeAndDisposition(isRadiology),
  ];
};

const versions = { 1: "Legacy", 2: "Latest" };

export default createChecklistStructure;
