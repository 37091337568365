import axios from "axios";
import * as Sentry from "@sentry/react";

const apiCatch = (error) => {
  const { url, data } = error.config;
  const { status, statusText } = error.request;
  Sentry.configureScope((scope) =>
    scope
      .setLevel("Error")
      .setExtra("url", url)
      .setExtra("data", data)
      .setExtra("status", status)
      .setExtra("statusText", statusText)
      .setExtra("message", error.message)
  );

  return Sentry.captureException(error);
};

const requestInterceptorSuccess = (config) => {
  // Do something before request is sent
  const idToken = localStorage.getItem("token");
  let modifiedConfig = config;

  if (idToken) {
    // add bearer token only if it is present
    modifiedConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${idToken}`,
      },
    };
  }
  return modifiedConfig;
};

const requestInterceptorError = (error) => {
  apiCatch(error);
  // Sentry.showReportDialog(); // If you want get users feedback on error
  // Do something with request error
  return Promise.reject(error);
};

const responseInterceptorSuccess = (response) => {
  // Do something with response data
  return response;
};

const responseInterceptorError = (error) => {
  // Do something with response error
  let globalErrorMessage = "";

  if (error.response) {
    // The request was made, but the server responded with a status code
    // that falls out of the range of 2xx
    const {
      response: { status },
    } = error;

    if (status === 401) {
      // handle 401
      globalErrorMessage = "Unauthorized";
    }

    // if status code is not '401 - Unauthorized'
    globalErrorMessage = "We are experiencing issues. Please try again later";
  } else {
    // Something happened in setting up the request that triggered an Error
    globalErrorMessage = "Trouble connecting to network";
  }

  apiCatch(error);
  // Sentry.showReportDialog(); // If you want get users feedback on error

  throw new Error(globalErrorMessage);
};

const createAxiosInstance = (config) => {
  const newAxiosInstance = axios.create({
    ...config,
    timeout: 30000, // TODO: change it to 30 sec
  });

  newAxiosInstance.interceptors.request.use(
    requestInterceptorSuccess,
    requestInterceptorError
  );
  newAxiosInstance.interceptors.response.use(
    responseInterceptorSuccess,
    responseInterceptorError
  );

  return newAxiosInstance;
};

const defaultAxiosService = createAxiosInstance();

export default defaultAxiosService;
