import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { UserActions } from "src/redux/reducers/PersonnelManagement/User";
import { useEffectOnce } from "react-use";
import { useNavigate } from "react-router-dom";
import {
  canCreateMeetings,
  facilitator as facilitatorRole,
} from "src/utilities/rbacFunctions";
import { globalVariables } from "src/components/App";
import { selectMeetingsByPermission } from "src/redux/selectors/meetingSelectors";
import TablePageCard from "src/components/individual/TablePageCard";
import ActionsButton from "./ActionsButton";
import CreateMeetingDialog from "./CreateMeetingDialog";
import DeleteMeetingDialog from "./DeleteMeetingDialog";
import UpdateMeetingDialog from "./UpdateMeetingDialog";
import AssignFacilitatorDialog from "./AssignFacilitatorDialog";
import CMECreditsDialog from "./CMECreditsDialog";
import AddRecordsToMeetingDialog from "./AddRecordsToMeetingDialog";
import { format, isValid } from "date-fns";
import { RecordActions } from "src/redux/reducers/Record";
import { ReviewActions } from "src/redux/reducers/Review";
import { MeetingActions } from "src/redux/reducers/Meeting";
import C from "src/utilities/constants";
import getUTCDate from "src/utilities/getUTCDate";
import get from "../helperFunctions";

const columns = [
  {
    name: "month",
    label: "Month",
    options: {
      filter: true,
      sort: true,
      download: true,
    },
  },
  {
    name: "year",
    label: "Year",
    options: {
      filter: true,
      sort: true,
      download: true,
    },
  },
  {
    name: "name",
    label: "Name",
    options: {
      filter: true,
      sort: true,
      download: true,
    },
  },
  {
    name: "numReviews",
    label: "Number of Reviews",
    options: {
      filter: false,
      sort: true,
      download: true,
    },
  },
  {
    name: "numRecords",
    label: "Number of Records",
    options: {
      filter: false,
      sort: true,
      download: true,
    },
  },
  {
    name: "mrtsReviewType",
    label: "Review Type",
    options: {
      filter: true,
      sort: true,
      download: true,
    },
  },
  {
    name: "actions",
    label: "Actions",
    options: {
      filter: false,
      sort: false,
      download: false,
      print: false,
    },
  },
];

const options = {
  filterType: "multiselect",
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
};

const MeetingManagement = (props) => {
  const navigate = useNavigate();
  const [isReloading, setIsReloading] = useState(false);
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [addRecordDialogVisible, setAddRecordDialogVisible] = useState(false);
  const [deleteRecordDialogVisible, setDeleteRecordDialogVisible] =
    useState(false);
  const [updateDialogVisible, setUpdateDialogVisible] = useState(false);
  const [assignFacilitatorDialogVisible, setAssignFacilitatorDialogVisible] =
    useState(false);
  const [cmeCreditsDialogVisible, setCMECreditsDialogVisible] = useState(false);
  const [meetingID, setMeetingID] = useState(null);
  const {
    data: { current },
  } = useContext(globalVariables);
  const Meetings = useSelector(selectMeetingsByPermission(current));
  const RecordReviews = useSelector((state) => state.Review);
  const Loaded = useSelector((state) => state.Loaded);

  const handleReload = () => {
    props.dispatch(MeetingActions.BatchReset());
  };

  // use useEffect to trigger when Loaded.Meeting changes to true
  useEffect(() => {
    if (Loaded.Meeting === true) {
      setIsReloading(false);
    } else {
      setIsReloading(true);
    }
  }, [Loaded.Meeting]);

  useEffectOnce(() => {
    props.dispatch(ReviewActions.BatchAddMeetingDashboardData(props.roles));
    Loaded.User === false && props.dispatch(UserActions.BatchAdd());
  });

  let data = Meetings.map((meeting, i) => {
    const numReviews = countReviews(RecordReviews, meeting.ID, props.roles);
    const meetingDate = getUTCDate(new Date(meeting.MeetingDate));
    return {
      month: isValid(meetingDate) ? format(meetingDate, "MMMM") : "-",
      year: isValid(meetingDate) ? format(meetingDate, "yyyy") : "-",
      name: meeting.MeetingName,
      numReviews,
      numRecords: meeting.TotalRecords,
      mrtsReviewType: get.MRTSReviewType(meeting.MeetingName),
      actions: (
        <ActionsButton
          id={i}
          spawnAddRecordsToMeetingDialog={() => {
            setMeetingID(meeting.ID);
            setAddRecordDialogVisible(true);
          }}
          spawnUpdateMeetingDialog={() => {
            setMeetingID(meeting.ID);
            setUpdateDialogVisible(true);
          }}
          spawnDeleteMeetingDialog={() => {
            setMeetingID(meeting.ID);
            setDeleteRecordDialogVisible(true);
          }}
          spawnAssignFacilitatorDialog={() => {
            setMeetingID(meeting.ID);
            setAssignFacilitatorDialogVisible(true);
          }}
          spawnCMECreditsDialog={() => {
            setMeetingID(meeting.ID);
            setCMECreditsDialogVisible(true);
          }}
          sendToReviewsDashboard={() => {
            navigate(`${meeting.ID}`);
          }}
          isFacilitator={() => facilitatorRole(props.roles)}
        />
      ),
    };
  });

  if (current.PermissionLevel === "Facilitator") {
    data = data.filter((meeting) => {
      return meeting.numReviews > 0;
    });
  }

  return (
    <>
      {createDialogVisible && (
        <CreateMeetingDialog
          {...props}
          dialogVisible={createDialogVisible}
          setDialogVisible={() => setCreateDialogVisible(false)}
        />
      )}
      {addRecordDialogVisible && (
        <AddRecordsToMeetingDialog
          {...props}
          meetingID={meetingID}
          dialogVisible={addRecordDialogVisible}
          setDialogVisible={() => {
            setAddRecordDialogVisible(false);
            setMeetingID(null);
          }}
        />
      )}
      {updateDialogVisible && (
        <UpdateMeetingDialog
          {...props}
          meetingID={meetingID}
          dialogVisible={updateDialogVisible}
          setDialogVisible={() => {
            setUpdateDialogVisible(false);
            setMeetingID(null);
          }}
        />
      )}
      {deleteRecordDialogVisible && (
        <DeleteMeetingDialog
          {...props}
          meetingID={meetingID}
          dialogVisible={deleteRecordDialogVisible}
          setDialogVisible={() => {
            setDeleteRecordDialogVisible(false);
            setMeetingID(null);
          }}
        />
      )}
      {assignFacilitatorDialogVisible && (
        <AssignFacilitatorDialog
          {...props}
          meetingID={meetingID}
          dialogVisible={assignFacilitatorDialogVisible}
          setDialogVisible={() => {
            setAssignFacilitatorDialogVisible(false);
            setMeetingID(null);
          }}
        />
      )}
      {cmeCreditsDialogVisible && (
        <CMECreditsDialog
          {...props}
          meetingID={meetingID}
          dialogVisible={cmeCreditsDialogVisible}
          setDialogVisible={() => {
            setCMECreditsDialogVisible(false);
            setMeetingID(null);
          }}
        />
      )}
      <TablePageCard
        title={"Meetings"}
        table={{ columns, data, options }}
        button={{
          isRendered: canCreateMeetings(props.roles),
          label: "New Meeting",
          handleClick: () => setCreateDialogVisible(true),
        }}
        reloadData={{
          isReloading,
          label: "Reload Meetings",
          handleClick: () => handleReload(),
        }}
      />
    </>
  );
};

const countReviews = (reviews, meetingID, roles) => {
  let reviewsInMeeting = reviews.filter(
    (review) => review.MeetingID === meetingID
  );
  if (facilitatorRole(roles)) {
    reviewsInMeeting = reviewsInMeeting.filter(({ ReviewStatus }) =>
      [
        C.REVIEW_STATUS.PENDING_FACILITATOR_REVIEW,
        C.REVIEW_STATUS.FACILITATOR_REVIEW_IN_PROGRESS,
      ].includes(ReviewStatus)
    );
  }
  return reviewsInMeeting.length;
};

export default MeetingManagement;
