const systemIssues = (isLegacyVersion) => ({
  label: "System Issues",
  render: { type: "Title", hide: isLegacyVersion },
  items: [systemIssuesDropdown()],
  version: 2,
});

const systemIssuesDropdown = () => ({
  name: "systemIssues",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No"],
  },
  itemsDependOn: [{ name: "systemIssues", is: "Yes" }],
  items: [
    lackOfMedicationAvailable(),
    equipmentFailure(),
    delayTransfer(),
    systemIssuesOther(),
  ],
});

const lackOfMedicationAvailable = () => ({
  name: "lackOfMedicationAvailable",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
});
const equipmentFailure = () => ({
  name: "equipmentFailure",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
  itemsDependOn: [{ name: "equipmentFailure", is: "Yes" }],
  items: [resultingDelayForTreatment()],
});

const resultingDelayForTreatment = () => ({
  name: "resultingDelayForTreatment",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown"],
  },
});

const delayTransfer = () => ({
  name: "delayTransfer",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No"],
  },
  itemsDependOn: [{ name: "delayTransfer", is: "Yes" }],
  items: [
    securingTransferAvailable(),
    transportationDelayWeather(),
    delayDecisionTransfer(),
    reasonForDelayDocumented(),
  ],
});

const securingTransferAvailable = () => ({
  name: "securingTransferAvailable",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown"],
  },
});
const transportationDelayWeather = () => ({
  name: "transportationDelayWeather",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown"],
  },
});
const delayDecisionTransfer = () => ({
  name: "delayDecisionTransfer",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown"],
  },
  itemsDependOn: [{ name: "delayDecisionTransfer", is: "Yes" }],
  items: [provider(), patientOrFamily()],
});

const provider = () => ({
  name: "provider",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown"],
  },
});
const patientOrFamily = () => ({
  name: "patientOrFamily",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown"],
  },
});

const reasonForDelayDocumented = () => ({
  name: "reasonForDelayDocumented",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "N/A"],
  },
});

const systemIssuesOther = () => ({
  name: "systemIssuesOther",
  render: {
    type: "TextBox",
    required: true,
  },
});

export default systemIssues;
