import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificARCHITrendIssuesChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: ARCHI Trend Issues"}
        xAxisLabel={"ARCHI Trend Issues"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: Records reviewed by ARCHI Trend Issues"}
      />
    </>
  );
};

export default FacilitySpecificARCHITrendIssuesChart;
