import React, { useContext } from "react";
import { Field } from "react-final-form";
import { Radios } from "mui-rff";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import blue from "@mui/material/colors/blue";
import { recordReviewData } from "src/components/pages/MeetingManagement/RecordReview";
import RenderEachItem from "../RenderEachItem";
import _ from "lodash";

const InlineRadios = ({
  items,
  itemsDependOn,
  name,
  label,
  render: { options },
}) => {
  const apiResponse = useContext(recordReviewData);
  const oChecklist = _.get(apiResponse, `OriginalData.Checklist`, []);
  const { Value: oValue } = oChecklist.find((oValue) => oValue.Name === name);
  const classes = useStyles();

  return (
    <Field name={name}>
      {({ input: { value } }) => {
        const isValueDiff = value !== oValue;
        return (
          <>
            <div className={"row pb-2"}>
              <div className={"col-5 d-flex text-left my-auto"}>{label}</div>
              <div className={"col d-flex justify-content-end inline-radio"}>
                <Box className={classes.radio}>
                  <Radios
                    name={name}
                    data={options.map((option) => ({
                      label: (
                        <span
                          className={
                            isValueDiff
                              ? option === oValue
                                ? classes.originalValue
                                : option === value
                                ? classes.facilitatorValue
                                : ""
                              : ""
                          }
                        >
                          {option}
                        </span>
                      ),
                      value: option,
                    }))}
                    className={classes.radio}
                  />
                </Box>
              </div>
            </div>
            <RenderEachItem
              items={items}
              itemsDependOn={itemsDependOn}
              indent
            />
          </>
        );
      }}
    </Field>
  );
};

const useStyles = makeStyles(() => ({
  originalValue: {
    textDecoration: "line-through",
    color: blue[700],
  },
  facilitatorValue: {
    color: blue[700],
  },
  radio: {
    textAlign: "right",
  },
}));

export default InlineRadios;
