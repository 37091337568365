import React from "react";
import { Button, Box } from "@mui/material";
import { Section } from "./index";
import { helpTemplate } from "./constants";
import Typography from "@mui/material/Typography";

const HelpView = ({ handleVisible }) => {
  return (
    <Section>
      <Box marginBottom={"10px"}>
        <Typography variant={"h4"}>Definitions</Typography>
      </Box>
      {helpTemplate.map(({ id, label, info }) => (
        <Paragraphs label={label} key={`help_definitions_${id}`}>
          {info}
        </Paragraphs>
      ))}
      <Box className={"row"}>
        <Box className={"col"} md={"3"} />
        <Box className={"col"}>
          <Button
            style={{ width: "100%" }}
            onClick={handleVisible}
            variant={"contained"}
            color={"primary"}
          >
            <i className={"fas fa-arrow-left mr-2"} />
            Return
          </Button>
        </Box>
        <Box className={"col"} md={"3"} />
      </Box>
    </Section>
  );
};

function Paragraphs({ label, children }) {
  return (
    <p className={"text-justify"}>
      <strong>{label}</strong> {children}
    </p>
  );
}

export default HelpView;
