import React, { useContext } from "react";
import { Field } from "react-final-form";
import { Radios as MuiRadios } from "mui-rff";
import { makeStyles } from "@mui/styles";
import blue from "@mui/material/colors/blue";
import { recordReviewData } from "src/components/pages/MeetingManagement/RecordReview";
import Box from "@mui/material/Box";
import _ from "lodash";

const Radios = ({ name, render: { options } }) => {
  const apiResponse = useContext(recordReviewData);
  const oChecklist = _.get(apiResponse, `OriginalData.Checklist`, []);
  const { Value: oValue } = oChecklist.find((oValue) => oValue.Name === name);
  const classes = useStyles();
  return (
    <Field name={name}>
      {({ input: { value } }) => {
        const isValueDiff = value !== oValue;
        return (
          <Box className={classes.radio}>
            <MuiRadios
              name={name}
              data={options.map((option) => ({
                label: (
                  <span
                    className={
                      isValueDiff
                        ? option === oValue
                          ? classes.originalValue
                          : option === value
                          ? classes.facilitatorValue
                          : ""
                        : ""
                    }
                  >
                    {option}
                  </span>
                ),
                value: option,
              }))}
            />
          </Box>
        );
      }}
    </Field>
  );
};

const useStyles = makeStyles(() => ({
  originalValue: {
    textDecoration: "line-through",
    color: blue[700],
  },
  facilitatorValue: {
    color: blue[700],
  },
  radio: {
    textAlign: "left",
  },
}));

export default Radios;
