import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityARCHITrendIssuesChart from "./AllFacilityRCHITrendIssuesChart";
import FacilitySpecificARCHITrendIssuesChart from "./FacilitySpecificRCHITrendIssuesChart";
import TextBegin from "./TextBegin";

const RCHITrendIssues = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, [
    "archiTrendIssues",
  ]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, [
      "archiTrendIssues",
    ]);

  return (
    <>
      <TextBegin {...props} />
      {chartDataAllFacilities !== false && (
        <AllFacilityARCHITrendIssuesChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"ARCHI Trend Issues"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificARCHITrendIssuesChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"ARCHI Trend Issues"}
        rows={chartData.rows}
      />
    </>
  );
};

export default RCHITrendIssues;
