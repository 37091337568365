import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import appReducer from "src/redux/reducers";

const consoleMessages = (_) => (next) => (action) => {
  let result;
  result = next(action);
  return result;
};

const Redux = (initialState = {}) => {
  return configureStore({
    reducer: appReducer,
    middleware:
      process.env.REACT_APP_ENABLE_STORE_LOGGING === "true"
        ? (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(logger).concat(consoleMessages)
        : (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(consoleMessages),
    devTools:
      process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "debug",
    preloadedState: initialState,
  });
};

export default Redux;
