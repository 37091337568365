import { sub } from "date-fns";

export const getContentById = (contentList, id) => {
  return contentList.length > 1 &&
    contentList.hasOwnProperty(id) &&
    Number(contentList[id]["ID"]) === Number(id)
    ? contentList[id]
    : findById(contentList, id);
};

export const findById = (contentList, id) => {
  for (const key in contentList) {
    if (parseInt(contentList[key]["ID"]) === parseInt(id)) {
      return contentList[key];
    }
  }
  return null;
};

// Must NOT have the object ID field
export const emptyObject = (contentName, FacilityID) => {
  switch (contentName) {
    case "Facility":
      return {
        ARCHIFacilityID: "",
        Name: "",
        Type: "",
        City: "",
        Active: 1,
      };
    case "FacilitySelected":
      return { ID: "", Text: "" };
    case "Contact":
      return {
        FirstName: "",
        LastName: "",
        EmailAddress: "",
        AreaCode: "",
        PhoneOffice: "",
        Extension: "",
        FacilityID: "",
        Active: 1,
      };
    case "Provider":
      return {
        FirstName: "",
        LastName: "",
        EmailAddress: "",
        ARCHIProviderID: "",
        Degree: "",
        Active: 1,
      };
    case "Midlevel":
      return {
        FirstName: "",
        LastName: "",
        EmailAddress: "",
        ARCHIProviderID: "",
        Degree: "",
        Active: 1,
      };
    case "Record":
      return {
        SubmissionDate: null,
        FacilityID: FacilityID,
        ContactID: null,
        ProviderID: null,
        MidlevelProviderID: null,
        RecordStatusID: "",
        FacilityRecordID: "",
        CommitteeID: null,
        CommitteeOtherDesc: "",
        HistoryPreSurgery: null,
        Midlevel: [],
        PatientSourceID: null,
        ReviewCategoryID: null,
        ReviewTypeID: null,
        ARCHIRecordID: "",
        PatientAge: "",
        AgeIncrementID: null,
        PatientGender: null,
        PatientRaceID: null,
        PatientSourceOther: "",
        ArrivalDate: null,
        DischargeDate: null,
        ReviewReason: [],
        ReferenceMaterial: [],
        RecordDiagnosis: [],
        OtherReason: "",
        ArrivalTime: "",
        DischargeTime: "",
        RecordComment: [],
        ApprovalStatus: "",
        Signature: null,
      };
    case "Filter":
      return {
        ModalVisible: false,
        StartDate: sub(new Date(), { days: 30 }),
        EndDate: new Date(),
        FacilityID: null,
        ApprovalStatusID: 3,
        RecordStatusID: 0,
      };
    case "RecordPDF":
      return {
        RecordDate: "",
        FacilityName: "",
        FacilityContactName: "",
        FacilityMedicalRecordNumber: "",
        Provider: "",
        Midlevel: "",
        AgeOfPatient: "",
        SexOfPatient: "",
        PatientSource: "",
        DateAndTimeOfArrival: "",
        DateAndTimeOfDischarge: "",
        LengthOfStay: "",
        Committee: "",
        Diagnoses: [],
        ReviewReason: [],
        ReviewReasonList: [],
        ReviewReasonInformation: "",
        ReferenceMaterialList: [],
        ReferenceMaterialInformation: "",
        ReferenceMaterial: "",
        AdditionalInformation: "",
        CommitteeQuestion: "",
      };
    case "RNAbbreviations":
      return {
        Abbreviation_1: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_2: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_3: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_4: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_5: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_6: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_7: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_8: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_9: { Switch: false },
        Abbreviation_10: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_11: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_12: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_13: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_14: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_15: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_16: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_17: { Provider: "0", Nurse: "0", Other: "0" },
        Abbreviation_18: { Switch: false },
      };
    case "RNChecklist":
      return [
        { Name: "surgicalTimeout", OptionValue: "Pending" },
        { Name: "shaved", OptionValue: "Pending" },
        { Name: "shavedComment", OptionValue: "" },
        { Name: "clipped", OptionValue: "Pending" },
        { Name: "clippedComment", OptionValue: "" },
        { Name: "medicalReconciliation", OptionValue: "Pending" },
        { Name: "dischargeTransferDocs", OptionValue: "Pending" },
        { Name: "swingBed", OptionValue: "false" },
        { Name: "ethicsIssue", OptionValue: "Pending" },
        { Name: "otherSafetyIssues", OptionValue: "Pending" },
        { Name: "othMedCommunication", OptionValue: "false" },
        { Name: "othMedTreatmentRendered", OptionValue: "false" },
        { Name: "othMedDischargePlanning", OptionValue: "false" },
        { Name: "othPsyCommunication", OptionValue: "false" },
        { Name: "othPsyTreatmentRendered", OptionValue: "false" },
        { Name: "othPsyDischarge", OptionValue: "false" },
        { Name: "otherSafetyIssueOther", OptionValue: "false" },
        { Name: "othSafIssOthAdditionalDetails", OptionValue: "" },
        { Name: "medicationError", OptionValue: "Pending" },
        { Name: "handoffIssue", OptionValue: "Pending" },
        { Name: "disagreementWithinFacility", OptionValue: "Pending" },
        { Name: "legibilityIssues", OptionValue: "Pending" },
        { Name: "legibilityIssuesAdditionalDetails", OptionValue: "" },
        { Name: "electronicHealthRecord", OptionValue: "Pending" },
        { Name: "standardizedIssues", OptionValue: "Pending" },
        { Name: "documentationIncongruity", OptionValue: "Pending" },
        { Name: "phyOppImprovement", OptionValue: "Pending" },
        { Name: "performanceDeficit", OptionValue: "Pending" },
        { Name: "prematureClosureAnchoring", OptionValue: "Pending" },
        { Name: "authorityGradientIssues", OptionValue: "Pending" },
        { Name: "phyOppoNeedEnhancedDocumentation", OptionValue: "Pending" },
        { Name: "facOpportunityImprovement", OptionValue: "Pending" },
        { Name: "relatedTraining", OptionValue: "Pending" },
        { Name: "relatedCommunication", OptionValue: "Pending" },
        { Name: "facOppNeedEnhancedDocumentation", OptionValue: "Pending" },
        { Name: "rulesApplicable", OptionValue: "Pending" },
        { Name: "policiesSubmitted", OptionValue: "Pending" },
        { Name: "documentationPolicies", OptionValue: "Pending" },
        { Name: "treatmentPolicies", OptionValue: "Pending" },
        { Name: "transferPolicies", OptionValue: "Pending" },
        { Name: "otherPolicies", OptionValue: "Pending" },
        { Name: "additionalBarriersToCareIdentified", OptionValue: "Pending" },
        { Name: "lackOfFacilityResources", OptionValue: "Pending" },
        { Name: "lackOfSocialSupport", OptionValue: "Pending" },
        { Name: "addBarrToCareIdOther", OptionValue: "" },
        { Name: "systemIssues", OptionValue: "Pending" },
        { Name: "lackOfMedicationAvailable", OptionValue: "Pending" },
        { Name: "equipmentFailure", OptionValue: "Pending" },
        { Name: "resultingDelayForTreatment", OptionValue: "Pending" },
        { Name: "delayTransfer", OptionValue: "Pending" },
        { Name: "securingTransferAvailable", OptionValue: "Pending" },
        { Name: "transportationDelayWeather", OptionValue: "Pending" },
        { Name: "delayDecisionTransfer", OptionValue: "Pending" },
        { Name: "provider", OptionValue: "Pending" },
        { Name: "patientOrFamily", OptionValue: "Pending" },
        { Name: "reasonForDelayDocumented", OptionValue: "Pending" },
        { Name: "systemIssuesOther", OptionValue: "" },
        { Name: "sentinelEvent", OptionValue: "" },
        { Name: "dischargeInstructionsAbbreviations", OptionValue: "Pending" },
        {
          Name: "dischargeInstructionsMedicalTerminology",
          OptionValue: "Pending",
        },
        { Name: "teachBacksNoted", OptionValue: "Pending" },
        { Name: "patientOutcome", OptionValue: "Pending" },
        { Name: "patientDisposition", OptionValue: "Pending" },
        { Name: "patientDispositionLocation", OptionValue: "Pending" },
        { Name: "patDisLocAdditionalDetails", OptionValue: "" },
        { Name: "rootCauseAnalysisRecommended", OptionValue: "false" },
        {
          Name: "difficultyToObtainMentalHealthServices",
          OptionValue: "false",
        },
        { Name: "handOff", OptionValue: "false" },
        { Name: "authorityGradient", OptionValue: "false" },
        { Name: "prematureClosure", OptionValue: "false" },
        { Name: "trendSystemIssues", OptionValue: "false" },
        { Name: "documentationIssues", OptionValue: "false" },
        { Name: "traumaInterventions", OptionValue: "false" },
        { Name: "traumaMedications", OptionValue: "false" },
        { Name: "traumaDiagnostics", OptionValue: "false" },
        { Name: "traumaDisposition", OptionValue: "false" },
        { Name: "antibioticTherapySelection", OptionValue: "false" },
        { Name: "antibioticTherapyDosage", OptionValue: "false" },
        { Name: "analgesicTherapySelection", OptionValue: "false" },
        { Name: "analgesicTherapyDosage", OptionValue: "false" },
        { Name: "analgesicTherapyLack", OptionValue: "false" },
        { Name: "electrocardiogramIncorrect", OptionValue: "false" },
        { Name: "electrocardiogramInadequate", OptionValue: "false" },
        { Name: "noTrendIdentified", OptionValue: "false" },
        { Name: "othMedContinuityOfCare", OptionValue: "false" },
      ];
    case "RNComments":
      return {
        PeerReviewNotes: "",
        PeerReviewComments: "",
        ProviderRecommendations: "",
        ProviderRecommendationsSwitch: false,
        PhysicianLegibility: `The provider is encouraged to dictate or print when possible in order to ensure accurate communication between all members of the health care team. Concern was voiced that poor legibility may result in a poor "hand off" if/when this patient's care is assumed by another, and thus adversely affect patient care.`,
        PhysicianLegibilityCheckbox: false,
        FacilityRecommendations: "",
        FacilityRecommendationsSwitch: false,
        NurseLegibility: `The facility may wish to reinforce with nursing staff the importance of legibility in the medical record.  Concern was voiced that poor legibility may result in a poor "hand off" if/when this patient's care is assumed by another, and thus adversely affect patient care.`,
        NurseLegibilityCheckbox: false,
        MedicalAbbreviations: `To ensure that the content of written materials is understandable, discharge instructions must include words that are well known to the patient. Technical words and abbreviations should be avoided and the definition of medical words should be provided when they are essential.`,
        MedicalAbbreviationsCheckbox: false,
        MedicalTerminology: `You should always seek to use plain, nonmedical language when speaking to patients. Words that clinician use in their day-to-day conversations with colleagues may be unfamiliar to the majority of non-medically trained persons. A good approach to adopt is to explain things to patients in language called "living room" language, or conversational language.  Conversational language creates opportunities for dialogue between the clinician and patient, rather than limiting communication to a monologue by the physician. Discharge instructions are frequently written beyond the comprehension of patients. Therefore, discharge instructions should use plain language at the fifth-grade level or lower.`,
        MedicalTerminologyCheckbox: false,
        TeachBacks: `The teach-back technique is an effective method for ensuring that patients understand what you have told them. The teach-back technique should replace the more common practice of simply asking a patient, "Do you understand what I have told you?"  Experience shows that patients often answer "yes" to such questions, even when they understand nothing. Research indicates that the teach-back technique is effective, not just for improving patients' understanding, but also for improving outcomes. An example of how to introduce and invite a teach-back would be a statement such as, "I have provided you with a lot of information today: can you please tell me in your own words what I have taught you?"`,
        TeachBacksCheckbox: false,
        LiteratureReview: "",
        LiteratureReviewSwitch: false,
        DirectReviewComments: "",
        Commendations: "",
      };
    case "RNOutcomes":
      return {
        MeetingID: null,
        Improvements: 0,
        OutcomeID: null,
        OtherImprovement: "",
      };
    case "Worksheet": {
      return {
        PageRangePartA: "",
        PageRangePartB: "",
        EmergencyRoomRecord: "",
        HistoryAndPhysical: "",
        OperativeReport: "",
        Anesthesia: "",
        ProgressNotes: "",
        DischargeSummary: "",
        LaboratoryStudies: "",
        Imaging: "",
        EKG: "",
        Strips: "",
        DCInstructions: "",
        NursingNoteSummary: "",
        MAR: "",
        PhysicianOrders: "",
        TotalPages: "",
        Active: 1,
      };
    }
    case "User": {
      return {
        UserName: "",
        EmailAddress: "",
        FirstName: "",
        LastName: "",
        AreaCode: "",
        PhoneOffice: "",
        Extension: "",
        PermissionID: "",
        Active: 1,
      };
    }
    case "Approval": {
      return {
        ReviewType: "",
        RecordID: "",
        EstReviewMonth: "",
        Comments: "",
      };
    }
    case "RNBlindings": {
      return [];
    }
    case "RNSignature": {
      return null;
    }

    default:
      return {};
  }
};

export const formatFilterDates = (params) => {
  return {
    StartDate: new Date(params.StartDate),
    EndDate: new Date(params.EndDate),
    FacilityID: params.FacilityID,
    ApprovalStatusID: params.ApprovalStatusID,
    RecordStatusID: params.RecordStatusID,
  };
};

export const formatName = (
  { FirstName, LastName, ARCHIProviderID, Degree } = {},
  template = "default"
) => {
  const templates = {
    default: `${FirstName}, ${LastName} (${Degree}) - ${ARCHIProviderID}`,
    dropdown: `${LastName}, ${FirstName} (${ARCHIProviderID})`,
    rnHeader: `${LastName}, ${FirstName} (${Degree})`,
  };
  return templates[template];
};

export const clone = (obj) => {
  let copy;
  if (null == obj || "object" != typeof obj) return obj;
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }
  if (obj instanceof Object) {
    copy = {};
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
    }
    return copy;
  }
  throw new Error("Unable to copy obj! Its type isn't supported.");
};
