import { Button } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { ProviderActions } from "src/redux/reducers/PersonnelManagement/Provider";
import { selectProvidersByFacilityID } from "src/redux/selectors";
import CreateAndLinkProviderDialog from "./CreateAndLinkProviderDialog";
import LinkFacilityToProviderDialog from "./LinkFacilityToProviderDialog";
import Typography from "@mui/material/Typography";

const columns = [
  {
    name: "archiProviderID",
    label: "ARCHI Provider ID",
  },
  {
    name: "firstName",
    label: "First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
  },
  {
    name: "emailAddress",
    label: "E-Mail Address",
  },
  {
    name: "degree",
    label: "Degree",
  },
  {
    name: "active",
    label: "Active",
  },
  {
    name: "actions",
    label: "Actions",
    options: {
      filter: false,
      sort: false,
      download: false,
      print: false,
    },
  },
];

const options = {
  filterType: "multiselect",
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
};

const ProvidersTable = (props) => {
  const [createProviderDialogVisible, setCreateProviderDialogVisible] =
    React.useState(false);
  const [
    linkFacilityToProviderDialogVisible,
    setLinkFacilityToProviderDialogVisible,
  ] = React.useState(false);
  const Providers = useSelector(selectProvidersByFacilityID(props.facilityID));
  const Loaded = useSelector((state) => state.Loaded);

  useEffectOnce(() => {
    Loaded.Provider === false && props.dispatch(ProviderActions.BatchAdd());
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
  });

  const data = Providers.map((provider, i) => {
    return {
      archiProviderID: provider.ARCHIProviderID,
      firstName: provider.FirstName,
      lastName: provider.LastName,
      emailAddress: provider.EmailAddress,
      degree: provider.Degree,
      active: provider.Active ? "Active" : "Inactive",
      actions: (
        <Button
          variant={"contained"}
          color={"secondary"}
          size={"small"}
          onClick={() => unlinkFromFacility(provider.ID)}
        >
          Unlink
        </Button>
      ),
    };
  });

  const unlinkFromFacility = (ID) => {
    props.dispatch(
      ProviderActions.RemoveFromFacility({
        FacilityID: props.facilityID,
        ProviderID: ID,
      })
    );
  };

  return (
    <>
      {createProviderDialogVisible && (
        <CreateAndLinkProviderDialog
          {...props}
          dialogVisible={createProviderDialogVisible}
          setDialogVisible={() => {
            setCreateProviderDialogVisible(false);
          }}
        />
      )}
      {linkFacilityToProviderDialogVisible && (
        <LinkFacilityToProviderDialog
          {...props}
          dialogVisible={linkFacilityToProviderDialogVisible}
          setDialogVisible={() => {
            setLinkFacilityToProviderDialogVisible(false);
          }}
        />
      )}
      <div className={"row pt-4"}>
        <div className={"col d-flex"}>
          <Typography variant={"h4"}>Providers</Typography>
        </div>
      </div>
      <div className={"row pt-1"}>
        <div className={"col d-flex"}>
          <div>
            <Button
              size={"small"}
              variant={"outlined"}
              color={"primary"}
              onClick={() => setCreateProviderDialogVisible(true)}
            >
              New Provider
            </Button>
          </div>
          <div className={"pl-2"}>
            <Button
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              onClick={() => setLinkFacilityToProviderDialogVisible(true)}
            >
              Link to Facility
            </Button>
          </div>
        </div>
      </div>
      <div className={"row pt-2"}>
        <div className={"col"}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </div>
      </div>
    </>
  );
};

export default ProvidersTable;
