import { createSelector } from "reselect";
import { emptyObject } from "src/utilities/objectUtilities";
import { selectContent } from "./contentSelectors";
import { findById } from "./helperFunctions";
import { selectRecordByID } from "src/redux/selectors/index";
const selectRecords = selectContent("Record");

const selectCommentByRecordID = (ID) =>
  createSelector(selectRecords, (records) => {
    const record = findById(records, ID);

    return record.RNComments && record.RNComments[0]
      ? record.RNComments[0]
      : emptyObject("RNComments");
  });

const selectRNWorksheetByRecordID = (ID) =>
  createSelector(selectRecordByID(ID), (record) =>
    record.Worksheet ? record.Worksheet : emptyObject("Worksheet")
  );

const selectRNChecklistByRecordID = (RecordID) =>
  createSelector(
    selectRecordByID(RecordID),
    selectContent("ChecklistQuestion"),
    ({ Checklist }, questions) => {
      if (Checklist !== undefined && questions !== undefined) {
        const checklistData = [];
        questions.forEach(({ id, name, label }, index) => {
          const checklistElem = Checklist.find(
            ({ OptionID }) => OptionID === id
          );
          const value =
            Checklist.length && questions.length && checklistElem
              ? checklistElem.OptionValue
              : emptyObject("RNChecklist").find(({ Name }) => Name === name)
                  .OptionValue;
          checklistData[index] = {
            Name: name,
            Value: value,
            OptionID: id,
            Label: label,
          };
        });
        return checklistData;
      } else {
        return [];
      }
    }
  );

const selectRNOutcomesByRecordID = (ID) =>
  createSelector(selectRecords, (records) => {
    const record = findById(records, ID);
    let data = {
      MeetingID: record.MeetingID,
      MRTSReviewTypeID: record.MRTSReviewTypeID,
    };
    data = record.RNOutcomes
      ? { ...data, ...record.RNOutcomes, RNOutcomesID: record.RNOutcomes.ID }
      : { ...emptyObject("RNOutcomes"), ...data };
    return data;
  });

const selectSignatureByRecordID = (ID) =>
  createSelector(selectRecords, (records) => findById(records, ID).SignatureID);

export {
  selectRNChecklistByRecordID,
  selectCommentByRecordID,
  selectRNWorksheetByRecordID,
  selectRNOutcomesByRecordID,
  selectSignatureByRecordID,
};
