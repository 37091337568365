import { combineReducers } from "redux";
import Abbreviation from "src/redux/reducers/Constants/Abbreviation";
import AgeIncrement from "src/redux/reducers/Constants/AgeIncrement";
import BlindingOptions from "./Constants/BlindingOptions";
import ChecklistQuestion from "src/redux/reducers/Constants/ChecklistQuestion";
import CommentType from "src/redux/reducers/Constants/CommentType";
import Committee from "src/redux/reducers/Constants/Committee";
import Fetching from "src/redux/reducers/Fetching";
import LastCreatedObject from "src/redux/reducers/LastCreatedObject";
import Loaded from "src/redux/reducers/Loaded";
import Meeting from "src/redux/reducers/Meeting";
import Notification from "src/redux/reducers/Notification";
import Outcome from "src/redux/reducers/Constants/Outcome";
import PatientGender from "src/redux/reducers/Constants/PatientGender";
import PatientRace from "src/redux/reducers/Constants/PatientRace";
import PatientSource from "src/redux/reducers/Constants/PatientSource";
import Permission from "src/redux/reducers/Constants/Permission";
import PersonnelManagement from "src/redux/reducers/PersonnelManagement";
import Record from "src/redux/reducers/Record";
import Report from "src/redux/reducers/Report";
import Review from "src/redux/reducers/Review";
import RecordStatus from "src/redux/reducers/Constants/RecordStatus";
import RecordTracking from "src/redux/reducers/RecordTracking";
import ReferenceMaterial from "src/redux/reducers/Constants/ReferenceMaterial";
import ReviewCategory from "src/redux/reducers/Constants/ReviewCategory";
import ReviewReason from "src/redux/reducers/Constants/ReviewReason";
import ReviewType from "src/redux/reducers/Constants/ReviewType";
import Signature from "src/redux/reducers/Constants/Signature";
import RNTools from "src/redux/reducers/RNTools";
import ReportTemplates from "src/redux/reducers/ReportTemplates";

const { RNWorksheet, Redirect } = RNTools;

const { Contact, Facility, Midlevel, Provider, User } = PersonnelManagement;

export default combineReducers({
  Abbreviation,
  AgeIncrement,
  BlindingOptions,
  ChecklistQuestion,
  CommentType,
  Committee,
  Contact,
  Facility,
  Fetching,
  LastCreatedObject,
  Loaded,
  Meeting,
  Midlevel,
  Notification,
  Outcome,
  PatientGender,
  PatientRace,
  PatientSource,
  Permission,
  Provider,
  Record,
  Report,
  Review,
  RecordStatus,
  RecordTracking,
  ReferenceMaterial,
  ReportTemplates,
  ReviewCategory,
  ReviewReason,
  ReviewType,
  Signature,
  RNWorksheet,
  Redirect,
  User,
});
