import React from "react";
import { Select } from "mui-rff";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

const Dropdown = ({ name, label, render: { options = [] } }) => {
  const classes = useStyles();

  return (
    <Section>
      <Select
        name={name}
        label={label}
        data={options}
        variant={"outlined"}
        className={classes.textLeft}
      />
    </Section>
  );
};

export const Section = ({ children }) => (
  <Box className={"row"} style={{ marginTop: 25, marginBottom: 25 }}>
    <Box className={"col"}>{children}</Box>
  </Box>
);

const useStyles = makeStyles(() => ({
  textLeft: {
    textAlign: "left",
  },
  box: {
    bgcolor: "background.paper",
    borderColor: "text.primary",
    m: 1,
    border: 1,
    style: { width: "5rem", height: "2rem" },
  },
  input: {
    color: "red",
  },
}));

export default Dropdown;
