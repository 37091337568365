import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-final-form";
import {
  selectRecordByID,
  selectRNChecklistByRecordID,
} from "src/redux/selectors";
import { RecordActions } from "src/redux/reducers/Record";
import { emptyObject } from "src/utilities/objectUtilities";
import createStructure from "src/components/templateStructures/checklistStructure";
import hydrateStructure from "src/components/templateStructures/checklistStructure/hydrateStructure";
import RecursiveComponent from "./RecursiveComponents";
import { FinalizeButtons } from "../Shared";
import Submitter from "../Submitter";

const Checklist = (props) => {
  const record = useSelector(selectRecordByID(props.record.ID));
  const recordChecklist = useSelector(
    selectRNChecklistByRecordID(props.record.ID)
  );
  const checklistStructure = useMemo(
    () =>
      recordChecklist.length
        ? hydrateStructure(
            createStructure(props.record.CommitteeID),
            recordChecklist
          )
        : [],
    [recordChecklist]
  );
  useMemo(() => {
    initialValues = {};
    recordChecklist.forEach(({ Name, Value }) => (initialValues[Name] = Value));
  }, [recordChecklist]);

  const onSubmit = (result) => {
    save(result);
    window.scrollTo(0, 0);
  };

  const save = (result) => {
    const baseSubmission = {};
    emptyObject("RNChecklist").forEach(({ Name, OptionValue }) => {
      baseSubmission[Name] = OptionValue;
    });
    const submitMethod = record.Checklist.length
      ? "UpdateChecklist"
      : "CreateChecklist";
    const submitContent = {
      RecordID: props.record.ID,
      RadioValues: Object.values({ ...baseSubmission, ...result }),
    };
    props.dispatch(RecordActions[submitMethod](submitContent));
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      subscription={{ values: true, dirty: true }}
    >
      {({ handleSubmit, values, dirty }) => {
        if (props.isFormDirty !== dirty) {
          props.setIsFormDirty(dirty);
        }
        return (
          <form onSubmit={handleSubmit}>
            {checklistStructure.length && (
              <RecursiveComponent items={checklistStructure} />
            )}
            <FinalizeButtons />
            <Submitter values={values} onSubmit={save} />
          </form>
        );
      }}
    </Form>
  );
};

let initialValues = {};

export default Checklist;
