import { createSelector } from "reselect";
import { selectContent } from "./contentSelectors";
import { findById } from "./helperFunctions";

const selectMeetings = selectContent("Meeting");
const selectReviews = selectContent("Review");

const selectMeetingByID = (ID) =>
  createSelector(selectMeetings, (meetings) => findById(meetings, ID));

const selectMeetingsByPermission = ({ PermissionLevel, UserID }) =>
  createSelector(selectMeetings, selectReviews, (meetings, reviews) => {
    if (PermissionLevel !== "Facilitator") {
      return meetings;
    }
    const meetingIds = reviews
      .filter((review) => review.FacilitatorID === UserID)
      .map((review) => review.MeetingID);
    return meetings.filter((meeting) => meetingIds.includes(meeting.ID));
  });

const selectMeetingsByMRTSReviewType = (MRTSReviewTypeID = 0) => {
  // MRTSReviewTypeID 1 => Committee Review
  // MRTSReviewTypeID 2 => Direct Review
  const MRTSReviewTypes = {
    0: { reviewType: () => false },
    1: { reviewType: (meeting) => !meeting.MeetingName.includes("Group") },
    2: { reviewType: (meeting) => meeting.MeetingName.includes("Group") },
  };
  return createSelector(selectMeetings, (meetings) =>
    meetings.filter((meeting) =>
      MRTSReviewTypes[MRTSReviewTypeID].reviewType(meeting)
    )
  );
};

export {
  selectMeetings,
  selectMeetingByID,
  selectMeetingsByMRTSReviewType,
  selectMeetingsByPermission,
};
