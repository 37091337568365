import "./App.css";
import React, { useState, useMemo, useEffect, createContext } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes, useLocation, Outlet } from "react-router-dom";
import { emptyObject, formatFilterDates } from "src/utilities/objectUtilities";
import FacilityLinks from "src/components/pages/PersonnelManagement/Facility/FacilityLinks";
import Login from "src/components/pages/Login";
import RecordReview from "src/components/pages/MeetingManagement/RecordReview";
import MeetingManagement from "src/components/pages/MeetingManagement/Dashboard";
import { store } from "src";
import {
  canAccessPersonnel,
  canAccessReports,
  facilitator,
} from "src/utilities/rbacFunctions";
import useAuthHook from "src/utilities/useAuthHook";
import CustomSidebar from "src/components/individual/CustomSidebar";
import Reports from "./pages/Reports";
import Contact from "./pages/PersonnelManagement/Contact";
import Provider from "./pages/PersonnelManagement/Provider";
import Midlevel from "./pages/PersonnelManagement/Midlevel";
import User from "./pages/PersonnelManagement/User";
import { ConstantActions } from "src/redux/reducers/Constants";
import { FetchingActions } from "src/redux/reducers/Fetching";
import ReviewsDashboard from "./pages/MeetingManagement/ReviewsDashboard";
import MRTSRecords from "./pages/MRTSRecords";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { NotificationActions } from "src/redux/reducers/Notification";
import RNToolsSearch from "src/components/pages/RNTools/Search";
import RNToolsEdit from "src/components/pages/RNTools/Edit";
import FacilityDashboard from "src/components/pages/PersonnelManagement/Facility/Dashboard";

export const globalVariables = createContext(null);

const idleTimeoutMinutes = process.env.REACT_APP_IDLE_TIMEOUT_MIN || 20;
const promptBeforeIdleMinutes = process.env.REACT_APP_PROMPT_BEFORE_IDLE_MIN || 2;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const App = (props) => {
  const timeout = 1000 * 60 * idleTimeoutMinutes;
  const promptTimeout = 1000 * 60 * promptBeforeIdleMinutes;
  // Time before idle
  const [remaining, setRemaining] = React.useState(0);
  // Idle modal open state
  const [idleDialogVisible, setIdleDialogVisible] = React.useState(false);
  const { isAuthenticated, roles, authenticate, logout, data } = useAuthHook();
  const Fetching = useSelector((state) => state.Fetching);
  const Notification = useSelector((state) => state.Notification);
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [filter, setFilter] = useState(
    localStorage.getItem("filter") !== null
      ? formatFilterDates(JSON.parse(localStorage.getItem("filter")))
      : emptyObject("Filter")
  );
  const location = useLocation().pathname.substr(1);
  const oauthLocation = useLocation();

  const toggleModalVisible = () => {
    setModalVisible(!modalVisible);
  };

  useMemo(async () => {
    const queryParams = new URLSearchParams(oauthLocation.search);
    const code = queryParams.get("code");
    await authenticate(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oauthLocation.search,
    oauthLocation.pathname,
    localStorage.getItem("token"),
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(ConstantActions.BatchAdd());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (Fetching.Error !== null) {
      if (Fetching.Error) {
        dispatch(
          NotificationActions.Open({
            Message: `${Fetching.Message} failed.`,
            Severity: "error",
          })
        );
      } else if (Fetching.Warning) {
        dispatch(
          NotificationActions.Open({
            Message: Fetching.Message,
            Severity: "warning",
          })
        );
      } else {
        dispatch(
          NotificationActions.Open({
            Message: `${Fetching.Message} was successful.`,
            Severity: "success",
          })
        );
      }
      dispatch(FetchingActions.Reset());
    }
  }, [Fetching]);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path:
        oauthLocation.pathname + oauthLocation.search + oauthLocation.hash,
      page_search: oauthLocation.search,
      page_hash: oauthLocation.hash,
    });
  }, [oauthLocation]);

  const authenticatedDispatch = async (action) => {
    await authenticate();
    store.dispatch(action);
  };

  const onPrompt = () => {
    if (isAuthenticated) {
      // Fire a Modal Prompt
      setIdleDialogVisible(true);
      setRemaining(promptTimeout);
    }
  };

  const onIdle = () => {
    // Close Modal Prompt
    setIdleDialogVisible(false);
    setRemaining(0);
    // Do some idle action like log out your user
    if (isAuthenticated) {
      logout();
    }
  };

  const onActive = (event) => {
    // Close Modal Prompt
    setIdleDialogVisible(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    crossTab: true,
    timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
  });

  const handleStillHere = () => {
    setIdleDialogVisible(false);
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <globalVariables.Provider
      value={{
        filter,
        setFilter,
        location,
        roles,
        dispatch,
        authenticate,
        authenticatedDispatch,
        data,
      }}
    >
      <Snackbar
        key={Notification.Message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Notification.Open}
        autoHideDuration={Number(Notification.Time)}
        onClose={() => {
          dispatch(NotificationActions.SetOpen(false));
        }}
      >
        <Alert
          onClose={() => {
            dispatch(NotificationActions.SetOpen(false));
          }}
          severity={Notification.Severity}
        >
          {Notification.Message}
        </Alert>
      </Snackbar>
      {isAuthenticated ? (
        <div className="App">
          <Dialog
            fullWidth
            maxWidth={"sm"}
            open={idleDialogVisible}
            onClose={async () => {
              await authenticate();
              handleStillHere();
            }}
          >
            <DialogTitle>Idle Timeout Detected</DialogTitle>
            <DialogContent className={"py-2"}>
              <p>Logging you out in {remaining} seconds</p>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={async () => {
                  await authenticate();
                  handleStillHere();
                }}
                color={"primary"}
                variant={"contained"}
              >
                Im Still Here
              </Button>
            </DialogActions>
          </Dialog>
          {location !== "Login" && (
            <CustomSidebar
              {...props}
              roles={roles}
              userData={data}
              dispatch={authenticatedDispatch}
              tokenData={data}
              location={location}
            />
          )}
          <div
            className="container-fluid App-content"
            style={{ width: "80vw" }}
          >
            <div className={"row"}>
              <div className={"col"}>
                {facilitator(roles) ? (
                  <Routes>
                    <Route path={"MeetingManagement"} element={<Outlet />}>
                      <Route
                        index
                        element={
                          <MeetingManagement
                            dispatch={authenticatedDispatch}
                            roles={roles}
                            {...props}
                          />
                        }
                      />
                      <Route
                        path={":meetingID"}
                        element={
                          <ReviewsDashboard
                            dispatch={authenticatedDispatch}
                            roles={roles}
                            {...props}
                          />
                        }
                      />
                    </Route>
                    <Route path={"RecordReview"} element={<Outlet />}>
                      <Route
                        path={":recordID"}
                        element={
                          <RecordReview
                            {...props}
                            roles={roles}
                            dispatch={authenticatedDispatch}
                          />
                        }
                      />
                    </Route>
                  </Routes>
                ) : (
                  <Routes>
                    <Route path="Logout">{logout}</Route>
                    <Route
                      path="MRTSRecords"
                      element={
                        <MRTSRecords
                          dispatch={authenticatedDispatch}
                          roles={roles}
                          authenticate={authenticate}
                          userData={data}
                        />
                      }
                    />
                    <Route path={"RNTools"} element={<Outlet />}>
                      <Route
                        index
                        element={
                          <RNToolsSearch
                            {...props}
                            roles={roles}
                            dispatch={authenticatedDispatch}
                            location={oauthLocation}
                          />
                        }
                      />
                      <Route
                        path={":recordID"}
                        element={
                          <RNToolsEdit
                            {...props}
                            modalVisible={modalVisible}
                            toggleModalVisible={toggleModalVisible}
                            setModalVisible={setModalVisible}
                            roles={roles}
                            dispatch={authenticatedDispatch}
                            location={oauthLocation}
                          />
                        }
                      />
                    </Route>
                    <Route path={"MeetingManagement"} element={<Outlet />}>
                      <Route
                        index
                        element={
                          <MeetingManagement
                            dispatch={authenticatedDispatch}
                            roles={roles}
                            {...props}
                          />
                        }
                      />
                      <Route
                        path={":meetingID"}
                        element={
                          <ReviewsDashboard
                            dispatch={authenticatedDispatch}
                            roles={roles}
                            {...props}
                          />
                        }
                      />
                    </Route>
                    <Route path={"RecordReview"} element={<Outlet />}>
                      <Route
                        path={":recordID"}
                        element={
                          <RecordReview
                            {...props}
                            roles={roles}
                            dispatch={authenticatedDispatch}
                          />
                        }
                      />
                    </Route>
                    {canAccessReports(roles) && (
                      <Route
                        path={"Reports"}
                        element={
                          <Reports
                            {...props}
                            roles={roles}
                            dispatch={authenticatedDispatch}
                            FacilityName={data.current.FacilityName}
                          />
                        }
                      />
                    )}
                    {canAccessPersonnel(roles) && (
                      <>
                        <Route path={"Facility"} element={<Outlet />}>
                          <Route
                            index
                            element={
                              <FacilityDashboard
                                {...props}
                                dispatch={authenticatedDispatch}
                                roles={roles}
                              />
                            }
                          />
                          <Route
                            path={":facilityID"}
                            element={
                              <FacilityLinks
                                {...props}
                                modalVisible={modalVisible}
                                toggleModalVisible={toggleModalVisible}
                                dispatch={authenticatedDispatch}
                              />
                            }
                          />
                        </Route>
                        <Route
                          path={"Contact"}
                          element={
                            <Contact
                              {...props}
                              dispatch={authenticatedDispatch}
                              roles={roles}
                            />
                          }
                        />
                        <Route
                          path={"Provider"}
                          element={
                            <Provider
                              {...props}
                              dispatch={authenticatedDispatch}
                              roles={roles}
                            />
                          }
                        />
                        <Route
                          path={"Midlevel"}
                          element={
                            <Midlevel
                              {...props}
                              dispatch={authenticatedDispatch}
                              roles={roles}
                            />
                          }
                        />
                        <Route
                          path={"User"}
                          element={
                            <User
                              {...props}
                              dispatch={authenticatedDispatch}
                              roles={roles}
                            />
                          }
                        />
                      </>
                    )}
                  </Routes>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Login />
      )}
    </globalVariables.Provider>
  );
};

export default App;
