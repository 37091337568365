const healthLiteracyReview = (radiology) => ({
  label: "Health Literacy Review",
  render: { type: "Title", hide: radiology },
  items: [
    dischargeInstructionsAbbreviations(),
    dischargeInstructionsMedicalTerminology(),
    teachBacksNoted(),
  ],
});

const dischargeInstructionsAbbreviations = () => ({
  name: "dischargeInstructionsAbbreviations",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
});

const dischargeInstructionsMedicalTerminology = () => ({
  name: "dischargeInstructionsMedicalTerminology",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
});

const teachBacksNoted = () => ({
  name: "teachBacksNoted",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
});

export default healthLiteracyReview;
