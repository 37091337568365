import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { LastCreatedObjectActions } from "src/redux/reducers/LastCreatedObject";
import { LoadedActions } from "src/redux/reducers/Loaded";
import { ReviewActions } from "src/redux/reducers/Review";

const meetingSlice = createSlice({
  name: "meeting",
  initialState: [],
  reducers: {
    add: (state, action) => {
      const newState = _.cloneDeep(state);
      const newMeeting = action.payload;
      newMeeting.TotalRecords = 0;
      newState.unshift(newMeeting);
      return _.orderBy(newState, ["MeetingDate"], "desc");
    },
    update: (state, action) => {
      const newState = state.filter((m) => m.ID !== action.payload.ID);
      newState.push({ ...action.payload });
      return _.orderBy(newState, ["MeetingDate"], "desc");
    },
    addFacilitator: (state, action) => {
      const targetMeeting = _.cloneDeep(
        state.find((m) => m.ID === action.payload.MeetingID)
      );
      const newState = state.filter((m) => m.ID !== action.payload.MeetingID);
      targetMeeting.FacilitatorID = action.payload.FacilitatorID;
      newState.push(targetMeeting);
      return _.orderBy(newState, ["MeetingDate"], "desc");
    },
    updateCredits: (state, action) => {
      let targetMeeting = state.find((m) => m.ID === action.payload.ID);
      const newState = state.filter((m) => m.ID !== action.payload.ID);
      newState.push({ ...targetMeeting, ...action.payload });
      return _.orderBy(newState, ["MeetingDate"], "desc");
    },
    batchAdd: (state, action) => {
      const newState = [...state, ...action.payload];
      return _.orderBy(newState, ["MeetingDate"], "desc");
    },
    batchReset: (state, action) => {
      return _.orderBy(action.payload, ["MeetingDate"], "desc");
    },
    remove: (state, action) => {
      const newState = state.filter((m) => m.ID !== action.payload.ID);
      return _.orderBy(newState, ["MeetingDate"], "desc");
    },
  },
});

const {
  add,
  update,
  addFacilitator,
  updateCredits,
  batchAdd,
  batchReset,
  remove,
} = meetingSlice.actions;

const Add = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}meeting/create`,
      content
    );
    dispatch(add(contents));
    dispatch(LastCreatedObjectActions.Set("Meeting", contents));
    dispatch(FetchingActions.Success("Creating Meeting"));
  } catch (error) {
    // If adding meeting failed, reset the meeting data:
    dispatch(BatchReset());
    dispatch(FetchingActions.Failure("Creating Meeting"));
  }
};

const Update = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}meeting/update`,
      content
    );
    dispatch(update(contents));
    dispatch(FetchingActions.Success("Updating Meeting"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Meeting"));
  }
};

const AddFacilitatorToMeeting = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}meeting/updateFacilitator`,
      content
    );
    dispatch(addFacilitator(contents));
    dispatch(FetchingActions.Success("Updating Meeting"));
    dispatch(ReviewActions.BatchReset());
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Meeting"));
  }
};

const UpdateMeetingCMECredits = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}meeting/updateMeetingCredits`,
      content
    );
    dispatch(updateCredits(contents));
    dispatch(FetchingActions.Success("Updating Meeting"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Meeting"));
  }
};

const BatchAdd = () => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(LoadedActions.SetMeeting("pending"));
    const { data: contents } = await apiService.get(`${apiUrl}meeting`);
    dispatch(batchAdd(contents));
    dispatch(LoadedActions.SetMeeting(true));
  } catch (error) {
    dispatch(FetchingActions.Failure("Fetching Meetings"));
  }
};

const BatchAddFromContent = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(batchAdd(content));
    dispatch(LoadedActions.SetMeeting(true));
  } catch (error) {
    dispatch(FetchingActions.Failure("Fetching Meetings"));
  }
};

const BatchReset = () => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(LoadedActions.SetMeeting("pending"));
    const { data: contents } = await apiService.get(`${apiUrl}meeting`);
    dispatch(batchReset(contents));
    dispatch(LoadedActions.SetMeeting(true));
  } catch (error) {
    dispatch(FetchingActions.Failure("Fetching Meetings"));
  }
};

const LoadData = (MeetingID) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(LoadedActions.SetMeeting("pending"));
    const { data: contents } = await apiService.get(
      `${apiUrl}meeting/getInfo`,
      {
        params: {
          MeetingID,
        },
      }
    );
    dispatch(update(contents));
    dispatch(LoadedActions.SetMeeting(true));
  } catch (error) {
    dispatch(FetchingActions.Failure("Fetching Meetings"));
  }
};

export const Remove = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(remove(content));
    dispatch(FetchingActions.Success("Removing Meeting"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Removing Meeting"));
  }
};

export const MeetingActions = {
  Add,
  Update,
  BatchAdd,
  BatchAddFromContent,
  BatchReset,
  LoadData,
  Remove,
  AddFacilitator: AddFacilitatorToMeeting,
  UpdateCredits: UpdateMeetingCMECredits,
};

export default meetingSlice.reducer;
