import React from "react";

const TextBegin = (props) => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>Abbreviation Use</h4>
      </div>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              One of the major causes of medication error is the use of
              potentially dangerous abbreviations and dose expressions. National
              Patient Safety Goal 2,3 "Creating a List of Abbreviations"
              suggests abbreviations that should not be used under any
              circumstance and then encourage facilities to develop a process to
              monitor compliance. In 2011, NPSG 02.02.01 was integrated into the
              Information Management Standards as elements of performance 2 and
              3 under IM 02.02.0. While automation will in part reduce such
              error, there are some cost- efficient and effective strategies
              facilities can adopt. One such strategy is adopting a "non-use"
              policy for items listed on the "Do Not Use" Abbreviation List
              found in the Stedman Medical Word Reference of Stedman's
              Abbreviations, Acronyms and Symbols (Appendix A). To assist RPPR©
              facilities with compliance on abbreviation use, each submitted
              medical record is reviewed for the use of abbreviations on the "Do
              Not Use" List. The specific abbreviation(s) used by a facility are
              listed at the end of each peer review report. These findings are
              inclusive of all healthcare providers documenting in the medical
              record. Below is a compilation of the five most often used
              abbreviations found in peer review medical records submitted in
              selected time period.
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextBegin;
