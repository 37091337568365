import React from "react";
import { NavLink } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import {
  Avatar,
  Icon,
  Drawer as MuiDrawer,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  Menu as MenuIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@mui/icons-material";

import {
  canAccessFacilities,
  canAccessContacts,
  canAccessProviders,
  canAccessMidlevels,
  canAccessUsers,
  facilitator,
  canAccessAdministration,
  canAccessReports,
  canAccessPersonnel,
} from "src/utilities/rbacFunctions";
import { ReportActions } from "src/redux/reducers/Report";

const logoutRoute = process.env.REACT_APP_LOGOUT_ROUTE || "";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const CustomSidebar = (props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [subMenuOpenList, setSubMenuOpenList] = React.useState({
    mrtsSubMenu: false,
    PerManSubMenu: false,
  });

  const themeColor = "#2bbbad";

  const handleSubMenuClick = (subMenuName) => {
    const updatedSubMenu = {
      ...subMenuOpenList,
      [subMenuName]: !subMenuOpenList[subMenuName],
    };
    setSubMenuOpenList(updatedSubMenu);
  };

  return (
    <div className={"noPrint"}>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ backgroundColor: themeColor }}>
          {open ? (
            <div
              className={"text-white"}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: 24,
                fontWeight: 400,
              }}
            >
              <div className={"row mx-auto my-auto"}>
                <div className="col">
                <Avatar
                  sx={{bgcolor: "#FFF",  width: 45, height: 45 }}
                  variant={"rounded"}
                >
                  <Icon
                      baseClassName="fas"
                      className="fa-clinic-medical"
                      sx={{ fontSize: 40, color: themeColor, bgColor: "#FFF" }}
                      style={{ position: "absolute" }}
                    />
                    <Icon
                      baseClassName="fas"
                      className="fa-square"
                      sx={{ fontSize: 25, color: themeColor }}
                      style={{
                        marginLeft: 17,
                        marginTop: 17,
                        position: "absolute",
                      }}
                    />
                    <Icon
                      baseClassName="fas"
                      className="fa-clipboard-list"
                      sx={{ fontSize: 15, color: "#FFF" }}
                      style={{
                        marginRight: 10,
                        marginTop: 20,
                        position: "absolute",
                      }}
                    />
                    <Icon
                      baseClassName="fas"
                      className="fa-user-md"
                      sx={{ fontSize: 23, color: "#FFF" }}
                      style={{
                        marginLeft: 25,
                        marginTop: 16,
                        position: "absolute",
                      }}
                    />
                </Avatar>
                </div>
                <div className="col">
                  
                <Typography variant="h4">PPR</Typography> 
                </div>
              </div>
              <IconButton onClick={() => setOpen(!open)} size="large">
                {theme.direction === "rtl" ? <MenuIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <IconButton onClick={() => setOpen(!open)}>
                {!open ? <MenuIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>
          )}
        </DrawerHeader>
        <Divider />
        <List component="div" disablePadding>
          {facilitator(props.roles) ? (
            <Tooltip title="Meetings" placement={"right"}>
              <ListItem
                button
                component={NavLink}
                to="/MeetingManagement"
                activeclassname="Mui-selected"
                onClick={() => {
                  props.dispatch(ReportActions.Reset());
                }}
              >
                <ListItemIcon
                  sx={{
                    fontSize: 24,
                    color: "#2bbbad",
                  }}
                >
                  <i className="fas fa-calendar-alt" />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    display: "block",
                  }}
                  primary="Meetings"
                />
              </ListItem>
            </Tooltip>
          ) : (
            <>
              {canAccessAdministration(props.roles) && (
                <Tooltip title="RN Tools" placement={"right"}>
                  <ListItem
                    button
                    component={NavLink}
                    to="/RNTools"
                    activeclassname="Mui-selected"
                    onClick={() => {
                      props.dispatch(ReportActions.Reset());
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        fontSize: 24,
                        color: "#2bbbad",
                      }}
                    >
                      <i className="fas fa-notes-medical ml-1" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        display: "block",
                      }}
                      primary="RN Tools"
                    />
                  </ListItem>
                </Tooltip>
              )}
              <Tooltip title="MRTS Records" placement={"right"}>
                <ListItem
                  button
                  component={NavLink}
                  to="/MRTSRecords"
                  activeclassname="Mui-selected"
                  onClick={() => {
                    props.dispatch(ReportActions.Reset());
                  }}
                >
                  <ListItemIcon
                    sx={{
                      fontSize: 24,
                      color: "#2bbbad",
                    }}
                  >
                    <i className="fa fa-file-medical-alt" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      display: "block",
                    }}
                    primary="MRTS Records"
                  />
                </ListItem>
              </Tooltip>
              {canAccessReports(props.roles) && (
                <>
                  <Tooltip title="Reports" placement={"right"}>
                    <ListItem
                      button
                      component={NavLink}
                      to="/Reports"
                      activeclassname="Mui-selected"
                    >
                      <ListItemIcon
                        sx={{
                          fontSize: 24,
                          color: "#2bbbad",
                        }}
                      >
                        <i className="fas fa-file-alt ml-1" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          display: "block",
                        }}
                        primary="Reports"
                      />
                    </ListItem>
                  </Tooltip>
                </>
              )}
              {canAccessAdministration(props.roles) && (
                <>
                  <Tooltip title="Meetings" placement={"right"}>
                    <ListItem
                      button
                      component={NavLink}
                      to="/MeetingManagement"
                      activeclassname="Mui-selected"
                      onClick={() => {
                        props.dispatch(ReportActions.Reset());
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          fontSize: 24,
                          color: "#2bbbad",
                        }}
                      >
                        <i className="fas fa-calendar-alt ml-1" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          display: "block",
                        }}
                        primary="Meetings"
                      />
                    </ListItem>
                  </Tooltip>
                </>
              )}
              {canAccessPersonnel(props.roles) && (
                <>
                  <Tooltip title="Personnel" placement={"right"}>
                    <ListItem
                      button
                      onClick={() => {
                        handleSubMenuClick("PerManSubMenu");
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          fontSize: 24,
                          color: "#2bbbad",
                        }}
                      >
                        <i className="fa fa-id-card" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          display: "block",
                        }}
                        primary="Personnel"
                      />
                      {subMenuOpenList["PerManSubMenu"] ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </ListItem>
                  </Tooltip>
                  <Collapse
                    in={subMenuOpenList["PerManSubMenu"]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {canAccessFacilities(props.roles) && (
                        <Tooltip title="Facilities" placement={"right"}>
                          <ListItem
                            button
                            component={NavLink}
                            sx={{
                              paddingLeft: theme.spacing(4),
                            }}
                            to="/Facility"
                            activeclassname="Mui-selected"
                            onClick={() => {
                              props.dispatch(ReportActions.Reset());
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                fontSize: 24,
                                color: "#2bbbad",
                              }}
                            >
                              <i className="fas fa-hospital ml-1" />
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                display: "block",
                              }}
                              primary="Facilities"
                            />
                          </ListItem>
                        </Tooltip>
                      )}
                      {canAccessUsers(props.roles) && (
                        <Tooltip title="Users" placement={"right"}>
                          <ListItem
                            button
                            component={NavLink}
                            sx={{
                              paddingLeft: theme.spacing(4),
                            }}
                            to="/User"
                            activeclassname="Mui-selected"
                            onClick={() => {
                              props.dispatch(ReportActions.Reset());
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                fontSize: 24,
                                color: "#2bbbad",
                              }}
                            >
                              <i className="fas fa-hospital-user" />
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                display: "block",
                              }}
                              primary="Users"
                            />
                          </ListItem>
                        </Tooltip>
                      )}
                      {canAccessContacts(props.roles) && (
                        <Tooltip title="Contacts" placement={"right"}>
                          <ListItem
                            button
                            component={NavLink}
                            sx={{
                              paddingLeft: theme.spacing(4),
                            }}
                            to="/Contact"
                            activeclassname="Mui-selected"
                            onClick={() => {
                              props.dispatch(ReportActions.Reset());
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                fontSize: 24,
                                color: "#2bbbad",
                              }}
                            >
                              <i className="fas fa-user-circle ml-1" />
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                display: "block",
                              }}
                              primary="Contacts"
                            />
                          </ListItem>
                        </Tooltip>
                      )}
                      {canAccessProviders(props.roles) && (
                        <Tooltip title="Providers" placement={"right"}>
                          <ListItem
                            button
                            component={NavLink}
                            sx={{
                              paddingLeft: theme.spacing(4),
                            }}
                            to="/Provider"
                            activeclassname="Mui-selected"
                            onClick={() => {
                              props.dispatch(ReportActions.Reset());
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                fontSize: 24,
                                color: "#2bbbad",
                              }}
                            >
                              <i className="fas fa-user-md ml-1" />
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                display: "block",
                              }}
                              primary="Providers"
                            />
                          </ListItem>
                        </Tooltip>
                      )}
                      {canAccessMidlevels(props.roles) && (
                        <Tooltip title="Midlevels" placement={"right"}>
                          <ListItem
                            button
                            component={NavLink}
                            sx={{
                              paddingLeft: theme.spacing(4),
                            }}
                            to="/Midlevel"
                            activeclassname="Mui-selected"
                            onClick={() => {
                              props.dispatch(ReportActions.Reset());
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                fontSize: 24,
                                color: "#2bbbad",
                              }}
                            >
                              <i className="fas fa-user-nurse ml-1" />
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                display: "block",
                              }}
                              primary="Midlevels"
                            />
                          </ListItem>
                        </Tooltip>
                      )}
                    </List>
                  </Collapse>
                </>
              )}
            </>
          )}
          <Divider />
          <Tooltip title="Logout" placement={"right"}>
            <ListItem
              button
              onClick={() => {
                localStorage.clear();
                window.location.href = logoutRoute;
              }}
              activeclassname="Mui-selected"
            >
              <ListItemIcon
                sx={{
                  fontSize: 24,
                  color: "#ff7961",
                }}
              >
                <i className="fas fa-sign-out ml-1" />
              </ListItemIcon>
              <ListItemText
                sx={{
                  display: "block",
                }}
                primary="Logout"
              />
            </ListItem>
          </Tooltip>
        </List>
      </Drawer>
    </div>
  );
};

export default CustomSidebar;
