import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityReasonForReviewChart from "./AllFacilityReasonForReviewChart";
import FacilitySpecificReasonForReviewChart from "./FacilitySpecificReasonForReviewChart";
import TextBegin from "./TextBegin";

const ReasonForReview = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, ["reason"]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, ["reason"]);

  return (
    <>
      <TextBegin {...props} />
      {chartDataAllFacilities !== false && (
        <AllFacilityReasonForReviewChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Reason"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificReasonForReviewChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Reason"}
        rows={chartData.rows}
      />
    </>
  );
};

export default ReasonForReview;
