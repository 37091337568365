import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const CardTemplate = ({ title, children }) => {
  return (
    <div className={"row pb-4"}>
      <div className={"col"}>
        <Card>
          <CardContent>
            <div className={"container-fluid"}>
              <div className={"row"}>
                <div className={"col"}>
                  <p className={"h4 text-center py-4"}>{title}</p>
                </div>
              </div>
              {children}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CardTemplate;
