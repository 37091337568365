import React, { useState } from "react";
import { FormSpy } from "react-final-form";
import { Button, CircularProgress, Stack } from "@mui/material";
import { useSelector } from "react-redux";

const CustomSubmitButton = ({ handleSubmit, label }) => {
  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  return (
    <>
      <FormSpy subscription={{ submitting: true, hasValidationErrors: true }}>
        {({ submitting, hasValidationErrors }) => {
          return (
            <TimeoutButton
              disable={submitting || (hasBeenClicked && hasValidationErrors)}
              handleSubmit={handleSubmit}
              setHasBeenClicked={setHasBeenClicked}
            >
              {label}
            </TimeoutButton>
          );
        }}
      </FormSpy>
    </>
  );
};

const TimeoutButton = ({
  children,
  disable,
  handleSubmit,
  timeout = 1000,
  setHasBeenClicked,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const Loading = useSelector((state) => state.Fetching.Loading);

  if ((isLoading && disable) || (isLoading && !Loading))
    setTimeout(() => setIsLoading(false), timeout);
  if (isLoading) {
    return (
      <Stack sx={{ color: "white" }} spacing={2} direction="row">
        <CircularProgress color="inherit" />
      </Stack>
    );
  }

  return (
    <Button
      autoFocus
      color="inherit"
      onClick={() => {
        setIsLoading(true);
        setHasBeenClicked(true);
        handleSubmit();
      }}
      disabled={disable || isLoading}
    >
      {children}
    </Button>
  );
};

export default CustomSubmitButton;
