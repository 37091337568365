const mutators = {
  setSelectedFacility: (args, state, utils) => {
    utils.changeValue(state, "facility", () => args[0]);
  },
  setSelectedContact: (args, state, utils) => {
    utils.changeValue(state, "contact", () => args[0]);
  },
  setSelectedProvider: (args, state, utils) => {
    utils.changeValue(state, "provider", () => args[0]);
  },
  setSelectedMidlevel: (args, state, utils) => {
    utils.changeValue(state, "midlevel", () => args[0]);
  },
  setSelectedCommittee: (args, state, utils) => {
    utils.changeValue(state, "committee", () => args[0]);
    utils.changeValue(state, "historyAndPhysicalOnChart", () => false);
  },
  clearSelectedCommittee: (args, state, utils) => {
    utils.changeValue(state, "committee", () => null);
  },
  clearAssociatedPersonnel: (args, state, utils) => {
    utils.changeValue(state, "contact", () => null);
    utils.changeValue(state, "provider", () => null);
    utils.changeValue(state, "midlevel", () => null);
  },
  clearCommitteeOther: (args, state, utils) => {
    utils.changeValue(state, "committeeOther", () => null);
  },
  clearPatientOriginationOther: (args, state, utils) => {
    utils.changeValue(state, "patientOriginationOther", () => null);
  },
  addICDCode: (args, state, utils) => {
    if (state.formState.values.icdCodes.some(({ code }) => code === args[0].Code))
      return;
    if (state.formState.values.icdCodes.some(({ id }) => id === args[0].id))
      return;
    utils.changeValue(state, "icdCodes", () => {
      if (state.formState.values.icdCodes.length > 0) {
        if (state.formState.values.makePrimaryDiagnosis) {
          state.formState.values.makePrimaryDiagnosis = false;
          return [
            { ...args[0], primary: true },
            ...state.formState.values.icdCodes,
          ];
        } else {
          return [
            ...state.formState.values.icdCodes,
            { ...args[0], primary: false },
          ];
        }
      } else {
        return [
          ...state.formState.values.icdCodes,
          { ...args[0], primary: true },
        ];
      }
    });
    utils.changeValue(state, "icdCodeDescription", () => null);
  },
  addCustomICDCode: (args, state, utils) => {
    if (state.formState.values.makePrimaryDiagnosis) {
      state.formState.values.makePrimaryDiagnosis = false;
      utils.changeValue(state, "icdCodes", () => [
        {
          id: "X00.0",
          text: state.formState.values.icdCodeDescription,
          noCodeAvailable: state.formState.values.noCodeAvailable,
          primary: true,
        },
        ...state.formState.values.icdCodes,
      ]);
    } else {
      utils.changeValue(state, "icdCodes", () => [
        ...state.formState.values.icdCodes,
        {
          id: "X00.0",
          text: state.formState.values.icdCodeDescription,
          noCodeAvailable: state.formState.values.noCodeAvailable,
        },
      ]);
    }
    utils.changeValue(state, "icdCodeDescription", () => null);
  },
  editICDCodeByDescription: (args, state, utils) => {
    // Check "No Code Available"
    state.formState.values.noCodeAvailable = true;

    // Set current text as the "(No Code Available) ICD Code Description)"
    const currentText = args[0];
    utils.changeValue(state, "icdCodeDescription", () => currentText);

    // Check "Make Primary Diagnosis" if it already was the "Primary"
    const isPrimary = args[1];
    utils.changeValue(state, "makePrimaryDiagnosis", () => isPrimary);

    const newCodes = state.formState.values.icdCodes.filter(
      (code) => code.text !== currentText
    );

    // Remove existing from current list (because it is now being edited)
    utils.changeValue(state, "icdCodes", () => newCodes);
  },
  removeICDCodeByDescription: (args, state, utils) => {
    const newCodes = state.formState.values.icdCodes.filter(
      (code) => code.text !== args[0]
    );
    utils.changeValue(state, "icdCodes", () => newCodes);
  },
  clearAll: (args, state, utils) => {
    utils.changeValue(state, "facility", () => null);
    utils.changeValue(state, "contact", () => null);
    utils.changeValue(state, "provider", () => null);
    utils.changeValue(state, "midlevels", () => null);
    utils.changeValue(state, "submissionDate", () => null);
    utils.changeValue(state, "reviewCategoryID", () => null);
    utils.changeValue(state, "reviewTypeID", () => null);
    utils.changeValue(state, "arrivalDate", () => null);
    utils.changeValue(state, "dischargeDate", () => null);
    utils.changeValue(state, "facilityMedicalRecordNumber", () => null);
    utils.changeValue(state, "patientAge", () => null);
    utils.changeValue(state, "ageIncrement", () => null);
    utils.changeValue(state, "patientOriginationID", () => null);
    utils.changeValue(state, "patientOriginationOther", () => null);
    utils.changeValue(state, "patientRace", () => null);
    utils.changeValue(state, "patientGender", () => null);
    utils.changeValue(state, "committee", () => null);
    utils.changeValue(state, "committeeOther", () => null);
    utils.changeValue(state, "historyAndPhysicalOnChart", () => false);
    utils.changeValue(state, "icdCodes", () => []);
    utils.changeValue(state, "noCodeAvailable", () => false);
    utils.changeValue(state, "icdCodeDescription", () => null);
    utils.changeValue(state, "reviewReasons", () => []);
    utils.changeValue(state, "otherReviewReason", () => null);
    utils.changeValue(state, "referenceMaterialChecklist", () => []);
    utils.changeValue(state, "otherReferenceMaterial", () => null);
    utils.changeValue(state, "referenceMaterial", () => null);
    utils.changeValue(state, "additionalInformation", () => null);
    utils.changeValue(state, "committeeQuestion", () => null);
    utils.changeValue(state, "recordStatus", () => null);
    utils.changeValue(state, "mrtsReviewType", () => null);
    utils.changeValue(state, "reviewMonth", () => null);
    utils.changeValue(state, "reviewYear", () => null);
    utils.changeValue(state, "comments", () => null);
    utils.changeValue(state, "rejectionReason", () => null);
    utils.changeValue(state, "rejectionComment", () => null);
  },
};

export default mutators;
