import React from "react";
import { Radios } from "mui-rff";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import RenderEachItem from "../RenderEachItem";

const InlineRadios = ({
  items,
  itemsDependOn,
  name,
  label,
  render: { options },
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={"row pb-2"}>
        <div className={"col-5 d-flex text-left my-auto"}>{label}</div>
        <div className={"col d-flex justify-content-end inline-radio"}>
          <Box className={classes.radio}>
            <Radios
              name={name}
              data={options.map((option) => ({ label: option, value: option }))}
              className={classes.radio}
            />
          </Box>
        </div>
      </div>
      <RenderEachItem items={items} itemsDependOn={itemsDependOn} indent />
    </>
  );
};

const useStyles = makeStyles(() => ({
  radio: {
    textAlign: "right",
  },
}));

export default InlineRadios;
