import { createSlice } from "@reduxjs/toolkit";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { LastCreatedObjectActions } from "src/redux/reducers/LastCreatedObject";
import { LoadedActions } from "src/redux/reducers/Loaded";
import { findSortedIndex } from "src/utilities/arrayMethods";

const initialState = [];

const providerSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {
    batchAdd: (_state, action) => {
      return [...action.payload];
    },
    create: (state, action) => {
      const targetIndex = findSortedIndex(state, action.payload);
      state.splice(targetIndex, 0, { ...action.payload, Facility: [] });
    },
    update: (state, action) => {
      const targetIndex = state.findIndex(
        (elem) => Number(elem.ID) === Number(action.payload.ID)
      );
      state[targetIndex] = { ...state[targetIndex], ...action.payload };
    },
    addFacility: (state, action) => {
      let targetIndex = state.findIndex(
        (p) => Number(p.ID) === Number(action.payload.ProviderID)
      );

      if (targetIndex === -1) {
        targetIndex = findSortedIndex(state, action.payload.BackUpProvider);
        state.splice(targetIndex, 0, {
          ...action.payload.BackUpProvider,
          Facility: [],
        });
      }
      state[targetIndex].Facility.push({
        ID: action.payload.FacilityID,
        facilityProvider: action.payload,
      });
    },
    removeFacility: (state, action) => {
      const providerIndex = state.findIndex(
        (p) => Number(p.ID) === Number(action.payload.ProviderID)
      );
      const facilityIndex = state[providerIndex].Facility.findIndex(
        (p) => Number(p.ID) === Number(action.payload.FacilityID)
      );
      state[providerIndex].Facility.splice(facilityIndex, 1);
    },
    merge: (state, action) => {
      const sourceIndex = state.findIndex(
        ({ ID }) => action.payload.source === ID
      );
      const source = state[sourceIndex];
      const destination = state.find(
        ({ ID }) => action.payload.destination === ID
      );

      source.Facility.forEach((sourceFacility) => {
        if (
          destination.Facility.every(
            (destinationFacility) =>
              destinationFacility.ID !== sourceFacility.ID
          )
        ) {
          destination.Facility.push(sourceFacility);
        }
      });
      source.Facility = [];
      if (action.payload.deleteSource) {
        state.splice(sourceIndex, 1);
      }
    },
  },
});

const { batchAdd, create, update, addFacility, removeFacility, merge } =
  providerSlice.actions;

const BatchAdd = () => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(LoadedActions.SetProvider("pending"));
    const { data: providers } = await apiService.get(`${apiUrl}provider`);
    dispatch(batchAdd([...providers]));
    dispatch(LoadedActions.SetProvider(true));
  } catch (error) {
    dispatch(FetchingActions.Failure("Loading Provider"));
  }
};

const Create = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Provider/create`,
      content
    );
    dispatch(create(contents));
    dispatch(LastCreatedObjectActions.Set("Provider", contents));
    dispatch(FetchingActions.Success("Creating Provider"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Creating Provider"));
  }
};

const Update = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Provider/update`,
      content
    );
    dispatch(update(contents));
    dispatch(FetchingActions.Success("Updating Provider"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Provider"));
  }
};

export const CreateAndLinkToFacility = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Provider/create`,
      content
    );
    dispatch(create(contents));
    dispatch(
      LinkToFacility({
        FacilityID: Number(content.FacilityID),
        ProviderID: contents.ID,
        Active: 1,
      })
    );
    dispatch(FetchingActions.Success("Creating Provider"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Creating Provider"));
  }
};

export const LinkToFacility = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Facility/addProvider`,
      content
    );
    dispatch(FacilityActions.AddProvider(contents));
    dispatch(
      addFacility({
        ...contents,
        BackUpProvider: content.BackUpProvider,
      })
    );
    dispatch(
      LastCreatedObjectActions.Set("AutoSelectProvider", {
        ...contents,
      })
    );
    dispatch(FetchingActions.Success("Linking Provider"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Linking Provider"));
  }
};

export const RemoveFromFacility = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Facility/removeProvider`,
      content
    );
    const payload = contents === 1 ? { ...content } : {};
    dispatch(FacilityActions.RemoveProvider(payload));
    dispatch(removeFacility(payload));
    dispatch(FetchingActions.Success("Removing Provider from Facility"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Removing Provider from Facility"));
  }
};

const Merge = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Provider/merge`,
      content
    );
    checkMergeResponse(contents);
    dispatch(merge(content));
    dispatch(FacilityActions.MergeProviders(content));
    dispatch(FetchingActions.Success("Providers Merged"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Providers Merged"));
  }
};

const checkMergeResponse = (data) => {
  if (!data) throw Error("endpoint failed");
};

export const ProviderActions = {
  BatchAdd,
  Create,
  Update,
  CreateAndLinkToFacility,
  LinkToFacility,
  RemoveFromFacility,
  Merge,
};

export default providerSlice.reducer;
