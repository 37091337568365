import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectFacilityByID,
  selectMidlevelByID,
  selectMidlevels,
} from "src/redux/selectors";
import Grid from "@mui/material/Grid";
import Custom from "src/components/individual/Custom";
import { Field } from "react-final-form";
import { formatName } from "src/utilities/objectUtilities";

const MidlevelSelect = ({ name, label }) => {
  const midlevels = useSelector(selectMidlevels);

  const options = useMemo(
    () =>
      midlevels.map((option) => ({
        label: formatName(option, "dropdown"),
        value: option.ID,
      })),
    [midlevels]
  );

  return (
    <Grid container sx={{ paddingInline: 1 }}>
      <Grid item xs={12}>
        <Custom.Autocomplete name={name} label={label} options={options} />
      </Grid>

      <Field name={name}>
        {({ input }) => (
          <Grid item xs={12} hidden={!input.value}>
            <MidlevelCard id={input.value} />
          </Grid>
        )}
      </Field>
    </Grid>
  );
};

const MidlevelCard = ({ id }) => {
  const midlevel = useSelector(selectMidlevelByID(id)) ?? { Facility: [] };
  return (
    <Grid
      container
      sx={{ border: 2, borderRadius: 5, padding: 2, marginTop: 1 }}
      hidden={midlevel.Facility.length === 0}
      flexDirection={"column"}
    >
      {midlevel.Facility.map((facility) => (
        <Grid item>
          <FacilityInfo id={facility.ID} />
        </Grid>
      ))}
    </Grid>
  );
};

const FacilityInfo = ({ id }) => {
  const facility = useSelector(selectFacilityByID(id));
  return (
    <Grid container>
      <Grid item>{facility.FullName}</Grid>
    </Grid>
  );
};

export default MidlevelSelect;
