const createOutcomeStructure = (meetings = [], outcomes = []) => {
  return [meeting(meetings), outcome(outcomes), improvementsTitle()];
};

export default createOutcomeStructure;

const meeting = (meetings) => {
  const meetingOptions = meetings.map(({ ID, MeetingName }) => ({
    label: MeetingName,
    value: ID,
  }));
  return {
    label: "Meeting",
    name: "MeetingID",
    render: { type: "Autocomplete", options: meetingOptions },
  };
};

const outcome = (outcomes) => ({
  label: "Peer Review Outcome",
  name: "OutcomeID",
  render: { type: "Dropdown", options: outcomeOptions(outcomes) },
});
const outcomeOptions = (outcomes) =>
  outcomes
    .filter((o) => o.Active === 1)
    .map((o) => ({
      label: o.Outcome,
      value: o.ID,
    }));

export const improvementsTitle = () => ({
  label: "Possible Improvement",
  name: "Improvements",
  render: { type: "Title" },
  items: [
    advanceDirectives(),
    documentation(),
    eHR(),
    medicationReconciliation(),
    lengthOfStay(),
    clinicalDecision(),
    therapeutic(),
    other(),
  ],
});

const advanceDirectives = () => ({
  label: "Advance Directives",
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 0,
});

const documentation = () => ({
  label: "Documentation",
  render: { type: "Checkbox", disabled: true },
  name: "Improvements",
  bitID: 1,
  items: [insufficient(), legibilityIssues()],
});

const insufficient = () => ({
  label: "Insufficient",
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 2,
});
const legibilityIssues = () => ({
  label: "Legibility Issues",
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 3,
});

const eHR = () => ({
  label: "Electronic Health Record (EHR) entries",
  render: { type: "Checkbox", disabled: true },
  name: "Improvements",
  bitID: 4,
  items: [discrepancies(), cloning(), needForExpansion()],
});

const discrepancies = () => ({
  label: "Discrepancies in EHR entries",
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 5,
});
const cloning = () => ({
  label: `Cloning documentation ("cut and paste")`,
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 6,
});
const needForExpansion = () => ({
  label: `Need for "free text" expansion`,
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 7,
});

const medicationReconciliation = () => ({
  label: "Medication Reconciliation",
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 8,
});

const lengthOfStay = () => ({
  label: "Length of Stay Inappropriate",
  render: { type: "Checkbox", disabled: true },
  name: "Improvements",
  bitID: 9,
  items: [briefStay(), extendedStay()],
});

const briefStay = () => ({
  label: "Brief stay",
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 10,
});
const extendedStay = () => ({
  label: "Extended stay",
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 11,
});

const clinicalDecision = () => ({
  label: "Clinical Decision Making",
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 12,
});

const therapeutic = () => ({
  label: "Therapeutic Choices",
  render: { type: "Checkbox", disabled: true },
  name: "Improvements",
  bitID: 13,
  items: [interventions(), medications(), diagnostics()],
});

const interventions = () => ({
  label: "Intervention",
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 14,
});
const medications = () => ({
  label: "Medications",
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 15,
});
const diagnostics = () => ({
  label: "Diagnostics",
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 16,
});

const other = () => ({
  label: "Other",
  render: { type: "Checkbox" },
  name: "Improvements",
  bitID: 17,
  items: [otherText()],
  itemsDependOn: [
    {
      name: "Improvements",
      method: (val) => 0 !== (val & Math.pow(2, 17)),
    },
  ],
});

const otherText = () => ({
  render: { type: "TextBox", required: true },
  name: "OtherImprovement",
});
