import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { TextField } from "mui-rff";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import { RecordActions } from "src/redux/reducers/Record";
import { Container, Card, CardContent, CardHeader, Paper } from "@mui/material";
import SelectFacility from "./SelectFacility";
import SelectRecord from "./SelectRecord";
import { selectRecords } from "src/redux/selectors";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffectOnce } from "react-use";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";

const Search = (props) => {
  const navigate = useNavigate();
  const [archiRecordID, setARCHIRecordID] = useState(null);
  const Loaded = useSelector((state) => state.Loaded);
  const Records = useSelector(selectRecords).filter(
    (r) => r.ARCHIRecordID !== undefined
  );

  useEffect(() => {
    if (archiRecordID) {
      const recordFound = Records.find(
        (r) => Number(r.ARCHIRecordID) === Number(archiRecordID)
      );
      if (recordFound) {
        navigate(`/RNTools/${recordFound.ID}`);
      }
    }
  }, [archiRecordID, Records, navigate]);

  const classes = useStyles();

  const onSubmit = (values) => {
    props.dispatch(
      RecordActions.LoadData(values.ARCHIRecordID, "ARCHIRecordID", true)
    );
    setARCHIRecordID(values.ARCHIRecordID);
  };

  useEffectOnce(() => {
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
    Loaded.Record === false &&
      props.dispatch(RecordActions.BatchAdd(props.roles, props.location));
  });

  return (
    <div className={"animate__animated animate__fadeIn"}>
      <Container className={classes.container}>
        <Card>
          <CardHeader
            title={
              <Paper className={classes.paperHeader}>
                <Typography variant={"h3"}>RN Search</Typography>
              </Paper>
            }
          />
          <CardContent>
            <Paper className={classes.paper}>
              <Form
                initialValues={{ FacilityID: null, RecordID: null }}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container direction={"column"} spacing={3}>
                      <Grid item>
                        <SelectFacility />
                      </Grid>
                      <Grid item>
                        <SelectRecord facilityID={values.FacilityID} />
                      </Grid>
                      <Grid item>
                        <Typography variant={"h5"}>OR</Typography>
                        <Typography variant={"h5"}>
                          Enter the ARCHI Record Number
                        </Typography>
                      </Grid>
                      <Grid item>
                        <TextField
                          name={"ARCHIRecordID"}
                          label={"ARCHI Record ID"}
                          required
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          type={"submit"}
                          variant={"contained"}
                          color={"primary"}
                          className={classes.button}
                          disabled={!values.ARCHIRecordID}
                        >
                          <SearchIcon />
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Form>
            </Paper>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default Search;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginTop: theme.spacing(5),
    },
    paperHeader: {
      background: theme.palette.primary.light,
      color: theme.palette.common.white,
      padding: 20,
    },
    paper: {
      padding: 50,
    },
    button: {
      paddingInline: 20,
    },
  };
});
