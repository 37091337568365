import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Constants: false,
  Contact: false,
  Facility: false,
  Meeting: false,
  Midlevel: false,
  Provider: false,
  Record: false,
  Review: false,
  RNTools: false,
  User: false,
};

const loadedSlice = createSlice({
  name: "loaded",
  initialState,
  reducers: {
    setConstants: (state, action) => {
      state.Constants = action.payload;
    },
    setContact: (state, action) => {
      state.Contact = action.payload;
    },
    setFacility: (state, action) => {
      state.Facility = action.payload;
    },
    setMeeting: (state, action) => {
      state.Meeting = action.payload;
    },
    setMidlevel: (state, action) => {
      state.Midlevel = action.payload;
    },
    setProvider: (state, action) => {
      state.Provider = action.payload;
    },
    setRecord: (state, action) => {
      state.Record = action.payload;
    },
    setReview: (state, action) => {
      state.Review = action.payload;
    },
    setRNTools: (state, action) => {
      state.RNTools = action.payload;
    },
    setUser: (state, action) => {
      state.User = action.payload;
    },
  },
});

const {
  setConstants,
  setContact,
  setFacility,
  setMeeting,
  setMidlevel,
  setProvider,
  setRecord,
  setReview,
  setRNTools,
  setUser,
} = loadedSlice.actions;

const SetConstants = (constants) => (dispatch) => {
  dispatch(setConstants(constants));
};

const SetContact = (contact) => (dispatch) => {
  dispatch(setContact(contact));
};

const SetFacility = (facility) => (dispatch) => {
  dispatch(setFacility(facility));
};

const SetMeeting = (meeting) => (dispatch) => {
  dispatch(setMeeting(meeting));
};

const SetMidlevel = (midlevel) => (dispatch) => {
  dispatch(setMidlevel(midlevel));
};

const SetProvider = (provider) => (dispatch) => {
  dispatch(setProvider(provider));
};

const SetRecord = (record) => (dispatch) => {
  dispatch(setRecord(record));
};

const SetReview = (review) => (dispatch) => {
  dispatch(setReview(review));
};

const SetRNTools = (RNTools) => (dispatch) => {
  dispatch(setRNTools(RNTools));
};

const SetUser = (user) => (dispatch) => {
  dispatch(setUser(user));
};

export const LoadedActions = {
  SetConstants,
  SetContact,
  SetFacility,
  SetMeeting,
  SetMidlevel,
  SetProvider,
  SetRecord,
  SetReview,
  SetRNTools,
  SetUser,
};

export default loadedSlice.reducer;
