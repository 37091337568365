import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificAbbreviationUseChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        hideChart={true}
        title={"Facility-Specific: Abbreviation Use"}
        xAxisLabel={"Abbreviation Used"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: Records by AbbreviationUse"}
      />
    </>
  );
};

export default FacilitySpecificAbbreviationUseChart;
