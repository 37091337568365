import React from "react";
import { Field } from "react-final-form";
import TextBox from "./Textbox";
import BoxTitle from "./BoxTitle";
import VariantView from "./VariantView";
import Condition from "./Condition";
import SideEffect from "./SideEffect";

const BoxWrapper = ({ items }) => {
  return (
    <>
      {items &&
        items.map(
          ({ name, items, variant, label, disabled, style, rows = 8 }) => (
            <div className={"container-fluid border border-dark"}>
              <Field name={name} key={`${name}Section`}>
                {({ input: { name } }) => (
                  <>
                    <BoxTitle name={name} />
                    <Condition
                      when={`${name}Switch`}
                      is={false}
                      ignore={variant !== "withSwitch"}
                    >
                      <TextBox
                        name={name}
                        variant={variant}
                        disabled={disabled}
                        style={style}
                        multiline
                        rows={rows}
                      />
                    </Condition>
                    <VariantView variant={variant} name={name} label={label} />
                    {variant === "withCheckbox" && <SideEffect name={name} />}
                  </>
                )}
              </Field>
              <Condition
                when={`${name}Switch`}
                is={false}
                ignore={variant !== "withSwitch"}
              >
                <BoxWrapper items={items} />
              </Condition>
            </div>
          )
        )}
    </>
  );
};

export default BoxWrapper;
