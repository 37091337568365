import React from "react";
import { Page, Document, Text, Image } from "@react-pdf/renderer";
import Abbreviations from "./Abbreviations";
import Checklist from "./Checklist";
import Comments from "./Comments";
import RNComments from "./RNComments";
import PossibleImprovements from "./PossibleImprovements";
import Description from "./Description";
import Footer from "../PDFUtilities/Footer";
import Header from "./Header";
import Signature from "./Signature";
import { styles } from "../PDFUtilities/PDFStyles";
import C from "src/utilities/constants";

const FinalReport = ({
  abbreviationList,
  outcomesList,
  recordData,
  checklistData,
}) => {
  return (
    <Document
      title={"Peer Review Final Report"}
      author={"ARCHI"}
      subject={"Final Report"}
      keywords={"Peer Review PPR ARCHI MRTS PDF"}
      creator={"ARCHI PPR System"}
      producer={"ARCHI"}
      language={"English"}
    >
      <Page size="LETTER" style={styles.finalPage}>
        <Image
          src={"/images/PDFWatermark.png"}
          fixed
          style={styles.watermark}
        />
        <Header />
        <Text style={styles.finalTitle}>PEER REVIEW FINAL REPORT</Text>
        <Description record={recordData} />
        <Comments
          reviewReasons={recordData.ReviewReasonsList}
          comments={recordData.RecordComment}
          rnOutcomes={recordData.RNOutcomes}
          outcomesList={outcomesList}
        />
        <RNComments rnComments={recordData.RNComments} />
        <PossibleImprovements rnOutcomes={recordData.RNOutcomes} />
        <Checklist
          checklistData={checklistData}
          committeeID={recordData.CommitteeID}
          recordVersion={recordData.Version}
        />
        <Abbreviations
          abbreviations={recordData.RecordAbbreviation}
          abbreviationList={abbreviationList}
          radiology={recordData.CommitteeID === C.COMMITTEE_ID_RADIOLOGY}
        />
        <Signature signature={recordData.Signature} />
        <Footer />
      </Page>
    </Document>
  );
};

export default FinalReport;
