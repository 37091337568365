import React from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { canCreateFacility } from "src/utilities/rbacFunctions";
import { selectFacilities } from "src/redux/selectors";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import CreateFacilityDialog from "./CreateFacilityDialog";
import UpdateFacilityDialog from "./UpdateFacilityDialog";
import ActionsButton from "./ActionsButton";
import TablePageCard from "src/components/individual/TablePageCard";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    name: "archiFacilityID",
    label: "ARCHI Facility ID",
  },
  {
    name: "name",
    label: "Name",
  },
  {
    name: "city",
    label: "City",
  },
  {
    name: "state",
    label: "State",
  },
  {
    name: "active",
    label: "Active",
  },
  {
    name: "actions",
    label: "Actions",
    options: {
      filter: false,
      sort: false,
      download: false,
      print: false,
    },
  },
];

const options = {
  filterType: "multiselect",
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
};

const Dashboard = (props) => {
  const navigate = useNavigate();
  const [createFacilityDialogVisible, setCreateFacilityDialogVisible] =
    React.useState(false);
  const [updateFacilityDialogVisible, setUpdateFacilityDialogVisible] =
    React.useState(false);
  const [facilityID, setFacilityID] = React.useState(null);
  const Facilities = useSelector(selectFacilities);
  const Loaded = useSelector((state) => state.Loaded);

  useEffectOnce(() => {
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
  });

  const data = Facilities.map((facility, i) => {
    return {
      archiFacilityID: facility.ARCHIFacilityID,
      name: facility.Name,
      city: facility.City,
      state: facility.State,
      active: facility.Active ? "Active" : "Inactive",
      actions: (
        <ActionsButton
          id={i}
          spawnUpdateFacilityDialog={() => {
            setFacilityID(facility.ID);
            setUpdateFacilityDialogVisible(true);
          }}
          sendToFacilityLinks={() => {
            navigate(`./${facility.ID}`);
          }}
        />
      ),
    };
  });

  return (
    <>
      {createFacilityDialogVisible && (
        <CreateFacilityDialog
          {...props}
          dialogVisible={createFacilityDialogVisible}
          setDialogVisible={() => {
            setCreateFacilityDialogVisible(false);
          }}
        />
      )}
      {updateFacilityDialogVisible && (
        <UpdateFacilityDialog
          {...props}
          dialogVisible={updateFacilityDialogVisible}
          facilityID={facilityID}
          setDialogVisible={() => {
            setUpdateFacilityDialogVisible(false);
          }}
        />
      )}
      <TablePageCard
        title={"Facilities"}
        table={{ columns, data, options }}
        button={{
          isRendered: canCreateFacility(props.roles),
          label: "New Facility",
          handleClick: () => setCreateFacilityDialogVisible(true),
        }}
      />
    </>
  );
};

export default Dashboard;
