import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../PDFUtilities/PDFStyles";
import { RenderTwoColumn } from "../../PDFUtilities/RenderPDFFunctions";

const Description = ({ record }) => {
  const leftPercentageWidth = "50%";
  const rightPercentageWidth = "50%";
  const leftColumnWidth = "40%";
  const rightColumnWidth = "60%";

  return (
    <View>
      <RenderTwoColumn
        RenderType={View}
        leftColumn={
          <RenderTwoColumn
            RenderType={Text}
            leftColumn="ARCHI Record"
            leftColumnStyle={styles.descriptionText}
            rightColumn={record.ARCHIRecordID}
            rightColumnStyle={styles.descriptionText}
            leftWidth={leftPercentageWidth}
            rightWidth={rightPercentageWidth}
          />
        }
        rightColumn={
          <RenderTwoColumn
            RenderType={Text}
            leftColumn="Date of Facility Request"
            leftColumnStyle={styles.descriptionText}
            rightColumn={record.RecordDate}
            rightColumnStyle={styles.descriptionText}
            leftWidth={leftPercentageWidth}
            rightWidth={rightPercentageWidth}
          />
        }
        leftWidth={leftColumnWidth}
        rightWidth={rightColumnWidth}
      />
      <RenderTwoColumn
        RenderType={View}
        leftColumn={
          <RenderTwoColumn
            RenderType={Text}
            leftColumn="ARCHI Facility ID"
            leftColumnStyle={styles.descriptionText}
            rightColumn={record.ARCHIFacilityID}
            rightColumnStyle={styles.descriptionText}
            leftWidth={leftPercentageWidth}
            rightWidth={rightPercentageWidth}
          />
        }
        rightColumn={
          <RenderTwoColumn
            RenderType={Text}
            leftColumn="Facility Record"
            leftColumnStyle={styles.descriptionText}
            rightColumn={record.FacilityRecordID}
            rightColumnStyle={styles.descriptionText}
            leftWidth={leftPercentageWidth}
            rightWidth={rightPercentageWidth}
          />
        }
        leftWidth={leftColumnWidth}
        rightWidth={rightColumnWidth}
      />
      <RenderTwoColumn
        RenderType={View}
        leftColumn={
          <RenderTwoColumn
            leftColumn="ARCHI Physician ID"
            leftColumnStyle={styles.descriptionText}
            rightColumn={record.ARCHIProviderID}
            rightColumnStyle={styles.descriptionText}
            RenderType={Text}
            leftWidth={leftPercentageWidth}
            rightWidth={rightPercentageWidth}
          />
        }
        rightColumn={
          <RenderTwoColumn
            RenderType={Text}
            leftColumn="Committee/Date of Review"
            leftColumnStyle={styles.descriptionText}
            rightColumn={record.MeetingName}
            rightColumnStyle={styles.descriptionText}
            leftWidth={leftPercentageWidth}
            rightWidth={rightPercentageWidth}
          />
        }
        leftWidth={leftColumnWidth}
        rightWidth={rightColumnWidth}
      />
      <RenderTwoColumn
        RenderType={View}
        leftColumn={
          record.ARCHIMidlevelID ? (
          <RenderTwoColumn            
            leftColumn={"ARCHI MidLevel Provider ID"}
            leftColumnStyle={styles.descriptionText}
            rightColumn={record.ARCHIMidlevelID}
            rightColumnStyle={styles.descriptionText}
            RenderType={Text}
            leftWidth={leftPercentageWidth}
            rightWidth={rightPercentageWidth}
            />
            ) : (
              <></>
            )
        }
        rightColumn={
          
            <RenderTwoColumn
              RenderType={Text}
              leftColumn="Date Report Sent to Facility"
              leftColumnStyle={styles.descriptionText}
              rightColumn={record.ReportSentToFacilityDate}
              rightColumnStyle={styles.descriptionText}
              leftWidth={leftPercentageWidth}
              rightWidth={rightPercentageWidth}
            />
       
        }
        leftWidth={leftColumnWidth}
        rightWidth={rightColumnWidth}
      />
    </View>
  );
};

export default Description;
