import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificPeerReviewOutcomesChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Peer Review Outcomes"}
        xAxisLabel={"Outcomes"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: Records by Peer Review Outcome"}
      />
    </>
  );
};

export default FacilitySpecificPeerReviewOutcomesChart;
