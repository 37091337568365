import React from "react";
import {
  renderTwoBoxColumn,
  createEmptyTwoBoxColumn,
} from "../PDFUtilities/RenderPDFFunctions";

const Workspace = () => {
  return (
    <>
      {renderTwoBoxColumn(
        "Clinical Findings",
        null,
        "Imaging/EKG Findings",
        null,
        "boxSubTitle",
        "boxSubTitle"
      )}
      {createEmptyTwoBoxColumn(5)}
      {renderTwoBoxColumn(
        null,
        null,
        "Laboratory Results",
        null,
        undefined,
        "boxSubTitle"
      )}
      {createEmptyTwoBoxColumn(6)}
      {renderTwoBoxColumn(
        null,
        null,
        "Procedures",
        null,
        undefined,
        "boxSubTitle"
      )}
      {createEmptyTwoBoxColumn(3)}
      {renderTwoBoxColumn(
        null,
        null,
        "Question/Comments",
        null,
        undefined,
        "boxSubTitle"
      )}
      {createEmptyTwoBoxColumn(3)}
      {renderTwoBoxColumn(
        "Core Measure",
        null,
        null,
        null,
        "boxSubTitle",
        undefined
      )}
      {createEmptyTwoBoxColumn(4)}
      {createEmptyTwoBoxColumn(1, true)}
    </>
  );
};

export default Workspace;
