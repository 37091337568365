import React from "react";
import { MenuItem } from "@mui/material";
import { Select } from "mui-rff";

const subcommittees = [
  { name: "A", value: "A" },
  { name: "A2", value: "A2" },
  { name: "A3", value: "A3" },
  { name: "A4", value: "A4" },
  { name: "B", value: "B" },
  { name: "B2", value: "B2" },
  { name: "B3", value: "B3" },
  { name: "B4", value: "B4" },
  { name: "C", value: "C" },
  { name: "C2", value: "C2" },
  { name: "C3", value: "C3" },
  { name: "C4", value: "C4" },
  { name: "D", value: "D" },
  { name: "D2", value: "D2" },
  { name: "D3", value: "D3" },
  { name: "D4", value: "D4" },
];

const CRSubcommitteeSelect = (props) => {
  return (
    <div className={"row pt-2"}>
      <div className={"col"}>
        <Select
          name={"subcommittee"}
          label={"Subcommittee"}
          labelId="create-meeting-cr-subcommittee-select-label"
          id="create-meeting-cr-subcommittee-select"
          variant={"outlined"}
          disabled={props.disabled}
          displayEmpty
          required={props.values.reviewType === "CR"}
        >
          <MenuItem value={null}>Select a Subcommittee</MenuItem>
          {subcommittees.map((option) => (
            <MenuItem
              key={`create-meeting-cr-subcommittee-${option.name}-${option.value}`}
              value={option.name}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default CRSubcommitteeSelect;
