import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityPeerReviewOutcomesChart from "./AllFacilityPeerReviewOutcomesChart";
import FacilitySpecificPeerReviewOutcomesChart from "./FacilitySpecificPeerReviewOutcomesChart";
import TextBegin from "./TextBegin";
import TextEnd from "./TextEnd";

const PeerReviewOutcomes = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, ["outcome"]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, ["outcome"]);

  return (
    <>
      <TextBegin {...props} />
      {chartDataAllFacilities !== false && (
        <AllFacilityPeerReviewOutcomesChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Peer Review Outcome"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificPeerReviewOutcomesChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Peer Review Outcome"}
        rows={chartData.rows}
      />
      <TextEnd {...props} />
    </>
  );
};

export default PeerReviewOutcomes;
