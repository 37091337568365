const wordWrapping = (longLabel) => {
  let splittedLabel = longLabel.split(" ");
  const shortLabel = [];
  for (let i = 0; i < splittedLabel.length; i++) {
    if (i === splittedLabel.length - 1) {
      shortLabel.push(splittedLabel[i]);
    } else {
      if (splittedLabel[i].length + splittedLabel[i + 1].length <= 12) {
        shortLabel.push(splittedLabel[i] + " " + splittedLabel[i + 1]);
        i++;
      } else {
        shortLabel.push(splittedLabel[i]);
      }
    }
  }

  return shortLabel;
};

const onlyCapitalLetters = (str) => {
  return str.replace(/[^A-Z]+/g, "");
};

const removeTextBetweenParenthesis = (str) => {
  return str.replace(/ \([\s\S]*?\)/g, "");
};

const shortenLabel = (str, count) => {
  let label = removeTextBetweenParenthesis(str);

  if (count < 10) {
    return wordWrapping(label);
  }

  let abbreviatedLabel =
    /-/.test(label) && str.match(/Committee|Direct|Pending/g)
      ? onlyCapitalLetters(label)
      : label;

  return abbreviatedLabel.length > 30
    ? abbreviatedLabel.substring(0, 27) + "..."
    : abbreviatedLabel;
};

const ChartDataOptions = (
  labelCount = 0,
  datasetCount = 0,
  chartType = "radar",
  groupBy = "",
  axisLabels = {},
  titleText = ""
) => {
  const options = [];

  const title = {
    display: false,
    text: titleText,
    fontSize: 50,
    fontColor: "#000",
    position: "top",
  };

  options["monthly"] = {
    scales: {
      y: {
        grace: "5%",
        ticks: {
          beginAtZero: true,
          precision: 0,
        },
        title: {
          display: true,
          text: axisLabels.y,
        },
      },
      x: {
        grid: {
          tickColor: "gray",
        },
        ticks: {
          autoSkip: false,
          maxRotation: labelCount < 10 ? 0 : labelCount < 30 ? 45 : 90,
          minRotation: labelCount < 10 ? 0 : labelCount < 30 ? 45 : 90,
          callback: function (value) {
            let label = this.getLabelForValue(value);
            return /\s/.test(label) ? wordWrapping(label) : label;
          },
        },
      },
    },
    plugins: {
      datalabels: {
        display: function (context) {
          return (
            datasetCount < 6 && context.dataset.data[context.dataIndex] > 0
          );
        },
        anchor: "end",
        offset: 2,
        align: "end",
        color: "black",
        font: {
          weight: "bold",
          size: 12,
        },
      },
      title,
    },
  };

  options["quarterly"] = {
    scales: {
      y: {
        grace: "5%",
        ticks: {
          beginAtZero: true,
          precision: 0,
        },
        title: {
          display: true,
          text: axisLabels.y,
        },
      },
      x: {
        grid: {
          tickColor: "gray",
        },
        ticks: {
          autoSkip: false,
          maxRotation: labelCount < 10 ? 0 : labelCount < 30 ? 45 : 90,
          minRotation: labelCount < 10 ? 0 : labelCount < 30 ? 45 : 90,
        },
      },
    },
    plugins: {
      datalabels: {
        display: function (context) {
          return (
            datasetCount < 6 && context.dataset.data[context.dataIndex] > 0
          );
        },
        anchor: "end",
        offset: 2,
        align: "end",
        color: "black",
        font: {
          weight: "bold",
          size: 12,
        },
      },
      title,
    },
  };

  options["yearly"] = {
    scales: {
      y: {
        grace: "5%",
        ticks: {
          beginAtZero: true,
          precision: 0,
        },
        title: {
          display: true,
          text: axisLabels.y,
        },
      },
      x: {
        grid: {
          tickColor: "gray",
        },
        ticks: {
          autoSkip: false,
          maxRotation: labelCount < 15 ? 0 : labelCount < 30 ? 45 : 90,
          minRotation: labelCount < 15 ? 0 : labelCount < 30 ? 45 : 90,
        },
      },
    },
    plugins: {
      datalabels: {
        display: function (context) {
          return (
            datasetCount < 6 && context.dataset.data[context.dataIndex] > 0
          );
        },
        anchor: "end",
        offset: 2,
        align: "end",
        color: "black",
        font: {
          weight: "bold",
          size: 12,
        },
      },
      title,
    },
  };

  options[""] = {
    responsive: true,
    scales: {
      y: {
        grace: "10%",
        ticks: {
          beginAtZero: true,
          precision: 0,
        },
        title: {
          display: true,
          text: axisLabels.y,
        },
      },
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: labelCount < 10 ? 0 : labelCount < 30 ? 45 : 90,
          callback: function (value) {
            let label = this.getLabelForValue(value);
            return shortenLabel(label, labelCount);
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: "end",
        offset: 5,
        align: "end",
        color: "black",
        font: {
          weight: "bold",
        },
      },
      title,
    },
  };

  options["line"] = {
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          precision: 0,
        },
        title: {
          display: true,
          text: axisLabels.y,
        },
      },
      x: {
        title: {
          display: true,
          text: axisLabels.x,
        },
        ticks: {
          callback: function (value) {
            let label = this.getLabelForValue(value);
            return shortenLabel(label, labelCount);
          },
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: datasetCount < 6,
        anchor: "end",
        offset: 2,
        align: "end",
        color: "black",
        font: {
          weight: "bold",
          size: 10,
        },
      },
      title,
    },
  };

  options["doughnut"] = {
    plugins: {
      datalabels: {
        display: datasetCount < 6,
        anchor: "end",
        offset: 2,
        align: "end",
        color: "black",
        font: {
          weight: "bold",
          size: 10,
        },
      },
      title,
    },
  };

  options["pie"] = {
    plugins: {
      datalabels: {
        display: datasetCount < 6,
        anchor: "end",
        offset: 2,
        align: "end",
        color: "black",
        font: {
          weight: "bold",
          size: 12,
        },
      },
      title,
    },
  };

  options["polar"] = {
    legend: {
      title: {
        display: false,
      },
    },
    plugins: {
      datalabels: {
        display: datasetCount < 6,
        anchor: "end",
        offset: 2,
        align: "end",
        color: "black",
        font: {
          weight: "normal",
          size: 10,
        },
      },
      title,
    },
  };

  options["radar"] = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: datasetCount < 6,
        anchor: "end",
        offset: 10,
        align: "end",
        color: "black",
        font: {
          weight: "bold",
          size: 12,
        },
      },
      title,
    },
  };

  return {
    options: chartType === "bar" ? options[groupBy] : options[chartType],
  };
};

export default ChartDataOptions;
