import React from "react";
import { useNavigate } from "react-router-dom";
import jwt from "jsonwebtoken";
import { apiUrl } from "src";
import apiService from "src/utilities/api_service";
import { facilitator, registeredNurse } from "src/utilities/rbacFunctions";
import * as Sentry from "@sentry/react";

const logoutRoute = process.env.REACT_APP_LOGOUT_ROUTE || "/logout";

// pre-compute new time to avoid doing computations after receiving time from Date.now()
// minus the buffer (in milliseconds)
function isExpired(time, bufferMs = 500) {
  const newTime = time * 1000 - bufferMs;
  return newTime < Date.now();
}

function useAuthHook() {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const navigate = useNavigate();
  const roles = React.useRef();
  const tokenExp = React.useRef();
  const data = React.useRef();

  function storeToken(token, refreshToken) {
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    handleToken(token, refreshToken);
  }

  async function authenticate(code = null) {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const refreshToken = localStorage.getItem("refreshToken");
        if (
          refreshToken === "undefined" ||
          refreshToken === undefined ||
          refreshToken === null ||
          refreshToken === ""
        ) {
          throw new Error("No valid Refresh Token. Login out...");
        }
        if (isExpired(jwt.decode(token).exp) && refreshToken) {
          await refreshAccessToken(refreshToken);
        } else {
          handleToken(token, refreshToken);
        }
      } else if(code){
         await retrieveAccessToken(code);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      Sentry.captureException(error);
      logout();
    }
  }

  const handleToken = React.useCallback((token) => {
    setIsAuthenticated(true);
    const jwtData = jwt.decode(token);
    const { roles: tokenRoles, exp, data: tokenData } = jwtData;
    roles.current = tokenRoles;
    data.current = tokenData;
    tokenExp.current = exp;
    Sentry.setUser(jwtData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrieveAccessToken = React.useCallback(async (code) => {
    const response = await apiService.get(`${apiUrl}oauth`, {
      params: {
        code,
      },
    });

    if (response.status === 200) {
      const { accessToken, refreshToken } = response.data;
      storeToken(accessToken, refreshToken);
      navigate(
        facilitator(roles)
          ? "/MeetingManagement"
          : registeredNurse(roles)
          ? "/RNTools"
          : "/MRTSRecords"
      );
    } else {
      throw new Error("Invalid response from server");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshAccessToken = React.useCallback(async (refreshToken) => {
    try {
      const response = await apiService.post(`${apiUrl}jwt/refresh`, {
        refreshToken,
      });

      if (response.status === 200) {
        const { accessToken } = response.data;
        storeToken(accessToken, refreshToken);
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      logout();
    }
  }, []);

  const logout = React.useCallback(() => {
    Sentry.setUser({});
    setIsAuthenticated(false);
    localStorage.clear();
    roles.current = [];
    window.location.href = logoutRoute;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isAuthenticated,
    retrieveAccessToken,
    refreshAccessToken,
    logout,
    roles,
    data,
    authenticate,
  };
}

export default useAuthHook;
