import React from "react";
import { Button } from "@mui/material";

const RejectedButton = (props) => {
  return (
    <Button
      size={"small"}
      variant={"contained"}
      color={"secondary"}
      style={{ width: "174px" }}
      onClick={props.spawnViewRejectionDialog}
    >
      View Rejection
    </Button>
  );
};

export default RejectedButton;
