import React from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import {
  canAccessAdministration,
  canAddMidlevel,
} from "src/utilities/rbacFunctions";
import { selectMidlevels } from "src/redux/selectors";
import { MidlevelActions } from "src/redux/reducers/PersonnelManagement/Midlevel";
import CreateMidlevelDialog from "./CreateMidlevelDialog";
import UpdateMidlevelDialog from "./UpdateMidlevelDialog";
import MergeDialog from "./MergeDialog";
import LinkMidlevelToFacilityDialog from "./LinkMidlevelToFacilityDialog";
import TablePageCard from "src/components/individual/TablePageCard";
import ActionsButton from "./ActionsButton";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { globalVariables } from "src/components/App";

const Midlevel = (props) => {
  const { data: userData } = React.useContext(globalVariables);
  const [createMidlevelDialogVisible, setCreateMidlevelDialogVisible] =
    React.useState(false);
  const [updateMidlevelDialogVisible, setUpdateMidlevelDialogVisible] =
    React.useState(false);
  const [mergeDialogVisible, setMergeDialogVisible] = React.useState(false);
  const [midlevelID, setMidlevelID] = React.useState(null);
  const [
    linkMidlevelToFacilityDialogVisible,
    setLinkMidlevelToFacilityDialogVisible,
  ] = React.useState(false);
  const Midlevels = useSelector(selectMidlevels);
  const Loaded = useSelector((state) => state.Loaded);

  useEffectOnce(() => {
    Loaded.Midlevel === false && props.dispatch(MidlevelActions.BatchAdd());
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
  });

  const data = Midlevels.map((midlevel, i) => {
    return {
      archiProviderID: midlevel.ARCHIProviderID,
      firstName: midlevel.FirstName,
      lastName: midlevel.LastName,
      emailAddress: midlevel.EmailAddress,
      degree: midlevel.Degree,
      active: midlevel.Active ? "Active" : "Inactive",
      actions: (
        <ActionsButton
          id={i}
          spawnUpdateMidlevelDialog={() => {
            setMidlevelID(midlevel.ID);
            setUpdateMidlevelDialogVisible(true);
          }}
          spawnLinkMidlevelToFacilityDialog={() => {
            setMidlevelID(midlevel.ID);
            setLinkMidlevelToFacilityDialogVisible(true);
          }}
        />
      ),
    };
  });

  return (
    <>
      {createMidlevelDialogVisible && (
        <CreateMidlevelDialog
          {...props}
          dialogVisible={createMidlevelDialogVisible}
          setDialogVisible={() => {
            setCreateMidlevelDialogVisible(false);
          }}
        />
      )}
      <MergeDialog open={mergeDialogVisible} setOpen={setMergeDialogVisible} />
      {updateMidlevelDialogVisible && (
        <UpdateMidlevelDialog
          {...props}
          dialogVisible={updateMidlevelDialogVisible}
          facilityID={userData.current.FacilityID}
          midlevelID={midlevelID}
          setDialogVisible={() => {
            setUpdateMidlevelDialogVisible(false);
          }}
        />
      )}
      {linkMidlevelToFacilityDialogVisible && (
        <LinkMidlevelToFacilityDialog
          {...props}
          dialogVisible={linkMidlevelToFacilityDialogVisible}
          midlevelID={midlevelID}
          setDialogVisible={() => {
            setLinkMidlevelToFacilityDialogVisible(false);
          }}
        />
      )}
      <TablePageCard
        title={"Midlevels"}
        table={{ columns, data, options }}
        button={{
          isRendered: canAddMidlevel(props.roles),
          label: "New Midlevel",
          handleClick: () => setCreateMidlevelDialogVisible(true),
        }}
        secondaryButton={{
          isRendered: canAccessAdministration(props.roles),
          label: "Merge Midlevels",
          handleClick: () => setMergeDialogVisible(true),
        }}
      />
    </>
  );
};

const columns = [
  {
    name: "archiProviderID",
    label: "ARCHI Provider ID",
  },
  {
    name: "firstName",
    label: "First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
  },
  {
    name: "emailAddress",
    label: "E-Mail Address",
  },
  {
    name: "degree",
    label: "Degree",
  },
  {
    name: "active",
    label: "Active",
  },
  {
    name: "actions",
    label: "Actions",
    options: {
      filter: false,
      sort: false,
      download: false,
      print: false,
    },
  },
];

const options = {
  filterType: "multiselect",
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
};

export default Midlevel;
