import React from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { canAddContact } from "src/utilities/rbacFunctions";
import { selectContacts } from "src/redux/selectors/personnelSelectors";
import { ContactActions } from "src/redux/reducers/PersonnelManagement/Contact";
import CreateContactDialog from "./CreateContactDialog";
import UpdateContactDialog from "./UpdateContactDialog";
import TablePageCard from "src/components/individual/TablePageCard";
import { Button } from "@mui/material";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";

const columns = [
  {
    name: "firstName",
    label: "First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
  },
  {
    name: "emailAddress",
    label: "E-Mail Address",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
  },
  {
    name: "active",
    label: "Active",
  },
  {
    name: "actions",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      download: false,
      print: false,
    },
  },
];

const options = {
  filterType: "multiselect",
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
};

const Contact = (props) => {
  const [createContactDialogVisible, setCreateContactDialogVisible] =
    React.useState(false);
  const [updateContactDialogVisible, setUpdateContactDialogVisible] =
    React.useState(false);
  const [contactID, setContactID] = React.useState(null);
  const Contacts = useSelector(selectContacts);
  const Loaded = useSelector((state) => state.Loaded);

  useEffectOnce(() => {
    Loaded.Contact === false && props.dispatch(ContactActions.BatchAdd());
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
  });

  const data = Contacts.map((contact, i) => {
    return {
      firstName: contact.FirstName,
      lastName: contact.LastName,
      emailAddress: contact.EmailAddress,
      phoneNumber:
        contact.PhoneNumber !== undefined && contact.PhoneNumber !== null
          ? `${
              contact.AreaCode !== undefined &&
              contact.AreaCode !== null &&
              `(${contact.AreaCode})`
            } ${contact.PhoneNumber} ${
              contact.Extension !== undefined && contact.Extension !== null
                ? `ext. ${contact.Extension}`
                : ""
            }`
          : "-",
      active: contact.Active ? "Active" : "Inactive",
      actions: (
        <Button
          variant={"contained"}
          color={"primary"}
          size={"small"}
          onClick={() => {
            setUpdateContactDialogVisible(true);
            setContactID(contact.ID);
          }}
        >
          Edit
        </Button>
      ),
    };
  });

  return (
    <>
      {createContactDialogVisible && (
        <CreateContactDialog
          {...props}
          dialogVisible={createContactDialogVisible}
          setDialogVisible={() => {
            setCreateContactDialogVisible(false);
          }}
        />
      )}
      {updateContactDialogVisible && (
        <UpdateContactDialog
          {...props}
          dialogVisible={updateContactDialogVisible}
          contactID={contactID}
          setDialogVisible={() => {
            setUpdateContactDialogVisible(false);
          }}
        />
      )}
      <TablePageCard
        title={"Contacts"}
        table={{ columns, data, options }}
        button={{
          isRendered: canAddContact(props.roles),
          label: "New Contact",
          handleClick: () => setCreateContactDialogVisible(true),
        }}
      />
    </>
  );
};

export default Contact;
