import { createSlice } from "@reduxjs/toolkit";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { LastCreatedObjectActions } from "src/redux/reducers/LastCreatedObject";
import { LoadedActions } from "src/redux/reducers/Loaded";
import { findSortedIndex } from "src/utilities/arrayMethods";

const initialState = [];

const midlevelSlice = createSlice({
  name: "midlevel",
  initialState,
  reducers: {
    batchAdd: (_state, action) => {
      return [...action.payload];
    },
    create: (state, action) => {
      const targetIndex = findSortedIndex(state, action.payload);
      state.splice(targetIndex, 0, { ...action.payload, Facility: [] });
    },
    update: (state, action) => {
      const targetIndex = state.findIndex(
        (elem) => Number(elem.ID) === Number(action.payload.ID)
      );
      state[targetIndex] = { ...state[targetIndex], ...action.payload };
    },
    addFacility: (state, action) => {
      let targetIndex = state.findIndex(
        (p) => Number(p.ID) === Number(action.payload.MidlevelID)
      );

      if (targetIndex === -1) {
        targetIndex = findSortedIndex(state, action.payload.BackUpMidlevel);
        state.splice(targetIndex, 0, {
          ...action.payload.BackUpMidlevel,
          Facility: [],
        });
      }
      state[targetIndex].Facility.push({
        ID: action.payload.FacilityID,
        facilityMidlevel: action.payload,
      });
    },
    removeFacility: (state, action) => {
      const removeMidlevel = action.payload.RemoveMidlevel;
      if (removeMidlevel) {
        const newState = state.filter(
          (m) => Number(m.ID) !== Number(action.payload.MidlevelID)
        );
        return newState;
      } else {
        const midlevelIndex = state.findIndex(
          (p) => Number(p.ID) === Number(action.payload.MidlevelID)
        );
        const facilityIndex = state[midlevelIndex].Facility.findIndex(
          (p) => Number(p.ID) === Number(action.payload.FacilityID)
        );
        state[midlevelIndex].Facility.splice(facilityIndex, 1);
      }
    },
    merge: (state, action) => {
      const sourceIndex = state.findIndex(
        ({ ID }) => action.payload.source === ID
      );
      const source = state[sourceIndex];
      const destination = state.find(
        ({ ID }) => action.payload.destination === ID
      );

      source.Facility.forEach((sourceFacility) => {
        if (
          destination.Facility.every(
            (destinationFacility) =>
              destinationFacility.ID !== sourceFacility.ID
          )
        ) {
          destination.Facility.push(sourceFacility);
        }
      });
      source.Facility = [];
      if (action.payload.deleteSource) {
        state.splice(sourceIndex, 1);
      }
    },
  },
});

const { batchAdd, create, update, addFacility, removeFacility, merge } =
  midlevelSlice.actions;

const BatchAdd = () => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(LoadedActions.SetMidlevel("pending"));
    const { data: midlevels } = await apiService.get(`${apiUrl}midlevel`);
    dispatch(batchAdd([...midlevels]));
    dispatch(LoadedActions.SetMidlevel(true));
  } catch (error) {
    dispatch(FetchingActions.Failure("Loading Midlevel"));
  }
};

const Create = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Midlevel/create`,
      content
    );
    dispatch(create(contents));
    dispatch(LastCreatedObjectActions.Set("Midlevel", contents));
    dispatch(FetchingActions.Success("Creating Midlevel"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Creating Midlevel"));
  }
};

const Update = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Midlevel/update`,
      content
    );
    dispatch(update(contents));
    dispatch(FetchingActions.Success("Updating Midlevel"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Midlevel"));
  }
};

export const CreateAndLinkToFacility = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Midlevel/create`,
      content
    );
    dispatch(create(contents));
    dispatch(
      LinkToFacility({
        FacilityID: Number(content.FacilityID),
        MidlevelID: contents.ID,
        Active: 1,
      })
    );
    dispatch(FetchingActions.Success("Creating Midlevel"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Creating Midlevel"));
  }
};

export const LinkToFacility = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Facility/addMidlevel`,
      content
    );
    dispatch(FacilityActions.AddMidlevel(contents));
    dispatch(
      addFacility({
        ...contents,
        BackUpMidlevel: content.BackUpMidlevel,
      })
    );
    dispatch(
      LastCreatedObjectActions.Set("AutoSelectMidlevel", {
        ...contents,
      })
    );
    dispatch(FetchingActions.Success("Linking Midlevel"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Linking Midlevel"));
  }
};

export const RemoveFromFacility = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Facility/removeMidlevel`,
      content
    );
    const payload = contents === 1 ? { ...content } : {};
    dispatch(FacilityActions.RemoveMidlevel(payload));
    dispatch(removeFacility(payload));
    dispatch(FetchingActions.Success("Removing Midlevel from Facility"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Removing Midlevel from Facility"));
  }
};

const Merge = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Midlevel/merge`,
      content
    );
    checkMergeResponse(contents);
    dispatch(merge(content));
    dispatch(FacilityActions.MergeMidlevels(content));
    dispatch(FetchingActions.Success("Midlevels Merged"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Midlevels Merged"));
  }
};

const checkMergeResponse = (data) => {
  if (!data) throw Error("endpoint failed");
};

export const MidlevelActions = {
  BatchAdd,
  Create,
  Update,
  CreateAndLinkToFacility,
  LinkToFacility,
  RemoveFromFacility,
  Merge,
};

export default midlevelSlice.reducer;
