import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Menu, MenuItem } from "@mui/material";
import { formatName, getContentById } from "src/utilities/objectUtilities";
import GeneratePDF from "src/components/individual/GeneratePDF";
import { canViewFaceSheet, canViewMRTSPDF } from "src/utilities/rbacFunctions";
import { selectFacilities, selectProviderByID } from "src/redux/selectors";
import ViewWorksheetDialog from "../../MRTSRecords/ViewWorksheetDialog";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const CustomBox = (props) => {
  const classes = useStyles();
  const getIcon = (name) => {
    switch (name) {
      case "Facility":
        return <i className="fad fa-hospital fa-6x" />;
      case "ARCHI Record Number":
        return (
          <i
            className="fad fa-address-card fa-6x fa-swap-opacity"
            style={{
              "--fa-secondary-opacity": 0.8,
              "--fa-primary-opacity": 0.2,
            }}
          />
        );
      case "Contacts":
        return <i className="fad fa-user-circle fa-6x" />;
      case "Provider":
        return (
          <i
            className="fad fa-user-md fa-6x"
            style={{
              "--fa-secondary-opacity": 0.8,
              "--fa-primary-opacity": 0.2,
            }}
          />
        );
      case "Facility Record Number":
        return (
          <i
            className="fad fa-id-badge fa-6x fa-swap-opacity"
            style={{
              "--fa-secondary-opacity": 0.8,
              "--fa-primary-opacity": 0.2,
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div
        className={`container-fluid ${classes.card}`}
        style={{ borderRadius: "15px", maxWidth: "20%", minHeight: "140px" }}
      >
        <div
          className={"row d-flex align-items-center justify-content-center"}
          style={{ height: "100%" }}
        >
          <div className={"col"}>
            <div className={"container-fluid"}>
              <div className={"row"}>
                <div className={"col d-flex justify-content-center"}>
                  <Typography
                    style={{ fontWeight: "bold", color: "lightgrey" }}
                  >
                    {props.name}
                  </Typography>
                </div>
              </div>
              <div className={"row"}>
                <div
                  className={"col d-flex justify-content-center"}
                  style={{ color: "white" }}
                >
                  {props.value}
                </div>
              </div>
            </div>
          </div>
          <div
            className={"col d-flex align-items-center justify-content-center"}
          >
            <div style={{ color: "white" }} className="Card-field">
              {getIcon(props.name)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DropDownBox = (props) => {
  const [isWorksheetDialogVisible, setIsWorksheetDialogVisible] =
    useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={"pb-3"}>
      {isWorksheetDialogVisible && (
        <ViewWorksheetDialog
          isDialogVisible={isWorksheetDialogVisible}
          onClose={() => setIsWorksheetDialogVisible(false)}
          recordID={props.record.ID}
        />
      )}
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant={"outlined"}
        color={"primary"}
        size={"small"}
      >
        Actions
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {canViewMRTSPDF && (
          <GeneratePDF recordID={props.record.ID} RecordType={"MRTS"}>
            <MenuItem onClick={handleClose}>
              <i className={"fas fa-file-pdf mr-3"} />
              View MRTS
            </MenuItem>
          </GeneratePDF>
        )}
        {canViewFaceSheet && (
          <GeneratePDF recordID={props.record.ID} RecordType={"Facesheet"}>
            <MenuItem onClick={handleClose}>
              <i className={"fas fa-file-pdf mr-3"} />
              View Facesheet
            </MenuItem>
          </GeneratePDF>
        )}
        {canViewMRTSPDF && (
          <GeneratePDF recordID={props.record.ID} RecordType={"FinalReport"}>
            <MenuItem onClick={handleClose}>
              <i className={"fas fa-file-pdf mr-3"} />
              View Final Report
            </MenuItem>
          </GeneratePDF>
        )}
        <GeneratePDF recordID={props.record.ID} RecordType={"Worksheet"}>
          <MenuItem onClick={handleClose}>
            <i className={"fas fa-file-pdf mr-3"} />
            View Worksheet
          </MenuItem>
        </GeneratePDF>
        <MenuItem
          onClick={() => {
            props.setEditTrackingDialogVisible();
            handleClose();
          }}
        >
          <i className={"fas fa-hiking mr-3"} />
          Update Tracking
        </MenuItem>
      </Menu>
    </div>
  );
};

const Header = (props) => {
  const facilityList = useSelector(selectFacilities);
  const providerValue = useSelector(
    selectProviderByID(props.record.ProviderID)
  );
  return (
    <div className={"container-fluid"}>
      <div className={"row d-flex justify-content-flex-start"}>
        <DropDownBox {...props} />
      </div>
      <div className={"row d-flex justify-content-between"}>
        <CustomBox
          name="ARCHI Record Number"
          value={props.record.ARCHIRecordID}
        />
        <CustomBox
          name="Facility"
          value={getContentById(facilityList, props.record.FacilityID).Name}
        />
        <CustomBox
          name="Provider"
          value={formatName(providerValue, "rnHeader")}
        />
        <CustomBox
          name="Facility Record Number"
          value={props.record.FacilityRecordID}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  card: { backgroundColor: theme.palette.primary.dark },
}));

export default Header;
