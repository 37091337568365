import React from "react";
import _ from "lodash";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import tabData from "./structure";

const RNTabs = ({ record, selectedTool, setSelectedTool, isFormDirty }) => {
  const checkTab = (name) => {
    const tabToCheck = tabData.find((tab) => name === tab.name);
    if (_.isEqual(record[tabToCheck.record.name], tabToCheck.record.empty))
      return false;
    if (selectedTool === name && isFormDirty) return false;
    if (
      tabToCheck.record.method &&
      tabToCheck.record.method(record[tabToCheck.record.name])
    )
      return false;
    return true;
  };

  return (
    <AppBar position={"relative"}>
      <Tabs value={selectedTool} aria-label="simple tabs example" centered>
        {tabData.map(({ name }, i) => (
          <Tab
            label={
              <div className={"text-white"}>
                <i
                  className={`fas ${
                    checkTab(name) ? "fa-check" : "fa-times"
                  } fa-check pr-2`}
                  style={{
                    color: `${checkTab(name) ? "#00C851" : "#FF4444"}`,
                  }}
                />
                {name}
              </div>
            }
            onClick={() => setSelectedTool(name)}
            {...a11yProps(i)}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default RNTabs;
