import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const reviewTypeSlice = createSlice({
  name: "reviewType",
  initialState,
  reducers: {
    batchAdd: (_state, action) => {
      return [...action.payload.reviewType];
    },
  },
});

const { batchAdd } = reviewTypeSlice.actions;

const BatchAdd = (content) => async (dispatch) => {
  dispatch(batchAdd(content));
};

export const ReviewTypeActions = {
  BatchAdd,
};

export default reviewTypeSlice.reducer;
