import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificDischargeInstructionsTeachbacksChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Discharge Instructions - Teach backs"}
        xAxisLabel={"Discharge Instructions - Teach back"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: Discharge Instructions - Teach backs"}
      />
    </>
  );
};

export default FacilitySpecificDischargeInstructionsTeachbacksChart;
