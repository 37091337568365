import React from "react";
import RenderEachItem from "../RenderEachItem";
import Typography from "@mui/material/Typography";

const Title = ({ label, items }) => {
  return (
    <>
      <Typography tag={"h4"} variant={"h4"}>
        {label}
      </Typography>
      <RenderEachItem items={items} />
    </>
  );
};

export default Title;
