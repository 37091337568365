import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityAbbreviationUseChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        hideChart={true}
        title={"All Facility: Abbreviation Use"}
        xAxisLabel={"Abbreviation Used"}
        yAxisLabel={"Number of Records"}
        figureText={"Abbreviation Use for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilityAbbreviationUseChart;
