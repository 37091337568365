import React from "react";

const TextBegin = (props) => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>Peer Review Outcomes</h4>
      </div>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              Measuring patient outcomes provides the opportunity to measure the
              effectiveness of the care or service provided. This activity also
              provides the participating facilities with the opportunity to
              document compliance with monitoring The Joint Commission
              MS.03.01.01, Management of Patient Care, Treatment, and Services.
              State regulatory guidelines also require a process for monitoring
              the quality of patient care activities. Findings such as those
              presented here must be viewed with caution, as patient care is
              dependent upon a team of care providers and not a single
              individual. Throughout the year participating physicians receive
              current up-to- date literature reviews consistent with patient
              care discussions. The Joint Commission and State Hospital
              Licensing guidelines both require the medical staff to be involved
              in processes that not only monitor and evaluate the quality of
              care a patient receives (usually done through peer review), but
              also require ongoing monitoring of a physician's ability to
              practice effectively, now outlined in the Joint Commission
              FPPE/OPPE requirements. The measurement of physician compliance
              with acceptable standards and guidelines by his or her peers is
              one effective way to help meet this requirement.
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextBegin;
