import React from "react";
import { CircularProgress } from "@mui/material";
import ContentAreaReport from "./ContentAreaReport";

const ContentArea = (props) => {
  return (
    <div
      style={{ width: "100%", height: "100%" }}
      className={"d-flex align-items-center justify-content-center"}
    >
      {props.Report.Loading ? (
        <>
          <CircularProgress size={"5rem"} />
        </>
      ) : (
        <>
          {props.Report.Fetched ? (
            <ContentAreaReport {...props} />
          ) : (
            <p>
              Select report parameters, then click <b>View Report</b> to
              preview, and then <b>Print Preview</b> to save.
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default ContentArea;
