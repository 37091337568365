import React from "react";
import { TextField } from "mui-rff";
import { FinalizeButtons } from "../Shared";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)(() => ({
  "& .MuiInputBase-input": {
    fontSize: "1.5em",
  },
}));

const PeerReviewNotesSection = () => {
  return (
    <div className={"col-4 border border-dark"}>
      <div className={"row"}>
        <div className={"col text-left  h2"}>Peer Review Notes</div>
      </div>
      <div className={"row"}>
        <div className={"col"}>
          <CustomTextField
            name={"PeerReviewNotes"}
            multiline
            rows={30}
            variant="outlined"
          />
          <FinalizeButtons />
        </div>
      </div>
    </div>
  );
};

export default PeerReviewNotesSection;
