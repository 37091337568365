import React, { useMemo } from "react";
import { Autocomplete } from "mui-rff";
import { useSelector } from "react-redux";
import { selectRecordsApprovedByFacilityID } from "src/redux/selectors";

const SelectRecord = ({ facilityID = null }) => {
  const records = useSelector(selectRecordsApprovedByFacilityID(facilityID));
  const recordOptions = useMemo(
    () =>
      records
        .filter(({ FacilityID }) => FacilityID === facilityID)
        .map(({ ARCHIRecordID }) => ({
          label: ARCHIRecordID,
          value: ARCHIRecordID,
        })),
    [records, facilityID]
  );

  return (
    <Autocomplete
      name={"ARCHIRecordID"}
      label={"Record"}
      autoComplete
      includeInputInList
      options={recordOptions}
      getOptionValue={(x) => x.value}
      getOptionLabel={(x) => x.label}
    />
  );
};

export default SelectRecord;
