import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificOutcomesByProviderChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Outcomes by Provider"}
        xAxisLabel={"Peer Review Outcome"}
        yAxisLabel={"Number of Providers"}
        figureText={
          "Facility-Specific: Records by Peer Review Outcome and Provider"
        }
      />
    </>
  );
};

export default FacilitySpecificOutcomesByProviderChart;
