import React from "react";
import ReportKeys from "../../../ReportKeys";
import GroupedDefault from "./GroupedDefault";
import GroupedWithProvider from "./GroupedWithProvider";
import UngroupedDefault from "./UngroupedDefault";
import UngroupedOutcomesByProvider from "./UngroupedOutcomesByProvider";
import UngroupedProviderRecordsReviewedByCommittee from "./UngroupedProviderRecordsReviewedByCommittee";
import UngroupedReviewReasonByProvider from "./UngroupedReviewReasonByProvider";
import DynamicTable from "./DynamicTable";
import AbbreviationUseTable from "./AbbreviationUseTable";

const ChartTable = (props) => {
  let table = <></>;
  if (
    props.values.displayOptions.groupBy === null &&
    props.values.displayOptions.displayType === null
  ) {
    table = UngroupedDefault(props);
  } else {
    switch (props.values.displayOptions.groupBy) {
      case "monthly":
      case "quarterly":
      case "yearly": {
        switch (props.reportKey) {
          case ReportKeys.AbbreviationUse:
          case ReportKeys.AbbreviationUseAllFacilities: {
            table = AbbreviationUseTable(props);
            break;
          }
          case ReportKeys.PatientAge:
          case ReportKeys.PatientAgeAllFacilities:
          case ReportKeys.LengthOfStayAllFacilities:
          case ReportKeys.LengthOfStay: {
            table = DynamicTable(props);
            break;
          }
          case ReportKeys.OutcomesByProvider:
          case ReportKeys.ProviderRecordsReviewedByCommittee:
          case ReportKeys.ReasonForReviewByProvider: {
            table = GroupedWithProvider(props);
            break;
          }
          default: {
            table = GroupedDefault(props);
            break;
          }
        }
        break;
      }
      default: {
        switch (props.reportKey) {
          case ReportKeys.OutcomesByProvider: {
            table = UngroupedOutcomesByProvider(props);
            break;
          }
          case ReportKeys.ProviderRecordsReviewedByCommittee: {
            table = UngroupedProviderRecordsReviewedByCommittee(props);
            break;
          }
          case ReportKeys.ReasonForReviewByProvider: {
            table = UngroupedReviewReasonByProvider(props);
            break;
          }
          default: {
            table = UngroupedDefault(props);
            break;
          }
        }
        break;
      }
    }
  }
  return <>{table}</>;
};

export default ChartTable;
