import React from "react";

const AdditionalAbbreviationsAcronymsAndSymbols = () => {
  return (
    <>
      <div>
        <h6 className={"font-weight-bold"}>
          Additional Abbreviations, Acronyms, and Symbols
        </h6>
      </div>
      <div>
        <p>
          <small>
            (For possible future inclusion in the Official "Do Not Use" List)
          </small>
        </p>
      </div>
      <table className={"table"}>
        <thead>
          <tr>
            <th scope={"col"}>
              <b>Do Not Use</b>
            </th>
            <th scope={"col"}>
              <b>Potential Problem</b>
            </th>
            <th scope={"col"}>
              <b>Use Instead</b>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>&gt; (greater than)</div>
              <div>&lt; (less than)</div>
            </td>
            <td>
              <div>
                Misinterpreted as the number "7" (seven) or the letter "L"
              </div>
              <div>Confused for one another</div>
            </td>
            <td>
              <div>Write "greater than"</div>
              <div>Write "less than"</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Abbreviations for drug names</div>
            </td>
            <td>
              <div>
                Misinterpreted due to similar abbreviations for multiple drugs
              </div>
            </td>
            <td>
              <div>Write drug names in full</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Apothecary units</div>
            </td>
            <td>
              <div>Unfamiliar to many practitioners</div>
              <div>Confused with metric units</div>
            </td>
            <td>
              <div>Use metric units</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>@</div>
            </td>
            <td>
              <div>Mistaken for the number "2" (two)</div>
            </td>
            <td>
              <div>Write "at"</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>cc</div>
            </td>
            <td>
              <div>Mistaken for U (units) when poorly written</div>
            </td>
            <td>
              <div>Write "mL" or "ml" or "milliliters" ("mL" is preferred)</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>μg</div>
            </td>
            <td>
              <div>
                Mistaken for mg (milligrams) resulting in one thousand-fold
                overdose
              </div>
            </td>
            <td>
              <div>Write "mcg" or "micrograms"</div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default AdditionalAbbreviationsAcronymsAndSymbols;
