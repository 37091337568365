import React from "react";
import { useSelector } from "react-redux";
import RecursiveComponent from "./RecursiveComponents";
import { selectMeetings, selectOutcomes } from "src/redux/selectors";
import createOutcomeStructure from "src/components/templateStructures/outcomeStructure";

const RNOutcome = () => {
  const meetings = useSelector(selectMeetings);
  const outcomes = useSelector(selectOutcomes);
  return (
    <RecursiveComponent items={createOutcomeStructure(meetings, outcomes)} />
  );
};

export default RNOutcome;
