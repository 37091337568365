import React from "react";
import { useSelector } from "react-redux";
import { Card, CardContent } from "@mui/material";
import { selectRNWorksheetByRecordID } from "src/redux/selectors";
import WorksheetForm from "./WorksheetForm";

const Worksheet = (props) => {
  const rnWorksheet = useSelector(selectRNWorksheetByRecordID(props.record.ID));

  return (
    <Card>
      <CardContent className="mx-4 mt-0">
        <WorksheetForm
          {...props}
          record={props.record}
          rnWorksheet={rnWorksheet}
          dispatch={props.dispatch}
          recordID={props.record.ID}
        />
      </CardContent>
    </Card>
  );
};

export default Worksheet;
