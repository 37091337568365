import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificPossibleImprovementsChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Possible Improvements"}
        xAxisLabel={"Improvement"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: Records by Possible Improvements"}
      />
    </>
  );
};

export default FacilitySpecificPossibleImprovementsChart;
