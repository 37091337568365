import React, { useContext } from "react";
import { Select } from "mui-rff";
import { makeStyles } from "@mui/styles";
import { recordReviewData } from "src/components/pages/MeetingManagement/RecordReview";
import C from "src/utilities/constants";
import get from "../../../helperFunctions/getData";

const Dropdown = ({ name, label, render: { options = [] } }) => {
  const classes = useStyles();
  const apiResponse = useContext(recordReviewData);
  const mrtsReviewTypeID = get.MRTSReviewTypeID(apiResponse);

  return (
    <Section>
      <Select
        name={name}
        label={label}
        data={options}
        variant={"outlined"}
        disabled={mrtsReviewTypeID !== C.DIRECT_REVIEW_ID}
        className={classes.textLeft}
      />
    </Section>
  );
};

export const Section = ({ children }) => (
  <div className={"row"} style={{ marginTop: 25, marginBottom: 25 }}>
    <div className={"col"}>{children}</div>
  </div>
);

const useStyles = makeStyles(() => ({
  textLeft: {
    textAlign: "left",
  },
  box: {
    bgcolor: "background.paper",
    borderColor: "text.primary",
    m: 1,
    border: 1,
    style: { width: "5rem", height: "2rem" },
  },
  input: {
    color: "red",
  },
}));

export default Dropdown;
