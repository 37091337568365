import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import FacilitySpecificOutcomesByProviderChart from "./FacilitySpecificOutcomesByProviderChart";
import TextBegin from "./TextBegin";
import TextEnd from "./TextEnd";
import _ from "lodash";
import MidlevelTable from "./MidlevelTable";

const OutcomesByProvider = (props) => {
  const customProps = _.cloneDeep(props);
  const midlevels = props.Report.Data.OutcomesByProvider[1];
  customProps.Report.Data.OutcomesByProvider =
    props.Report.Data.OutcomesByProvider[0];

  const chartData = ChartDataBuilder(customProps, props.reportKey, ["outcome"]);

  return (
    <>
      <TextBegin {...props} />
      <FacilitySpecificOutcomesByProviderChart
        {...customProps}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Peer Review Outcome"}
        rows={chartData.rows}
      />
      <>
        {midlevels.map((elem) => {
          const key = Object.keys(elem)[0];
          return (
            <MidlevelTable
              title={`Supervising Provider: ${key}`}
              midlevels={elem[key]}
              columnName={"Peer Review Outcome"}
            />
          );
        })}
        <br />
      </>
      <TextEnd {...props} />
    </>
  );
};

export default OutcomesByProvider;
