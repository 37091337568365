import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilitySystemIssuesChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: System Issues"}
        xAxisLabel={"System Issues"}
        yAxisLabel={"Number of Records"}
        figureText={"System Issues for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilitySystemIssuesChart;
