import React from "react";
// ui
import { Alert, MenuItem } from "@mui/material";
import { Select, TextField } from "mui-rff";

const rejectionReasons = [
  {
    Name: "Facility Requested Cancellation of Review",
    ID: 0,
  },
  {
    Name: "Review Specialty Unavailable",
    ID: 1,
  },
  {
    Name: "Other Reason",
    ID: 2,
  },
];

const RejectApprovedMRTS = () => {
  return (
    <>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <h5>Are you sure that you want to Reject this MRTS?</h5>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <b>
            Provide a Rejection Reason, include any comments, then click Reject.
          </b>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <Select
            variant={"outlined"}
            label={"Rejection Reason"}
            displayEmpty
            name="rejectionReason"
            required
          >
            <MenuItem value={null}>Select a Rejection Reason</MenuItem>
            {rejectionReasons.map((rejectionReason) => (
              <MenuItem
                value={rejectionReason.Name}
                key={`create-mrts-record-rejection-reason-select-${rejectionReason.Name}-${rejectionReason.ID}`}
              >
                {rejectionReason.Name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <TextField
            label={"Rejection Comment"}
            name={"rejectionComment"}
            variant={"outlined"}
            rows={8}
            multiline
          />
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <Alert severity="error">
            <b>WARNING</b>: Record Status will be changed to "Rejected"
          </Alert>
        </div>
      </div>
    </>
  );
};

export default RejectApprovedMRTS;
