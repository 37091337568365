const PatientGender = [
  { ID: false, Name: "Male", Active: 1 },
  { ID: true, Name: "Female", Active: 1 },
];

let constants = {
  ABBREVIATION_ID_NO_ABBREVIATIONS: 9,
  ABBREVIATION_ID_NO_ADDITIONAL_ABBREVIATIONS: 18,
  REFERENCE_MATERIAL_RECORD_COMMENT_TYPE: "Reference Material",
  REFERENCE_MATERIAL_ID_OTHER: 6,
  ADDITIONAL_INFORMATION_RECORD_COMMENT_TYPE: "Additional Information",
  COMMITTEE_QUESTION_RECORD_COMMENT_TYPE: "Committee Question",
  PATIENT_GENDER: PatientGender,
  COMMITTEE_ID_SX: 10,
  COMMITTEE_ID_NEURO_SX: 16,
  COMMITTEE_ID_OB_GYN: 18,
  COMMITTEE_ID_ORTHOPEDIC_SX: 21,
  COMMITTEE_ID_PLASTIC_SX: 25,
  COMMITTEE_ID_RADIOLOGY: 29,
  COMMITTEE_ID_OTHER: 35,
  COMMITTEE_ID_VASCULAR_SX: 43,
  REVIEW_REASONS_ID_OTHER: 16,
  COMMENT_TYPE_ID_REFERENCE_MATERIAL: 1,
  COMMENT_TYPE_ID_ADDITIONAL_INFORMATION: 2,
  COMMENT_TYPE_ID_COMMITTEE_QUESTION: 3,
  PARTICIPATING_HOSPITAL: [5, 7, 8, 10, 13, 18, 21, 24],
  PATIENT_SOURCE_ID_OTHER: 7,
  TIME_ZONE: "+05:00",
  TIME_ZONE_NUM: 5,
  PATIENT_SOURCE_ASSISTED_LIVING_ID: 2,
  AGE_INCREMENT_YEAR: 1,
  AGE_INCREMENT_MONTH: 2,
  AGE_INCREMENT_DAYS: 3,
  AGE_INCREMENT_NEW_BORN: 4,
  AGE_INCREMENT_89_PLUS: 5,
  TRACKING_MODAL: "TRACKING_MODAL",
  OUTCOMES_MODAL: "OUTCOMES_MODAL",
  SIGNATURES_MODAL: "SIGNATURES_MODAL",
  APPENDIX_MODAL: "APPENDIX_MODAL",
  WORKSHEET_MODAL: "WORKSHEET_MODAL",
  COMMITTEE_REVIEW_ID: 1,
  DIRECT_REVIEW_ID: 2,
  REVIEW_STATUS: {
    PENDING_FACILITATOR_REVIEW: "Pending Facilitator Review",
    FACILITATOR_REVIEW_IN_PROGRESS: "Facilitator Review In Progress",
    PENDING_RN_APPROVAL: "Pending RN Approval",
    RN_REVIEW_IN_PROGRESS: "RN Review In Progress",
    APPROVED: "Approved",
  },
  PATIENT_DISPOSITION_ID: 63,
};

constants.COMMITTEE_CHECK = [
  constants.COMMITTEE_ID_SX,
  constants.COMMITTEE_ID_NEURO_SX,
  constants.COMMITTEE_ID_ORTHOPEDIC_SX,
  constants.COMMITTEE_ID_PLASTIC_SX,
  constants.COMMITTEE_ID_VASCULAR_SX,
];

constants.HAIR_REMOVAL_COMMITTEE_CHECK = [
  constants.COMMITTEE_ID_SX,
  constants.COMMITTEE_ID_NEURO_SX,
  constants.COMMITTEE_ID_OB_GYN,
  constants.COMMITTEE_ID_ORTHOPEDIC_SX,
  constants.COMMITTEE_ID_PLASTIC_SX,
  constants.COMMITTEE_ID_VASCULAR_SX,
];

constants.VERSION = "2.3.13b";

export default constants;
