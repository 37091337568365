import React from "react";
import { Field } from "react-final-form";

const Condition = ({ when, is, ignore, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (ignore || value === is ? children : null)}
  </Field>
);

export default Condition;
