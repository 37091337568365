import React from "react";

const TextBegin = (props) => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>Legibility Issues by Provider Type</h4>
      </div>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              The legibility of physician orders has long been an issue. Patient
              safety literature attributes many medical errors to poorly written
              or illegible orders. The issue of legibility throughout the
              medical record can also be a concern in the event of medical
              litigation. When medical records are submitted to peer review,
              they undergo screening for legibility.
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextBegin;
