import React from "react";

const UngroupedDefault = (props) => {
  const labels = props.labels;
  const data = props.data;
  const sum = data.reduce((a, b) => a + b, 0);
  const rows = [];
  labels.forEach((label, i) => {
    if (data[i] === 0) return;
    rows.push(
      <tr key={`report-table-row-${label}-${i}`}>
        <td class={"pl-5"} style={{ textAlign: "left" }}>
          {label}
        </td>
        <td>{data[i]}</td>
        <td>{Math.round(100 * ((data[i] / sum) * 100)) / 100}%</td>
      </tr>
    );
  });
  return (
    <table
      className={"table table-bordered table-striped table-hover table-sm"}
    >
      <thead>
        <tr>
          <th scope={"col"}>
            <b>{props.heading}</b>
          </th>
          <th scope={"col"}>
            <b>Total</b>
          </th>
          <th scope={"col"}>
            <b>Percentage</b>
          </th>
        </tr>
      </thead>
      <tbody>{rows !== undefined && rows}</tbody>
    </table>
  );
};

export default UngroupedDefault;
