import React, { useState } from "react";
import PageCard from "src/components/individual/PageCard";
import { useSelector } from "react-redux";
import {
  selectContent,
  selectFacilities,
  selectLoaded,
  selectRecords,
} from "src/redux/selectors";
import { useEffectOnce } from "react-use";
import { RecordActions } from "src/redux/reducers/Record";
import { MeetingActions } from "src/redux/reducers/Meeting";
import ActionsButton from "./ActionsButton";
import CreateMRTSRecordDialog from "./MRTSRecordDialog/CreateMRTSRecordDialog";
import UpdateMRTSRecordDialog from "./MRTSRecordDialog/UpdateMRTSRecordDialog";
import RejectApprovedRecordDialog from "./RejectApprovedRecordDialog";
import EditTrackingDialog from "./EditTrackingDialog";
import { facilityUser } from "src/utilities/rbacFunctions";
import ViewRejectionDialog from "./ViewRejectionDialog";
import { useNavigate } from "react-router-dom";
import ViewWorksheetDialog from "./ViewWorksheetDialog";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MUIDataTable from "mui-datatables";
import { format } from "date-fns";
import { ContactActions } from "src/redux/reducers/PersonnelManagement/Contact";
import { ProviderActions } from "src/redux/reducers/PersonnelManagement/Provider";
import { MidlevelActions } from "src/redux/reducers/PersonnelManagement/Midlevel";
import getUTCDate from "src/utilities/getUTCDate";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const options = {
  filterType: "multiselect",
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
};

const DropdownDashboard = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const facilityView = facilityUser(props.roles);
  const viewLevel = 2;
  const [selectedFacilityID, setSelectedFacilityID] = useState(null);
  const [createMRTSRecordDialogVisible, setCreateMRTSRecordDialogVisible] =
    useState(false);
  const [updateMRTSRecordDialogVisible, setUpdateMRTSRecordDialogVisible] =
    useState(false);
  const [editTrackingDialogVisible, setEditTrackingDialogVisible] =
    useState(false);
  const [recordID, setRecordID] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [viewRejectionDialogVisible, setViewRejectionDialogVisible] =
    useState(false);
  const [isWorksheetDialogVisible, setIsWorksheetDialogVisible] =
    useState(false);
  const [rejectMRTSRecordVisible, setRejectMRTSDialogVisible] =
    React.useState(false);
  const Records = useSelector(selectRecords);
  const ReviewTypes = useSelector(selectContent("ReviewType"));
  const RecordStatuses = useSelector(selectContent("RecordStatus"));
  const Loaded = useSelector(selectLoaded);
  const Facilities = useSelector(selectFacilities);
  let AllFacilities = [
    {
      ID: "All",
      City: "All",
      Name: "All Facilities",
      FullName: "(All) - All Facilities",
      Active: 1,
    },
  ];
  const render =
    Loaded.Record &&
    Loaded.Facility &&
    Loaded.Constants &&
    Loaded.Contact &&
    Loaded.Provider &&
    Loaded.Midlevel;
  let data = [];

  const columns = [
    {
      name: "archiRecordID",
      label: "ARCHI Record ID",
    },
    {
      name: "facilityRecordID",
      label: "Facility Record ID",
    },
    {
      name: "facilityName",
      label: "Facility Name",
    },
    {
      name: "reviewType",
      label: "Review Type",
    },
    {
      name: "mrtsReviewType",
      label: "MRTS Review Type",
    },
    {
      name: "submissionDate",
      label: "Submission Date",
    },
    {
      name: "recordStatus",
      label: "Record Status",
    },
    {
      name: "actions",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
      },
    },
  ];

  useEffectOnce(() => {
    Loaded.Record === false &&
      props.dispatch(RecordActions.BatchAdd(props.roles));
    if (!facilityView) {
      Loaded.Meeting === false &&
        props.dispatch(MeetingActions.BatchAdd(props.roles));
      Loaded.Facility === false &&
        props.dispatch(FacilityActions.BatchAdd(props.roles));
      Loaded.Contact === false &&
        props.dispatch(ContactActions.BatchAdd(props.roles));
      Loaded.Provider === false &&
        props.dispatch(ProviderActions.BatchAdd(props.roles));
      Loaded.Midlevel === false &&
        props.dispatch(MidlevelActions.BatchAdd(props.roles));
    }
  });

  if (render) {
    AllFacilities = [...AllFacilities, ...Facilities];
    const selectedRecords =
      selectedFacilityID === "All"
        ? Records
        : Records.filter((r) => r.FacilityID === selectedFacilityID);
    data = selectedRecords.map((record, i) => {
      const archiRecordID =
        record.ARCHIRecordID !== null ? record.ARCHIRecordID : "-";
      const reviewType =
        record.ReviewTypeID !== null &&
        ReviewTypes.find((r) => r.ID === record.ReviewTypeID).Name;
      const mrtsReviewType =
        record.MRTSReviewTypeID !== null && record.MRTSReviewTypeID === 1
          ? "Committee"
          : "Direct";
      const facility =
        record.FacilityID !== null &&
        Facilities.find((f) => f.ID === record.FacilityID);
      const recordStatus =
        record.ApprovalStatus === 0
          ? "Rejected"
          : record.ApprovalStatus === 1
          ? "Pending Approval"
          : record.RecordStatusID !== null
          ? RecordStatuses.find((r) => r.ID === record.RecordStatusID).Name
          : "-";
      return {
        archiRecordID,
        facilityRecordID: record.FacilityRecordID,
        facilityName: facility !== null ? facility.FullName : "-",
        reviewType,
        mrtsReviewType,
        submissionDate: record.SubmissionDate
          ? format(getUTCDate(new Date(record.SubmissionDate)), "MM/dd/yyyy")
          : "-",
        recordStatus,
        actions: (
          <ActionsButton
            {...props}
            approvalStatus={record.ApprovalStatus}
            setApprovalStatus={() => setApprovalStatus(record.ApprovalStatus)}
            viewLevel={viewLevel}
            id={i}
            record={record}
            recordID={record.ID}
            spawnRecordTrackingDialog={() => {
              setRecordID(record.ID);
              setEditTrackingDialogVisible(true);
            }}
            sendToRNTools={() => {
              navigate(`/RNTools/${record.ID}`);
            }}
            viewWorksheet={() => {
              setRecordID(record.ID);
              setIsWorksheetDialogVisible(true);
            }}
            spawnUpdateMRTSRecordDialog={() => {
              setRecordID(record.ID);
              setUpdateMRTSRecordDialogVisible(true);
            }}
            spawnViewRejectionDialog={() => {
              setRecordID(record.ID);
              setViewRejectionDialogVisible(true);
            }}
            spawnRejectMRTSRecordDialog={() => {
              setRecordID(record.ID);
              setRejectMRTSDialogVisible(true);
            }}
          />
        ),
      };
    });
  }

  return (
    <>
      {createMRTSRecordDialogVisible && (
        <CreateMRTSRecordDialog
          {...props}
          viewLevel={viewLevel}
          dialogVisible={createMRTSRecordDialogVisible}
          setDialogVisible={() => {
            setCreateMRTSRecordDialogVisible(false);
            setApprovalStatus(null);
          }}
        />
      )}
      {updateMRTSRecordDialogVisible && (
        <UpdateMRTSRecordDialog
          {...props}
          viewLevel={viewLevel}
          approvalStatus={approvalStatus}
          dialogVisible={updateMRTSRecordDialogVisible}
          setDialogVisible={() => {
            setUpdateMRTSRecordDialogVisible(false);
            setRecordID(null);
            setApprovalStatus(null);
          }}
          recordID={recordID}
        />
      )}
      {editTrackingDialogVisible && (
        <EditTrackingDialog
          {...props}
          dialogVisible={editTrackingDialogVisible}
          setDialogVisible={() => {
            setEditTrackingDialogVisible(false);
            setRecordID(null);
            setApprovalStatus(null);
          }}
          recordID={recordID}
        />
      )}
      {viewRejectionDialogVisible && (
        <ViewRejectionDialog
          {...props}
          dialogVisible={viewRejectionDialogVisible}
          setDialogVisible={() => {
            setViewRejectionDialogVisible(false);
            setRecordID(null);
            setApprovalStatus(null);
          }}
          recordID={recordID}
        />
      )}
      {rejectMRTSRecordVisible && (
        <RejectApprovedRecordDialog
          {...props}
          dialogVisible={rejectMRTSRecordVisible}
          setDialogVisible={() => {
            setRejectMRTSDialogVisible(false);
            setRecordID(null);
            setApprovalStatus(null);
          }}
          recordID={recordID}
        />
      )}
      {isWorksheetDialogVisible && (
        <ViewWorksheetDialog
          isDialogVisible={isWorksheetDialogVisible}
          onClose={() => {
            setIsWorksheetDialogVisible(false);
            setRecordID(null);
          }}
          recordID={recordID}
        />
      )}
      <PageCard
        title={"MRTS Records"}
        render={
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col d-flex justify-content-flext-start"}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Facility
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant={"outlined"}
                    onChange={(e) => setSelectedFacilityID(e.target.value.ID)}
                  >
                    {AllFacilities.filter(({ Active }) => Active).map((f) => (
                      <MenuItem key={f.FullName} value={f}>
                        {f.FullName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col d-flex justify-content-flex-start pl-4"}>
                <Button
                  color={"primary"}
                  variant={"outlined"}
                  startIcon={<AddIcon />}
                  onClick={() => setCreateMRTSRecordDialogVisible(true)}
                >
                  New MRTS
                </Button>
              </div>
            </div>
            {selectedFacilityID !== null && (
              <div className={"row pt-3"}>
                <div className={"col"}>
                  <MUIDataTable
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </div>
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

export default DropdownDashboard;
