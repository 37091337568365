import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "mui-rff";
import { ReportTemplatesActions } from "src/redux/reducers/ReportTemplates";

const SaveTemplateDialog = (props) => {
  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={props.saveTemplateDialogVisible}
      onClose={() => {
        props.setSaveTemplateDialogVisible(false);
      }}
    >
      <DialogTitle>Save Template</DialogTitle>
      <DialogContent>
        <div className={"row"}>
          <div className={"col"}>
            <TextField
              label={"Template Name"}
              name={"templateName"}
              variant={"outlined"}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color={"secondary"}
          variant={"contained"}
          onClick={() => {
            props.setSaveTemplateDialogVisible(false);
          }}
        >
          Close
        </Button>
        <Button
          color={"primary"}
          variant={"contained"}
          disabled={props.values.templateName === null}
          onClick={() => {
            props.dispatch(ReportTemplatesActions.Add(props.values));
            props.setSaveTemplateDialogVisible(false);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveTemplateDialog;
