import React from "react";
import commentsTemplate from "./template";
import BoxWrapper from "./BoxWrapper";

const CommentSections = ({ reviewID, initialValues }) => {
  return (
    <div style={{ flex: 1, height: "80vh", overflowY: "auto" }}>
      <BoxWrapper
        items={commentsTemplate(reviewID)}
        initialValues={initialValues}
      />
    </div>
  );
};

export default CommentSections;
