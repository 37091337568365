import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "./PDFStyles";
import DiamondIcon from "./Graphics/DiamondIcon";

const Footer = () => {
  const intentionalWhiteSpace = "      ";

  return (
    <View fixed style={styles.finalFooter}>
      <Text style={styles.footerText}>
        Crystal Park Plaza, 2700 Earl Rudder Freeway South, Suite 3000, College
        Station, TX 77845{intentionalWhiteSpace}www.architexas.org
      </Text>
      <DiamondIcon bottom={10} left={370.5} />
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </View>
  );
};

export default Footer;
