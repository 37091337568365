import React from "react";
import ApprovedAdminButton from "./ApprovedAdminButton";
import RejectedButton from "./RejectedButton";
import UnapprovedAdminButton from "./UnapprovedAdminButton";
import UnapprovedFacilityButtons from "./UnapprovedFacilityButtons";
import ApprovedFacilityAdminButton from "./ApprovedFacilityAdminButton";

const ActionsButton = (props) => {
  if (props.approvalStatus === 0) {
    switch (props.viewLevel) {
      case 0: {
        if (props.record.CreatedBy === props.userData.current.UserID) {
          return <RejectedButton {...props} />;
        }
        return "-";
      }
      default: {
        return <RejectedButton {...props} />;
      }
    }
  } else if (props.approvalStatus === 1) {
    switch (props.viewLevel) {
      case 1: {
        return <UnapprovedFacilityButtons {...props} />;
      }
      case 2: {
        return <UnapprovedAdminButton {...props} />;
      }
      case 0:
      default: {
        return <UnapprovedFacilityButtons {...props} />;
      }
    }
  } else {
    switch (props.viewLevel) {
      case 1: {
        return <ApprovedFacilityAdminButton {...props} />;
      }
      case 2: {
        return <ApprovedAdminButton {...props} />;
      }
      case 0:
      default: {
        return <ApprovedFacilityAdminButton {...props} />;
      }
    }
  }
};

export default ActionsButton;
