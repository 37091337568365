import React from "react";
import { MenuItem } from "@mui/material";
import { Select } from "mui-rff";

const YearlyRange = (props) => {
  return (
    <>
      <div className={"row pt-2 pr-2"}>
        <div className={"col"}>
          <Select
            name={"dateRange.range.startingYear"}
            label={"Starting Year"}
            labelId="reports-starting-year-select-label"
            id="reports-starting-year-select"
            variant={"outlined"}
            disabled={props.disabled}
            displayEmpty
            required={props.values.dateRange.type === "yearly"}
          >
            <MenuItem value={null}>Select a Starting Year</MenuItem>
            {props.yearOptions.map((option) => (
              <MenuItem
                key={`requirements-starting-year-${option.name}-${option.value}`}
                value={option.name}
              >
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={"row pt-2 pr-2"}>
        <div className={"col"}>
          <Select
            name={"dateRange.range.endingYear"}
            label={"Ending Year"}
            labelId="reports-ending-year-select-label"
            id="reports-ending-year-select"
            variant={"outlined"}
            disabled={props.disabled}
            displayEmpty
            required={props.values.dateRange.type === "yearly"}
          >
            <MenuItem value={null}>Select an Ending Year</MenuItem>
            {props.yearOptions.map((option) => (
              <MenuItem
                key={`requirements-ending-year-${option.name}-${option.value}`}
                value={option.name}
              >
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </>
  );
};

export default YearlyRange;
