import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityPatientDispositionChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Patient Disposition"}
        xAxisLabel={"Patient Disposition"}
        yAxisLabel={"Number of Records"}
        figureText={"Patient Disposition for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilityPatientDispositionChart;
