import React from "react";

const TextBegin = (props) => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>System Issues</h4>
      </div>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              Systems Issues are items that are identified during the review
              that pertain to the facility that were thought to have impacted
              care rendered by the provider/facility. Review of processes
              related to these issues could improve care in the future.
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextBegin;
