import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificPatientAgeChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Patient Age (Years)"}
        xAxisLabel={"Patient Age (Years)"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: Records by Patient Age (Years)"}
      />
    </>
  );
};

export default FacilitySpecificPatientAgeChart;
