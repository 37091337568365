import React from "react";
import { format } from "date-fns";
import { facilityAdmin } from "src/utilities/rbacFunctions";

const months = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

const quarters = {
  0: "Q1",
  1: "Q2",
  2: "Q3",
  3: "Q4",
};

const Heading = (props) => {
  const facilityName = facilityAdmin(props.roles)
    ? props.FacilityName
    : props.Facilities.find(
        (facility) => facility.ARCHIFacilityID === props.values.archiFacilityId
      ).Name;
  let headingDate;
  switch (props.values.dateRange.type) {
    case "monthly": {
      headingDate = `${months[props.values.dateRange.range.startingMonth]} ${
        props.values.dateRange.range.startingYear
      } to ${months[props.values.dateRange.range.endingMonth]} ${
        props.values.dateRange.range.endingYear
      }`;
      break;
    }
    case "quarterly": {
      headingDate = `${
        quarters[props.values.dateRange.range.startingQuarter]
      } ${props.values.dateRange.range.startingYear} to ${
        quarters[props.values.dateRange.range.endingQuarter]
      } ${props.values.dateRange.range.endingYear}`;
      break;
    }
    case "yearly": {
      headingDate = `${props.values.dateRange.range.startingYear} to ${props.values.dateRange.range.endingYear}`;
      break;
    }
    case "custom": {
      headingDate = `${format(
        props.values.dateRange.range.startDate,
        "PPP"
      )} to ${format(props.values.dateRange.range.endDate, "PPP")}`;
      break;
    }
    default: {
      headingDate = "";
    }
  }

  return (
    <div className={"cover"}>
      <div className={"pb-5 mb-5"}>
        <img
          src={"/images/ARCHI-HorizontalLogo-07.2023.png"}
          width={800}
          alt="Peer Review Logo"
        />
      </div>
      <div className={"pt-5 pb-2"}>
        <img src={"/images/PPR.png"} width={300} alt="Peer Review Logo" />
      </div>
      <div>
        <h2>Rural Physician Peer Review Report</h2>
      </div>
      <div>
        <h2>{facilityName}</h2>
      </div>
      <div className={"py-5"}>
        <h2>{headingDate}</h2>
      </div>
    </div>
  );
};

export default Heading;
