import React from "react";
import PropTypes from "prop-types";
import RenderEachItem from "../RenderEachItem";
import MuiCheckbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Field, FormSpy } from "react-final-form";

const Checkbox = ({ name, label, items, itemsDependOn, bitID, render }) => {
  return (
    <>
      <div className={"d-flex text-left"}>
        <Field name={name}>
          {({ input: { value: improvementValue } }) => {
            const isChecked = 0 !== (improvementValue & Math.pow(2, bitID));
            return (
              <FormControlLabel
                key={`Checkbox_FormControl_${name}`}
                control={
                  <FormSpy subscription={{ form: true }}>
                    {({ form }) => {
                      if (
                        items &&
                        items.some((item) => item.bitID !== undefined)
                      ) {
                        const isShouldBe = items.reduce(
                          (acc, item) =>
                            0 !== (improvementValue & Math.pow(2, item.bitID))
                              ? true
                              : acc,
                          false
                        );
                        if (isShouldBe !== isChecked) {
                          isShouldBe
                            ? form.change(name, improvementValue + 2 ** bitID)
                            : form.change(name, improvementValue - 2 ** bitID);
                        }
                      }
                      return (
                        <MuiCheckbox
                          key={`Checkbox_${name}`}
                          onClick={(e) => {
                            if (e.target.type === "checkbox") {
                              form.change(
                                name,
                                e.target.checked
                                  ? improvementValue + 2 ** bitID
                                  : improvementValue - 2 ** bitID
                              );
                            }
                          }}
                          checked={isChecked}
                          color={"primary"}
                          disabled={render.disabled}
                        />
                      );
                    }}
                  </FormSpy>
                }
                label={label}
              />
            );
          }}
        </Field>
      </div>
      <RenderEachItem items={items} itemsDependOn={itemsDependOn} indent />
    </>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  name: PropTypes.string,
  items: PropTypes.array,
  itemsVisible: PropTypes.bool,
};
