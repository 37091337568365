import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { Radios } from "mui-rff";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import {
  selectSignatureNameList,
  selectSignatureByRecordID,
} from "src/redux/selectors";
import { RecordActions } from "src/redux/reducers/Record";
import Submitter from "./Submitter";
import Custom from "src/components/individual/Custom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  button: { height: theme.spacing(8) },
  box: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridAlignment: {
    align: "center",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Signatures = (props) => {
  const signatureList = useSelector(selectSignatureNameList);
  const recordSignature = useSelector(
    selectSignatureByRecordID(props.record.ID)
  );
  const signatureOptions = useMemo(
    () =>
      Object.keys(signatureList).map((key) => ({
        value: key,
        label: signatureList[key],
      })),
    [signatureList]
  );

  const handleSignatory = ({ signatureID }) => {
    props.dispatch(
      RecordActions.SaveSignature({
        RecordID: props.record.ID,
        SignatureID: Number(signatureID),
      })
    );
  };

  const classes = useStyles();

  return (
    <div>
      <Form
        onSubmit={handleSignatory}
        initialValues={{ signatureID: JSON.stringify(recordSignature) }}
        render={({ handleSubmit, values, pristine, submitting, dirty }) => {
          if (props.isFormDirty !== dirty) {
            props.setIsFormDirty(dirty);
          }
          return (
            <Paper className={classes.paper}>
              <form noValidate>
                <Grid container spacing={3} className={classes.gridAlignment}>
                  <Grid item xs={6} md={3}>
                    <Box className={classes.paper}>
                      <Radios
                        label="Select Signatory"
                        name="signatureID"
                        required={true}
                        data={signatureOptions}
                      />
                    </Box>
                  </Grid>
                  <Grid item spacing={3} xs={6} md={9}>
                    <Grid item xs={12}>
                      <Box className={classes.paper}>
                        <Typography variant={"h2"}>
                          {JSON.parse(values.signatureID)
                            ? signatureList[values.signatureID]
                            : "No Signature"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={classes.box}>
                        <Custom.SubmitButton
                          style={{ width: "100%" }}
                          color="primary"
                          onClick={handleSubmit}
                          variant={"contained"}
                          disabled={pristine || submitting}
                          className={classes.button}
                        >
                          <i className={"fas fa-edit pr-1"} />
                          Save Signature
                        </Custom.SubmitButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Submitter values={values} onSubmit={handleSignatory} />
              </form>
            </Paper>
          );
        }}
      />
    </div>
  );
};

export default Signatures;
