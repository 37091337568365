import React from "react";

const TextBegin = () => {
  return (
    <>
      <div>
        <h4>Patient Age</h4>
      </div>
    </>
  );
};

export default TextBegin;
