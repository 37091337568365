import { Button } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { MidlevelActions } from "src/redux/reducers/PersonnelManagement/Midlevel";
import { selectMidlevelsByFacilityID } from "src/redux/selectors";
import CreateAndLinkMidlevelDialog from "./CreateAndLinkMidlevelDialog";
import LinkFacilityToMidlevelDialog from "./LinkFacilityToMidlevelDialog";
import Typography from "@mui/material/Typography";

const columns = [
  {
    name: "archiProviderID",
    label: "ARCHI Provider ID",
  },
  {
    name: "firstName",
    label: "First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
  },
  {
    name: "emailAddress",
    label: "E-Mail Address",
  },
  {
    name: "degree",
    label: "Degree",
  },
  {
    name: "active",
    label: "Active",
  },
  {
    name: "actions",
    label: "Actions",
    options: {
      filter: false,
      sort: false,
      download: false,
      print: false,
    },
  },
];

const options = {
  filterType: "multiselect",
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
};

const MidlevelsTable = (props) => {
  const [createMidlevelDialogVisible, setCreateMidlevelDialogVisible] =
    React.useState(false);
  const [
    linkFacilityToMidlevelDialogVisible,
    setLinkFacilityToMidlevelDialogVisible,
  ] = React.useState(false);
  const Midlevels = useSelector(selectMidlevelsByFacilityID(props.facilityID));
  const Loaded = useSelector((state) => state.Loaded);

  useEffectOnce(() => {
    Loaded.Midlevel === false && props.dispatch(MidlevelActions.BatchAdd());
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
  });

  const data = Midlevels.map((midlevel, i) => {
    return {
      archiProviderID: midlevel.ARCHIProviderID,
      firstName: midlevel.FirstName,
      lastName: midlevel.LastName,
      emailAddress: midlevel.EmailAddress,
      degree: midlevel.Degree,
      active: midlevel.Active ? "Active" : "Inactive",
      actions: (
        <Button
          variant={"contained"}
          color={"secondary"}
          size={"small"}
          onClick={() => unlinkFromFacility(midlevel.ID)}
        >
          Unlink
        </Button>
      ),
    };
  });

  const unlinkFromFacility = (ID) => {
    props.dispatch(
      MidlevelActions.RemoveFromFacility({
        FacilityID: props.facilityID,
        MidlevelID: ID,
      })
    );
  };

  return (
    <>
      {createMidlevelDialogVisible && (
        <CreateAndLinkMidlevelDialog
          {...props}
          dialogVisible={createMidlevelDialogVisible}
          setDialogVisible={() => {
            setCreateMidlevelDialogVisible(false);
          }}
        />
      )}
      {linkFacilityToMidlevelDialogVisible && (
        <LinkFacilityToMidlevelDialog
          {...props}
          dialogVisible={linkFacilityToMidlevelDialogVisible}
          setDialogVisible={() => {
            setLinkFacilityToMidlevelDialogVisible(false);
          }}
        />
      )}
      <div className={"row pt-4"}>
        <div className={"col d-flex"}>
          <Typography variant={"h4"}>Midlevels</Typography>
        </div>
      </div>
      <div className={"row pt-1"}>
        <div className={"col d-flex"}>
          <div>
            <Button
              size={"small"}
              variant={"outlined"}
              color={"primary"}
              onClick={() => setCreateMidlevelDialogVisible(true)}
            >
              New Midlevel
            </Button>
          </div>
          <div className={"pl-2"}>
            <Button
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              onClick={() => setLinkFacilityToMidlevelDialogVisible(true)}
            >
              Link to Facility
            </Button>
          </div>
        </div>
      </div>
      <div className={"row pt-2"}>
        <div className={"col"}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </div>
      </div>
    </>
  );
};

export default MidlevelsTable;
