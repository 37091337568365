import { apiUrl } from "src";
import APP_C from "src/utilities/constants";
import apiService from "src/utilities/api_service";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { LoadedActions } from "src/redux/reducers/Loaded";
import { AbbreviationActions } from "./Abbreviation";
import { AgeIncrementActions } from "./AgeIncrement";
import { BlindingOptionsActions } from "./BlindingOptions";
import { ChecklistQuestionActions } from "./ChecklistQuestion";
import { CommentTypeActions } from "./CommentType";
import { CommitteeActions } from "./Committee";
import { OutcomeActions } from "./Outcome";
import { PatientGenderActions } from "./PatientGender";
import { PatientRaceActions } from "./PatientRace";
import { PatientSourceActions } from "./PatientSource";
import { PermissionActions } from "./Permission";
import { RecordStatusActions } from "./RecordStatus";
import { ReferenceMaterialActions } from "./ReferenceMaterial";
import { ReviewCategoryActions } from "./ReviewCategory";
import { ReviewReasonActions } from "./ReviewReason";
import { ReviewTypeActions } from "./ReviewType";
import { SignatureActions } from "./Signature";

const BatchAdd = () => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: constants } = await apiService.get(`${apiUrl}constants`);
    constants.patientGender = APP_C.PATIENT_GENDER;
    dispatch(AbbreviationActions.BatchAdd({ ...constants }));
    dispatch(AgeIncrementActions.BatchAdd({ ...constants }));
    dispatch(BlindingOptionsActions.BatchAdd({ ...constants }));
    dispatch(ChecklistQuestionActions.BatchAdd({ ...constants }));
    dispatch(CommentTypeActions.BatchAdd({ ...constants }));
    dispatch(CommitteeActions.BatchAdd({ ...constants }));
    dispatch(OutcomeActions.BatchAdd({ ...constants }));
    dispatch(PatientGenderActions.BatchAdd({ ...constants }));
    dispatch(PatientRaceActions.BatchAdd({ ...constants }));
    dispatch(PatientSourceActions.BatchAdd({ ...constants }));
    dispatch(PermissionActions.BatchAdd({ ...constants }));
    dispatch(RecordStatusActions.BatchAdd({ ...constants }));
    dispatch(ReferenceMaterialActions.BatchAdd({ ...constants }));
    dispatch(ReviewCategoryActions.BatchAdd({ ...constants }));
    dispatch(ReviewReasonActions.BatchAdd({ ...constants }));
    dispatch(ReviewTypeActions.BatchAdd({ ...constants }));
    dispatch(SignatureActions.BatchAdd({ ...constants }));
    dispatch(LoadedActions.SetConstants(true));
    dispatch(FetchingActions.Reset());
  } catch (error) {
    dispatch(FetchingActions.Failure("Loading Constants"));
  }
};

export const ConstantActions = {
  BatchAdd,
  Abbreviation: AbbreviationActions,
  AgeIncrement: AgeIncrementActions,
  BlindingOptions: BlindingOptionsActions,
  ChecklistQuestionActions: ChecklistQuestionActions,
  CommentType: CommentTypeActions,
  Committee: CommitteeActions,
  Outcome: OutcomeActions,
  PatientGender: PatientGenderActions,
  PatientRace: PatientRaceActions,
  PatientSource: PatientSourceActions,
  Permission: PermissionActions,
  RecordStatus: RecordStatusActions,
  ReferenceMaterial: ReferenceMaterialActions,
  ReviewCategory: ReviewCategoryActions,
  ReviewReason: ReviewReasonActions,
  ReviewType: ReviewTypeActions,
  Signature: SignatureActions,
};
