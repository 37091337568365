import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "./PDFStyles";
import C from "../../../../utilities/constants";

export const renderInfo = (label, data) => {
  return (
    <View style={styles.contentColumn}>
      <View style={styles.halfColumn}>
        <Text style={styles.columnTextBold}>{label}</Text>
      </View>
      <View style={styles.halfColumn}>
        <Text style={styles.text}>{data}</Text>
      </View>
    </View>
  );
};

export const renderShortInfo = (label, data) => {
  return (
    <View style={styles.contentColumn}>
      <View style={{ flex: "1 0 auto" }}>
        <Text style={styles.columnTextBold}>{label}</Text>
      </View>
      <View style={{ flex: "3 2 auto" }}>
        <Text style={styles.text}>{data}</Text>
      </View>
    </View>
  );
};

export const renderBox = (label, type) => {
  return (
    <View style={styles.boxHeader}>
      <Text style={styles[type ? type : "boxedText"]}>{label}</Text>
    </View>
  );
};

export const createEmptyTwoBoxColumn = (n, lastBox) => {
  const emptyTwoBoxColumns = [];
  for (let i = 0; i < n; i++) {
    emptyTwoBoxColumns.push(
      renderTwoBoxColumn(
        null,
        null,
        null,
        null,
        "boxedText",
        "boxedText",
        lastBox
      )
    );
  }
  return emptyTwoBoxColumns;
};

export const renderBoxRow = (label, data) => {
  return (
    <View style={styles.boxRow}>
      <Text style={styles.boxedText}>
        {label}: {data}
      </Text>
    </View>
  );
};

export const renderTwoBoxColumn = (
  labelLeft,
  dataLeft,
  labelRight,
  dataRight,
  styleLeft,
  styleRight,
  lastBox
) => {
  return (
    <View
      style={{
        flexDirection: "row",
        borderLeft: true,
        borderRight: true,
        borderTop: true,
        borderBottom: !!lastBox,
      }}
    >
      {renderLeftColumn(labelLeft, dataLeft, styleLeft)}
      {renderRightColumn(labelRight, dataRight, styleRight)}
    </View>
  );
};

export const renderLeftColumn = (label, data, style) => {
  return (
    <View style={{ width: "50%", flexGrow: 1, borderRight: true }}>
      <Text style={styles[style ? style : "boxedText"]}>
        {label}
        {data !== null ? ":" : ""} {data}
      </Text>
    </View>
  );
};

export const renderRightColumn = (label, data, style) => {
  return (
    <View style={{ width: "50%", flexGrow: 1 }}>
      <Text style={styles[style ? style : "boxedText"]}>
        {label}
        {data !== null ? ":" : ""} {data}
      </Text>
    </View>
  );
};

export const RenderTwoColumn = ({
  leftColumn,
  rightColumn,
  customStyle,
  RenderType,
  leftWidth = "50%",
  leftColumnStyle,
  rightWidth = "50%",
  rightColumnStyle,
}) => {
  return (
    <View style={{ flexDirection: "row", customStyle }}>
      {leftColumn && (
        <RenderHalfColumn
          RenderType={RenderType}
          width={leftWidth}
          bold={true}
          customStyle={leftColumnStyle}
        >
          {leftColumn}
        </RenderHalfColumn>
      )}
      {rightColumn !== undefined && (
        <RenderHalfColumn
          RenderType={RenderType}
          width={rightWidth}
          customStyle={rightColumnStyle}
          bold={false}
        >
          {rightColumn === null ? "Pending" : rightColumn}
        </RenderHalfColumn>
      )}
    </View>
  );
};

export const RenderHalfColumn = ({
  bold,
  children,
  RenderType,
  width = "50%",
  customStyle,
}) => {
  return (
    <RenderType
      style={[
        {
          width: width,
          flexGrow: 1,
          color: children === "Pending" ? "red" : "black",
        },
        bold === true && RenderType === "TEXT"
          ? styles.columnTextBold
          : styles.columnText,
        customStyle,
      ]}
    >
      {children}
    </RenderType>
  );
};

export const RenderLabelAndData = ({ label, data }) => {
  return `${label}:${data !== undefined ? data : ""}`;
};

export const RenderSubTitleCard = ({
  children,
  title,
  wrap = true,
  align = "left",
}) => {
  return (
    <>
      <View minPresenceAhead={60} />
      <View wrap={wrap}>
        <Text style={[styles.finalSubTitle, { textAlign: align }]} fixed={true}>
          {title}
          {align === "left" ? ":" : ""}
        </Text>
        <View style={{ paddingLeft: 20 }} wrap={true}>
          {children}
        </View>
      </View>
    </>
  );
};

export const RenderFourColumns = ({
  firstColumn,
  secondColumn,
  thirdColumn,
  fourthColumn,
  lastBox,
}) => {
  return (
    <View style={{ flexDirection: "row" }}>
      {[firstColumn, secondColumn, thirdColumn, fourthColumn].map(
        (column, index) => {
          return (
            <Text
              style={[
                {
                  width: "25%",
                  flexGrow: 1,
                  borderLeft: true,
                  borderRight: index === 3,
                  borderTop: true,
                  borderBottom: lastBox,
                  textAlign: "center",
                  padding: 5,
                },
                styles.boxedText,
              ]}
            >
              {column}
            </Text>
          );
        }
      )}
    </View>
  );
};

export const renderHistoryPreSurgery = (
  committeeID,
  historyPreSurgery,
  isShort = true
) => {
  if (C.COMMITTEE_CHECK.includes(committeeID)) {
    return isShort
      ? renderShortInfo("History and Physical", historyPreSurgery)
      : renderInfo("History and Physical", historyPreSurgery);
  }
};

export const renderDiagnoses = (Diagnoses) => {
  return Diagnoses.map((element, index) => {
    let renderICDCode = element.CodeAndDescription;
    return (
      <View style={styles.contentColumn} key={index}>
        <View style={{ flex: "1 0 auto" }}>
          <Text style={styles.columnTextBold}>{element.DiagnosisType}</Text>
        </View>
        <View style={{ flex: "3 2 auto" }}>
          <Text style={styles.text}>{renderICDCode}</Text>
        </View>
      </View>
    );
  });
};

export const renderReviewReasons = (ReviewReasons) => {
  return (
    <View style={[styles.contentColumn, { paddingTop: 10 }]}>
      <View style={{ flex: "1 0 auto" }}>
        <Text style={styles.columnTextBold}>Reason(s) for Review:</Text>
      </View>
      <View style={{ flex: "3 2 auto" }}>
        {ReviewReasons.map((reason) => {
          return (
            <View key={reason.ID} style={{ flexDirection: "column" }}>
              <Text style={styles.text}>
                {reason.Description +
                  (reason.Other ? "\n Description - " + reason.Other : "")}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};
