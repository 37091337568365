import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificEthicsIssueChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Ethics Issue"}
        xAxisLabel={"Ethics Issue"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: Ethics Issue"}
      />
    </>
  );
};

export default FacilitySpecificEthicsIssueChart;
