import React from "react";

const OfficialDoNotUseList = () => {
  return (
    <>
      <div>
        <h6 className={"font-weight-bold"}>
          Official "Do Not Use" List<sup>1</sup>
        </h6>
      </div>
      <table className={"table"}>
        <thead>
          <tr>
            <th scope={"col"}>
              <b>Do Not Use</b>
            </th>
            <th scope={"col"}>
              <b>Potential Problem</b>
            </th>
            <th scope={"col"}>
              <b>Use Instead</b>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>U (unit)</div>
            </td>
            <td>
              <div>Mistaken for "0" (zero), the number "4", or "cc"</div>
            </td>
            <td>
              <div>Write "unit"</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>IU (International Unit)</div>
            </td>
            <td>
              <div>Mistaken for IV (intravenous) or the number 10 (ten)</div>
            </td>
            <td>
              <div>Write "International Unit"</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Q.D., QD, q.d., qd (daily)</div>
              <div>Q.O.D., QOD, q.o.d., qod (every other day)</div>
            </td>
            <td>
              <div>Mistaken for each other</div>
              <div>
                Period after the Q mistaken for "I" and the "O" mistaken for "I"
              </div>
            </td>
            <td>
              <div>Write "daily"</div>
              <div>Write "every other day"</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Trailing zero (X.0 mg)*</div>
            </td>
            <td>
              <div>Decimal point is missed</div>
            </td>
            <td>
              <div>Write X mg</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Lack of leading zero (.X mg)</div>
            </td>
            <td />
            <td>
              <div>Write 0.X mg</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                MS MSO<sub>4</sub> and MgSO<sub>4</sub>
              </div>
            </td>
            <td>
              <div>Can mean morphine sulfate or magnesium sulfate</div>
              <div>Confused for one another</div>
            </td>
            <td>
              <div>Write "morphinesulfate"</div>
              <div>Write "magnesium sulfate"</div>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        <small>
          <sup>1</sup> Applies to all orders and medication-related
          documentation that is handwritten (including free-text computer-entry)
          or on pre-printed forms
        </small>
      </p>
      <br />
      <p>
        <small>
          <b>*Exception:</b> A "trailing zero" may be used only where required
          to demonstrate the level of precision of the value being reported,
          such as for laboratory, results, imaging studies that report size of
          lesions, or catheter/tube sizes. It may not be used in medication
          orders or other medication-related documentation.
        </small>
      </p>
      <br />
    </>
  );
};

export default OfficialDoNotUseList;
