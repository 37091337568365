import React from "react";
import PropTypes from "prop-types";
import createOutcomeStructure from "src/components/templateStructures/outcomeStructure";
import { RenderChecklist } from "../Checklist/RenderChecklistComponents";

const PossibleImprovements = ({ rnOutcomes }) => {
  return <>{rnOutcomes && <ImprovementList rnOutcomes={rnOutcomes} />}</>;
};

const ImprovementList = ({ rnOutcomes }) => {
  const improvementStructure = createOutcomeStructure().filter(
    ({ name }) => name === "Improvements"
  );

  const data = rnOutcomes.Improvements;

  const hydrateQuestions = (items) => {
    return items.reduce((acc, curr, i) => {
      acc[i] = curr.hasOwnProperty("bitID") ? hydrateQuestion(curr) : curr;
      if (curr.render.type === "TextBox") {
        acc[i].value = rnOutcomes.OtherImprovement;
      }
      if (curr.hasOwnProperty("items")) {
        acc[i].items = hydrateQuestions(curr.items);
      }
      return acc;
    }, []);
  };

  const hydrateQuestion = (defaultProperties) => {
    return {
      ...defaultProperties,
      value:
        0 !== (data & Math.pow(2, defaultProperties.bitID)) ? "true" : "false",
    };
  };
  return <RenderChecklist items={hydrateQuestions(improvementStructure)} />;
};

ImprovementList.propTypes = {
  rnOutcomes: PropTypes.object,
};

export default PossibleImprovements;
