import React from "react";
import { isEmpty } from "lodash";
import { Bar, Doughnut, Line, Pie, PolarArea, Radar } from "react-chartjs-2";
import ChartTable from "./ChartTable";
import ChartDataOptions from "../ChartDataOptions";
import ChartDataFormat from "../ChartDataFormat";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getFigureCount } from "src/components/pages/Reports/ContentArea/ContentAreaReport";

const ChartCard = (props) => {
  const title = props.title;
  const labels = props.labels;
  const data = props.data;
  const datasets = props.datasets;
  const chartType = props.chartType;
  const xAxisLabel = props.xAxisLabel;
  const yAxisLabel = props.yAxisLabel;
  const figureText = props.figureText;
  const groupBy = props.values.displayOptions.groupBy;
  const hideChart = props.hideChart;
  let chart = <></>;

  const chartOptions = ChartDataOptions(
    labels.length,
    isEmpty(datasets) ? 0 : datasets.length,
    chartType,
    isEmpty(groupBy) ? "" : groupBy,
    { x: xAxisLabel, y: yAxisLabel },
    title
  );

  const chartData = ChartDataFormat(
    labels,
    chartType,
    isEmpty(groupBy) ? "" : groupBy,
    data,
    datasets,
    {
      x: xAxisLabel,
      y: yAxisLabel,
    }
  );

  switch (chartType) {
    case "bar": {
      chart = (
        <Bar
          data={chartData.data}
          plugins={[ChartDataLabels]}
          options={chartOptions.options}
        />
      );
      break;
    }
    case "line": {
      chart = (
        <Line
          data={chartData.data}
          plugins={[ChartDataLabels]}
          options={chartOptions.options}
        />
      );
      break;
    }
    case "doughnut": {
      chart = (
        <Doughnut
          data={chartData.data}
          plugins={[ChartDataLabels]}
          options={chartOptions.options}
        />
      );
      break;
    }
    case "pie": {
      chart = (
        <Pie
          data={chartData.data}
          plugins={[ChartDataLabels]}
          options={chartOptions.options}
        />
      );
      break;
    }
    case "polar": {
      chart = (
        <PolarArea
          data={chartData.data}
          plugins={[ChartDataLabels]}
          options={chartOptions.options}
        />
      );
      break;
    }
    case "radar":
    default: {
      chart = (
        <Radar
          data={chartData.data}
          plugins={[ChartDataLabels]}
          options={chartOptions.options}
        />
      );
      break;
    }
  }

  if (data.length === 0)
    return (
      <div className={"p-2 grouped"}>
        <div>
          <h6>{title}</h6>
        </div>
        <div>
          <div className={"pt-5 pb-2"}>
            <img
              src={"/images/NoData.png"}
              alt="No information avaialable for this report section"
            />
          </div>
        </div>
      </div>
    );

  return (
    <>
      {hideChart !== true && (
        <div className="grouped">
          <div>
            <h6>{title}</h6>
            <h7>
              * Reports will not display data if no data exists during those
              timeframes
            </h7>
          </div>
          <div className={"row"}>
            <div
              className={"col d-flex align-items-center justify-content-center"}
            >
              <div style={{ width: "950px" }}>{chart}</div>
            </div>
          </div>
          <div className={"mt-3 mb-5"}>
            <small>
              Figure/Table {getFigureCount()}: {figureText}
            </small>
          </div>
        </div>
      )}

      {props.values.displayOptions.sections.includes("table") && (
        <div className="mb-5">
          <ChartTable
            {...props}
            xAxisLabel={xAxisLabel}
            labels={labels}
            data={data}
          />
        </div>
      )}
    </>
  );
};

export default ChartCard;
