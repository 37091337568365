import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import FacilitySpecificAbbreviationUseChart from "./FacilitySpecificAbbreviationUseChart";
import AllFacilityAbbreviationUseChart from "./AllFacilityAbbreviationUseChart";
import TextBegin from "./TextBegin";
import TextEnd from "./TextEnd";

const AbbreviationUse = (props) => {
  const isAnObject = props.values.displayOptions.groupBy !== null;
  const chartData = ChartDataBuilder(
    props,
    props.reportKey,
    ["abbreviationUsed"],
    isAnObject
  );
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(
      props,
      `${props.reportKey}AllFacilities`,
      ["abbreviationUsed"],
      isAnObject
    );

  return (
    <>
      <TextBegin {...props} />
      {chartDataAllFacilities !== false && (
        <AllFacilityAbbreviationUseChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Abbreviation Use"}
          rows={chartDataAllFacilities.rows}
          tableRows={chartDataAllFacilities.tableRows}
        />
      )}
      <FacilitySpecificAbbreviationUseChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Abbreviation Use"}
        rows={chartData.rows}
        tableRows={chartData.tableRows}
      />
      <TextEnd {...props} />
    </>
  );
};

export default AbbreviationUse;
