import React from "react";

const TextBegin = (props) => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>Ethics Issue</h4>
      </div>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              Medical Record reviews, at times, will involve the study of
              medical ethics and/or professional responsibility in the standard
              of patient care. In the event there was an "ethical" discussion in
              the course of the review of a record submitted for peer review, it
              will be reflected in the graph below. Additionally, each committee
              provider receives one continuing education credit for an ethics
              discussion.
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextBegin;
