import React, { useContext, useMemo } from "react";
import _ from "lodash";
import TextCompare from "src/components/pages/MeetingManagement/RecordReview/TextCompare";
import { recordReviewData } from "src/components/pages/MeetingManagement/RecordReview";

const TextBox = ({ label, name }) => {
  const apiResponse = useContext(recordReviewData);
  const oChecklist = _.get(apiResponse, `OriginalData.Checklist`, []);
  const fChecklist = _.get(apiResponse, `FacilitatorData.Checklist`, []);
  const { Value: oValue } = useMemo(
    () => oChecklist.find((oValue) => oValue.Name === name),
    [oChecklist]
  );
  const { Value: fValue } = useMemo(
    () => fChecklist.find((oValue) => oValue.Name === name),
    [fChecklist]
  );
  return (
    <TextCompare name={name} label={label} fValue={fValue} oValue={oValue} />
  );
};

export default TextBox;
