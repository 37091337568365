import * as React from "react";
//ui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form } from "react-final-form";
import { Select, TextField, Checkboxes } from "mui-rff";
import { MenuItem } from "@mui/material";
//data
import { ContactActions } from "src/redux/reducers/PersonnelManagement/Contact";
import { useSelector } from "react-redux";
import { selectContentByID, selectFacilities } from "src/redux/selectors";

//validation
import { contactValidationSchema as validationSchema } from "../validationSchema";
import { makeValidate } from "mui-rff";

const validateForm = makeValidate(validationSchema);

const UpdateContactDialog = (props) => {
  const Facilities = useSelector(selectFacilities);
  const Contact = useSelector(selectContentByID("Contact", props.contactID));
  const render = Facilities !== undefined && Contact !== undefined;

  const initialValues = {
    firstName: Contact.FirstName,
    lastName: Contact.LastName,
    email: Contact.EmailAddress,
    areaCode: Contact.AreaCode,
    phoneNumber: Contact.PhoneNumber,
    extension: Contact.Extension,
    active: Contact.Active,
    facilityID: Contact.FacilityID,
  };

  const updateContact = (values) => {
    const castValues = validationSchema.cast(values);

    props.dispatch(
      ContactActions.Update({
        ID: props.contactID,
        FirstName: castValues.firstName,
        LastName: castValues.lastName,
        EmailAddress: castValues.email,
        AreaCode: castValues.areaCode,
        PhoneNumber: castValues.phoneNumber,
        Extension: castValues.extension,
        FacilityID: castValues.facilityID,
        Active: castValues.active,
      })
    );
    props.setDialogVisible();
  };

  return (
    <>
      {render && (
        <Form
          onSubmit={updateContact}
          initialValues={initialValues}
          validate={validateForm}
          render={({ handleSubmit }) => (
            <>
              <form>
                <Dialog
                  fullWidth
                  maxWidth={"sm"}
                  open={props.dialogVisible}
                  onClose={() => {
                    props.setDialogVisible();
                  }}
                >
                  <DialogTitle>Update Contact</DialogTitle>
                  <DialogContent>
                    <div className={"row py-2"}>
                      <div className={"col"}>
                        <TextField
                          label="First Name"
                          name="firstName"
                          variant={"outlined"}
                        />
                      </div>
                      <div className={"col"}>
                        <TextField
                          label="Last Name"
                          name="lastName"
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="E-Mail"
                          name="email"
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col-3"}>
                        <TextField
                          label="Area Code"
                          name="areaCode"
                          variant={"outlined"}
                        />
                      </div>
                      <div className={"col"}>
                        <TextField
                          label="Phone Number ex.(123-1234)"
                          name="phoneNumber"
                          variant={"outlined"}
                        />
                      </div>
                      <div className={"col-3"}>
                        <TextField
                          label="Extension"
                          name="extension"
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <Select
                          name={"facilityID"}
                          label={"Facility"}
                          labelId="create-contact-facility-select-label"
                          id="create-contact-facility-select"
                          variant={"outlined"}
                        >
                          <MenuItem disabled>Select a Facility</MenuItem>
                          {Facilities.map((option) => (
                            <MenuItem
                              key={`create-contact-facility-select-option-${option.Name}-${option.ID}`}
                              value={option.ID}
                            >
                              {option.FullName}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <Checkboxes
                          size={"small"}
                          name={"active"}
                          data={{ label: "Active", value: true }}
                          color={"primary"}
                        />
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color={"secondary"}
                      variant={"contained"}
                      onClick={() => {
                        props.setDialogVisible();
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      color={"primary"}
                      variant={"contained"}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </>
          )}
        />
      )}
    </>
  );
};

export default UpdateContactDialog;
