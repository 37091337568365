import React from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { selectLoaded } from "src/redux/selectors";
import { ContactActions } from "src/redux/reducers/PersonnelManagement/Contact";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { MidlevelActions } from "src/redux/reducers/PersonnelManagement/Midlevel";
import { ProviderActions } from "src/redux/reducers/PersonnelManagement/Provider";
import UpdateUnapprovedAdminDialog from "./UpdateUnapprovedAdminDialog";
import UpdateApprovedAdminDialog from "./UpdateApprovedAdminDialog";
import UpdateUnapprovedFacilityDialog from "./UpdateUnapprovedFacilityDialog";

const UpdateMRTSRecordDialog = (props) => {
  const Loaded = useSelector(selectLoaded);

  useEffectOnce(() => {
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
    Loaded.Contact === false && props.dispatch(ContactActions.BatchAdd());
    Loaded.Midlevel === false && props.dispatch(MidlevelActions.BatchAdd());
    Loaded.Provider === false && props.dispatch(ProviderActions.BatchAdd());
  });

  const fetching = useSelector((state) => state.Fetching);
  if (props.dialogVisible && fetching.Error === false) {
    props.setDialogVisible();
  }

  if (props.approvalStatus === 2) {
    return <UpdateApprovedAdminDialog {...props} />;
  } else {
    if (props.viewLevel === 0 || props.viewLevel === 1) {
      return <UpdateUnapprovedFacilityDialog {...props} />;
    } else {
      return <UpdateUnapprovedAdminDialog {...props} />;
    }
  }
};

export default UpdateMRTSRecordDialog;
