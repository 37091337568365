import React, { useContext } from "react";
import _ from "lodash";
import { recordReviewData } from "src/components/pages/MeetingManagement/RecordReview";
import TextCompare from "src/components/pages/MeetingManagement/RecordReview/TextCompare";

const TextBox = ({ label, name }) => {
  const apiResponse = useContext(recordReviewData);
  const oRNOutcomes = _.get(apiResponse, `OriginalData.RNOutcomes`, []);
  const fRNOutcomes = _.get(apiResponse, `FacilitatorData.RNOutcomes`, []);
  const oValue = oRNOutcomes[name];
  const fValue = fRNOutcomes[name];
  return (
    <TextCompare name={name} label={label} fValue={fValue} oValue={oValue} />
  );
};

export default TextBox;
