import React from "react";

const TextEnd = (props) => {
  return (
    <>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              <u>No Variance from Standard of Care</u> is defined as "the
              committee determined that the care was appropriate for the record
              reviewed."
            </p>
            <br />
            <p className={"text-left"}>
              <u>No Variance from Standard of Care/Documentation Issue</u> is
              defined as "no variance from the standard of care that ALSO
              includes missing documentation or lack of documentation that
              reviewers identified as necessary components of the reviewed
              record."
            </p>
            <br />
            <p className={"text-left"}>
              <u>Minor Variance from Standard of Care</u> is defined as "a
              variance from the standard of care that the usual prudent
              physician would not have done, but which had only minimal/modest
              potential for harm." Example: use of multiple broad-spectrum
              antibiotics for Urinary Tract Infection (UTI) in which the
              identified organism is sensitive to a single narrow-spectrum
              agent.
            </p>
            <br />
            <p className={"text-left"}>
              <u>Minor Variance from Standard of Care/Documentation Issue</u> is
              defined as "an identified minor variance from the standard of care
              that ALSO includes missing documentation or lack of documentation
              that potentially impacted the ability to accurately depict the
              clinical decision making and quality of care for the patient."
            </p>
            <br />
            <p className={"text-left"}>
              <u>Major Variance from Standard of Care</u> is defined as "a
              variance from the standard of care that has the potential to
              result in serious harm (death, loss of function, prolonged
              hospitalization, etc.)." It does not have to actually have caused
              the event but just have the potential to do so, Example:
              prescribing penicillin to someone with a history of anaphylaxis to
              Penicillin.
            </p>
            <br />
            <p className={"text-left"}>
              <u>Major Variance from Standard of Care/Documentation Issue</u> is
              defined as "an identified major variance from the standard of care
              that ALSO includes missing documentation of lack of documentation
              that potentially impacted the ability to accurately depict the
              clinical decision making and quality of care for the patient."
            </p>
            <br />
            <p className={"text-left"}>
              <u>Unable to Determine Variance from Standard of Care</u> is
              defined as "the committee members did not come to an agreement
              that a variance occurred."
            </p>
            <br />
            <p className={"text-left"}>
              <u>Variance from Standard of Care Unable to Determine Severity</u>{" "}
              is defined as "the committee did not come to an agreement on a
              minor variance versus a major variance from the standard of care."
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextEnd;
