import * as yup from "yup";

const validationSchema = yup.object({
  archiFacilityId: yup.number().typeError("facility is required").label("facility").required(),
  chartType: yup.string().typeError("chart type is required").required(),
  dateRange: yup.object({
    type: yup.string().typeError("range type is required").required(),
    range: yup.object({
      startingMonth: yup.string().nullable(),
      startingQuarter: yup.string().nullable(),
      startingYear: yup.string().nullable(),
      startingDate: yup.date().nullable(),
      endingMonth: yup.string().nullable(),
      endingQuarter: yup.string().nullable(),
      endingYear: yup.string().nullable(),
      endingDate: yup.date().nullable(),
    }),
  }),
  displayOptions: yup.object({
    groupBy: yup.string().nullable(),
    displayType: yup.string().nullable(),
    sections: yup.array().of(yup.string()),
  }),
  reports: yup.array().of(yup.string()),
  template: yup.string().nullable(),
  templateName: yup.string().nullable(),
});

export default validationSchema;
