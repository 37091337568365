import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityPatientDispositionChart from "./AllFacilityPatientDispositionChart";
import FacilitySpecificPatientDispositionChart from "./FacilitySpecificPatientDispositionChart";
import TextBegin from "./TextBegin";

const PatientDisposition = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, [
    "patientDisposition",
  ]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, [
      "patientDisposition",
    ]);

  return (
    <>
      <TextBegin />
      {chartDataAllFacilities !== false && (
        <AllFacilityPatientDispositionChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Patient Disposition"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificPatientDispositionChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Patient Disposition"}
        rows={chartData.rows}
      />
    </>
  );
};

export default PatientDisposition;
