import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityReasonForReviewByProviderChart from "./AllFacilityReasonForReviewByProviderChart";
import FacilitySpecificReasonForReviewByProviderChart from "./FacilitySpecificReasonForReviewByProviderChart";
import TextBegin from "./TextBegin";
import _ from "lodash";
import MidlevelTable from "../OutcomesByProvider/MidlevelTable";

const ReasonForReviewByProvider = (props) => {
  const customProps = _.cloneDeep(props);
  const midlevels = props.Report.Data.ReviewReasonByProvider[1];
  customProps.Report.Data.ReviewReasonByProvider =
    props.Report.Data.ReviewReasonByProvider[0];

  const chartData = ChartDataBuilder(customProps, props.reportKey, ["reason"]);
  const chartDataAllFacilities =
    customProps.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(customProps, `${props.reportKey}AllFacilities`, [
      "reason",
    ]);

  return (
    <>
      <TextBegin {...props} />
      {chartDataAllFacilities !== false && (
        <AllFacilityReasonForReviewByProviderChart
          {...customProps}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Reason"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificReasonForReviewByProviderChart
        {...customProps}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Reason"}
        rows={chartData.rows}
      />
      {midlevels.map((elem) => {
        const key = Object.keys(elem)[0];
        return (
          <MidlevelTable
            title={`Supervising Provider: ${key}`}
            midlevels={elem[key]}
            columnName={"Reason"}
          />
        );
      })}
      <br />
    </>
  );
};

export default ReasonForReviewByProvider;
