import React from "react";
import PageCard from "src/components/individual/PageCard";
import Button from "@mui/material/Button";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import CachedIcon from "@mui/icons-material/Cached";
import MergeIcon from "@mui/icons-material/MergeType";
import Grid from "@mui/material/Grid";

const TablePageCard = ({
  title = "",
  table: { columns, data, options },
  button,
  button: { isRendered = false, label = "", handleClick, icon = <AddIcon /> },
  reloadData,
  secondaryButton,
}) => {
  return (
    <PageCard
      title={title}
      render={
        <>
          {isRendered && (
            <Grid container flexDirection={"row"} spacing={2}>
              <Grid item>
                {button instanceof Element ? (
                  <button />
                ) : (
                  <Button
                    variant={"outlined"}
                    color={"primary"}
                    size={"small"}
                    onClick={handleClick}
                    startIcon={icon}
                  >
                    {label}
                  </Button>
                )}
              </Grid>
              {secondaryButton && secondaryButton.isRendered && (
                <Grid item>
                  {button instanceof Element ? (
                    <secondaryButton />
                  ) : (
                    <Button
                      variant={"outlined"}
                      color={"warning"}
                      size={"small"}
                      onClick={secondaryButton.handleClick}
                      startIcon={<MergeIcon />}
                    >
                      {secondaryButton.label}
                    </Button>
                  )}
                </Grid>
              )}
              {reloadData && (
                <Grid item>
                  <Button
                    variant={"outlined"}
                    color={"primary"}
                    size={"small"}
                    disabled={reloadData.isReloading}
                    onClick={reloadData.handleClick}
                    startIcon={
                      reloadData.isReloading ? (
                        <CircularProgress size={12} />
                      ) : (
                        <CachedIcon />
                      )
                    }
                  >
                    {reloadData.label}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
          <div className={"row pt-3"}>
            <div className={"col"}>
              <MUIDataTable data={data} columns={columns} options={options} />
            </div>
          </div>
        </>
      }
    />
  );
};

export default TablePageCard;
