import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Switches } from "mui-rff";
import TableContainer from "@mui/material/TableContainer";
import withStyles from "@mui/styles/withStyles";
import { makeStyles } from "@mui/styles";
import TimesUsedTextField from "./TimesUsedTextField";

export default function AbbreviationsTable({
  lookUpConstants: { label, list, noDataLabel },
  switchID,
  form,
}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell>{label}</StyledTableCell>
            {list.map(({ Name }) => (
              <StyledTableCell align="center" width={150}>
                {Name}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row}>
              <TableCell component="th" scope="row">
                {row}
              </TableCell>
              {list.map(({ ID }) => (
                <TableCell align="center">
                  <TimesUsedTextField ID={ID} row={row} switchID={switchID} />
                </TableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Switches
        name={`Abbreviation_${switchID}.Switch`}
        data={{
          label: noDataLabel,
          value: false,
        }}
        onChange={(event) => {
          form.change(`Abbreviation_${switchID}.Switch`, event.target.checked);
          if (event.target.checked) {
            rows.forEach((row) =>
              list.forEach(({ ID }) =>
                form.change(`Abbreviation_${ID}.${row}`, "0")
              )
            );
          }
        }}
      />
    </TableContainer>
  );
}

const rows = ["Provider", "Nurse", "Other"];

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: 20,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.light,
  },
  table: {
    minWidth: 650,
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      padding: 0,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    padding: 0,
  },
  body: {
    padding: 0,
    fontSize: 14,
  },
  root: {
    padding: 0,
  },
}))(TableCell);
