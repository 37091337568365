import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityPatientRaceChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Patient Race"}
        xAxisLabel={"Patient Race"}
        yAxisLabel={"Number of Records"}
        figureText={"Records by Patient Race for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilityPatientRaceChart;
