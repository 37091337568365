import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityDischargeInstructionsOrTransferDocumentsProvidedChart from "./AllFacilityDischargeInstructionsOrTransferDocumentsProvidedChart";
import FacilitySpecificDischargeInstructionsOrTransferDocumentsProvidedChart from "./FacilitySpecificDischargeInstructionsOrTransferDocumentsProvidedChart";
import TextBegin from "./TextBegin";

const DischargeOrTransferDocumentsProvided = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, [
    "dischargeTransferDocs",
  ]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, [
      "dischargeTransferDocs",
    ]);

  return (
    <>
      <TextBegin {...props} />
      {chartDataAllFacilities !== false && (
        <AllFacilityDischargeInstructionsOrTransferDocumentsProvidedChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Discharge or Transfer Documents Provided"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificDischargeInstructionsOrTransferDocumentsProvidedChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Discharge or Transfer Documents Provided"}
        rows={chartData.rows}
      />
    </>
  );
};

export default DischargeOrTransferDocumentsProvided;
