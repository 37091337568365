import * as yup from "yup";
import C from "src/utilities/constants";

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export const submissionDateSchema = yup.object({
  submissionDate: yup
    .date()
    .label("submission date")
    .required()
    .min(
      yup.ref("dischargeDate"),
      "submission date must be later than discharge date"
    )
    .max(tomorrow, "No future dates allowed")
    .nullable(),
});

export const rejectionSchema = yup.object({
  rejectionReason: yup.string().nullable(),
  rejectionComment: yup.string().nullable(),
});

export const trackingRecordSchema = yup.object({
  recordStatus: yup.number().label("record status").required().nullable(),
  mrtsReviewType: yup.number().label("MRTS review type").required().nullable(),
  reviewMonth: yup.number().label("review month").required().nullable(),
  reviewYear: yup
    .number()
    .label("review year")
    .min(2000)
    .max(3000)
    .required()
    .nullable(),
  comments: yup.string().nullable().max(2000),
});

const validationSchema = yup.object({
  // PERSONNEL INVOLVED
  facility: yup
    .object({
      ARCHIFacilityID: yup.string().required(),
      Active: yup.boolean().required(),
      City: yup.string().required(),
      Contact: yup.array().of(
        yup.object({
          ID: yup.number().required(),
        })
      ),
      ID: yup.number().required(),
      Midlevel: yup.array().of(yup.object()),
      Name: yup.string().required(),
      Provider: yup.array().of(yup.object()),
      State: yup.string().required(),
      User: yup.array().of(yup.object()),
    })
    .required()
    .nullable(),
  contact: yup.object().required().nullable(),
  provider: yup
    .object({
      ARCHIProviderID: yup.string().required(),
      Active: yup.boolean().required(),
      Degree: yup.string().required(),
      EmailAddress: yup.string().nullable(),
      Facility: yup.array().of(yup.object()).required(),
      FirstName: yup.string().required(),
      ID: yup.number().required(),
      LastName: yup.string().required(),
    })
    .required()
    .nullable(),
  midlevel: yup
    .object({
      ARCHIProviderID: yup.string().required(),
      Active: yup.boolean().required(),
      Degree: yup.string().required(),
      EmailAddress: yup.string().nullable(),
      Facility: yup.array().of(yup.object()).required(),
      FirstName: yup.string().required(),
      ID: yup.number().required(),
      LastName: yup.string().required(),
    })
    .nullable(),

  // REVIEW INFOMATION
  arrivalDate: yup
    .date()
    .required()
    .label("arrival date")
    .min(new Date(2000, 0, 1), "no dates before 2000 are allowed")
    .max(
      yup.ref("dischargeDate"),
      "arrival date must be earlier than discharge date"
    )
    .nullable(),
  dischargeDate: yup
    .date()
    .required()
    .label("discharge date")
    .min(
      yup.ref("arrivalDate"),
      "discharge date must be later than arrival date"
    )
    .max(new Date(), "no future dates allowed")
    .nullable(),
  reviewCategoryID: yup
    .number()
    .label("review category id")
    .required()
    .nullable(),
  reviewTypeID: yup.number().label("review type id").required().nullable(),
  facilityMedicalRecordNumber: yup
    .string()
    .label("facility medical record number")
    .required(
      "Facility medical record number is required. Duplicates will cause the submission to fail"
    )
    .nullable(),

  // PATIENT INFORMATION
  patientAge: yup
    .number()
    .label("patient age")
    .required()
    .moreThan(-1)
    .integer()
    .nullable(),
  ageIncrement: yup.number().label("age increment").required().nullable(),
  patientRace: yup.number().nullable(),
  patientGender: yup.boolean().label("patient gender").required().nullable(),
  patientOriginationID: yup
    .number()
    .label("patient origination")
    .required()
    .nullable(),
  // patientOriginationOther: yup.string().nullable(), DEPRECATED
  committee: yup
    .object({
      Abbreviation: yup.string().required(),
      Active: yup.number().required(),
      ID: yup.number().required(),
      Name: yup.string().required(),
    })
    .required()
    .nullable(),
  committeeOther: yup.string().label("committee other").nullable(),
  // Code below is needed to validate committee other but
  // all validations stop working when code is added

  // .when("committee", {
  //   is: (val) => val.ID === C.COMMITTEE_ID_OTHER,
  //   then: yup.string().required()
  // })
  icdCodes: yup
    .array()
    .of(
      yup.object({
        id: yup.string(),
        text: yup.string().required(),
        noCodeAvailable: yup.boolean().nullable(),
      })
    )
    .min(1, "ICD code is required"),
  icdCodeDescription: yup.string().nullable(),
  noCodeAvailable: yup.boolean().nullable(),
  historyAndPhysicalOnChart: yup.boolean().nullable(),

  // REVIEW REASONS
  reviewReasons: yup
    .array()
    .of(yup.number())
    .label("review reasons")
    .required()
    .min(1, "review reasons is required"),
  otherReviewReason: yup
    .string()
    .label("other review reason")
    .nullable()
    .when("reviewReasons", {
      is: (val) => val.includes(C.REVIEW_REASONS_ID_OTHER),
      then: yup.string().required().nullable().max(255),
    }),

  // REFERENCE MATERIAL
  referenceMaterialChecklist: yup
    .array()
    .of(yup.number())
    .label("reference material checklist")
    .required()
    .nullable(),
  otherReferenceMaterial: yup
    .string()
    .label("other reference material")
    .nullable()
    .when("referenceMaterialChecklist", {
      is: (val) => val.includes(C.REFERENCE_MATERIAL_ID_OTHER),
      then: yup.string().required().nullable().max(255),
    }),
  additionalInformation: yup
    .string()
    .label("additional information")
    .nullable(),
  committeeQuestion: yup
    .string()
    .label("committee question")
    .required()
    .nullable(),
});

export default validationSchema;
