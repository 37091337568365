import { useUnmount } from "react-use";

const Submitter = ({ values, onSubmit }) => {
  useUnmount(() => {
    onSubmit(values);
  });
  return null;
};

export default Submitter;
