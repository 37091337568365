export const helpTemplate = [
  {
    id: 1,
    label: "No Variance from Standard of Care",
    info: `is defined as "the committee determined that the care was appropriate
        for the record reviewed."`,
  },
  {
    id: 2,
    label: "No Variance from Standard of Care/Documentation Issue",
    info: ` is defined as "no variance from the standard of care that ALSO includes
        missing documentation or lack of documentation that reviewers identified
        as necessary components of the reviewed record."`,
  },
  {
    id: 3,
    label: "Minor Variance from Standard of Care",
    info: `is defined as "a
        variance from the standard of care that the usual prudent physician
        would not have done, but which had only minimal/modest potential for
        harm." Example: use of multiple broad-spectrum antibiotics for Urinary
        Tract Infection (UTI) in which the identified organism is sensitive to a
        single narrow-spectrum agent.`,
  },
  {
    id: 4,
    label: "Minor Variance from Standard of Care/Documentation Issue",
    info: `is defined as "an identified minor variance from the standard of care
        that ALSO includes missing documentation or lack of documentation that
        potentially impacted the ability to accurately depict the clinical
        decision making and quality of care for the patient".`,
  },
  {
    id: 5,
    label: "Major Variance from Standard of Care",
    info: `is defined as "a
        variance from the standard of care that has the potential to result in
        serious harm (death, loss of function, prolonged hospitalization,
        etc.)." It does not have to actually have caused the event but just have
        the potential to do so, Example: prescribing penicillin to someone with
        a history of anaphylaxis to Penicillin`,
  },
  {
    id: 6,
    label: "Major Variance from Standard of Care/Documentation Issue",
    info: `is defined as "an identified major variance from the standard of care
        that ALSO includes missing documentation of lack of documentation that
        potentially impacted the ability to accurately depict the clinical
        decision making and quality of care for the patient".`,
  },
  {
    id: 7,
    label: "Unable to Determine Variance from Standard of Care",
    info: `is
        defined as "the committee members did not come to an agreement that a
        variance occurred."`,
  },
  {
    id: 8,
    label: "Variance from Standard of Care Unable to Determine Severity",
    info: `is defined as "the committee did not come to an agreement on a minor
        variance versus a major variance from the standard of care."`,
  },
];
