import React from "react";
import Button from "@mui/material/Button";
import AdminReviewButtons from "./AdminReviewButtons";
import ReviewRequestedButtons from "./ReviewRequestedButtons";
import { facilitator } from "src/utilities/rbacFunctions";
import { parseISO, format } from "date-fns";
import C from "src/utilities/constants";
import get from "../helperFunctions";

export const getReviewsDashboardTable = (
  records,
  reviews,
  roles,
  navigate,
  handleOpen,
  user
) => {
  const rows = [];
  if (reviews !== undefined && reviews.length > 0) {
    reviews
      .filter(
        ({ ReviewStatus }) =>
          !facilitator(roles) ||
          [
            C.REVIEW_STATUS.PENDING_FACILITATOR_REVIEW,
            C.REVIEW_STATUS.FACILITATOR_REVIEW_IN_PROGRESS,
          ].includes(ReviewStatus)
      )
      .forEach((review) => {
        let record = records.find(
          (lookupRecord) => lookupRecord.ID === review.RecordID
        );
        rows.push({
          ARCHIRecordID: record.ARCHIRecordID,
          AssignedDate: format(parseISO(review.AssignedDate), "MM/dd/yyyy"),
          ReviewStatus: review.ReviewStatus,
          FacilitatorName: get.userNameByID(review.FacilitatorID, user),
          Actions: actions(
            roles,
            review.FacilitatorID,
            review.RecordID,
            navigate,
            review.ReviewStatus,
            handleOpen,
            review.DirectReviewComments,
            review.PeerReviewNotes
          ),
        });
      });
  }

  const columns = [
    {
      label: "ARCHI Record ID",
      name: "ARCHIRecordID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Assigned Date",
      name: "AssignedDate",
      options: {
        range: true,
        sort: true,
      },
    },
    {
      label: "Review Status",
      name: "ReviewStatus",
      options: {
        sort: true,
      },
    },
    {
      label: "Facilitator",
      name: "FacilitatorName",
      options: {
        sort: true,
      },
    },
    {
      label: "Actions",
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        range: false,
      },
    },
  ];
  return {
    columns,
    rows,
  };
};

export const actions = (
  roles,
  facilitatorID,
  recordID,
  navigate,
  reviewStatus,
  handleOpen,
  directReviewComments = {},
  peerReviewNotes = ""
) => {
  const actionButtons = () => {
    if (facilitator(roles)) {
      return ReviewButton(
        () => navigate(`/RecordReview/${recordID}`),
        "Review"
      );
    } else if (reviewStatus === "Committee Review Requested") {
      return RequestedActionButtons(
        () =>
          handleOpen(
            recordID,
            directReviewComments,
            peerReviewNotes,
            "Committee Review Requested"
          ),
        "Approve Request"
      );
    } else if (reviewStatus === "Side Review Requested") {
      return RequestedActionButtons(
        () =>
          handleOpen(
            recordID,
            directReviewComments,
            peerReviewNotes,
            "Side Review Requested"
          ),
        "Re-assign Facilitator"
      );
    } else {
      return AdminActionButtons();
    }
  };

  const ReviewButton = (onClick, text) => {
    return (
      <Button
        color="primary"
        variant="contained"
        size={"small"}
        onClick={onClick}
      >
        <i className={"fas fa-edit pr-1"} />
        {text}
      </Button>
    );
  };

  const RequestedActionButtons = (onClick, text) => {
    return (
      <ReviewRequestedButtons
        recordID={recordID}
        requestType={{
          text,
          onClick,
        }}
        spawnResetReviewStatusDialog={() => {
          handleOpen(
            recordID,
            directReviewComments,
            peerReviewNotes,
            "Reset Review Status"
          );
        }}
      />
    );
  };

  const AdminActionButtons = () => {
    return (
      <AdminReviewButtons
        facilitatorID={facilitatorID}
        recordID={recordID}
        spawnReassignMeetingMeetingDialog={() => {
          handleOpen(
            recordID,
            directReviewComments,
            peerReviewNotes,
            "Re-Assign Meeting"
          );
        }}
        spawnAssignFacilitatorDialog={() => {
          handleOpen(
            recordID,
            directReviewComments,
            peerReviewNotes,
            "Re-Assign Facilitator"
          );
        }}
        spawnResetReviewStatusDialog={() => {
          handleOpen(
            recordID,
            directReviewComments,
            peerReviewNotes,
            "Reset Review Status"
          );
        }}
        spawnRemoveFromMeetingDialog={() => {
          handleOpen(
            recordID,
            directReviewComments,
            peerReviewNotes,
            "Remove from Meeting"
          );
        }}
        sendToReviewsDashboard={() => navigate(`/RecordReview/${recordID}`)}
        isFacilitator={() => facilitator(roles)}
      />
    );
  };

  return actionButtons();
};
