import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityLengthOfStayChart from "./AllFacilityLengthOfStayChart";
import FacilitySpecificLengthOfStayChart from "./FacilitySpecificLengthOfStayChart";
import TextBegin from "./TextBegin";
import {
  filterChartData,
  sortChartData,
  sortChartDataByTime
} from "src/components/pages/Reports/ContentArea/ContentAreaReport/PossibleImprovements";

const LengthOfStay = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, [], true);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, [], true);

    let filteredChartData, filteredChartDataAllFacilities;

    if(props.values.displayOptions.groupBy){
      filteredChartData = filterChartData(sortChartDataByTime(chartData));
      filteredChartDataAllFacilities = chartDataAllFacilities !== false && filterChartData(sortChartDataByTime(chartDataAllFacilities));
    }else{
      filteredChartData = filterChartData(sortChartData(chartData));
      filteredChartDataAllFacilities = chartDataAllFacilities !== false && filterChartData(sortChartData(chartDataAllFacilities));
    }

  return (
    <>
      <TextBegin />
      {chartDataAllFacilities !== false && (
        <AllFacilityLengthOfStayChart
          {...props}
          labels={filteredChartDataAllFacilities.labels}
          data={filteredChartDataAllFacilities.data}
          datasets={filteredChartDataAllFacilities.datasets}
          heading={"Stay Range"}
          rows={filteredChartDataAllFacilities.rows}
          tableRows={filteredChartDataAllFacilities.tableRows}
        />
      )}
      <FacilitySpecificLengthOfStayChart
        {...props}
        labels={filteredChartData.labels}
        data={filteredChartData.data}
        datasets={filteredChartData.datasets}
        heading={"Stay Range"}
        rows={filteredChartData.rows}
        tableRows={filteredChartData.tableRows}
      />
    </>
  );
};

export default LengthOfStay;
