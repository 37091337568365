import React, { useState } from "react";

// ui
import { makeStyles } from "@mui/styles";
import { Form, FormSpy } from "react-final-form";
import {
  Alert,
  AppBar,
  Button,
  ButtonGroup,
  Collapse,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { Checkboxes } from "mui-rff";
import CloseIcon from "@mui/icons-material/Close";
import CustomSubmitButton from "src/components/pages/MRTSRecords/MRTSRecordDialog/CustomSubmitButton";

//data
import mappers from "../../mappers";
import mutators from "../../mutators";
import initialValues from "../initialValues";
import PersonnelInvolved from "../../ReusableSections/PersonnelInvolved";
import ReviewInformation from "../../ReusableSections/ReviewInformation";
import PatientInformation from "../../ReusableSections/PatientInformation";
import ReviewReasons from "../../ReusableSections/ReviewReasons";
import ReferenceMaterial from "../../ReusableSections/ReferenceMaterial";
import { RecordActions } from "src/redux/reducers/Record";
import capSentence from "src/utilities/capSentence";
import { convertDateToLocal } from "src/utilities/dateFunctions";

// validation
import validationSchema from "../../validationSchema";
import { makeValidate } from "mui-rff";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    paddingRight: "0px !important",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateUnapprovedFacilityDialog = (props) => {
  const [warningVisible, setWarningVisible] = useState(false);
  initialValues.acknowledgeDuplicate = false;
  const classes = useStyles();

  const createMRTS = (values) => {
    setWarningVisible(false);
    const acknowledgeDuplicate = values.acknowledgeDuplicate;
    const content = {
      AcknowledgeDuplicate: acknowledgeDuplicate,
      FacilityID: values.facility.ID,
      ContactID: values.contact.ID,
      ProviderID: values.provider.ID,
      RecordStatusID: 1,
      OriginalFacilityRecordID: values.facilityMedicalRecordNumber,
      CommitteeID: values.committee.ID,
      CommitteeOtherDesc:
        values.committeeOther !== null ? values.committeeOther : "",
      HistoryPreSurgery: values.historyAndPhysicalOnChart,
      MidlevelProviderID:
        values.midlevel !== null
          ? values.midlevel.ID
          : null,
      PatientSourceID: values.patientOriginationID,
      ReviewCategoryID: values.reviewCategoryID,
      ReviewTypeID: values.reviewTypeID,
      ARCHIRecordID: "",
      PatientAge: values.patientAge,
      AgeIncrementID: values.ageIncrement,
      PatientGender: values.patientGender,
      PatientRaceID: values.patientRace,
      PatientSourceOther: "",
      ArrivalDate: convertDateToLocal(values.arrivalDate),
      DischargeDate: convertDateToLocal(values.dischargeDate),
      ReviewReason: values.reviewReasons.map((r) => {
        return {
          ID: r,
          recordReviewReason: {
            ReviewReasonID: r,
            OtherReason: values.otherReviewReason,
          },
        };
      }),
      ReferenceMaterial: values.referenceMaterialChecklist.map((r) => {
        return {
          ID: r,
          recordReferenceMaterial: {
            ReferenceMaterialID: r,
            OtherReference: capSentence(values.otherReferenceMaterial),
          },
        };
      }),
      RecordDiagnosis: values.icdCodes.map((i) => {
        return mappers.recordDiagnosis(i);
      }),
      OtherReason: values.otherReviewReason,
      RecordComment: [
        {
          TypeID: 2,
          Description: capSentence(values.additionalInformation),
        },
        { TypeID: 3, Description: capSentence(values.committeeQuestion) },
      ],
      ApprovalStatus: 1,
      Signature: null,
    };
    if (!acknowledgeDuplicate) {
      props.dispatch(
        RecordActions.CheckOrCreateUnapproved(content, setWarningVisible)
      );
    } else {
      props.dispatch(RecordActions.CreateUnapproved(content));
    }
  };

  return (
    <Form
      onSubmit={createMRTS}
      initialValues={initialValues}
      validate={makeValidate(validationSchema)}
      mutators={mutators}
      subscription={{
        form: false,
        pristine: false,
        submitting: false,
        values: false,
        handleSubmit: true,
      }}
      render={({ handleSubmit }) => (
        <form>
          <Dialog
            fullScreen
            open={props.dialogVisible}
            onClose={props.setDialogVisible}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <FormSpy subscription={{ form: true }}>
                  {({ form }) => (
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        form.mutators.clearAll();
                        props.setDialogVisible();
                      }}
                      aria-label="close"
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </FormSpy>
                <Typography variant="h6" className={classes.title}>
                  Create MRTS
                </Typography>
                <CustomSubmitButton
                  handleSubmit={handleSubmit}
                  label={"Create"}
                />
              </Toolbar>
              {warningVisible && (
                <Collapse in={warningVisible}>
                  <Alert
                    variant="standard"
                    severity="error"
                    sx={{
                      boxShadow: 1,
                      pl: 2.5,
                      border: 2,
                      minWidth: 300,
                      borderRadius: 0,
                    }}
                  >
                    <h5>
                      Please search your submissions for possible duplication in
                      the database.
                    </h5>
                    <h6>
                      If this submission is a different Date of Service, proceed
                      and check "Acknowledge Possible Duplicate"
                    </h6>
                    <Checkboxes
                      name="acknowledgeDuplicate"
                      data={{
                        label: "Acknowledge Possible Duplicate",
                        value: false,
                      }}
                      size={"small"}
                    />
                  </Alert>
                </Collapse>
              )}
            </AppBar>
            <div className={"container-fluid"}>
              <div className={"row py-2"}>
                <div className={"col"}>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      onClick={() =>
                        document
                          .getElementById("personnel-involved-title")
                          .scrollIntoView()
                      }
                    >
                      Personnel Involved
                    </Button>
                    <Button
                      onClick={() =>
                        document
                          .getElementById("review-information-title")
                          .scrollIntoView()
                      }
                    >
                      Review Information
                    </Button>
                    <Button
                      onClick={() =>
                        document
                          .getElementById("patient-information-title")
                          .scrollIntoView()
                      }
                    >
                      Patient Information
                    </Button>
                    <Button
                      onClick={() =>
                        document
                          .getElementById("review-reasons-title")
                          .scrollIntoView()
                      }
                    >
                      Review Reasons
                    </Button>
                    <Button
                      onClick={() =>
                        document
                          .getElementById("reference-material-title")
                          .scrollIntoView()
                      }
                    >
                      Reference Material
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
            <div
              style={{
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <PersonnelInvolved {...props} classes={classes} />
              <ReviewInformation {...props} classes={classes} />
              <PatientInformation {...props} classes={classes} />
              <ReviewReasons {...props} classes={classes} />
              <ReferenceMaterial {...props} classes={classes} />
            </div>
          </Dialog>
        </form>
      )}
    />
  );
};

export default CreateUnapprovedFacilityDialog;
