import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import {
  selectContentByID,
  selectRecordsAssignableToMeeting,
  selectRecordsByMeetingID,
} from "src/redux/selectors";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { RecordActions } from "src/redux/reducers/Record";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddRecordsToMeetingDialog = (props) => {
  const meeting = useSelector(selectContentByID("Meeting", props.meetingID));
  const classes = useStyles();
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const availableRecords = useSelector(selectRecordsAssignableToMeeting);
  const defaultProps = {
    options: availableRecords,
    getOptionLabel: (option) => String(option.ARCHIRecordID),
  };
  const assignedRecords = useSelector(
    selectRecordsByMeetingID(props.meetingID)
  );

  function createData(ID, archiRecordID, facilityRecordID) {
    return { ID, archiRecordID, facilityRecordID };
  }

  const rows = assignedRecords
    .sort((a, b) => {
      return a.ARCHIRecordID - b.ARCHIRecordID;
    })
    .map((record) =>
      createData(record.ID, record.ARCHIRecordID, record.FacilityRecordID)
    );

  const noRows = !Array.isArray(rows) || !rows.length;

  const addRecord = () => {
    props.dispatch(
      RecordActions.AddToMeeting({
        MeetingID: props.meetingID,
        RecordID: [selectedRecord],
      })
    );
    setSelectedRecord(null);
  };

  const removeRecord = (ID) => {
    props.dispatch(
      RecordActions.RemoveFromMeeting({
        MeetingID: props.meetingID,
        RecordID: ID,
      })
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={props.dialogVisible}
      onClose={() => {
        props.setDialogVisible();
      }}
    >
      <DialogTitle>
        {meeting.MeetingName} <br /> Add Records
      </DialogTitle>
      <DialogContent className={"py-2"}>
        <div className={"row"}>
          <div className={"col"}>
            <Autocomplete
              {...defaultProps}
              id="add-records-to-meeting-autocomplete"
              autoComplete
              includeInputInList
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setSelectedRecord(newValue.ID);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant={"outlined"}
                  label="Record"
                />
              )}
            />
          </div>
          <div
            className={"col-1 d-flex align-items-center justify-content-center"}
          >
            <IconButton
              aria-label="delete"
              className={classes.margin}
              color={"primary"}
              disabled={selectedRecord === null}
              onClick={addRecord}
              size="large"
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
        <div className={"row pt-2"}>
          <div className={"col"}>
            {!noRows && (
              <Table size="small" aria-label="assigned records">
                <TableHead>
                  <TableRow>
                    <TableCell>ARCHI Record ID</TableCell>
                    <TableCell>Facility Record ID</TableCell>
                    <TableCell align={"right"}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.ID}>
                      <TableCell>{row.archiRecordID}</TableCell>
                      <TableCell>{row.facilityRecordID}</TableCell>
                      <TableCell align={"right"}>
                        <IconButton
                          aria-label="delete"
                          className={classes.margin}
                          color={"secondary"}
                          size={"small"}
                          onClick={() => removeRecord(row.ID)}
                        >
                          <RemoveIcon fontSize="inherit" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.setDialogVisible();
          }}
          color={"secondary"}
          variant={"contained"}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddRecordsToMeetingDialog;
