import React from "react";
// data
import { useSelector } from "react-redux";
import { selectContent } from "src/redux/selectors";
// ui
import { MenuItem } from "@mui/material";
import { Select, TextField } from "mui-rff";

const MRTSReviewTypes = [
  { ID: 1, Name: "Committee Review" },
  { ID: 2, Name: "Direct Review" },
];

const ReviewMonths = [
  { ID: 1, Name: "January" },
  { ID: 2, Name: "February" },
  { ID: 3, Name: "March" },
  { ID: 4, Name: "April" },
  { ID: 5, Name: "May" },
  { ID: 6, Name: "June" },
  { ID: 7, Name: "July" },
  { ID: 8, Name: "August" },
  { ID: 9, Name: "September" },
  { ID: 10, Name: "October" },
  { ID: 11, Name: "November" },
  { ID: 12, Name: "December" },
];

const EditTracking = () => {
  const RecordStatuses = useSelector(selectContent("RecordStatus")).filter(
    (r) => r.Active === true || r.Active === 1
  );

  return (
    <>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <Select
            variant={"outlined"}
            label={"Record Status"}
            displayEmpty
            name="recordStatus"
            required
          >
            <MenuItem value={null}>Select a Record Status</MenuItem>
            {RecordStatuses.map(
              (recordStatus) =>
                (recordStatus.Active === 1 || recordStatus.Active === true) && (
                  <MenuItem
                    value={recordStatus.ID}
                    key={`create-mrts-record-record-status-select-${recordStatus.Name}-${recordStatus.ID}`}
                  >
                    {recordStatus.Name}
                  </MenuItem>
                )
            )}
          </Select>
        </div>
        <div className={"col"}>
          <Select
            variant={"outlined"}
            label={"MRTS Review Type"}
            displayEmpty
            name="mrtsReviewType"
            required
          >
            <MenuItem value={null}>Select an MRTS Review Type</MenuItem>
            {MRTSReviewTypes.map((mrtsReviewType) => (
              <MenuItem
                value={mrtsReviewType.ID}
                key={`create-mrts-record-mrts-review-type-select-${mrtsReviewType.Name}-${mrtsReviewType.ID}`}
              >
                {mrtsReviewType.Name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <Select
            variant={"outlined"}
            label={"Review Month"}
            displayEmpty
            name="reviewMonth"
            required
          >
            <MenuItem value={null}>Select a Review Month</MenuItem>
            {ReviewMonths.map((reviewMonth) => (
              <MenuItem
                value={reviewMonth.ID}
                key={`create-mrts-record-review-month-select-${reviewMonth.Name}-${reviewMonth.ID}`}
              >
                {reviewMonth.Name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={"col"}>
          <TextField
            variant={"outlined"}
            label={"Review Year"}
            name={"reviewYear"}
          />
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <TextField
            label={"Comments"}
            name={"comments"}
            variant={"outlined"}
            rows={5}
            multiline
          />
        </div>
      </div>
    </>
  );
};

export default EditTracking;
