import { createSlice } from "@reduxjs/toolkit";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { FetchingActions } from "./Fetching";

const initialState = {
  Loading: false,
  Fetched: false,
  Data: {},
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.Loading = action.payload;
    },
    setFetched: (state, action) => {
      state.Fetched = action.payload;
    },
    setData: (state, action) => {
      state.Data = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

const { setLoading, setFetched, setData, reset } = reportSlice.actions;

const Fetch = (values) => async (dispatch) => {
  const reports = {};
  const reportCategories = [
    "checklistReports",
    "outcomeReports",
    "patientTypeReports",
    "recommendationReports",
  ];
  reportCategories.forEach((category) => {
    values[category].forEach((report) => {
      if (reports[report] === undefined) {
        reports[report] = true;
      }
    });
  });
  const requestObject = {
    archiFacilityId: values.archiFacilityId,
    dateRange: values.dateRange,
    groupBy: values.displayOptions.groupBy,
    displayType: values.displayOptions.displayType,
    allFacilities: values.displayOptions.sections.includes("allFacilities"),
    reports,
  };

  try {
    dispatch(reset());
    dispatch(setLoading(true));
    const response = await apiService.post(`${apiUrl}report/generate`, {
      ...requestObject,
    });
    dispatch(setData(response.data));
    dispatch(setFetched(true));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(reset());
    dispatch(FetchingActions.Failure("Fetching Report"));
  }
};

const Reset = () => async (dispatch) => {
  dispatch(reset());
};

export const ReportActions = {
  Fetch,
  Reset,
};

export default reportSlice.reducer;
