import * as Yup from "yup";

const validationSchema = Yup.object({
  source: Yup.number().required(),
  destination: Yup.number()
    .when("source", (source, schema) => {
      return schema.test({
        test: (destination) => destination !== source,
        message: "Midlevels must be different",
      });
    })
    .required(),
});

export default validationSchema;
