import { isNumber, isEmpty } from "lodash";

const MRTSReviewTypes = {
  0: "Pending",
  1: "Committee Review",
  2: "Direct Review",
};

const findByMeetingName = (meetingName) =>
  meetingName.includes("Group") ? MRTSReviewTypes[2] : MRTSReviewTypes[1];

const MRTSReviewType = (param = 0) =>
  isNumber(param) ? MRTSReviewTypes[param] : findByMeetingName(param);

const userNameByID = (id, userList) => {
  const user = userList.find(({ ID }) => ID === id);
  return isEmpty(user) ? "" : `${user.FirstName} ${user.LastName}`;
};

const get = {
  MRTSReviewType,
  userNameByID,
};

export default get;
