import React from "react";

const BoxTitle = ({ name = "" }) => (
  <div className={"row"}>
    <div className={"col text-left h2"}>
      {name.replace(/([A-Z])/g, " $1").trim()}
    </div>
  </div>
);

export default BoxTitle;
