import React, { useState } from "react";
import TablePageCard from "src/components/individual/TablePageCard";
import Typography from "@mui/material/Typography";
import { getReviewsDashboardTable } from "src/components/pages/MeetingManagement/ReviewsDashboard/ReviewsDashboardFunctions";
import ReviewDialog from "./ReviewDialog";
import { useNavigate } from "react-router-dom";
import get from "../helperFunctions";

const RecordReviewTable = ({
  meeting,
  facilitator,
  records,
  reviews,
  roles,
  users,
}) => {
  const [dialog, setDialog] = useState({
    visible: false,
    recordID: null,
    directReviewComments: {},
    peerReviewNotes: "",
    requestType: "",
    facilitatorID: null,
  });
  const navigate = useNavigate();

  const handleOpen = (
    recordID,
    directReviewComments,
    peerReviewNotes,
    requestType
  ) => {
    setDialog(() => ({
      visible: true,
      recordID: recordID,
      directReviewComments: directReviewComments,
      peerReviewNotes: peerReviewNotes,
      requestType: requestType,
      facilitatorID: meeting.FacilitatorID,
    }));
  };
  const handleClose = () =>
    setDialog({
      visible: false,
      recordID: null,
      directReviewComments: [],
      peerReviewNotes: "",
      requestType: "",
      facilitatorID: null,
    });

  const { rows, columns } = getReviewsDashboardTable(
    records,
    reviews,
    roles,
    navigate,
    handleOpen,
    users
  );

  return (
    <>
      <ReviewDialog dialog={dialog} handleClose={handleClose} />
      <TablePageCard
        title={
          <div className={"col"}>
            <div className={"row"}>
              <div className={"col text-left"}>
                <Typography variant="h3">{meeting.MeetingName}</Typography>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col text-left"}>
                <Typography variant="h4">
                  MRTS Review Type: {get.MRTSReviewType(meeting.MeetingName)}
                </Typography>
              </div>
            </div>
          </div>
        }
        table={{
          columns: columns,
          data: rows,
          options: { selectableRowsHideCheckboxes: true },
        }}
        button={{ isRender: false }}
      />
    </>
  );
};

export default RecordReviewTable;
