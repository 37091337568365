import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form } from "react-final-form";
import * as yup from "yup";
import validateFinalForm from "src/utilities/validateFinalForm";
import stateOptions from "src/utilities/stateOptions";
import { Select, TextField, Checkboxes } from "mui-rff";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { useSelector } from "react-redux";
import { selectFacilityByID } from "src/redux/selectors";
import { MenuItem } from "@mui/material";

const validationSchema = yup.object({
  name: yup.string().required().nullable(),
  archiFacilityID: yup.string().required().nullable(),
  city: yup.string().required().nullable(),
  state: yup.string().required().nullable(),
  active: yup.boolean().required(),
});

const mutators = {
  clearAll: (args, state, utils) => {
    utils.changeValue(state, "name", () => null);
    utils.changeValue(state, "archiFacilityID", () => null);
    utils.changeValue(state, "city", () => null);
    utils.changeValue(state, "state", () => null);
    utils.changeValue(state, "active", () => false);
  },
  setState: (args, state, utils) => {
    utils.changeValue(state, "state", () => args[0]);
  },
};

const UpdateFacilityDialog = (props) => {
  const Facility = useSelector(selectFacilityByID(props.facilityID));
  const render = Facility !== undefined;

  const initialValues = {
    name: Facility.Name,
    archiFacilityID: Facility.ARCHIFacilityID,
    city: Facility.City,
    state: Facility.State,
    active: Facility.Active,
  };

  const updateFacility = (values) => {
    props.dispatch(
      FacilityActions.Update({
        ID: props.facilityID,
        Name: values.name,
        ARCHIFacilityID: values.archiFacilityID,
        City: values.city,
        State: values.state,
        Active: values.active,
      })
    );
  };

  return (
    <>
      {render && (
        <Form
          onSubmit={updateFacility}
          initialValues={initialValues}
          validate={validateFinalForm(validationSchema)}
          mutators={mutators}
          render={({ form, submitting, values }) => (
            <>
              <form>
                <Dialog
                  fullWidth
                  maxWidth={"sm"}
                  open={props.dialogVisible}
                  onClose={() => {
                    props.setDialogVisible();
                    form.mutators.clearAll();
                  }}
                >
                  <DialogTitle>Update Facility</DialogTitle>
                  <DialogContent>
                    <div className={"row"}>
                      <div className={"col"}>
                        <TextField
                          label="Name"
                          name="name"
                          variant={"outlined"}
                          required
                          disabled={submitting}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="ARCHI Facility ID"
                          name="archiFacilityID"
                          variant={"outlined"}
                          required
                          disabled={submitting}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="City"
                          name="city"
                          variant={"outlined"}
                          required
                          disabled={submitting}
                        />
                      </div>
                      <div className={"col-4"}>
                        <Select
                          name={"state"}
                          label={"State"}
                          labelId="update-facility-state-select-label"
                          id="update-facility-state-select"
                          variant={"outlined"}
                          disabled={submitting}
                          displayEmpty
                          required
                        >
                          <MenuItem value={null}>Select a State</MenuItem>
                          {stateOptions.map((option) => (
                            <MenuItem
                              key={`update-facility-state-select-option-${option.name}-${option.value}`}
                              value={option.value}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <Checkboxes
                          size={"small"}
                          name={"active"}
                          data={{ label: "Active", value: true }}
                          color={"primary"}
                          disabled={submitting}
                        />
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color={"secondary"}
                      variant={"contained"}
                      onClick={() => {
                        props.setDialogVisible();
                        form.mutators.clearAll();
                      }}
                      disabled={submitting}
                    >
                      Close
                    </Button>
                    <Button
                      color={"primary"}
                      variant={"contained"}
                      onClick={() => {
                        updateFacility(values);
                        props.setDialogVisible();
                        form.mutators.clearAll();
                      }}
                      disabled={
                        values.name === null ||
                        values.archiFacilityID === null ||
                        values.city === null ||
                        values.state === null ||
                        submitting
                      }
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </>
          )}
        />
      )}
    </>
  );
};

export default UpdateFacilityDialog;
