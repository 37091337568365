import { createSelector } from "reselect";
import { findById } from "./helperFunctions";

const selectContent = (contentName) => (state) => state[contentName];

const selectContentByID = (contentName, ID) =>
  createSelector(selectContent(contentName), (contents) =>
    findById(contents, ID)
  );

const selectLoaded = selectContent("Loaded");

export { selectContent, selectContentByID, selectLoaded };
