import { createSlice } from "@reduxjs/toolkit";
import { FetchingActions } from "src/redux/reducers/Fetching";

const initialState = {
  To: null,
};

const redirectSlice = createSlice({
  name: "redirect",
  initialState,
  reducers: {
    set: (_state, action) => {
      return { To: action.payload };
    },
    reset: () => {
      return { ...initialState };
    },
  },
});

const { set, reset } = redirectSlice.actions;

const Set = (contents) => async (dispatch) => {
  dispatch(set(contents));
};

const Reset = () => async (dispatch) => {
  dispatch(reset());
};

const To = (Link) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(set(Link));
  } catch (error) {
    dispatch(FetchingActions.Failure("Redirecting"));
  }
};

export const RedirectActions = {
  Set,
  To,
  Reset,
};

export default redirectSlice.reducer;
