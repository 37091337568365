import { isEmpty } from "lodash";

const ChartDataFormat = (
  labels = [],
  chartType = "radar",
  groupBy = "",
  data = [],
  datasets = [],
  axisLabel = {}
) => {
  // Colors
  const redOffset = 63;
  const greenOffset = 149;
  const blueOffset = 202;
  const colorStep = 255;

  const getColor = (index, opacity) => {
    return `rgba(${(redOffset * (index + 1)) % colorStep}, ${
      (greenOffset * (index + 1)) % colorStep
    }, ${(blueOffset * (index + 1)) % colorStep}, ${opacity})`;
  };

  const getBackgroundColor = (values, outerIndex) => {
    const colors = [];
    for (let i = 0; i < values.length; i++) {
      if (values[i] !== undefined) {
        colors.push(getColor(outerIndex, .7));
      } else {
        colors.push(null);
      }
    }

    return colors;
  };

  const getBorderColor = (values, outerIndex) => {
    const colors = [];
    for (let i = 0; i < values.length; i++) {
      if (values[i] !== undefined) {
        colors.push(getColor(outerIndex, 1));
      } else {
        colors.push(null);
      }
    }

    return colors;
  };

  const mapDatasets = (localDatasets) => {
    return isEmpty(localDatasets)
      ? []
      : localDatasets.map((value, i) => {
          return {
            label: value.label,
            data: value.data,
            backgroundColor: getBackgroundColor(value.data, i),
            borderColor: getBorderColor(value.data, i),
            borderWidth: 1,
          };
        });
  };

  const backgroundColor = data.map(() => "rgba(63, 149, 202, 0.7)");
  const borderColor = data.map(() => "rgba(63, 149, 202, 1)");
  const backgroundColors = data.map(
    (value, i) =>
      `rgba(${(redOffset * (i + 1)) % colorStep}, ${
        (greenOffset * (i + 1)) % colorStep
      }, ${(blueOffset * (i + 1)) % colorStep}, 0.7)`
  );
  const borderColors = data.map(
    (value, i) =>
      `rgba(${(redOffset * (i + 1)) % colorStep}, ${
        (greenOffset * (i + 1)) % colorStep
      }, ${(blueOffset * (i + 1)) % colorStep}, 1)`
  );

  let formattedData = [];

  formattedData["monthly"] = {
    labels,
    datasets: mapDatasets(datasets),
  };

  formattedData["quarterly"] = {
    labels,
    datasets: mapDatasets(datasets),
  };

  formattedData["yearly"] = {
    labels,
    datasets: mapDatasets(datasets),
  };

  formattedData[""] = {
    labels,
    datasets: [
      {
        label: axisLabel.y,
        data,
        backgroundColor,
        borderColor,
        borderWidth: 1,
      },
    ],
  };

  formattedData["line"] = {
    labels,
    datasets: [
      {
        label: axisLabel.y,
        data,
        fill: false,
        backgroundColor: borderColor[0],
        borderColor: backgroundColor[0],
      },
    ],
  };

  formattedData["doughnut"] = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  formattedData["pie"] = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  formattedData["polar"] = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  formattedData["radar"] = {
    labels,
    datasets: [
      {
        label: axisLabel.y,
        data,
        backgroundColor: backgroundColor[0],
        borderColor: borderColor[0],
        borderWidth: 1,
      },
    ],
  };

  return {
    data:
      chartType === "bar" ? formattedData[groupBy] : formattedData[chartType],
  };
};

export default ChartDataFormat;
