const findById = (array, ID) =>
  array.find((elem) => Number(elem.ID) === Number(ID));

const findByIds = (array, arrayOfIDs) =>
  array.filter((elem) => arrayOfIDs.includes(elem.ID));

const findOneByUniqueID = (array = [], ID = null, name = "ID") =>
  array.find((elem) => Number(elem[name]) === Number(ID));

const findMultipleByUniqueID = (array = [], ID = null, name = "ID") =>
  ID && array.length
    ? array.filter((elem) => Number(elem[name]) === Number(ID))
    : [];

const findActiveMultipleByUniqueID = (array = [], ID = null, name = "ID") =>
  ID && array.length
    ? array.filter((elem) => Number(elem[name]) === Number(ID) && elem.Active)
    : [];

export {
  findById,
  findByIds,
  findOneByUniqueID,
  findMultipleByUniqueID,
  findActiveMultipleByUniqueID,
};
