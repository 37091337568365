import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form } from "react-final-form";
import { Select, TextField, Checkboxes } from "mui-rff";
import { useSelector } from "react-redux";
import {
  selectContent,
  selectContentByID,
  selectActiveFacilities,
} from "src/redux/selectors";
import { MenuItem } from "@mui/material";
import { UserActions } from "src/redux/reducers/PersonnelManagement/User";
//validation
import { userValidationSchema as validationSchema } from "../../validationSchema";
import { makeValidate, showErrorOnBlur } from "mui-rff";

const mutators = {
  clearAll: (args, state, utils) => {
    utils.changeValue(state, "username", () => null);
    utils.changeValue(state, "email", () => null);
    utils.changeValue(state, "firstName", () => null);
    utils.changeValue(state, "lastName", () => null);
    utils.changeValue(state, "areaCode", () => null);
    utils.changeValue(state, "phoneNumber", () => null);
    utils.changeValue(state, "extension", () => null);
    utils.changeValue(state, "permissionID", () => null);
    utils.changeValue(state, "active", () => true);
    utils.changeValue(state, "facilityID", () => null);
  },
  clearFacilityID: (args, state, utils) => {
    utils.changeValue(state, "facilityID", () => null);
  },
};

const UpdateUserAdminDialog = (props) => {
  const User = useSelector(selectContentByID("User", props.userID));
  const Permissions = useSelector(selectContent("Permission"));
  const Facilities = useSelector(selectActiveFacilities());
  let startedWithNoFacilityID = true;
  const render =
    User !== undefined && Permissions !== undefined && Facilities !== undefined;

  const facilityOptions = Facilities.map((facility) => {
    return {
      name: facility.FullName,
      value: facility.ID,
    };
  });

  const permissionIDsRequiringFacility = [];
  const permissionOptions = [];

  Permissions.forEach((permission) => {
    if (permission.Active) {
      if (permission.Description.includes("Facility")) {
        permissionIDsRequiringFacility.push(permission.ID);
      }

      permissionOptions.push({
        name: permission.Description,
        value: permission.ID,
      });
    }
  });

  const checkFacilityID =
    User.FacilityID !== undefined && User.FacilityID !== null;

  const initialValues = {
    username: User.UserName,
    email: User.EmailAddress,
    firstName: User.FirstName,
    lastName: User.LastName,
    areaCode: User.AreaCode,
    phoneNumber: User.PhoneNumber,
    extension: User.Extension,
    permissionID: User.PermissionID,
    active: User.Active,
    facilityID: checkFacilityID ? User.FacilityID : null,
  };

  if (checkFacilityID) {
    startedWithNoFacilityID = false;
  }

  const updateUser = (values) => {
    const castValues = validationSchema.cast(values);

    if (startedWithNoFacilityID && castValues.facilityID !== null) {
      props.dispatch(
        UserActions.LinkToFacility({
          ID: props.userID,
          UserName: castValues.username,
          EmailAddress: castValues.email,
          FirstName: castValues.firstName,
          LastName: castValues.lastName,
          AreaCode: castValues.areaCode,
          PhoneNumber: castValues.phoneNumber,
          Extension: castValues.extension,
          PermissionID: castValues.permissionID,
          Active: castValues.active,
          FacilityID: castValues.facilityID,
        })
      );
    } else {
      props.dispatch(
        UserActions.Update({
          ID: props.userID,
          UserName: castValues.username,
          EmailAddress: castValues.email,
          FirstName: castValues.firstName,
          LastName: castValues.lastName,
          AreaCode: castValues.areaCode,
          PhoneNumber: castValues.phoneNumber,
          Extension: castValues.extension,
          PermissionID: castValues.permissionID,
          Active: castValues.active,
          FacilityID: castValues.facilityID,
        })
      );
    }
  };

  return (
    <>
      {render && (
        <Form
          onSubmit={updateUser}
          initialValues={initialValues}
          validate={makeValidate(validationSchema)}
          mutators={mutators}
          render={({ form, submitting, values }) => (
            <>
              <form>
                <Dialog
                  fullWidth
                  maxWidth={"sm"}
                  open={props.dialogVisible}
                  onClose={() => {
                    props.setDialogVisible();
                    form.mutators.clearAll();
                  }}
                >
                  <DialogTitle>Update User</DialogTitle>
                  <DialogContent>
                    <div className={"row py-2"}>
                      <div className={"col"}>
                        <TextField
                          label="Username"
                          name="username"
                          variant={"outlined"}
                          required
                          disabled={submitting}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="E-Mail"
                          name="email"
                          variant={"outlined"}
                          required
                          disabled={submitting}
                          type={"email"}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="First Name"
                          name="firstName"
                          variant={"outlined"}
                          required
                          disabled={submitting}
                        />
                      </div>
                      <div className={"col"}>
                        <TextField
                          label="Last Name"
                          name="lastName"
                          variant={"outlined"}
                          required
                          disabled={submitting}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="Area Code"
                          name="areaCode"
                          variant={"outlined"}
                          disabled={submitting}
                        />
                      </div>
                      <div className={"col"}>
                        <TextField
                          label="Phone Number"
                          name="phoneNumber"
                          variant={"outlined"}
                          disabled={submitting}
                        />
                      </div>
                      <div className={"col"}>
                        <TextField
                          label="Extension"
                          name="extension"
                          variant={"outlined"}
                          disabled={submitting}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <Select
                          name={"permissionID"}
                          label={"Permission Level"}
                          labelId="create-user-select-label"
                          id="create-user-select"
                          variant={"outlined"}
                          disabled={submitting}
                          displayEmpty
                          required
                        >
                          <MenuItem disabled>Select a Permission</MenuItem>
                          {permissionOptions.map((option) => (
                            <MenuItem
                              onClick={form.mutators.clearFacilityID}
                              key={`create-user-select-option-${option.name}-${option.value}`}
                              value={option.value}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <Select
                          name={"facilityID"}
                          label={"Facility"}
                          labelId="create-user-facility-select-label"
                          id="create-user-facility-select"
                          variant={"outlined"}
                          disabled={
                            values.permissionID === null ||
                            !permissionIDsRequiringFacility.includes(
                              values.permissionID
                            ) ||
                            submitting
                          }
                          required={permissionIDsRequiringFacility.includes(
                            values.permissionID
                          )}
                          displayEmpty
                        >
                          <MenuItem value={null}>Select a Facility</MenuItem>
                          {facilityOptions.map((option) => (
                            <MenuItem
                              key={`create-user-facility-select-option-${option.name}-${option.value}`}
                              value={option.value}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <Checkboxes
                          size={"small"}
                          name={"active"}
                          data={{ label: "Active", value: "true" }}
                          color={"primary"}
                          disabled={submitting}
                        />
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color={"secondary"}
                      variant={"contained"}
                      onClick={() => {
                        props.setDialogVisible();
                        form.mutators.clearAll();
                      }}
                      disabled={submitting}
                    >
                      Close
                    </Button>
                    <Button
                      color={"primary"}
                      variant={"contained"}
                      onClick={() => {
                        updateUser(values);
                        props.setDialogVisible();
                        form.mutators.clearAll();
                      }}
                      disabled={
                        values.username === null ||
                        values.email === null ||
                        values.firstName === null ||
                        values.lastName === null ||
                        values.permissionID === null ||
                        submitting
                      }
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </>
          )}
        />
      )}
    </>
  );
};

export default UpdateUserAdminDialog;
