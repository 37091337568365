import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  useMemo,
} from "react";
import { useEffectOnce } from "react-use";
import { useSelector } from "react-redux";
import { selectReviewByRecordID } from "src/redux/selectors";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import { apiUrl } from "src";
import apiService from "src/utilities/api_service";
import { globalVariables } from "src/components/App";
import useAuthHook from "src/utilities/useAuthHook";
import { FetchingActions } from "src/redux/reducers/Fetching";
import useStyles from "./styles";
import Layout from "./Layout";
import RNOutcome from "./RNOutcome";
import ReviewCommentsSection from "./ReviewCommentsSection";
import RNChecklist from "./RNChecklist";
import FacilitatorRequest from "./FacilitatorRequest";
import initiateValues from "./helperFunctions/initiateValues";
import submitRecordReview from "./helperFunctions/submitRecordReview";
import { ReviewActions } from "src/redux/reducers/Review";

export const recordReviewData = createContext(null);

const RecordReview = () => {
  const { recordID } = useParams();
  const reviewFromStore = useSelector(selectReviewByRecordID(recordID));
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = useState({});
  const { authenticate } = useAuthHook();
  const { authenticatedDispatch, roles } = useContext(globalVariables);
  const classes = useStyles();
  const [countInvalid, setCountInvalid] = useState(0);

  useEffectOnce(() => {
    authenticatedDispatch(ReviewActions.BatchAddMeetingDashboardData(roles));
  });

  const fetchData = useCallback(async () => {
    await authenticate();
    const { data: contents } = await apiService.get(
      `${apiUrl}facilitatorReview/getInfo?RecordID=${recordID}`
    );
    await setApiResponse(contents);
  }, [recordID]);

  useEffect(async () => {
    reviewFromStore && reviewFromStore.hasOwnProperty("OriginalData")
      ? setApiResponse(_.cloneDeep(reviewFromStore))
      : await fetchData();
  }, [fetchData]);

  useMemo(() => {
    initialValues = {};
    initiateValues(initialValues, apiResponse);
  }, [apiResponse]);

  const onSubmitRecordReview = async (values, actionStage) => {
    try {
      await submitRecordReview(
        recordID,
        values,
        actionStage,
        apiResponse,
        setApiResponse,
        authenticate,
        authenticatedDispatch,
        navigate
      );
    } catch (error) {
      authenticatedDispatch(
        FetchingActions.Failure("Record Review Submission")
      );
    }
  };

  return !_.isEmpty(apiResponse) ? (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmitRecordReview}
      subscription={{ initialValues: true }}
    >
      {({ handleSubmit }) => {
        return (
          <Card>
            <form className={classes.container} onSubmit={handleSubmit}>
              <recordReviewData.Provider
                value={{ ...apiResponse, countInvalid, setCountInvalid }}
              >
                <Layout
                  onSubmitRecordReview={onSubmitRecordReview}
                  roles={roles}
                >
                  <CardContent>
                    <RNOutcome />
                  </CardContent>
                  <CardContent>
                    <FacilitatorRequest
                      recordId={recordID}
                      onSubmitRecordReview={onSubmitRecordReview}
                    />
                    <ReviewCommentsSection />
                  </CardContent>
                  <CardContent>
                    <RNChecklist />
                  </CardContent>
                </Layout>
              </recordReviewData.Provider>
            </form>
          </Card>
        );
      }}
    </Form>
  ) : (
    <CircularProgress />
  );
};

let initialValues = {};

export default RecordReview;
