const checkForKey = (key, values) => {
  let present = false;
  const allValues = [
    ...values.checklistReports,
    ...values.outcomeReports,
    ...values.patientTypeReports,
    ...values.programOverviewSelected,
    ...values.recommendationReports,
  ];
  if (allValues.includes(key)) {
    present = true;
  }
  return present;
};

export default checkForKey;
