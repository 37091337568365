import React from "react";
import { MenuItem } from "@mui/material";
import { Select } from "mui-rff";
import { useSelector } from "react-redux";
import { selectContent } from "src/redux/selectors";

const CommitteeSelect = (props) => {
  const committeeList = useSelector(selectContent("Committee"));
  return (
    <div className={"row pt-2"}>
      <div className={"col"}>
        <Select
          name={"committeeID"}
          label={"Committee"}
          labelId="create-meeting-committee-select-label"
          id="create-meeting-committee-select"
          variant={"outlined"}
          disabled={props.disabled}
          displayEmpty
          required
        >
          <MenuItem value={null}>Select a Committee</MenuItem>
          {committeeList.map((option) => (
            <MenuItem
              key={`create-meeting-committee-${option.Name}-${option.ID}`}
              value={option.ID}
            >
              {option.Name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default CommitteeSelect;
