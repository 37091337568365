import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityPatientOutcomeChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Patient Outcome"}
        xAxisLabel={"Patient Outcome"}
        yAxisLabel={"Number of Records"}
        figureText={"Patient Outcome for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilityPatientOutcomeChart;
