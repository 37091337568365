import React from "react";
import { Button, MenuItem } from "@mui/material";
import { Select, Checkboxes } from "mui-rff";

const groupByOptions = [
  { name: "Monthly", value: "monthly" },
  { name: "Quarterly", value: "quarterly" },
  { name: "Yearly", value: "yearly" },
];

const displayTypeOptions = [
  { name: "ID", value: "providerId" },
  { name: "Name", value: "nameOnly" },
  { name: "Name and ID", value: "nameAndId" },
];

const checkboxOptions = [
  { label: "Text", value: "text" },
  { label: "Table", value: "table" },
  { label: "All Facilities", value: "allFacilities" },
];

const chartOptions = [
  { name: "Bar", value: "bar" },
  { name: "Line", value: "line" },
  { name: "Pie", value: "pie" },
  { name: "Doughnut", value: "doughnut" },
  { name: "Polar Area", value: "polar" },
  { name: "Radar", value: "radar" },
];

const DisplayOptionsSection = (props) => {
  return (
    <>
      <div className={"row pt-2 pr-2"}>
        <div className={"col text-left h4"}>Display Options</div>
      </div>
      <div className={"row pt-2 pr-2"}>
        <div className={"col"}>
          <Select
            name={"chartType"}
            label={"Chart Type"}
            labelId="reports-chart-type-select-label"
            id="reports-chart-type-select"
            variant={"outlined"}
            required
            disabled={props.disabled}
            displayEmpty
          >
            <MenuItem value={null}>Select a Chart Type</MenuItem>
            {chartOptions.map((option) => (
              <MenuItem
                key={`reports-chart-type-select-${option.name}-${option.value}`}
                value={option.value}
                onClick={
                  option.value !== "bar" && props.form.mutators.clearGroupBy
                }
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={"row pt-2 pr-2"}>
        <div className={"col"}>
          <Select
            name={"displayOptions.groupBy"}
            label={"Group By"}
            labelId="display-options-group-by-select-label"
            id="display-options-group-by-select"
            variant={"outlined"}
            disabled={props.values.chartType !== "bar" || props.disabled}
            displayEmpty
          >
            <MenuItem value={null}>Select a Grouping Method</MenuItem>
            {groupByOptions.map((option) => (
              <MenuItem
                key={`display-options-group-by-${option.name}-${option.value}`}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={"row pt-2 pr-2"}>
        <div className={"col"}>
          <Select
            name={"displayOptions.displayType"}
            label={"Provider Display Type"}
            labelId="display-options-display-type-select-label"
            id="display-options-display-type-select"
            variant={"outlined"}
            disabled={props.disabled}
            displayEmpty
          >
            <MenuItem value={null}>Select a Display Type</MenuItem>
            {displayTypeOptions.map((option) => (
              <MenuItem
                key={`display-options-display-type-${option.name}-${option.value}`}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={"row pt-2 pr-2"}>
        <div className={"col text-left h5"}>Display Sections</div>
      </div>
      <div className={"row pt-2 pr-2"}>
        <div className={"col d-flex align-items-center justify-content-begin"}>
          <Button
            className={"mx-1"}
            variant="outlined"
            size={"small"}
            color={"primary"}
            onClick={props.form.mutators.selectAllDisplaySections}
            disabled={props.disabled}
          >
            Select All
          </Button>
          <Button
            className={"mx-1"}
            variant="outlined"
            size={"small"}
            color={"secondary"}
            onClick={props.form.mutators.unselectAllDisplaySections}
            disabled={props.disabled}
          >
            Unselect All
          </Button>
        </div>
      </div>
      <div className={"row"}>
        <div
          className={"col pl-4 d-flex align-items-center justify-content-begin"}
        >
          <Checkboxes
            size={"small"}
            name={"displayOptions.sections"}
            data={checkboxOptions}
            color={"primary"}
            disabled={props.disabled}
          />
        </div>
      </div>
    </>
  );
};

export default DisplayOptionsSection;
