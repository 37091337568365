import React, { useContext } from "react";
// data
import { globalVariables } from "src/components/App";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { selectContent } from "src/redux/selectors";
import C from "src/utilities/constants";
// ui
import { makeStyles } from "@mui/styles";
import { Field, FormSpy } from "react-final-form";
import { NotificationActions } from "src/redux/reducers/Notification";
import {
  Typography,
  Button,
  IconButton,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Checkboxes, TextField, Select } from "mui-rff";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";
import ICDSearch from "./ICDSearch";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PatientInformation = (props) => {
  const { authenticate, authenticatedDispatch } = useContext(globalVariables);
  const classes = useStyles();
  const AgeIncrements = useSelector(selectContent("AgeIncrement")).filter(
    (a) => a.Active === true || a.Active === 1
  );
  const PatientRaces = useSelector(selectContent("PatientRace")).filter(
    (p) => p.Active === true || p.Active === 1
  );
  const PatientGenders = useSelector(selectContent("PatientGender")).filter(
    (p) => p.Active === true || p.Active === 1
  );
  const PatientOriginations = useSelector(
    selectContent("PatientSource")
  ).filter((p) => p.Active === true || p.Active === 1);
  const ReviewTypes = useSelector(selectContent("ReviewType")).filter(
    (r) => r.Active === true || r.Active === 1
  );
  const Committees = useSelector(selectContent("Committee")).filter(
    (c) => c.Active === true || c.Active === 1
  );

  const committeeIDsWithCheckbox = Committees.filter((v) =>
    ["General Surgery", "Orthopedics"].includes(v.Name)
  ).map((c) => c.ID);

  const otherCommittee = Committees.find((c) => c.Name === "Other");

  return (
    <>
      <div className={"row pt-2"} id={"patient-information-title"}>
        <div className={"col"}>
          <Typography variant="h4" className={props.classes.title}>
            Patient Information
          </Typography>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <TextField
            label={"Patient Age"}
            name={"patientAge"}
            variant={"outlined"}
            type={"number"}
            required
          />
        </div>
        <div className={"col"}>
          <Select
            variant={"outlined"}
            label={"Age Increment"}
            displayEmpty
            name="ageIncrement"
            required
          >
            <MenuItem disabled>Select an Age Increment</MenuItem>
            {AgeIncrements.map((ageIncrement) => (
              <MenuItem
                value={ageIncrement.ID}
                key={`create-mrts-record-age-increment-select-${ageIncrement.Name}-${ageIncrement.ID}`}
              >
                {ageIncrement.Name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <Select
            variant={"outlined"}
            label={"Patient Race"}
            displayEmpty
            name="patientRace"
          >
            <MenuItem value={null}>Select a Race</MenuItem>
            {PatientRaces.map((patientRace) => (
              <MenuItem
                value={patientRace.ID}
                key={`create-mrts-record-patient-race-select-${patientRace.Name}-${patientRace.ID}`}
              >
                {patientRace.Name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={"col"}>
          <Select
            variant={"outlined"}
            label={"Patient Gender"}
            displayEmpty
            name="patientGender"
            required
          >
            <MenuItem value={null} disabled>
              Select a Gender
            </MenuItem>
            {PatientGenders.map((patientGender) => (
              <MenuItem
                value={patientGender.ID}
                key={`create-mrts-record-patient-gender-select-${patientGender.Name}-${patientGender.ID}`}
              >
                {patientGender.Name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <FormSpy subscription={{ form: true }}>
            {({ form }) => (
              <Select
                variant={"outlined"}
                label={"Patient Origination"}
                displayEmpty
                name="patientOriginationID"
                required
              >
                <MenuItem value={null} disabled>
                  Select a Source
                </MenuItem>
                {PatientOriginations.map((patientOrigination) => (
                  <MenuItem
                    value={patientOrigination.ID}
                    key={`create-mrts-record-patient-origination-select-${patientOrigination.Name}-${patientOrigination.ID}`}
                    onClick={form.mutators.clearPatientOriginationOther}
                  >
                    {patientOrigination.Name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormSpy>
        </div>
        <div className={"col"}>
          <Field name={"reviewTypeID"}>
            {({ input }) => {
              let validCommittees = Committees;
              const currentReviewType = ReviewTypes.find(
                (r) => r.ID === input.value
              );
              if (
                currentReviewType !== undefined &&
                currentReviewType.Name === "Participating Hospital"
              ) {
                validCommittees = Committees.filter((c) =>
                  C.PARTICIPATING_HOSPITAL.includes(c.ID)
                );
              } else {
                validCommittees = Committees.filter(
                  (c) => !C.PARTICIPATING_HOSPITAL.includes(c.ID)
                );
              }
              return (
                <Select
                  variant={"outlined"}
                  label={"Committee"}
                  displayEmpty
                  name="committee"
                  required
                >
                  <MenuItem value={null} disabled>
                    Select a Committee
                  </MenuItem>
                  {validCommittees.map((committee) => (
                    <MenuItem
                      value={committee}
                      key={`create-mrts-record-committee-select-${committee.Name}-${committee.ID}`}
                    >
                      {`${committee.Abbreviation} - ${committee.Name}`}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          </Field>
        </div>
        <Field name={"committee"}>
          {({ input }) => (
            <>
              {input.value !== undefined &&
                input.value !== null &&
                otherCommittee !== undefined &&
                otherCommittee !== null &&
                input.value.ID === otherCommittee.ID && (
                  <div className={"col"}>
                    <TextField
                      label={"Describe Committee"}
                      name={"committeeOther"}
                      variant={"outlined"}
                      required
                    />
                  </div>
                )}
              {input.value !== undefined &&
                input.value !== null &&
                committeeIDsWithCheckbox.includes(input.value.ID) && (
                  <div
                    className={
                      "col-3 d-flex align-items-center justify-content-begin"
                    }
                  >
                    <Checkboxes
                      name="historyAndPhysicalOnChart"
                      data={{
                        label: "History and Physical on chart prior to Surgery",
                        value: true,
                      }}
                      size={"small"}
                    />
                  </div>
                )}
            </>
          )}
        </Field>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <strong>
            <i className="fas fa-exclamation-triangle pr-2" />
            Primary Diagnosis must be entered first.
          </strong>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <Field name={"noCodeAvailable"}>
          {({ input }) => (
            <>
              {input.value ? (
                <>
                  <div
                    className={
                      "col d-flex align-items-center justify-content-center"
                    }
                  >
                    <TextField
                      label={"ICD Code Description"}
                      name={"icdCodeDescription"}
                      variant={"outlined"}
                    />
                  </div>
                  <div
                    className={
                      "col-3 d-flex align-items-center justify-content-begin"
                    }
                  >
                    <FormSpy
                      subscription={{ form: true, values: true, meta: true }}
                    >
                      {({ form, values, meta }) => (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            if (values.icdCodes.some(({ text }) => text === values.icdCodeDescription)) {
                              authenticatedDispatch(
                                NotificationActions.Open({
                                  Message: `ICD Code already exist`,
                                  Severity: "error",
                                  Time: 5000,
                                })
                              );
                            } else {
                              form.mutators.addCustomICDCode(values);
                            }
                          }}
                          disabled={isEmpty(values.icdCodeDescription)}
                        >
                          Add Code
                        </Button>
                      )}
                    </FormSpy>
                  </div>
                </>
              ) : (
                <ICDSearch />
              )}
            </>
          )}
        </Field>
        <div
          className={"col-3 d-flex align-items-center justify-content-begin"}
        >
          <Checkboxes
            name="noCodeAvailable"
            data={{
              label: "No Code Available",
              value: true,
            }}
            size={"small"}
          />
        </div>
      </div >
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <Checkboxes
            name="makePrimaryDiagnosis"
            data={{
              label: "Make Primary Diagnosis",
              value: false,
            }}
            size={"small"}
          />
        </div>
      </div>
      <Field name={"icdCodes"}>
        {({ input }) => (
          <>
            {Array.isArray(input.value) && input.value.length > 0 && (
              <div className={"row pt-2 mx-1"}>
                <div
                  className={
                    "col d-flex align-items-center justify-content-center"
                  }
                >
                  <Table aria-label="mrts record icd codes">
                    <TableHead>
                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>Diagnosis Level</TableCell>
                        <TableCell align={"right"}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <FormSpy subscription={{ form: true }}>
                      {({ form }) => (
                        <TableBody>
                          {input.value.map((row, index) => (
                            <TableRow
                              key={`create-mrts-record-icd-codes-${row.id}-${row.text}`}
                            >
                              <TableCell>{row.text}</TableCell>
                              <TableCell>
                                {index === 0 ? "Primary" : "Secondary"}
                              </TableCell>
                              <TableCell align={"right"}>
                                {row.noCodeAvailable ? (
                                  <IconButton
                                    aria-label="edit"
                                    className={classes.margin}
                                    color={"secondary"}
                                    size={"small"}
                                    onClick={() =>
                                      form.mutators.editICDCodeByDescription(
                                        row.text,
                                        index === 0
                                      )
                                    }
                                  >
                                    <EditIcon fontSize="inherit" />
                                  </IconButton>
                                ) : (
                                  <div></div>
                                )}
                                <IconButton
                                  aria-label="delete"
                                  className={classes.margin}
                                  color={"secondary"}
                                  size={"small"}
                                  onClick={() =>
                                    form.mutators.removeICDCodeByDescription(
                                      row.text
                                    )
                                  }
                                >
                                  <RemoveIcon fontSize="inherit" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                    </FormSpy>
                  </Table>
                </div>
              </div>
            )}
          </>
        )}
      </Field>
    </>
  );
};

export default PatientInformation;
