import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { RecordActions } from "src/redux/reducers/Record";
import GeneratePDF from "src/components/individual/GeneratePDF";

const UnapprovedFacilityButtons = (props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex] = React.useState(0);

  const options = ["Edit MRTS", "View MRTS"];

  const handleSelection = (index) => {
    if (index === 0) {
      props.spawnUpdateMRTSRecordDialog();
    } else {
      props.viewMRTS();
    }
  };

  const handleClick = () => {
    handleSelection(selectedIndex);
  };

  const handleMenuItemClick = (event, index) => {
    handleSelection(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const sameUser = () => {
    return props.record.CreatedBy === props.userData.current.UserID;
  };

  const isPersonnel = () => {
    return props.viewLevel === 0;
  };

  const canViewActions = () => {
    if (isPersonnel()) {
      return sameUser();
    }

    return true;
  };

  return (
    <>
      {canViewActions() ? (
        <Grid container direction="column" alignItems="left">
          <Grid item xs={12}>
            <ButtonGroup
              variant="contained"
              color="primary"
              size={"small"}
              ref={anchorRef}
              aria-label="split button"
            >
              <Button style={{ width: "130px" }} onClick={handleClick}>
                {options[selectedIndex]}
              </Button>
              <Button
                color="primary"
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={() => {
                  props.dispatch(RecordActions.LoadData(props.recordID, "ID"));
                  handleToggle();
                }}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id={`split-button-menu-${props.id}`}>
                        {props.record.Loaded !== undefined &&
                        props.record.Loaded !== null ? (
                          <>
                            {options.slice(1).map((option, index) => {
                              if (option === "View MRTS") {
                                return (
                                  <GeneratePDF {...props} RecordType={"MRTS"}>
                                    <MenuItem
                                      key={option}
                                      selected={index === selectedIndex}
                                    >
                                      {option}
                                    </MenuItem>
                                  </GeneratePDF>
                                );
                              } else {
                                return (
                                  <MenuItem
                                    key={option}
                                    selected={index === selectedIndex}
                                    onClick={(event) =>
                                      handleMenuItemClick(event, index)
                                    }
                                  >
                                    {option}
                                  </MenuItem>
                                );
                              }
                            })}
                          </>
                        ) : (
                          <MenuItem key={"split-button-menu-loading"}>
                            <i
                              className="fas fa-spinner fa-pulse"
                              style={{ color: "dodgerblue" }}
                            />
                            &nbsp; Loading
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Grid>
      ) : (
        "-"
      )}
    </>
  );
};

export default UnapprovedFacilityButtons;
