import React from "react";
import { MenuItem } from "@mui/material";
import { Select } from "mui-rff";
import { format } from "date-fns";
import MonthlyRange from "./MonthlyRange";
import QuarterlyRange from "./QuarterlyRange";
import YearlyRange from "./YearlyRange";
import CustomRange from "./CustomRange";

const options = [
  { name: "Monthly", value: "monthly" },
  { name: "Quarterly", value: "quarterly" },
  { name: "Yearly", value: "yearly" },
  { name: "Custom", value: "custom" },
];

const yearOptions = [];
for (let i = Number(format(new Date(), "yyyy")); i >= 2000; --i) {
  yearOptions.push({ name: `${i}`, value: i });
}

const ReportOptionsSection = (props) => {
  return (
    <>
      <div className={"row pt-2 pr-2"}>
        <div className={"col text-left h4"}>Report Options</div>
      </div>
      <div className={"row pr-2"}>
        <div className={"col"}>
          <Select
            name={"dateRange.type"}
            label={"Range Type"}
            labelId="reports-range-type-select-label"
            id="reports-range-type-select"
            variant={"outlined"}
            required
            disabled={props.disabled}
            displayEmpty
          >
            <MenuItem value={null}>Select a Range Type</MenuItem>
            {options.map((option) => (
              <MenuItem
                key={`reports-range-type-select-label-${option.name}-${option.value}`}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      {props.values.dateRange.type === "monthly" && (
        <MonthlyRange {...props} yearOptions={yearOptions} />
      )}
      {props.values.dateRange.type === "quarterly" && (
        <QuarterlyRange {...props} yearOptions={yearOptions} />
      )}
      {props.values.dateRange.type === "yearly" && (
        <YearlyRange {...props} yearOptions={yearOptions} />
      )}
      {props.values.dateRange.type === "custom" && <CustomRange {...props} />}
    </>
  );
};

export default ReportOptionsSection;
