import apiService from "src/utilities/api_service";
import C from "src/utilities/constants";
import { apiUrl } from "src";
import get from "./getData";

const finalizeValues = async (finalValues) => {
  const recordID = finalValues.RecordID;
  const oMeetingID = get.MeetingID(finalValues);
  const oOutcomes = get.OutcomesO(finalValues);
  const aOutcomes = get.OutcomesA(finalValues);
  const updatedDirectReviewValues = get.DirectReviewComments(finalValues);

  const getFinalOutcomeID = () => {
    const mrtsReviewTypeID = get.MRTSReviewTypeID(finalValues);
    return mrtsReviewTypeID === C.DIRECT_REVIEW_ID
      ? aOutcomes.OutcomeID
      : oOutcomes.OutcomeID;
  };

  const finalOutcomeID = getFinalOutcomeID();

  const { data: record } = await apiService.get(`${apiUrl}record/getInfo`, {
    params: {
      ID: recordID,
      SearchBy: "ID",
    },
  });
  const radioValues = finalValues.ApprovalData.Checklist.map(
    ({ Value }) => Value
  );
  return {
    dataExist: {
      checklist: !!record[0].Checklist.length,
      rnComments: !!record[0].RNComments.length,
      rnOutcomes: !!record[0].RNOutcomes,
    },
    Checklist: {
      RecordID: recordID,
      RadioValues: radioValues,
    },
    RNComments: {
      ID: record[0].RNComments.length ? record[0].RNComments[0].ID : null,
      RecordID: recordID,
      ...finalValues.OriginalData.RNComments,
      ...finalValues.ApprovalData.RNComments,
      DirectReviewComments: Object.entries(updatedDirectReviewValues)
        .filter(([_, value]) => value !== "")
        .reduce(
          (acc, [key, value]) => `${acc}
        ${key}: ${value}`,
          ""
        ),
    },
    RNOutcomes: {
      RecordID: recordID,
      RNOutcomes: {
        ID: oOutcomes.ID,
        MeetingID: oMeetingID,
        OutcomeID: finalOutcomeID,
        Improvements: aOutcomes.Improvements,
        OtherImprovement: aOutcomes.OtherImprovement
          ? aOutcomes.OtherImprovement
          : "",
      },
    },
  };
};

export default finalizeValues;
