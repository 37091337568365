import { createSlice } from "@reduxjs/toolkit";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { LastCreatedObjectActions } from "src/redux/reducers/LastCreatedObject";

const initialState = [];

const committeeSlice = createSlice({
  name: "committee",
  initialState,
  reducers: {
    batchAdd: (_state, action) => {
      return [...action.payload.committee];
    },
    create: (state, action) => {
      state.push(action.payload);
    },
    update: (state, action) => {
      const index = state.findIndex((elem) => elem.ID === action.payload.ID);
      state[index] = action.payload;
    },
  },
});

const { batchAdd, create, update } = committeeSlice.actions;

const BatchAdd = (content) => async (dispatch) => {
  dispatch(batchAdd(content));
};

const Create = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Committee/create`,
      content
    );
    dispatch(create(contents));
    dispatch(LastCreatedObjectActions.Set("Committee", contents));
    dispatch(FetchingActions.Success("Creating Committee"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Creating Committee"));
  }
};

const Update = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Committee/update`,
      content
    );
    dispatch(update(contents));
    dispatch(FetchingActions.Success("Updating Committee"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Committee"));
  }
};

export const CreateLookUpTable = (contents) => async (dispatch) => {
  contents.forEach(async (content) => {
    try {
      dispatch(FetchingActions.Begin());
      let body = content;
      const { data: newContent } = await apiService.post(
        `${apiUrl}constants/createCommittees`,
        body
      );
      dispatch(create(newContent));
      dispatch(FetchingActions.Success("Creating Committee"));
    } catch (error) {
      dispatch(FetchingActions.Failure("Creating Committee"));
    }
  });
};

export const UpdateLookUpTable = (contents) => async (dispatch) => {
  contents.forEach(async (content) => {
    try {
      dispatch(FetchingActions.Begin());
      let body = content;
      const { data: newContent } = await apiService.post(
        `${apiUrl}constants/updateCommittees`,
        body
      );
      dispatch(update(newContent));
      dispatch(FetchingActions.Success("Updating Committee"));
    } catch (error) {
      dispatch(FetchingActions.Failure("Updating Committee"));
    }
  });
};

export const CommitteeActions = {
  BatchAdd,
  Create,
  Update,
  CreateLookUpTable,
  UpdateLookUpTable,
};

export default committeeSlice.reducer;
