import React from "react";
import AdditionalAbbreviationsAcronymsAndSymbols from "./AdditionalAbbreviationsAcronymsAndSymbols";
import OfficialDoNotUseList from "./OfficialDoNotUseList";

const TextEnd = (props) => {
  return (
    <>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <OfficialDoNotUseList />
          <AdditionalAbbreviationsAcronymsAndSymbols />
        </>
      )}
    </>
  );
};

export default TextEnd;
