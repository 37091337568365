const commentsTemplate = (reviewID) => [
  {
    name: "PeerReviewComments",
    rows: 24
  },
  {
    name: "Commendations",
  },
  {
    name: "ProviderRecommendations",
    variant: "withSwitch",
    label:
      "There were no provider recommendations identified in review of this record",
    items: [
      {
        name: "PhysicianLegibility",
        variant: "withCheckbox",
        label: "Attach Statement to Final Report",
      },
    ],
  },
  {
    name: "FacilityRecommendations",
    variant: "withSwitch",
    label:
      "There were no facility recommendations identified in review of this record",
    items: [
      {
        name: "NurseLegibility",
        variant: "withCheckbox",
        label: "Attach Statement to Final Report",
      },
      {
        name: "MedicalAbbreviations",
        variant: "withCheckbox",
        label: "Attach Statement to Final Report",
      },
      {
        name: "MedicalTerminology",
        variant: "withCheckbox",
        label: "Attach Statement to Final Report",
      },
      {
        name: "TeachBacks",
        variant: "withCheckbox",
        label: "Attach Statement to Final Report",
      },
    ],
  },
  {
    name: "LiteratureReview",
    variant: "withSwitch",
    label:
      "In the review of this record, there was no request for a literature search",
  },
  {
    name: "DirectReviewComments",
    disabled: reviewID !== 2,
    style: reviewID !== 2 ? "grey" : "",
  },
];

export default commentsTemplate;
