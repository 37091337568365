import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-final-form";
import { Container, Box } from "@mui/material";
import { RecordActions } from "src/redux/reducers/Record";
import { ReviewActions } from "src/redux/reducers/Review";
import { selectRNOutcomesByRecordID } from "src/redux/selectors";
import { emptyObject } from "src/utilities/objectUtilities";
import HelpView from "./HelpView";
import { makeValidate } from "mui-rff";
import * as Yup from "yup";
import Buttons from "./Buttons";
import OutcomeForm from "./OutcomeForm";
import Submitter from "../Submitter";

const Outcomes = ({ dispatch, record, isFormDirty, setIsFormDirty }) => {
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const RNOutcomes = useSelector(selectRNOutcomesByRecordID(record.ID));
  const { RNOutcomesID, MRTSReviewTypeID } = RNOutcomes;
  const [initialValues, setInitialValues] = useState(emptyObject("RNOutcomes"));

  useEffect(() => {
    setInitialValues(RNOutcomes);
  }, [record.RNOutcomes]);

  const handleVisible = () => {
    setIsHelpVisible(!isHelpVisible);
  };
  const onSubmit = ({
    MeetingID,
    OutcomeID,
    Improvements,
    OtherImprovement,
  }) => {
    const data = {
      RecordID: record.ID,
      RNOutcomes: {
        ID: RNOutcomesID,
        MeetingID,
        OutcomeID,
        Improvements,
        OtherImprovement: OtherImprovement ? OtherImprovement : "",
      },
    };
    RNOutcomesID
      ? dispatch(RecordActions.UpdateOutcomes(data))
      : dispatch(RecordActions.CreateOutcomes(data));

    if (MeetingID !== null) {
      dispatch(ReviewActions.BatchReset());
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={makeValidate(schema)}
      subscription={{ values: true, dirty: true }}
      render={({ handleSubmit, values, dirty }) => {
        if (isFormDirty !== dirty) {
          setIsFormDirty(dirty);
        }
        return (
          <Container>
            {isHelpVisible ? (
              <HelpView handleVisible={handleVisible} />
            ) : (
              <form>
                <OutcomeForm MRTSReviewTypeID={MRTSReviewTypeID} />
                <Buttons
                  handleVisible={handleVisible}
                  handleSubmit={handleSubmit}
                />
                <Submitter values={values} onSubmit={handleSubmit} />
              </form>
            )}
          </Container>
        );
      }}
    />
  );
};

export const Section = ({ children }) => (
  <Box className={"row"} style={{ marginTop: 40 }}>
    <Box className={"col"}>{children}</Box>
  </Box>
);

const schema = Yup.object().shape({
  OutcomeID: Yup.number().required(),
});

export default Outcomes;
