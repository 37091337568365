import MUIDataTable from "mui-datatables";
import React from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { UserActions } from "src/redux/reducers/PersonnelManagement/User";
import {
  selectContent,
  selectFacilities,
  selectUsersByFacilityID,
} from "src/redux/selectors";
import Typography from "@mui/material/Typography";

const columns = [
  {
    name: "ID",
    label: "ID",
  },
  {
    name: "firstName",
    label: "First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
  },
  {
    name: "username",
    label: "Username",
  },
  {
    name: "emailAddress",
    label: "E-Mail Address",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
  },
  {
    name: "permission",
    label: "Permission",
  },
  {
    name: "facility",
    label: "Facility",
  },
  {
    name: "active",
    label: "Active",
  },
];

const options = {
  filterType: "multiselect",
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
};

const UsersTable = (props) => {
  const Users = useSelector(selectUsersByFacilityID(props.facilityID));
  const Facilities = useSelector(selectFacilities);
  const Permissions = useSelector(selectContent("Permission"));
  const Loaded = useSelector((state) => state.Loaded);

  useEffectOnce(() => {
    Loaded.User === false && props.dispatch(UserActions.BatchAdd());
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
  });

  const data = Users.map((user, i) => {
    const candidatePermission = Permissions.find(
      (permission) => permission.ID === user.PermissionID
    );
    const candidateFacility = Facilities.find(
      (facility) => facility.ID === user.FacilityID
    );
    return {
      ID: user.ID,
      firstName: user.FirstName ? user.FirstName : "-",
      lastName: user.LastName ? user.LastName : "-",
      username: user.UserName,
      emailAddress: user.EmailAddress,
      phoneNumber:
        user.PhoneNumber !== undefined && user.PhoneNumber !== null
          ? `${
              user.AreaCode !== undefined &&
              user.AreaCode !== null &&
              `(${user.AreaCode})`
            } ${user.PhoneNumber} ${
              user.Extension !== undefined && user.Extension !== null
                ? `ext. ${user.Extension}`
                : ""
            }`
          : "-",
      permission:
        candidatePermission !== undefined && candidatePermission !== null
          ? candidatePermission.Level
          : "-",
      facility:
        candidateFacility !== undefined && candidateFacility !== null
          ? candidateFacility.FullName
          : "-",
      active: user.Active ? "Active" : "Inactive",
    };
  });

  return (
    <>
      <div className={"row"}>
        <div className={"col d-flex"}>
          <Typography variant={"h4"}>Users</Typography>
        </div>
      </div>
      <div className={"row pt-2"}>
        <div className={"col"}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </div>
      </div>
    </>
  );
};

export default UsersTable;
