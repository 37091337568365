const ReportKeys = {
  AbbreviationUse: "AbbreviationUse",
  AbbreviationUseAllFacilities: "AbbreviationUseAllFacilities",
  Appendix: "Appendix",
  DischargeInstructionsMedicalAbbreviations:
    "DischargeInstructionAbbreviations",
  DischargeInstructionsMedicalAbbreviationsAllFacilities:
    "DischargeInstructionAbbreviationsAllFacilities",
  DischargeInstructionsMedicalTerminology: "DischargeInstructionTerm",
  DischargeInstructionsMedicalTerminologyAllFacilities:
    "DischargeInstructionTermAllFacilities",
  DischargeInstructionsTeachbacks: "DischargeInstructionTeachbacks",
  DischargeInstructionsTeachbacksAllFacilities:
    "DischargeInstructionTeachbacksAllFacilities",
  DischargeOrTransferDocumentsProvided: "DischargeOrTransferDocumentsProvided",
  DischargeOrTransferDocumentsProvidedAllFacilities:
    "DischargeOrTransferDocumentsProvidedAllFacilities",
  EthicsIssue: "EthicsIssue",
  EthicsIssueAllFacilities: "EthicsIssueAllFacilities",
  EvidenceOfMedicationReconciliation: "EvidenceOfMedicationReconciliation",
  EvidenceOfMedicationReconciliationeAllFacilities:
    "EvidenceOfMedicationReconciliationAllFacilities",
  LegibilityIssuesByProviderType: "LegibilityIssuesByProviderType",
  LegibilityIssuesByProviderTypeAllFacilities:
    "LegibilityIssuesByProviderTypeAllFacilities",
  LengthOfStay: "LengthOfStay",
  LengthOfStayAllFacilities: "LengthOfStayAllFacilities",
  MedicalManagement: "MedicalManagement",
  MedicalManagementAllFacilities: "MedicalManagementAllFacilities",
  OtherPatientSafetyConcern: "OtherPatientSafetyConcern",
  OtherPatientSafetyConcernAllFacilities:
    "OtherPatientSafetyConcernAllFacilities",
  OutcomesByProvider: "OutcomesByProvider",
  OutcomesByProviderAllFacilities: "OutcomesByProviderAllFacilities",
  PatientDisposition: "PatientDisposition",
  PatientDispositionAllFacilities: "PatientDispositionAllFacilities",
  PatientGender: "PatientGender",
  PatientGenderAllFacilities: "PatientGenderAllFacilities",
  PatientOutcome: "PatientOutcome",
  PatientOutcomeAllFacilities: "PatientOutcomeAllFacilities",
  PatientRace: "PatientRace",
  PatientRaceAllFacilities: "PatientRaceAllFacilities",
  PatientAge: "PatientAge",
  PatientAgeAllFacilities: "PatientAgeAllFacilities",
  PeerReviewOutcomes: "PeerReviewOutcome",
  PeerReviewOutcomesAllFacilities: "PeerReviewOutcomeAllFacilities",
  PossibleImprovements: "PossibleImprovements",
  ProgramOverview: "ProgramOverview",
  ProviderRecordsReviewedByCommittee: "ProviderRecordsReviewedByCommittee",
  ProviderRecordsReviewedByCommitteeAllFacilities:
    "ProviderRecordsReviewedByCommitteeAllFacilities",
  RCHITrendIssues: "RCHITrendIssues",
  RCHITrendIssuesAllFacilities: "RCHITrendIssuesAllFacilities",
  ReasonForReview: "ReviewReason",
  ReasonForReviewAllFacilities: "ReviewReasonAllFacilities",
  ReasonForReviewByProvider: "ReviewReasonByProvider",
  ReasonForReviewByProvidierAllFacilities:
    "ReviewReasonByProviderAllFacilities",
  RecordsReviewedByCommittee: "RecordsReviewedByCommittee",
  RecordsReviewedByCommitteeAllFacilities:
    "RecordsReviewedByCommitteeAllFacilities",
  SentinelEvent: "SentinelEvent",
  SentinelEvenAllFacilities: "SentinelEventAllFacilities",
  SystemIssues: "SystemIssues",
  SystemIssuesAllFacilities: "SystemIssuesAllFacilities",
  TrendingAndTracking: "TrendingAndTracking",
};

export default ReportKeys;
