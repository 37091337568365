import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { AbbreviationActions } from "src/redux/reducers/Constants/Abbreviation";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { emptyObject } from "src/utilities/objectUtilities";
import { canViewFinalReport } from "src/utilities/rbacFunctions";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { LastCreatedObjectActions } from "src/redux/reducers/LastCreatedObject";
import { LoadedActions } from "src/redux/reducers/Loaded";
import { NotificationActions } from "src/redux/reducers/Notification";
import { RedirectActions } from "src/redux/reducers/RNTools/Redirect";
import { RecordTrackingActions } from "src/redux/reducers/RecordTracking";
import { ReviewActions } from "src/redux/reducers/Review";
import isNullishString from "src/utilities/isNullishString";

const recordSlice = createSlice({
  name: "record",
  initialState: [],
  reducers: {
    batchAdd: (state, action) => {
      const newState = _.cloneDeep(state);
      const formattedRecords = action.payload;
      return _.orderBy([...newState, ...formattedRecords], ["ID"], "desc");
    },
    batchAddAbbreviations: (state, action) => {
      const newState = _.cloneDeep(state);
      const target = newState.find((r) => r.ID === action.payload[0].RecordID);
      const results = newState.filter(
        (r) => r.ID !== action.payload[0].RecordID
      );
      const newAbbreviations = [];
      target.RecordAbbreviation.forEach((abbreviation) => {
        let notUpdated = false;
        action.payload.forEach((payloadElement) => {
          if (payloadElement.ID !== abbreviation.ID) {
            notUpdated = true;
          }
        });
        if (notUpdated) {
          newAbbreviations.push(abbreviation);
        }
      });
      action.payload.forEach((payloadElement) => {
        newAbbreviations.push(payloadElement);
      });
      target.RecordAbbreviation = newAbbreviations;
      results.push(target);
      return _.orderBy(results, ["ID"], "desc");
    },
    batchRemoveAbbreviations: (state, action) => {
      const newState = _.cloneDeep(state);
      const target = newState.find((r) => r.ID === action.payload[0].RecordID);
      const results = newState.filter(
        (r) => r.ID !== action.payload[0].RecordID
      );
      target.RecordAbbreviation = [];
      results.push(target);
      return _.orderBy(results, ["ID"], "desc");
    },
    saveAbbreviations: (state, action) => {
      const { Abbreviations, RecordID } = action.payload;
      state[state.findIndex(({ ID }) => ID === RecordID)].RecordAbbreviation =
        Abbreviations;
    },
    saveSignature: (state, action) => {
      const { SignatureID, RecordID } = action.payload;
      state[state.findIndex(({ ID }) => ID === RecordID)].SignatureID =
        SignatureID;
    },
    saveMissedBlindings: (state, action) => {
      const { RecordID, MissedBlinding } = action.payload;
      const indexOfRecord = state.findIndex(({ ID }) => ID === RecordID);
      const modifiedRecord = {
        ...state[indexOfRecord],
        MissedBlinding,
      };
      return state
        .slice(0, indexOfRecord)
        .concat(modifiedRecord)
        .concat(state.slice(indexOfRecord + 1));
    },
    createChecklist: (state, action) => {
      const recordIndex = state.findIndex(
        ({ ID }) => ID === action.payload.RecordID
      );
      state[recordIndex].Checklist = action.payload.radioValues;
    },
    updateChecklist: (state, action) => {
      const recordIndex = state.findIndex(
        ({ ID }) => ID === Number(action.payload.RecordID)
      );
      state[recordIndex].Checklist = action.payload.radioValues;
    },
    createComments: (state, action) => {
      const recordIndex = state.findIndex(
        ({ ID }) => ID === action.payload.RecordID
      );
      state[recordIndex].RNComments = [action.payload];
    },
    updateComments: (state, action) => {
      const recordIndex = state.findIndex(
        ({ ID }) => ID === Number(action.payload.RecordID)
      );
      state[recordIndex].RNComments = [action.payload];
    },
    createOutcomes: (state, action) => {
      const recordIndex = state.findIndex(
        ({ ID }) => ID === action.payload.RecordID
      );
      state[recordIndex].RNOutcomes = {
        ...state[recordIndex].RNOutcomes,
        ID: action.payload.RNOutcomes.ID,
        Improvements: action.payload.RNOutcomes.Improvements,
        OtherImprovement: action.payload.RNOutcomes.OtherImprovement,
        OutcomeID: action.payload.RNOutcomes.OutcomeID,
      };
      state[recordIndex].MeetingID = action.payload.RNOutcomes.MeetingID;
    },
    updateOutcomes: (state, action) => {
      const recordIndex = state.findIndex(
        ({ ID }) => ID === Number(action.payload.RecordID)
      );
      state[recordIndex].RNOutcomes = {
        ...state[recordIndex].RNOutcomes,
        ID: action.payload.RNOutcomes.ID,
        Improvements: action.payload.RNOutcomes.Improvements,
        OtherImprovement: action.payload.RNOutcomes.OtherImprovement,
        OutcomeID: action.payload.RNOutcomes.OutcomeID,
      };
      state[recordIndex].MeetingID = action.payload.RNOutcomes.MeetingID;
    },
    saveWorksheet: (state, action) => {
      const targetRecord = state.find((r) => r.ID === action.payload.ID);
      const newState = state.filter((r) => r.ID !== action.payload.ID);
      const newRecord = { ...targetRecord, ...action.payload };
      newState.push(newRecord);
      return _.orderBy(newState, ["ID"], "desc");
    },
    searchData: (_state, action) => {
      const formattedRecords = action.payload;
      return _.orderBy(formattedRecords, ["ID"], "desc");
    },
    create: (state, action) => {
      state.unshift({
        ...action.payload,
      });
    },
    update: (state, action) => {
      const newState = state.filter((r) => r.ID !== action.payload.ID);
      newState.push(action.payload);
      return _.orderBy(newState, ["ID"], "desc");
    },
    updateStatus: (state, action) => {
      const targetRecord = state.find((r) => r.ID === action.payload.ID);
      const newState = state.filter((r) => r.ID !== action.payload.ID);
      targetRecord.RecordStatusID = action.payload.RecordStatusID;
      newState.push(targetRecord);
      return _.orderBy(newState, ["ID"], "desc");
    },
    loadData: (state, action) => {
      if (action.payload !== undefined) {
        let targetRecord = action.payload[0];
        const newState = state.filter((r) => r.ID !== action.payload[0].ID);
        targetRecord.Loaded = true;
        newState.push(targetRecord);
        return _.orderBy(newState, ["ID"], "desc");
      }
    },
    loadRejectionReason: (state, action) => {
      if (action.payload !== undefined) {
        let targetRecord = action.payload[0];
        const newState = state.filter((r) => r.ID !== action.payload[0].ID);
        targetRecord.Loaded = true;
        newState.push(targetRecord);
        return _.orderBy(newState, ["ID"], "desc");
      }
    },
    addInfoToBatch: (_state, action) => {
      return [
        ...action.payload
          .filter(
            (record, index) => record.ID !== action.payload[0].ID || index === 0
          )
          .map((recordFiltered, index) => {
            if (index === 0) {
              recordFiltered.Loaded = true;
            }
            return recordFiltered;
          }),
      ];
    },
    reject: (state, action) => {
      const newState = _.cloneDeep(state);
      const target = newState.find((r) => r.ID === action.payload);
      const results = newState.filter((r) => r.ID !== action.payload);
      target.ApprovalStatus = 0;
      results.push(target);
      return _.orderBy(results, ["ID"], "desc");
    },
    createApproved: (state, action) => {
      state.unshift({
        ...action.payload.Record,
        RecordTracking: action.payload.RecordTracking,
      });
    },
    approvePending: (state, action) => {
      const newState = _.cloneDeep(state);
      const results = newState.filter((r) => r.ID !== action.payload.Record.ID);
      const target = {
        ...action.payload.Record,
        RecordTracking: action.payload.RecordTracking,
      };
      results.push(target);
      return _.orderBy(results, ["ID"], "desc");
    },
    updateTracking: (state, action) => {
      const newState = _.cloneDeep(state);
      let target = newState.find(
        (r) => r.ID === action.payload.RecordTracking.RecordID
      );
      const results = newState.filter(
        (r) => r.ID !== action.payload.RecordTracking.RecordID
      );
      target.RecordStatusID = action.payload.Record.RecordStatusID;
      target.MRTSReviewTypeID = action.payload.Record.MRTSReviewTypeID;
      target.RecordTracking = action.payload.RecordTracking;
      results.push(target);
      return _.orderBy(results, ["ID"], "desc");
    },
    addToMeeting: (state, action) => {
      action.payload.RecordsUpdated.forEach((record) => {
        let index = state.findIndex((r) => r.ID === record.ID);
        state[index].MRTSReviewTypeID = action.payload.MRTSReviewTypeID;
        state[index].MeetingID = action.payload.MeetingID;
      });
    },
    removeFromMeeting: (state, action) => {
      let newState = _.cloneDeep(state);
      let affectedRecords = [];
      action.payload.RecordsUpdated.forEach((record) => {
        let current = newState.find((r) => r.ID === record.ID);
        current.MeetingID = null;
        affectedRecords.push(current);
        newState = newState.filter((r) => r.ID !== record.ID);
      });
      affectedRecords.forEach((record) => {
        newState.push(record);
      });
      return _.orderBy(newState, ["ID"], "desc");
    },
    removeMeetingID: (state, action) => {
      const newState = _.cloneDeep(state);
      newState[newState.findIndex((r) => r.ID === action.payload)].MeetingID =
        null;
      return newState;
    },
  },
});

const {
  batchAdd,
  batchAddAbbreviations,
  batchRemoveAbbreviations,
  saveAbbreviations,
  saveSignature,
  saveMissedBlindings,
  createChecklist,
  updateChecklist,
  createComments,
  updateComments,
  createOutcomes,
  updateOutcomes,
  saveWorksheet,
  searchData,
  create,
  update,
  updateStatus,
  loadData,
  addInfoToBatch,
  reject,
  createApproved,
  approvePending,
  addToMeeting,
  removeFromMeeting,
  loadRejectionReason,
  updateTracking,
} = recordSlice.actions;

const BatchAdd = (roles, location) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(LoadedActions.SetRecord("pending"));
    if (canViewFinalReport(roles)) {
      const { data: records } = await apiService.get(`${apiUrl}record/search`);
      let recordData = [];
      if (location !== undefined && location.pathname.includes("RNTools/")) {
        recordData = await apiService
          .get(`${apiUrl}record/getInfo`, {
            params: {
              ID: parseInt(location.pathname.split("/")[2]),
              SearchBy: "ID",
            },
          })
          .then((response) => response.data);
      }
      let rntools;
      rntools = await apiService
        .get(`${apiUrl}rntools`)
        .then((response) => response.data);
      if (recordData[0]) {
        dispatch(addInfoToBatch([...recordData, ...records]));
      } else {
        dispatch(batchAdd([...records]));
      }
      AbbreviationActions.BatchAdd({ ...rntools });
      dispatch(LoadedActions.SetRecord(true));
      dispatch(LoadedActions.SetRNTools(true));
    } else {
      const { data: records } = await apiService.get(`${apiUrl}record/search`);
      dispatch(batchAdd([...records]));
      dispatch(LoadedActions.SetRecord(true));
    }
  } catch (error) {
    dispatch(FetchingActions.Failure("Fetching Records"));
  }
};

const LoadRNToolsData = () => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(LoadedActions.SetRNTools("pending"));
    const rntools = await apiService
      .get(`${apiUrl}rntools`)
      .then((response) => response.data);
    AbbreviationActions.BatchAdd({ ...rntools });
    dispatch(LoadedActions.SetRNTools(true));
  } catch (error) {
    dispatch(FetchingActions.Failure("Fetching RN Tools"));
  }
};

const BatchAddFromContent = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(batchAdd(content));
    dispatch(LoadedActions.SetRecord(true));
  } catch (error) {
    dispatch(FetchingActions.Failure("Fetching Meetings"));
  }
};

const BatchAddRecordByMeetingId =
  (meetings = []) =>
  async (dispatch) => {
    try {
      dispatch(FetchingActions.Begin());
      const meetingIds = [];
      meetings.forEach((meeting) => meetingIds.push(meeting.ID));
      const { data: contents } = await apiService.get(
        `${apiUrl}record/search?meetingIds=${JSON.stringify(meetingIds)}`
      );
      dispatch(batchAdd(contents));
      dispatch(LoadedActions.SetRecord(true));
    } catch (error) {
      dispatch(FetchingActions.Failure("Fetching Records By MeetingId"));
    }
  };

const BatchAddAbbreviations = (content) => async (dispatch) => {
  dispatch(batchAddAbbreviations(content));
};

const BatchRemoveAbbreviations = (content) => async (dispatch) => {
  dispatch(batchRemoveAbbreviations(content));
};

const SaveAbbreviations = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data } = await apiService.post(
      `${apiUrl}rntools/saveRNAbbreviations`,
      content
    );
    dispatch(
      saveAbbreviations({ Abbreviations: data, RecordID: content.RecordID })
    );
    dispatch(FetchingActions.Success("Saving Abbreviations"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Saving Abbreviations"));
  }
};

const SaveSignature = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    await apiService.post(`${apiUrl}rntools/updateRNSignature`, content);
    dispatch(saveSignature(content));
    dispatch(FetchingActions.Success("Saving Signature"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Saving Signature"));
  }
};

const SaveMissedBlindings = (content) => async (dispatch) => {
  dispatch(saveMissedBlindings(content));
};

const CreateChecklist = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}checklist/create/`,
      content
    );
    dispatch(createChecklist(contents));
    dispatch(FetchingActions.Success("Creating Checklist"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Creating Checklist"));
  }
};

const UpdateChecklist = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}checklist/update/`,
      content
    );
    dispatch(updateChecklist(contents));
    dispatch(FetchingActions.Success("Updating Checklist"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Checklist"));
  }
};

const CreateComments = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}rnTools/CreateRNComments/`,
      {
        ...content,
        NurseLegibility: isNullishString(content.NurseLegibility)
          ? ""
          : content.NurseLegibility,
        MedicalAbbreviations: isNullishString(content.MedicalAbbreviations)
          ? ""
          : content.MedicalAbbreviations,
        MedicalTerminology: isNullishString(content.MedicalTerminology)
          ? ""
          : content.MedicalTerminology,
        TeachBacks: isNullishString(content.TeachBacks)
          ? ""
          : content.TeachBacks,
      }
    );
    dispatch(createComments(contents));
    if (!content.FacilityRecommendationsSwitch) {
      if (isNullishString(content.FacilityRecommendations)) {
        dispatch(
          FetchingActions.Warning("Facility Recommendations is missing")
        );
      } else if (
        isNullishString(content.NurseLegibility) &&
        !content.NurseLegibilityCheckbox
      ) {
        dispatch(FetchingActions.Warning("Nurse Legibility is missing"));
      } else if (
        isNullishString(content.MedicalAbbreviations) &&
        !content.MedicalAbbreviationsCheckbox
      ) {
        dispatch(FetchingActions.Warning("Medical Abbreviations is missing"));
      } else if (
        isNullishString(content.MedicalTerminology) &&
        !content.MedicalTerminologyCheckbox
      ) {
        dispatch(FetchingActions.Warning("Medical Terminology is missing"));
      } else if (
        isNullishString(content.TeachBacks) &&
        !content.TeachBacksCheckbox
      ) {
        dispatch(FetchingActions.Warning("Teach Backs is missing"));
      }
    } else {
      dispatch(FetchingActions.Success("Creating Comments"));
    }
  } catch (error) {
    dispatch(FetchingActions.Failure("Error Creating Comments"));
  }
};

const UpdateComments = (content) => async (dispatch) => {
  const defaultComments = emptyObject("RNComments");
  try {
    dispatch(FetchingActions.Begin());
    const { data } = await apiService.post(
      `${apiUrl}rnTools/UpdateRNComments/`,
      {
        ...defaultComments,
        ...content,
        NurseLegibility: isNullishString(content.NurseLegibility)
          ? ""
          : content.NurseLegibility,
        MedicalAbbreviations: isNullishString(content.MedicalAbbreviations)
          ? ""
          : content.MedicalAbbreviations,
        MedicalTerminology: isNullishString(content.MedicalTerminology)
          ? ""
          : content.MedicalTerminology,
        TeachBacks: isNullishString(content.TeachBacks)
          ? ""
          : content.TeachBacks,
      }
    );
    dispatch(updateComments(data));
    if (!data.FacilityRecommendationsSwitch) {
      if (isNullishString(data.FacilityRecommendations)) {
        dispatch(
          FetchingActions.Warning("Facility Recommendations is missing")
        );
      } else if (isNullishString(data.NurseLegibility)) {
        dispatch(FetchingActions.Warning("Nurse Legibility is missing"));
      } else if (isNullishString(data.MedicalAbbreviations)) {
        dispatch(FetchingActions.Warning("Medical Abbreviations is missing"));
      } else if (isNullishString(data.MedicalTerminology)) {
        dispatch(FetchingActions.Warning("Medical Terminology is missing"));
      } else if (isNullishString(data.TeachBacks)) {
        dispatch(FetchingActions.Warning("Teach Backs is missing"));
      }
    } else {
      dispatch(FetchingActions.Success("Updating Comments"));
    }
  } catch (error) {
    dispatch(FetchingActions.Failure("Error Updating Comments"));
  }
};

const CreateOutcomes = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}rnTools/CreateRNOutcomes/`,
      content
    );
    dispatch(createOutcomes(contents));
    dispatch(FetchingActions.Success("Creating Outcomes"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Creating Outcomes"));
  }
};

const UpdateOutcomes = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}rnTools/UpdateRNOutcomes/`,
      content
    );
    await dispatch(updateOutcomes(contents));
    dispatch(FetchingActions.Success("Updating Outcomes"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Outcomes"));
  }
};

const SaveWorksheet = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: payload } = await apiService.post(
      `${apiUrl}worksheet/${
        content.Worksheet.hasOwnProperty("ID") ? "update" : "create"
      }`,
      content
    );
    dispatch(saveWorksheet(payload));
    dispatch(FetchingActions.Success("Saving Worksheet"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Saving Worksheet"));
  }
};

const UpdateTracking = (content) => async (dispatch) => {
  dispatch(updateTracking(content));
};

const SearchData = (params) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(LoadedActions.SetRecord("pending"));
    const { data: records } = await apiService.get(`${apiUrl}record/search`, {
      params,
    });
    dispatch(searchData([...records]));
  } catch (error) {
    dispatch(FetchingActions.Failure("Searching Records"));
  }
};

const CheckOrCreateUnapproved =
  (content, setWarningVisible) => async (dispatch) => {
    try {
      dispatch(FetchingActions.Begin());
      const { data: duplicates } = await apiService.post(
        `${apiUrl}Record/checkForDuplicates`,
        content
      );
      if (duplicates.length > 0) {
        setWarningVisible(true);
        dispatch(FetchingActions.Failure("Possible Duplicate Record"));
      } else {
        dispatch(CreateUnapproved(content));
      }
    } catch (error) {
      dispatch(FetchingActions.Failure("Creating Unapproved Record"));
    }
  };

const CreateUnapproved = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: response } = await apiService.post(
      `${apiUrl}Record/createUnapproved`,
      content
    );
    dispatch(create(response));
    dispatch(LastCreatedObjectActions.Set("Record", response));
    dispatch(FetchingActions.Success("Creating Unapproved Record"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Creating Unapproved Record"));
  }
};

const UpdateApproved = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: payload } = await apiService.post(
      `${apiUrl}Record/updateApproved`,
      content
    );
    dispatch(update(payload));
    dispatch(FetchingActions.Success("Updating Approved Record"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Approved Record"));
  }
};

const UpdateUnapproved = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: payload } = await apiService.post(
      `${apiUrl}Record/updateUnapproved`,
      content
    );
    dispatch(update(payload));
    dispatch(FetchingActions.Success("Updating Unapproved Record"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Unapproved Record"));
  }
};

const CreateApproved = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Record/createApproved`,
      content
    );
    dispatch(createApproved(contents));
    dispatch(RecordTrackingActions.UpdateRecordTrackingState(contents));
    dispatch(LastCreatedObjectActions.Set("RecordAndApproval", contents));
    dispatch(FetchingActions.Success("Creating and Approving Record"));
    dispatch(
      NotificationActions.Open({
        Message: `Creating and Approving Record Success! - ARCHI RecordID: ${contents.Record.ARCHIRecordID}`,
        Severity: "success",
        Time: 300000,
      })
    );
  } catch (error) {
    dispatch(FetchingActions.Failure("Creating and Approving Record"));
  }
};

const ApprovePending = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Record/approvePending`,
      content
    );
    dispatch(approvePending(contents));
    dispatch(RecordTrackingActions.UpdateRecordTrackingState(contents));
    dispatch(LastCreatedObjectActions.Set("RecordAndApproval", contents));
    dispatch(FetchingActions.Success("Updating and Approving Record"));
    dispatch(
      NotificationActions.Open({
        Message: `Updating and Approving Record Success! - ARCHI RecordID: ${contents.Record.ARCHIRecordID}`,
        Severity: "success",
        Time: 300000,
      })
    );
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating and Approving Record"));
  }
};

const UpdateRNTools =
  ({ dataExist, Checklist, RNComments, RNOutcomes }) =>
  async (dispatch) => {
    const saveChecklistApi = async (content) => {
      const { data: contents } = await apiService.post(
        `${apiUrl}checklist/${dataExist.checklist ? "update" : "create"}/`,
        content
      );
      return contents;
    };
    const saveRNCommentsApi = async (content) => {
      const { data } = await apiService.post(
        `${apiUrl}rnTools/${
          dataExist.rnComments ? "Update" : "Create"
        }RNComments/`,
        content
      );
      return data;
    };
    const saveRNOutcomesApi = async (content) => {
      const { data: contents } = await apiService.post(
        `${apiUrl}rnTools/${
          dataExist.rnOutcomes ? "Update" : "Create"
        }RNOutcomes/`,
        content
      );
      return contents;
    };
    try {
      dispatch(FetchingActions.Begin());
      const [respChecklist, respRNComments, respRNOutcomes] = await Promise.all(
        [
          saveChecklistApi(Checklist),
          saveRNCommentsApi(RNComments),
          saveRNOutcomesApi(RNOutcomes),
        ]
      );
      dispatch(updateChecklist(respChecklist));
      dispatch(updateComments(respRNComments));
      dispatch(updateOutcomes(respRNOutcomes));
    } catch (e) {
      dispatch(FetchingActions.Failure("Updating Record RNTools"));
    }
  };

const UpdateStatus = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}record/updateStatus`,
      {
        ID: content.ID,
        RecordStatusID: content.RecordStatusID,
      }
    );
    const payload = contents[0] === 1 ? { ...content } : {};
    dispatch(updateStatus(payload));
    dispatch(FetchingActions.Success("Updating Record Status"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Record Status"));
  }
};

const LoadData =
  (ID, SearchBy = "ID", RNLink = false) =>
  async (dispatch) => {
    try {
      dispatch(FetchingActions.Begin());
      dispatch(LoadedActions.SetRecord("pending"));
      const { data: record } = await apiService.get(`${apiUrl}record/getInfo`, {
        params: {
          ID: ID,
          SearchBy: SearchBy,
        },
      });
      dispatch(loadData([...record]));
      if (RNLink) {
        dispatch(RedirectActions.To(`RNTools/${record[0].ID}`));
      }
      dispatch(LoadedActions.SetRecord(true));
      dispatch(FetchingActions.Reset());
    } catch (error) {
      dispatch(FetchingActions.Failure("Fetching Record"));
    }
  };

const LoadRejectionReason = (ID, SearchBy, RNLink) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: record } = await apiService.get(
      `${apiUrl}record/getRejectionReason`,
      {
        params: {
          ID: ID,
          SearchBy: SearchBy,
        },
      }
    );
    dispatch(loadRejectionReason([...record]));
    if (RNLink) {
      dispatch(RedirectActions.To(`RNTools/${record[0].ID}`));
    }
  } catch (error) {
    dispatch(FetchingActions.Failure("Fetching Reject Reasons"));
  }
};

const Reject = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}record/reject`,
      content
    );
    if (contents[0] === 1) {
      dispatch(reject(content.RecordID));
    }
    dispatch(FetchingActions.Success("Rejecting Record"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Rejecting Record"));
  }
};

const AddToMeeting = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}record/assignMeeting`,
      content
    );
    dispatch(addToMeeting(contents));
    dispatch(FetchingActions.Success("Adding Record to Meeting"));
    const review = contents.RecordsAssigned;
    if (review.length) {
      dispatch(ReviewActions.Add(review[0].Review));
    }
  } catch (error) {
    dispatch(FetchingActions.Failure("Adding Record to Meeting"));
  }
};

const RemoveFromMeeting = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}record/removeMeeting`,
      content
    );
    dispatch(removeFromMeeting(contents));
    dispatch(FetchingActions.Success("Removing Record from Meeting"));
    dispatch(ReviewActions.BatchReset());
  } catch (error) {
    dispatch(FetchingActions.Failure("Removing Record from Meeting"));
  }
};

const Reload = (recordID) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: record } = await apiService.get(`${apiUrl}record/getInfo`, {
      params: {
        ID: recordID,
        SearchBy: "ID",
      },
    });
    dispatch(update(record[0]));
    dispatch(FetchingActions.Success("Removing Record from Meeting"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Removing Record from Meeting"));
  }
};

export const RecordActions = {
  BatchAdd,
  BatchAddFromContent,
  BatchAddAbbreviations,
  BatchRemoveAbbreviations,
  BatchAddRecordByMeetingId,
  SaveMissedBlindings,
  SaveAbbreviations,
  CreateChecklist,
  UpdateChecklist,
  CreateComments,
  UpdateComments,
  CreateOutcomes,
  UpdateOutcomes,
  SaveWorksheet,
  SearchData,
  CheckOrCreateUnapproved,
  CreateUnapproved,
  UpdateApproved,
  UpdateUnapproved,
  UpdateRNTools,
  UpdateStatus,
  LoadData,
  LoadRNToolsData,
  Reject,
  ApprovePending,
  CreateApproved,
  AddToMeeting,
  RemoveFromMeeting,
  Reload,
  LoadRejectionReason,
  UpdateTracking,
  SaveSignature,
};

export default recordSlice.reducer;
