import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilitySentinelEventChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Sentinel Event"}
        xAxisLabel={"Sentinel Event"}
        yAxisLabel={"Number of Records"}
        figureText={"Sentinel Event for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilitySentinelEventChart;
