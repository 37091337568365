import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { FetchingActions } from "./Fetching";

const initialState = {
  Loaded: false,
  Active: [],
};

const reportTemplatesSlice = createSlice({
  name: "reportTemplates",
  initialState,
  reducers: {
    load: (state, action) => {
      state.Loaded = true;
      const newActive = _.orderBy(action.payload, ["Name"], "asc");
      state.Active = newActive;
    },
    reset: () => {
      return initialState;
    },
  },
});

const { load, reset } = reportTemplatesSlice.actions;

const Load = () => async (dispatch) => {
  try {
    const response = await apiService.get(`${apiUrl}templates/`);
    dispatch(
      load(
        response.data.map((template) => {
          return {
            ID: template.ID,
            Name: template.Name,
            DateRange: JSON.parse(template.DateRange),
            ChartType: template.ChartType,
            DisplayOptions: JSON.parse(template.DisplayOptions),
            ProgramOverviewSelected: template.ProgramOverviewSelected,
            ChecklistReports: JSON.parse(template.ChecklistReports),
            OutcomeReports: JSON.parse(template.OutcomeReports),
            PatientTypeReports: JSON.parse(template.PatientTypeReports),
            RecommendationReports: JSON.parse(template.RecommendationReports),
          };
        })
      )
    );
  } catch (error) {
    dispatch(reset());
    dispatch(FetchingActions.Failure("Fetching Templates"));
  }
};

const Add = (values) => async (dispatch) => {
  try {
    await apiService.post(`${apiUrl}templates/`, {
      Name: values.templateName,
      DateRange: JSON.stringify(values.dateRange),
      ChartType: values.chartType,
      DisplayOptions: JSON.stringify(values.displayOptions),
      ProgramOverviewSelected:
        values.programOverviewSelected[0] === "ProgramOverview",
      ChecklistReports: JSON.stringify(values.checklistReports),
      OutcomeReports: JSON.stringify(values.outcomeReports),
      PatientTypeReports: JSON.stringify(values.patientTypeReports),
      RecommendationReports: JSON.stringify(values.recommendationReports),
    });
    dispatch(reset());
    dispatch(Load());
    dispatch(FetchingActions.Success("Saving Template"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Saving Template"));
  }
};

const Remove = (id) => async (dispatch) => {
  try {
    await apiService.delete(`${apiUrl}templates/`, {
      data: {
        ID: id,
      },
    });
    dispatch(reset());
    dispatch(Load());
    dispatch(FetchingActions.Success("Removing Template"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Removing Template"));
  }
};

export const ReportTemplatesActions = {
  Load,
  Add,
  Remove,
};

export default reportTemplatesSlice.reducer;
