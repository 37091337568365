import "@architx/fontawesome-pro/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "src/index.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import DateFnsUtils from "@date-io/date-fns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import App from "src/components/App";
import storeFactory from "src/redux";
import Constants from "src/utilities/constants";
import { CssBaseline } from "@mui/material";
import * as Sentry from "@sentry/react";

const serverHost = process.env.REACT_APP_API_HOST || "";
const serverPort = process.env.REACT_APP_API_PORT || "";
const publicApiUrl = process.env.REACT_APP_API_URL || "";
const sentryDSNKey = process.env.REACT_APP_UI_DSN_KEY || "";
const environment = process.env.NODE_ENV || "development";

if (sentryDSNKey !== "") {
  Sentry.init({ dsn: sentryDSNKey, environment, release: Constants.VERSION });
}

export const apiUrl = publicApiUrl
  ? publicApiUrl
  : `http://${serverHost}:${serverPort}/`;

export const store = storeFactory();

const theme = createTheme({
  palette: {
    primary: {
      light: "#2bbbad",
      main: "#00796b",
      dark: "#006156",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ff0023",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: "rgba(92,114,106,0.65)",
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "#00796b",
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: "#00a844",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: "rgba(2,166,64,0.78)",
        },
      },
    },
  },
});

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;

const Wrapper = () => (
  <Sentry.ErrorBoundary fallback={myFallback}>
    <Router>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={responsiveFontSizes(theme)}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <App />
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </Router>
  </Sentry.ErrorBoundary>
);

ReactDOM.render(<Wrapper />, document.getElementById("root"));
