import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const blindingOptionsSlice = createSlice({
  name: "blindingOptions",
  initialState,
  reducers: {
    batchAdd: (_state, action) => {
      return _.orderBy([...action.payload.requiredBlinding], ["Name"], "asc");
    },
  },
});

const { batchAdd } = blindingOptionsSlice.actions;

const BatchAdd = (content) => async (dispatch) => {
  dispatch(batchAdd(content));
};

export const BlindingOptionsActions = {
  BatchAdd,
};

export default blindingOptionsSlice.reducer;
