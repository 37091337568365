import React from "react";
import { MenuItem } from "@mui/material";
import { Select } from "mui-rff";
import { canAccessAdministration } from "src/utilities/rbacFunctions";

const FacilitySection = (props) => {
  return (
    <>
      {canAccessAdministration(props.roles) && (
        <div className={"row pt-2 pr-2"}>
          <div className={"col"}>
            <Select
              name={"archiFacilityId"}
              label={"Facility"}
              labelId="reports-facility-select-label"
              id="reports-facility-select"
              variant={"outlined"}
              required
              disabled={props.disabled}
              displayEmpty
            >
              <MenuItem value={null}>Select a Facility</MenuItem>
              {props.Facilities.map((facility) => (
                <MenuItem
                  key={`reports-facility-select-${facility.FullName}-${facility.ARCHIFacilityID}`}
                  value={facility.ARCHIFacilityID}
                >
                  {facility.FullName}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      )}
    </>
  );
};

export default FacilitySection;
