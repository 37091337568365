import React from "react";
import {
  renderBox,
  renderTwoBoxColumn,
} from "../PDFUtilities/RenderPDFFunctions";

const RecordIndex = (props) => {
  const Record = props.recordData;
  const recordIndex =
    props.settings[2] && Record.Worksheet
      ? {
          PageRangePartA: Record.Worksheet.PageRangePartA,
          PageRangePartB: Record.Worksheet.PageRangePartB,
          EmergencyRoomRecord: Record.Worksheet.EmergencyRoomRecord,
          HistoryAndPhysical: Record.Worksheet.HistoryAndPhysical,
          PhysicianOrders: Record.Worksheet.PhysicianOrders,
          ProgressNotes: Record.Worksheet.ProgressNotes,
          DischargeSummary: Record.Worksheet.DischargeSummary,
          OperativeReport: Record.Worksheet.OperativeReport,
          Anesthesia: Record.Worksheet.Anesthesia,
          LaboratoryStudies: Record.Worksheet.LaboratoryStudies,
          Imaging: Record.Worksheet.Imaging,
          EKG: Record.Worksheet.EKG,
          Strips: Record.Worksheet.Strips,
          DCInstructions: Record.Worksheet.DCInstructions,
          NursingNoteSummary: Record.Worksheet.NursingNoteSummary,
          MAR: Record.Worksheet.MAR,
          TotalPages: Record.Worksheet.TotalPages,
        }
      : {
          PageRangePartA: "",
          PageRangePartB: "",
          EmergencyRoomRecord: "",
          HistoryAndPhysical: "",
          PhysicianOrders: "",
          ProgressNotes: "",
          DischargeSummary: "",
          OperativeReport: "",
          Anesthesia: "",
          LaboratoryStudies: "",
          Imaging: "",
          EKG: "",
          Strips: "",
          DCInstructions: "",
          NursingNoteSummary: "",
          MAR: "",
          TotalPages: "",
        };
  return (
    <>
      {renderBox("Record Index", "boxSubTitle")}
      {renderTwoBoxColumn(
        "Page Range for Part A",
        recordIndex.PageRangePartA,
        "Laboratory Studies",
        recordIndex.LaboratoryStudies
      )}
      {renderTwoBoxColumn(
        "Page Range for Part B",
        recordIndex.PageRangePartB,
        "Imaging",
        recordIndex.Imaging
      )}
      {renderTwoBoxColumn(
        "Emergency Room Record",
        recordIndex.EmergencyRoomRecord,
        "EKG",
        recordIndex.EKG
      )}
      {renderTwoBoxColumn(
        "History and Physical",
        recordIndex.HistoryAndPhysical,
        "Strips",
        recordIndex.Strips
      )}
      {renderTwoBoxColumn(
        "Operative Report",
        recordIndex.OperativeReport,
        "D/C Instructions",
        recordIndex.DCInstructions
      )}
      {renderTwoBoxColumn(
        "Anesthesia",
        recordIndex.Anesthesia,
        "Nursing Note Summary (IO, VS)",
        recordIndex.NursingNoteSummary
      )}
      {renderTwoBoxColumn(
        "Progress Notes",
        recordIndex.ProgressNotes,
        "MAR",
        recordIndex.MAR
      )}
      {renderTwoBoxColumn(
        "Discharge Summary",
        recordIndex.DischargeSummary,
        "Physician Orders",
        recordIndex.PhysicianOrders
      )}
      {renderTwoBoxColumn(null, null, "Total Pages", recordIndex.TotalPages)}
    </>
  );
};

export default RecordIndex;
