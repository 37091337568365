import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityPatientAgeChart from "./AllFacilityPatientAgeChart";
import FacilitySpecificPatientAgeChart from "./FacilitySpecificPatientAgeChart";
import TextBegin from "./TextBegin";

const PatientAge = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, [], true);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, [], true);

  return (
    <>
      <TextBegin />
      {chartDataAllFacilities !== false && (
        <AllFacilityPatientAgeChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Patient Age"}
          rows={chartDataAllFacilities.rows}
          tableRows={chartDataAllFacilities.tableRows}
        />
      )}
      <FacilitySpecificPatientAgeChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Patient Age"}
        rows={chartData.rows}
        tableRows={chartData.tableRows}
      />
    </>
  );
};

export default PatientAge;
