import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form } from "react-final-form";
import * as yup from "yup";
import validateFinalForm from "src/utilities/validateFinalForm";
import { TextField, Checkboxes } from "mui-rff";
import { ContactActions } from "src/redux/reducers/PersonnelManagement/Contact";

const initialValues = {
  firstName: null,
  lastName: null,
  email: null,
  areaCode: null,
  phoneNumber: null,
  extension: null,
  active: true,
};

const validationSchema = yup.object({
  firstName: yup.string().required().nullable(),
  lastName: yup.string().required().nullable(),
  email: yup.string().required().nullable(),
  areaCode: yup.number().required().nullable(),
  phoneNumber: yup.string().required().nullable(),
  extension: yup.string().nullable(),
  active: yup.boolean().required(),
});

const mutators = {
  clearAll: (args, state, utils) => {
    utils.changeValue(state, "firstName", () => null);
    utils.changeValue(state, "lastName", () => null);
    utils.changeValue(state, "email", () => null);
    utils.changeValue(state, "areaCode", () => null);
    utils.changeValue(state, "phoneNumber", () => null);
    utils.changeValue(state, "extension", () => null);
    utils.changeValue(state, "active", () => true);
  },
};

const CreateAndLinkContactDialog = (props) => {
  const createContact = (values) => {
    props.dispatch(
      ContactActions.Create({
        FirstName: values.firstName,
        LastName: values.lastName,
        EmailAddress: values.email,
        AreaCode: values.areaCode,
        PhoneNumber: values.phoneNumber,
        Extension: values.extension,
        FacilityID: props.facilityID,
        Active: values.active,
      })
    );
  };

  return (
    <Form
      onSubmit={createContact}
      initialValues={initialValues}
      validate={validateFinalForm(validationSchema)}
      mutators={mutators}
      render={({ form, submitting, pristine, values }) => (
        <>
          <form>
            <Dialog
              fullWidth
              maxWidth={"sm"}
              open={props.dialogVisible}
              onClose={() => {
                props.setDialogVisible();
                form.mutators.clearAll();
              }}
            >
              <DialogTitle>Create Contact</DialogTitle>
              <DialogContent>
                <div className={"row"}>
                  <div className={"col"}>
                    <TextField
                      label="First Name"
                      name="firstName"
                      variant={"outlined"}
                      required
                      disabled={submitting}
                    />
                  </div>
                  <div className={"col"}>
                    <TextField
                      label="Last Name"
                      name="lastName"
                      variant={"outlined"}
                      required
                      disabled={submitting}
                    />
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col"}>
                    <TextField
                      label="E-Mail"
                      name="email"
                      variant={"outlined"}
                      required
                      disabled={submitting}
                      type={"email"}
                    />
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col"}>
                    <TextField
                      label="Area Code"
                      name="areaCode"
                      variant={"outlined"}
                      required
                      disabled={submitting}
                    />
                  </div>
                  <div className={"col"}>
                    <TextField
                      label="Phone Number"
                      name="phoneNumber"
                      variant={"outlined"}
                      required
                      disabled={submitting}
                    />
                  </div>
                  <div className={"col"}>
                    <TextField
                      label="Extension"
                      name="extension"
                      variant={"outlined"}
                      disabled={submitting}
                    />
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col"}>
                    <Checkboxes
                      size={"small"}
                      name={"active"}
                      data={{ label: "Active", value: true }}
                      color={"primary"}
                      disabled={submitting}
                    />
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color={"secondary"}
                  variant={"contained"}
                  onClick={() => {
                    props.setDialogVisible();
                    form.mutators.clearAll();
                  }}
                  disabled={submitting}
                >
                  Close
                </Button>
                <Button
                  color={"primary"}
                  variant={"contained"}
                  onClick={() => {
                    createContact(values);
                    props.setDialogVisible();
                    form.mutators.clearAll();
                  }}
                  disabled={
                    values.firstName === null ||
                    values.lastName === null ||
                    values.email === null ||
                    values.areaCode === null ||
                    values.phoneNumber === null ||
                    submitting ||
                    pristine
                  }
                >
                  Create
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </>
      )}
    />
  );
};

export default CreateAndLinkContactDialog;
