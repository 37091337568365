import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilitySystemIssuesChart from "./AllFacilitySystemIssuesChart";
import FacilitySpecificSystemIssuesChart from "./FacilitySpecificSystemIssuesChart";
import TextBegin from "./TextBegin";

const SystemIssues = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, ["systemIssues"]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, [
      "systemIssues",
    ]);

  return (
    <>
      <TextBegin {...props} />
      {chartDataAllFacilities !== false && (
        <AllFacilitySystemIssuesChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"System Issues"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificSystemIssuesChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"System Issues"}
        rows={chartData.rows}
      />
    </>
  );
};

export default SystemIssues;
