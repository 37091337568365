import React from "react";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { selectContentByID } from "src/redux/selectors";
import { format, isValid } from "date-fns";

const CRPreview = (props) => {
  const Committee = useSelector(
    selectContentByID("Committee", props.values.committeeID)
  );

  return (
    <div className={"row pt-2"}>
      <div className={"col"}>
        <TextField
          label={"Meeting Name"}
          variant={"outlined"}
          fullWidth
          value={`${Committee !== undefined ? Committee.Abbreviation : ""}-${
            props.values.subcommittee !== null ? props.values.subcommittee : ""
          } ${
            isValid(props.values.meetingDate)
              ? format(props.values.meetingDate, "MM.dd.yyyy")
              : ""
          }`}
          disabled
        />
      </div>
    </div>
  );
};

export default CRPreview;
