import React from "react";
import { Button, Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Custom from "src/components/individual/Custom";

const Buttons = ({ handleVisible, handleSubmit }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Box className={"row"}>
        <Box className={"col"}>
          <Button
            style={{ width: "80%" }}
            onClick={handleVisible}
            className={classes.infoButton}
            variant={"contained"}
          >
            <i className={"fas fa-info-circle pr-1"} />
            Help
          </Button>
        </Box>
        <Box className={"col"}>
          <Custom.SubmitButton
            style={{ width: "80%" }}
            color="success"
            onClick={handleSubmit}
            className={classes.successButton}
            variant={"contained"}
          >
            <i className={"fas fa-edit pr-1"} />
            Save Outcomes
          </Custom.SubmitButton>
        </Box>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  infoButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.main,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
      boxShadow: theme.shadows[5],
    },
  },
  successButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      boxShadow: theme.shadows[5],
    },
  },
}));

export default Buttons;
