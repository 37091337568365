import React from "react";
import { Checkboxes } from "mui-rff";

const Checkbox = ({ name, label }) => {
  const checkboxName = `${name}Checkbox`;

  return (
    <Checkboxes name={checkboxName} data={{ label: label, value: false }} />
  );
};

export default Checkbox;
