import React from "react";
// data
import { useSelector } from "react-redux";
import { selectContent } from "src/redux/selectors";
import DateFnsUtils from "@date-io/date-fns";
// ui
import { DateTimePicker, TextField, Select } from "mui-rff";
import { Field, FormSpy } from "react-final-form";
import { MenuItem, Typography } from "@mui/material";

const ReviewInformation = (props) => {
  const ReviewCategories = useSelector(selectContent("ReviewCategory"));
  const ReviewTypes = useSelector(selectContent("ReviewType"));

  return (
    <>
      <div className={"row pt-2"} id={"review-information-title"}>
        <div className={"col"}>
          <Typography variant="h4" className={props.classes.title}>
            Review Information
          </Typography>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <Field name={"arrivalDate"}>
            {({ input, meta }) => (
              <>
                <DateTimePicker
                  dateFunsUtils={DateFnsUtils}
                  name={"arrivalDate"}
                  label={"Arrival Date and Time (MM/DD/YYYY HH:MM)"}
                  variant={"inline"}
                  inputVariant={"outlined"}
                  ampm={false}
                  disableFuture={true}
                />
                {!meta.valid && meta.touched && meta.error && (
                  <span style={{ color: "red" }}>
                    Please correct the above error.{" "}
                  </span>
                )}
              </>
            )}
          </Field>
        </div>
        <div className={"col"}>
          <Field name={"dischargeDate"}>
            {({ input, meta }) => (
              <>
                <DateTimePicker
                  dateFunsUtils={DateFnsUtils}
                  name={"dischargeDate"}
                  label={"Discharge Date and Time (MM/DD/YYYY)"}
                  variant={"inline"}
                  inputVariant={"outlined"}
                  ampm={false}
                  disableFuture={true}
                  required={true}
                />
                {!meta.valid && meta.touched && meta.error && (
                  <span style={{ color: "red" }}>
                    Please correct the above error.{" "}
                  </span>
                )}
              </>
            )}
          </Field>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <Select
            variant={"outlined"}
            label={"Review Category"}
            displayEmpty
            name="reviewCategoryID"
            required
          >
            <MenuItem disabled>Select a Review Category</MenuItem>
            {ReviewCategories.filter((category) => category.Active).map(
              (reviewCategory) => (
                <MenuItem
                  value={reviewCategory.ID}
                  key={`create-mrts-record-review-category-select-${reviewCategory.Name}-${reviewCategory.ID}`}
                >
                  {reviewCategory.Name}
                </MenuItem>
              )
            )}
          </Select>
        </div>
        <div className={"col"}>
          <FormSpy subscription={{ form: true }}>
            {({ form }) => (
              <Select
                variant={"outlined"}
                label={"Review Type"}
                displayEmpty
                name="reviewTypeID"
                required
              >
                <MenuItem disabled>Select a Review Type</MenuItem>
                {ReviewTypes.filter((type) => type.Active).map((reviewType) => (
                  <MenuItem
                    value={reviewType.ID}
                    key={`create-mrts-record-review-type-select-${reviewType.Name}-${reviewType.ID}`}
                    onClick={() => {
                      form.mutators.clearSelectedCommittee();
                      form.mutators.clearCommitteeOther();
                    }}
                  >
                    {reviewType.Name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormSpy>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <strong>
            <i className="fas fa-exclamation-triangle pr-2" />
            The Facility Medical Record number should include both the number
            assigned to the patient and the episode, visit, account or control
            number.
          </strong>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <TextField
            label={
              "Facility Medical Record Number - Patient Control/Episode/Account Number , ie 12344-0001"
            }
            name={"facilityMedicalRecordNumber"}
            variant={"outlined"}
            required
          />
        </div>
      </div>
    </>
  );
};

export default ReviewInformation;
