import React from "react";
import hydrateStructure from "src/components/templateStructures/checklistStructure/hydrateStructure";
import createStructure from "src/components/templateStructures/checklistStructure";
import { RenderChecklist } from "./RenderChecklistComponents";

const Checklist = ({ checklistData, committeeID, recordVersion }) => {
  const checklistStructure = hydrateStructure(
    createStructure(committeeID, recordVersion),
    checklistData
  );
  return <RenderChecklist items={checklistStructure} />;
};

export default Checklist;
