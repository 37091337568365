import React from "react";
import AdminHeader from "./AdminHeader";
import FacilitatorHeader from "./FacilitatorHeader";
import { facilitator as facilitatorRole } from "src/utilities/rbacFunctions";

const Header = ({ roles }) => {
  if (facilitatorRole(roles)) {
    return (
      <>
        <FacilitatorHeader />
      </>
    );
  } else {
    return (
      <>
        <AdminHeader />
      </>
    );
  }
};

export default Header;
