import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificReasonForReviewByProviderChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Reason for Review by Provider"}
        xAxisLabel={"Reason"}
        yAxisLabel={"Number of Records"}
        figureText={
          "Facility-Specific: Records reviewed by Reason for Review and Provider"
        }
      />
    </>
  );
};

export default FacilitySpecificReasonForReviewByProviderChart;
