import React from "react";

const AbbreviationUseTable = (props) => {
  const groupBy = props.values.displayOptions.groupBy;
  const table = props.tableRows;
  var totals = table.pop();
  const tableDataRows = createTableRows(table, totals);

  let tableHeaders;

  if (["monthly", "quarterly"].includes(groupBy)) {
    tableHeaders = createGroupedTableHeaders(table, groupBy);
  } else {
    tableHeaders = createTableHeaders(table);
  }

  return (
    <table
      className={"table table-bordered table-striped table-hover table-sm"}
    >
      {tableHeaders}
      <tbody>{tableDataRows !== undefined && tableDataRows}</tbody>
    </table>
  );
};

const getPercentage = (value, total) => {
  if (value === 0) {
    return value;
  }

  return Math.round((value / total) * 100);
};

function createTableRows(table, totals) {
  const tempRows = [];
  table.forEach((slice, i) => {
    let tempRow = [];
    for (const [key, value] of Object.entries(slice)) {
      if (key !== "Abbreviation Used") {
        const columnTotal = totals[key];
        const columnPercentage = getPercentage(value, columnTotal);
        tempRow.push(
          <td>
            {value}/{columnTotal}
            <br />({columnPercentage}%)
          </td>
        );
      } else {
        tempRow.push(<td>{value}</td>);
      }
    }
    tempRows.push(
      <tr key={`report-table-row-${slice.sortdate}-${i}`}>{tempRow}</tr>
    );
  });

  return tempRows;
}

function createTableHeaders(table) {
  const headers = [];
  const firstRow = table[0];
  for (const [key] of Object.entries(firstRow)) {
    headers.push(
      <th scope={"col"}>
        <b>{key}</b>
      </th>
    );
  }

  return (
    <thead>
      <tr>{headers}</tr>
    </thead>
  );
}

function createGroupedTableHeaders(
  table,
  groupBy,
  reportkey = "Abbreviation Used"
) {
  const headers = [];
  const secondHeaders = [];
  const firstRow = table[0];
  const { [reportkey]: _abbreviationUsed, Total, ...reportData } = firstRow;
  headers.push(
    <th scope={"col"} rowSpan={"2"}>
      <b>{reportkey}</b>
    </th>
  );
  const splitBy = groupBy === "monthly" ? "." : " - ";
  let columnCount = 0;
  let previousYear;
  for (const [key] of Object.entries(reportData)) {
    const [monthOrQuarter, year] = key.split(splitBy);
    secondHeaders.push(
      <th scope={"col"}>
        <b>{monthOrQuarter}</b>
      </th>
    );

    if (previousYear !== year) {
      if (previousYear) {
        headers.push(
          <th scope={"col"} colSpan={columnCount}>
            <b>{previousYear}</b>
          </th>
        );
      }
      previousYear = year;
      columnCount = 1;
    } else {
      columnCount++;
    }
  }

  headers.push(
    <th scope={"col"} colSpan={columnCount}>
      <b>{previousYear}</b>
    </th>
  );

  headers.push(
    <th scope={"col"} rowSpan={"2"}>
      <b>Total</b>
    </th>
  );

  return (
    <thead>
      <tr>{headers}</tr>
      <tr>{secondHeaders}</tr>
    </thead>
  );
}

export default AbbreviationUseTable;
