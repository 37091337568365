import React from "react";
import { Field } from "react-final-form";
import { TextField } from "mui-rff";
import { makeStyles } from "@mui/styles";

const TimesUsedTextField = ({ ID, row, switchID }) => {
  const classes = useStyles();
  return (
    <Field
      name={`Abbreviation_${switchID}.Switch`}
      subscription={{ value: true }}
    >
      {({ input: switchInput }) => {
        return (
          <TextField
            name={`Abbreviation_${ID}.${row}`}
            fullWidth={true}
            required={true}
            id={`outlined-number${ID}`}
            variant="outlined"
            subscription={{ value: true }}
            disabled={switchInput.value}
            type={"number"}
            onFocus={(event) => {
              event.target.select();
            }}
            className={classes.textField}
            inputProps={{
              className: classes.input,
            }}
          />
        );
      }}
    </Field>
  );
};

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  input: {
    textAlign: "center",
    padding: 0,
  },
}));

export default TimesUsedTextField;
