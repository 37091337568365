import React from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import { RenderSubTitleCard } from "../../PDFUtilities/RenderPDFFunctions";
import { styles } from "../../PDFUtilities/PDFStyles";
import PropTypes from "prop-types";
import isNullishString from "src/utilities/isNullishString";

const RenderEachItem = ({ items, itemsVisible }) => {
  let isItemsVisible = true;
  if (itemsVisible !== undefined) {
    isItemsVisible = itemsVisible;
  }
  return (
    <View>
      {items &&
        isItemsVisible &&
        items.map((item) => {
          const RenderComponent = RenderChecklistComponents[item.render.type];
          return item.render.hide || item.render.hidePDF ? (
            <View />
          ) : (
            <RenderComponent
              label={item.label}
              items={item.items}
              itemsVisible={item.itemsVisible}
              value={item.value}
              text={item.text}
              required={item.render.required}
            />
          );
        })}
    </View>
  );
};

const Title = ({ label, items }) => {
  return (
    <RenderSubTitleCard title={label} wrap={false}>
      <RenderEachItem items={items} />
    </RenderSubTitleCard>
  );
};

const SubTitle = ({ label, items }) => {
  return (
    <View>
      <View style={{ flexDirection: "row" }}>
        <Text style={styles.text}>{label}</Text>
      </View>
      <View style={{ paddingLeft: 20 }}>
        <RenderEachItem items={items} />
      </View>
    </View>
  );
};

const Dropdown = ({ label, value, items, itemsVisible }) => {
  return (
    <View>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: "3 1 auto" }}>
          <Text style={styles.text}>{label}</Text>
        </View>
        <View style={{ flex: "1 0 auto" }}>
          <Text
            style={[
              styles.text,
              {
                textAlign: "right",
                marginRight: 40,
                color: value === "Pending" ? "red" : "black",
              },
            ]}
          >
            {value}
          </Text>
        </View>
      </View>
      <View style={{ paddingLeft: 20 }}>
        <RenderEachItem items={items} itemsVisible={itemsVisible} />
      </View>
    </View>
  );
};

export const Checkbox = ({ label, value, items, itemsVisible }) => {
  Checkbox.propTypes = {
    label: PropTypes.string,
    items: PropTypes.array,
    value: PropTypes.string,
    itemsVisible: PropTypes.bool,
  };
  return (
    <View>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flexGrow: 1, width: "5%", textAlign: "center" }}>
          <Image
            style={{ width: "12pt", maxHeight: "12pt" }}
            src={`/images/icons/${
              value === "true" ? "check" : "check-box-empty"
            }.png`}
          />
        </View>
        <View style={{ flexGrow: 1, width: "100%" }}>
          <Text style={[styles.text, { textAlign: "left" }]}>{label}</Text>
        </View>
      </View>
      <View style={{ paddingLeft: 20 }}>
        <RenderEachItem items={items} itemsVisible={itemsVisible} />
      </View>
    </View>
  );
};

const TextBox = ({ label, value, required = false }) => {
  return (
    <View>
      {(required || value !== "") && (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flex: "1 0 auto" }}>
            <Text style={styles.text}>
              {label}
              {isNullishString(label) ? "Additional Details:" : ":"}
            </Text>
          </View>
          <View
            style={{
              flex: label === "Other" ? "14 0 auto" : "4 0 auto",
              marginRight: 40,
            }}
          >
            <Text
              style={[
                styles.text,
                {
                  textAlign: required && value === "" ? "right" : "left",
                  color: required && value === "" ? "red" : "black",
                },
              ]}
            >
              {value}
            </Text>
          </View>
        </View>
      )}
    </View>
  );
};

const Radios = ({ value }) => {
  return (
    <View style={{ flex: "3 0 auto", marginRight: 40, marginBottom: 20 }}>
      <Text
        style={[
          styles.text,
          {
            textAlign: value === "" ? "right" : "left",
            color: value === "" ? "red" : "black",
          },
        ]}
      >
        {value === "" ? "Pending" : value}
      </Text>
    </View>
  );
};

const InlineRadios = ({ label, value, items, itemsVisible }) => {
  return (
    <View>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: "3 1 auto" }}>
          <Text style={styles.text}>{label}</Text>
        </View>
        <View style={{ flex: "1 0 auto" }}>
          <Text
            style={[
              styles.text,
              {
                textAlign: "right",
                marginRight: 40,
                color: value === "Pending" ? "red" : "black",
              },
            ]}
          >
            {value}
          </Text>
        </View>
      </View>
      <View style={{ paddingLeft: 20 }}>
        <RenderEachItem items={items} itemsVisible={itemsVisible} />
      </View>
    </View>
  );
};

const RenderChecklistComponents = {
  Title,
  SubTitle,
  Dropdown,
  Checkbox,
  TextBox,
  Radios,
  InlineRadios,
};

export const RenderChecklist = ({ items }) => {
  return <RenderEachItem items={items} />;
};
