import React from "react";

const TextBegin = () => {
  return (
    <div className={"section-report-title"}>
      <h4>Medical Management</h4>
    </div>
  );
};

export default TextBegin;
