import React from "react";
import { MenuItem } from "@mui/material";
import { Select } from "mui-rff";

const quarterOptions = [
  { name: "Q1", value: 0 },
  { name: "Q2", value: 1 },
  { name: "Q3", value: 2 },
  { name: "Q4", value: 3 },
];

const QuarterlyRange = (props) => {
  return (
    <>
      <div className={"row pt-2 pr-2"}>
        <div className={"col"}>
          <Select
            name={"dateRange.range.startingQuarter"}
            label={"Starting Quarter"}
            labelId="reports-starting-quarter-select-label"
            id="reports-starting-quarter-select"
            variant={"outlined"}
            required={props.values.dateRange.type === "quarterly"}
            disabled={props.disabled}
            displayEmpty
          >
            <MenuItem value={null}>Select a Starting Quarter</MenuItem>
            {quarterOptions.map((option) => (
              <MenuItem
                key={`reports-starting-quarter-${option.name}-${option.value}`}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={"col"}>
          <Select
            name={"dateRange.range.startingYear"}
            label={"Starting Year"}
            labelId="reports-starting-year-select-label"
            id="reports-starting-year-select"
            variant={"outlined"}
            required={props.values.dateRange.type === "quarterly"}
            disabled={props.disabled}
            displayEmpty
          >
            <MenuItem value={null}>Select a Starting Year</MenuItem>
            {props.yearOptions.map((option) => (
              <MenuItem
                key={`reports-starting-year-${option.name}-${option.value}`}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={"row pt-2 pr-2"}>
        <div className={"col"}>
          <Select
            name={"dateRange.range.endingQuarter"}
            label={"Ending Quarter"}
            labelId="reports-ending-quarter-select-label"
            id="reports-ending-quarter-select"
            variant={"outlined"}
            required={props.values.dateRange.type === "quarterly"}
            disabled={props.disabled}
            displayEmpty
          >
            <MenuItem value={null}>Select an Ending Quarter</MenuItem>
            {quarterOptions.map((option) => (
              <MenuItem
                key={`reports-ending-quarter-${option.name}-${option.value}`}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={"col"}>
          <Select
            name={"dateRange.range.endingYear"}
            label={"Ending Year"}
            labelId="reports-ending-year-select-label"
            id="reports-ending-year-select"
            variant={"outlined"}
            required={props.values.dateRange.type === "quarterly"}
            disabled={props.disabled}
            displayEmpty
          >
            <MenuItem value={null}>Select an Ending Year</MenuItem>
            {props.yearOptions.map((option) => (
              <MenuItem
                key={`reports-ending-year-${option.name}-${option.value}`}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </>
  );
};

export default QuarterlyRange;
