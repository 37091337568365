import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityPatientAgeChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Age of Patient (Years)"}
        xAxisLabel={"Patient Age (Years)"}
        yAxisLabel={"Number of Records"}
        figureText={"Records by Patient Age (Years) for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilityPatientAgeChart;
