import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityProviderRecordsReviewedChart from "./AllFacilityProviderRecordsReviewedChart";
import FacilitySpecificProviderRecordsReviewedChart from "./FacilitySpecificProviderRecordsReviewedChart";
import TextBegin from "./TextBegin";
import MidlevelTable from "../OutcomesByProvider/MidlevelTable";

const ProviderRecordsReviewedByCommittee = (props) => {
  const customProps = JSON.parse(JSON.stringify(props));
  const midlevels = props.Report.Data.ProviderRecordsReviewedByCommittee[1];
  customProps.Report.Data.ProviderRecordsReviewedByCommittee =
    props.Report.Data.ProviderRecordsReviewedByCommittee[0];
  console.log({ props });
  const chartData = ChartDataBuilder(customProps, props.reportKey, [
    "mrtsReviewType",
    "committee",
  ]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(customProps, `${props.reportKey}AllFacilities`, [
      "mrtsReviewType",
      "committee",
    ]);

  return (
    <>
      <TextBegin {...props} />
      {chartDataAllFacilities !== false && (
        <AllFacilityProviderRecordsReviewedChart
          {...customProps}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Committee"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificProviderRecordsReviewedChart
        {...customProps}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Committee"}
        rows={chartData.rows}
      />
      {midlevels.map((elem) => {
        const key = Object.keys(elem)[0];
        return (
          <MidlevelTable
            title={`Supervising Provider: ${key}`}
            midlevels={elem[key]}
            columnName={"Committee"}
          />
        );
      })}
    </>
  );
};

export default ProviderRecordsReviewedByCommittee;
