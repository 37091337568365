import React from "react";
import FacilitySection from "./FacilitySection";
import TemplateSection from "./TemplateSection";
import ReportOptionsSection from "./ReportOptionsSection";
import DisplayOptionsSection from "./DisplayOptionsSection";
import ReportSelectionSection from "./ReportSelectionSection";

const Sidebar = (props) => {
  return (
    <>
      <FacilitySection {...props} />
      <TemplateSection {...props} />
      <ReportOptionsSection {...props} />
      <DisplayOptionsSection {...props} />
      <ReportSelectionSection {...props} />
    </>
  );
};

export default Sidebar;
