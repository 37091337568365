import React from "react";
import { Select } from "mui-rff";
import RenderEachItem from "../RenderEachItem";

const Dropdown = ({
  name,
  label,
  items,
  itemsDependOn,
  render: { options = [] },
}) => {
  return (
    <>
      <div className={"row pb-2"}>
        <div className={"col-9 d-flex text-left my-auto"}>{label}</div>
        <div className={"col d-flex justify-content-end"}>
          <Select
            name={name}
            data={options.map((option) => ({
              label: option === "Pending" ? "Choose Your Option" : option,
              value: option,
            }))}
            variant={"outlined"}
            style={styles.textLeft}
          />
        </div>
      </div>
      <RenderEachItem items={items} itemsDependOn={itemsDependOn} indent />
    </>
  );
};

const styles = {
  textLeft: {
    textAlign: "left",
  },
};

export default Dropdown;
