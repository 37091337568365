import React, { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Form } from "react-final-form";
import { Checkboxes } from "mui-rff";
import validateFinalForm from "src/utilities/validateFinalForm";
import validationSchema from "./validationSchema";
import MidlevelSelect from "./MidlevelSelect";
import Buttons from "../../Provider/MergeDialog/Buttons";
import { globalVariables } from "src/components/App";
import { MidlevelActions } from "src/redux/reducers/PersonnelManagement/Midlevel";

const MergeDialog = ({ open, setOpen }) => {
  const { authenticatedDispatch } = useContext(globalVariables);

  const handleMerge = (values) => {
    authenticatedDispatch(MidlevelActions.Merge(values));
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ style: { overflow: "visible" } }}
    >
      <Form
        onSubmit={handleMerge}
        subscription={{}}
        validate={validate}
        initialValues={{ source: null, destination: null, deleteSource: false }}
      >
        {({ handleSubmit }) => (
          <>
            <DialogTitle>Merge Midlevel</DialogTitle>
            <DialogContent>
              <Grid container flexDirection={"column"} sx={{ marginTop: 2 }}>
                <Grid item container flexDirection={"row"} xs={12}>
                  <Grid item xs={6}>
                    <MidlevelSelect name={"source"} label={"Source"} />
                  </Grid>
                  <Grid item xs={6}>
                    <MidlevelSelect
                      name={"destination"}
                      label={"Destination"}
                    />
                  </Grid>
                </Grid>
                <Grid item sx={{ paddingInline: 1 }}>
                  <Checkboxes
                    size={"small"}
                    name={"deleteSource"}
                    data={{ label: "Delete Source After Merge", value: true }}
                    color={"primary"}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Buttons onClose={() => setOpen(false)} onMerge={handleSubmit} />
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

const validate = validateFinalForm(validationSchema);

export default MergeDialog;
