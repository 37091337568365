import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { formatPDFDate } from "src/components/individual/GeneratePDF/PDFUtilities/FormatPDFDataFunctions";
import useStyles from "../styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import { recordReviewData } from "src/components/pages/MeetingManagement/RecordReview";
import { selectMeetingByID } from "src/redux/selectors";

const AdminHeader = () => {
  const apiResponse = useContext(recordReviewData);
  const ReviewStatus = _.get(apiResponse, "ReviewStatus", "");
  const MeetingID = _.get(apiResponse, `MeetingID`, null);
  const RecordData = _.get(apiResponse, `OriginalData`, {});
  const createdAt = _.get(apiResponse, `createdAt`, "");
  const archiProviderID = _.get(
    apiResponse,
    `OriginalData.Provider.ARCHIProviderID`,
    ""
  );
  const archiFacilityID = _.get(
    apiResponse,
    `OriginalData.Facility.ARCHIFacilityID`,
    ""
  );
  const archiRecordID = _.get(apiResponse, `OriginalData.ARCHIRecordID`, "");
  const selectedMeeting = useSelector(selectMeetingByID(MeetingID));
  const MeetingName = selectedMeeting ? selectedMeeting.MeetingName : "";
  const classes = useStyles();
  return (
    <Container>
      <p className={classes.header}>FINAL REPORT: RECORD REVIEW</p>
      <Grid
        container
        className={classes.recordDetailsList}
        justifyContent={"space-evenly"}
        justifyItems={"start"}
        direction={"row"}
      >
        <ListData title="ARCHI Record #:" data={archiRecordID} />
        <ListData
          title="Date of Facility Request:"
          data={createdAt ? formatPDFDate(createdAt) : "Error"}
        />
        <ListData
          title="Facility Medical Record #:"
          data={RecordData.FacilityRecordID}
        />
        <ListData title="Committee/Date of Review:" data={MeetingName} />
        <ListData title="ARCHI Facility ID #:" data={archiFacilityID} />
        <ListData
          title="Date Report Sent to Facility:"
          data={"IMPLEMENTATION PENDING"}
        />
        <ListData title="ARCHI Physician ID #:" data={archiProviderID} />
        <ListData title="Review Status:" data={ReviewStatus} />
      </Grid>
    </Container>
  );
};

const ListData = ({ title, data }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} lg={6} textAlign={"start"}>
      <span className={classes.listKey}>{title}</span>
      <span className={classes.listValue}>{data}</span>
    </Grid>
  );
};

export default AdminHeader;
