import React, { useContext, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { ReviewActions } from "src/redux/reducers/Review";
import { UserActions } from "src/redux/reducers/PersonnelManagement/User";
import RecordReviewTable from "./RecordReviewTable";
import { useParams } from "react-router-dom";
import { globalVariables } from "src/components/App";

const ReviewsDashboard = (props) => {
  const { meetingID } = useParams();
  const Loaded = useSelector((state) => state.Loaded);
  const { Meeting, Record, Review, User } = props;
  const [meeting, setMeeting] = React.useState({});
  const [records, setRecords] = React.useState([]);
  const [reviews, setReviews] = React.useState([]);
  const [facilitator, setFacilitator] = React.useState({
    LastName: "No facilitator assigned",
    FirstName: "",
  });
  const { roles } = useContext(globalVariables);
  const render =
    Meeting !== undefined &&
    Meeting.length > 0 &&
    Record !== undefined &&
    Record.length > 0 &&
    User !== undefined &&
    User.length > 0;

  useEffectOnce(() => {
    props.dispatch(ReviewActions.BatchAddMeetingDashboardData(roles));
    Loaded.User === false && props.dispatch(UserActions.BatchAdd());
  });

  useEffect(() => {
    if (render) {
      const candidateMeeting = Meeting.find(
        (meeting) => Number(meeting.ID) === Number(meetingID)
      );
      setRecords(Record);
      setReviews(
        Review.filter((review) => review.MeetingID === Number(meetingID))
      );
      setMeeting(candidateMeeting);
      const user = User.find(
        (user) => user.ID === candidateMeeting.FacilitatorID
      );
      if (user !== null && user !== undefined) {
        setFacilitator(user);
      }
    }
  }, [meetingID, Meeting, Record, Review, User, render]);

  return (
    <>
      {render && (
        <RecordReviewTable
          meeting={meeting}
          facilitator={facilitator}
          records={records}
          reviews={reviews}
          users={User}
          roles={props.roles}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Meeting: state.Meeting,
    Record: state.Record,
    Review: state.Review,
    User: state.User,
  };
};

export default connect(mapStateToProps)(ReviewsDashboard);
