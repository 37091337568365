import React from "react";
import { CardTemplate } from "src/components/individual/Templates";
import RenderEachItem from "../RenderEachItem";

const Title = ({ label, items }) => {
  return (
    <CardTemplate title={label}>
      <RenderEachItem items={items} />
    </CardTemplate>
  );
};

export default Title;
