const humanFactorsTraining = (isLegacyVersion) => ({
  label: "Human Factors - Training",
  render: { type: "Title" },
  items: [physicianProvider(isLegacyVersion), newLine(), facilitySystem()],
});

const physicianProvider = (isLegacyVersion) => ({
  label: "Physician/Provider",
  render: { type: "SubTitle" },
  items: [
    phyOppImprovement(isLegacyVersion),
    performanceDeficit(),
    prematureClosureAnchoring(),
    authorityGradientIssues(),
    phyOppoNeedEnhancedDocumentation(isLegacyVersion),
  ],
});

const newLine = () => ({
  label: "",
  render: { type: "SubTitle" },
});

const facilitySystem = () => ({
  label: "Facility/System",
  render: { type: "SubTitle" },
  items: [facOpportunityImprovement()],
});

const facOpportunityImprovement = () => ({
  name: "facOpportunityImprovement",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
  itemsDependOn: [{ name: "facOpportunityImprovement", is: "Yes" }],
  items: [
    relatedTraining(),
    relatedCommunication(),
    facOppNeedEnhancedDocumentation(),
  ],
});

const relatedTraining = () => ({
  name: "relatedTraining",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "No Track"],
  },
});
const relatedCommunication = () => ({
  name: "relatedCommunication",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "No Track"],
  },
});
const facOppNeedEnhancedDocumentation = () => ({
  name: "facOppNeedEnhancedDocumentation",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "No Track"],
  },
});

const phyOppImprovement = (isLegacyVersion) => ({
  name: "phyOppImprovement",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
    hide: isLegacyVersion,
  },
  version: 2,
});

const performanceDeficit = () => ({
  name: "performanceDeficit",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
});

const prematureClosureAnchoring = () => ({
  name: "prematureClosureAnchoring",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
});

const authorityGradientIssues = () => ({
  name: "authorityGradientIssues",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
});

const phyOppoNeedEnhancedDocumentation = (isLegacyVersion) => ({
  name: "phyOppoNeedEnhancedDocumentation",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "No Track"],
    hide: isLegacyVersion,
  },
  version: 2,
});

export default humanFactorsTraining;
