import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Field, Form } from "react-final-form";
import * as yup from "yup";
import validateFinalForm from "src/utilities/validateFinalForm";
import { Select, TextField, Checkboxes } from "mui-rff";
import { MenuItem } from "@mui/material";
import { MidlevelActions } from "src/redux/reducers/PersonnelManagement/Midlevel";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src/";
import _ from "lodash";
import { SimilarMidlevels } from "src/components/pages/PersonnelManagement/Midlevel/CreateMidlevelDialog";

const initialValues = {
  firstName: null,
  lastName: null,
  email: null,
  degree: null,
  active: true,
};

const validationSchema = yup.object({
  firstName: yup.string().required().nullable(),
  lastName: yup.string().required().nullable(),
  email: yup.string().nullable(),
  degree: yup.string().required().nullable(),
  active: yup.boolean().required(),
});

const mutators = {
  clearAll: (args, state, utils) => {
    utils.changeValue(state, "firstName", () => null);
    utils.changeValue(state, "lastName", () => null);
    utils.changeValue(state, "email", () => null);
    utils.changeValue(state, "degree", () => null);
    utils.changeValue(state, "active", () => true);
  },
};

const degrees = [
  { name: "PA", value: "PA" },
  { name: "NP", value: "NP" },
  { name: "CNM", value: "CNM" },
];

const CreateAndLinkMidlevelDialog = (props) => {
  const [similarMidlevels, setSimilarMidlevels] = React.useState([]);

  const createMidlevel = async (values) => {
    const { data } = await apiService.post(`${apiUrl}midlevel/verify`, values);

    if (data.length > 0 && !_.isEqual(data, similarMidlevels))
      return setSimilarMidlevels(data);

    props.dispatch(
      MidlevelActions.CreateAndLinkToFacility({
        FirstName: values.firstName,
        LastName: values.lastName,
        EmailAddress: values.email,
        Degree: values.degree,
        Active: values.active,
        FacilityID: props.facilityID,
      })
    );
    props.setDialogVisible();
  };

  return (
    <Form
      onSubmit={createMidlevel}
      initialValues={initialValues}
      validate={validateFinalForm(validationSchema)}
      mutators={mutators}
      render={({ form, submitting, pristine, handleSubmit }) => (
        <>
          <form>
            <Dialog
              fullWidth
              maxWidth={"sm"}
              open={props.dialogVisible}
              onClose={() => {
                props.setDialogVisible();
                form.mutators.clearAll();
              }}
            >
              <DialogTitle>Create Midlevel</DialogTitle>
              <DialogContent>
                <div className={"row"}>
                  <div className={"col"}>
                    <TextField
                      label="First Name"
                      name="firstName"
                      variant={"outlined"}
                      required
                      disabled={submitting}
                    />
                  </div>
                  <div className={"col"}>
                    <TextField
                      label="Last Name"
                      name="lastName"
                      variant={"outlined"}
                      required
                      disabled={submitting}
                    />
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col"}>
                    <TextField
                      label="E-Mail"
                      name="email"
                      variant={"outlined"}
                      disabled={submitting}
                      type={"email"}
                    />
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col"}>
                    <Select
                      name={"degree"}
                      label={"Degree"}
                      labelId="create-midlevel-degree-select-label"
                      id="create-midlevel-degree-select"
                      variant={"outlined"}
                      disabled={submitting}
                      displayEmpty
                      required
                    >
                      <MenuItem value={null}>Select a Degree</MenuItem>
                      {degrees.map((option) => (
                        <MenuItem
                          key={`create-midlevel-degree-select-option-${option.name}-${option.value}`}
                          value={option.value}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col"}>
                    <Checkboxes
                      size={"small"}
                      name={"active"}
                      data={{ label: "Active", value: true }}
                      color={"primary"}
                      disabled={submitting}
                    />
                  </div>
                </div>
                <SimilarMidlevels
                  similarMidlevels={similarMidlevels}
                  FacilityID={props.facilityID}
                  closeDialog={props.setDialogVisible}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color={"secondary"}
                  variant={"contained"}
                  onClick={() => {
                    props.setDialogVisible();
                    form.mutators.clearAll();
                  }}
                  disabled={submitting}
                >
                  Close
                </Button>
                <Button
                  color={"primary"}
                  variant={"contained"}
                  onClick={handleSubmit}
                  disabled={submitting || pristine}
                >
                  Create
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </>
      )}
    />
  );
};

export default CreateAndLinkMidlevelDialog;
