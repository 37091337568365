import React, { useContext, useMemo } from "react";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Form } from "react-final-form";
import { Select } from "mui-rff";
import { useSelector } from "react-redux";
import {
  selectMeetings,
  selectActiveUserByPermissionID,
} from "src/redux/selectors";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { globalVariables } from "src/components/App";
import { ReviewActions } from "src/redux/reducers/Review";
import { RecordActions } from "src/redux/reducers/Record";
import useAuthHook from "src/utilities/useAuthHook";
import ViewDirectReviewComments from "src/components/individual/RecordReview/ViewDirectReviewComments";
import Custom from "src/components/individual/Custom";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    "overflow-y": "visible",
  },
}));

const ReviewDialog = ({ dialog, handleClose }) => {
  const classes = useStyles();
  const { authenticatedDispatch } = useContext(globalVariables);
  const facilitatorList = useSelector(selectActiveUserByPermissionID(4));
  const meetingList = useSelector(selectMeetings);
  const facilitatorOptions = useMemo(
    () =>
      facilitatorList
        .filter(
          (facilitatorData) => facilitatorData.ID !== dialog.facilitatorID
        )
        .map((facilitatorData) => ({
          label: `${facilitatorData.FirstName} ${facilitatorData.LastName}`,
          value: facilitatorData.ID,
        })),
    [facilitatorList]
  );
  const meetingOptions = useMemo(
    () =>
      meetingList
        .filter((meetingData) => meetingData.ID !== dialog.facilitatorID)
        .map((meetingData) => ({
          label: meetingData.MeetingName,
          value: meetingData.ID,
        })),
    [meetingList]
  );
  const { authenticate } = useAuthHook();
  const gridItems = () => {
    if (dialog.requestType === "Committee Review Requested") {
      return (
        <>
          <ViewDirectReviewComments
            peerReviewNotes={dialog.peerReviewNotes}
            directReviewComments={dialog.directReviewComments}
          />
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() =>
              approve(
                dialog.recordID,
                dialog.directReviewComments,
                authenticatedDispatch,
                handleClose,
                authenticate
              )
            }
          >
            Approve
          </Button>
        </>
      );
    } else if (dialog.requestType === "Side Review Requested") {
      return (
        <>
          <ViewDirectReviewComments
            peerReviewNotes={dialog.peerReviewNotes}
            directReviewComments={dialog.directReviewComments}
          />
          <Form
            initialValues={{ facilitatorID: null }}
            onSubmit={({ facilitatorID }) =>
              reassignSidebarFacilitator(
                dialog.recordID,
                facilitatorID,
                authenticatedDispatch,
                handleClose,
                authenticate
              )
            }
            render={({ handleSubmit, pristine }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} direction={"column"}>
                  <Grid item>
                    <Select
                      name={"facilitatorID"}
                      data={facilitatorOptions}
                      label={"Facilitator ID"}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      type={"submit"}
                      disabled={pristine}
                    >
                      Assign Facilitator
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </>
      );
    } else if (dialog.requestType === "Re-Assign Facilitator") {
      return (
        <>
          <Form
            initialValues={{ facilitatorID: null }}
            onSubmit={({ facilitatorID }) =>
              reassignFacilitator(
                dialog.recordID,
                facilitatorID,
                authenticatedDispatch,
                handleClose,
                authenticate
              )
            }
            render={({ handleSubmit, pristine }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} direction={"column"}>
                  <Grid item>
                    <Select
                      name={"facilitatorID"}
                      data={facilitatorOptions}
                      label={"Facilitator ID"}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      type={"submit"}
                      disabled={pristine}
                    >
                      Assign Facilitator
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </>
      );
    } else if (dialog.requestType === "Re-Assign Meeting") {
      return (
        <Form
          initialValues={{ meetingID: null }}
          onSubmit={({ meetingID }) =>
            reassignMeeting(
              dialog.recordID,
              meetingID,
              authenticatedDispatch,
              handleClose,
              authenticate
            )
          }
          render={({ handleSubmit, pristine }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} direction={"column"}>
                <Grid item>
                  <Custom.Autocomplete
                    label={"Meeting ID"}
                    name={"meetingID"}
                    options={meetingOptions}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    type={"submit"}
                    disabled={pristine}
                  >
                    Assign Meeting
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      );
    } else if (dialog.requestType === "Reset Review Status") {
      return (
        <>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Reset Review Status back to "Pending Facilitator Review"
            </DialogContentText>
          </DialogContent>
          <Alert severity="warning">
            <b>NOTE</b>: will also update "Assigned Date" to today's date!
          </Alert>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="error">
              Cancel
            </Button>
            <Button
              onClick={() =>
                resetReviewStatus(
                  dialog.recordID,
                  authenticatedDispatch,
                  handleClose,
                  authenticate
                )
              }
              autoFocus
              variant="contained"
              color="primary"
            >
              Continue
            </Button>
          </DialogActions>
        </>
      );
    } else if (dialog.requestType === "Remove from Meeting") {
      return (
        <>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Unassign Record and Remove Review
            </DialogContentText>
          </DialogContent>
          <Alert severity="error">
            <b>NOTE</b>: will also remove any record reviews!
          </Alert>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="error">
              Cancel
            </Button>
            <Button
              onClick={() =>
                removeMeeting(
                  dialog.recordID,
                  authenticatedDispatch,
                  handleClose,
                  authenticate
                )
              }
              autoFocus
              variant="contained"
              color="primary"
            >
              Continue
            </Button>
          </DialogActions>
        </>
      );
    } else {
      return <></>;
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      open={dialog.visible}
      fullWidth={true}
      scroll="paper"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>{dialog.requestType}</DialogTitle>
      <DialogContent dividers>
        <Grid container direction={"column"} spacing={2}>
          <Grid item>{gridItems()}</Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const approve = async (
  recordID,
  directReviewComments,
  authenticatedDispatch,
  handleClose,
  authenticate
) => {
  try {
    await authenticate();
    const resp = await apiService.post(
      `${apiUrl}recordReview/committeeReview/approve`,
      {
        RecordID: recordID,
        DirectReviewComments: directReviewComments,
      }
    );
    if (resp.status === 200) {
      authenticatedDispatch(FetchingActions.Success("Action"));
      authenticatedDispatch(RecordActions.Reload(recordID));
      authenticatedDispatch(ReviewActions.BatchReset());
    } else {
      authenticatedDispatch(FetchingActions.Failure("Error"));
    }
  } catch (error) {
    authenticatedDispatch(FetchingActions.Failure("Error"));
  } finally {
    handleClose();
  }
};

const reassignFacilitator = async (
  recordID,
  facilitatorID,
  authenticatedDispatch,
  handleClose,
  authenticate
) => {
  try {
    await authenticate();
    const resp = await apiService.post(
      `${apiUrl}recordReview/reassignFacilitator`,
      {
        RecordID: recordID,
        FacilitatorID: facilitatorID,
      }
    );
    if (resp.status === 200) {
      authenticatedDispatch(FetchingActions.Success("Action"));
      authenticatedDispatch(ReviewActions.BatchReset());
    } else {
      authenticatedDispatch(FetchingActions.Failure("Error"));
    }
  } catch (error) {
    authenticatedDispatch(FetchingActions.Failure("Error"));
  } finally {
    handleClose();
  }
};

const reassignSidebarFacilitator = async (
  recordID,
  facilitatorID,
  authenticatedDispatch,
  handleClose,
  authenticate
) => {
  try {
    await authenticate();
    const resp = await apiService.post(
      `${apiUrl}recordReview/sidebar/reassignFacilitator`,
      {
        RecordID: recordID,
        FacilitatorID: facilitatorID,
      }
    );
    if (resp.status === 200) {
      authenticatedDispatch(FetchingActions.Success("Action"));
      authenticatedDispatch(ReviewActions.BatchReset());
    } else {
      authenticatedDispatch(FetchingActions.Failure("Error"));
    }
  } catch (error) {
    authenticatedDispatch(FetchingActions.Failure("Error"));
  } finally {
    handleClose();
  }
};

const reassignMeeting = async (
  recordID,
  meetingID,
  authenticatedDispatch,
  handleClose,
  authenticate
) => {
  try {
    await authenticate();
    const resp = await apiService.post(
      `${apiUrl}recordReview/reassignMeeting`,
      {
        RecordID: recordID,
        MeetingID: meetingID,
      }
    );
    if (resp.status === 200) {
      authenticatedDispatch(FetchingActions.Success("Action"));
      authenticatedDispatch(ReviewActions.BatchReset());
    } else {
      authenticatedDispatch(FetchingActions.Failure("Error"));
    }
  } catch (error) {
    authenticatedDispatch(FetchingActions.Failure("Error"));
  } finally {
    handleClose();
  }
};

const removeMeeting = async (
  recordID,
  authenticatedDispatch,
  handleClose,
  authenticate
) => {
  try {
    await authenticate();
    const resp = await apiService.post(`${apiUrl}record/removeMeeting`, {
      RecordID: recordID,
    });
    if (resp.status === 200) {
      authenticatedDispatch(FetchingActions.Success("Action"));
      authenticatedDispatch(ReviewActions.BatchReset());
    } else {
      authenticatedDispatch(FetchingActions.Failure("Error"));
    }
  } catch (error) {
    authenticatedDispatch(FetchingActions.Failure("Error"));
  } finally {
    handleClose();
  }
};

const resetReviewStatus = async (
  recordID,
  authenticatedDispatch,
  handleClose,
  authenticate
) => {
  try {
    await authenticate();
    const resp = await apiService.post(
      `${apiUrl}recordReview/resetReviewStatus`,
      {
        RecordID: recordID,
      }
    );
    if (resp.status === 200) {
      authenticatedDispatch(FetchingActions.Success("Action"));
      authenticatedDispatch(ReviewActions.BatchReset());
    } else {
      authenticatedDispatch(FetchingActions.Failure("Error"));
    }
  } catch (error) {
    authenticatedDispatch(FetchingActions.Failure("Error"));
  } finally {
    handleClose();
  }
};

export default ReviewDialog;
