import React from "react";
import UpdateUserAdminDialog from "./UpdateUserAdminDialog";
import UpdateUserFacilityDialog from "./UpdateUserFacilityDialog";
import { adminUser } from "src/utilities/rbacFunctions";

const UpdateUserDialog = (props) => {
  if (adminUser(props.roles)) {
    return <UpdateUserAdminDialog {...props} />;
  } else {
    return <UpdateUserFacilityDialog {...props} />;
  }
};

export default UpdateUserDialog;
