import { Button } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { ContactActions } from "src/redux/reducers/PersonnelManagement/Contact";
import { selectContactsByFacilityID } from "src/redux/selectors/personnelSelectors";
import CreateAndLinkContactDialog from "./CreateAndLinkContactDialog";
import Typography from "@mui/material/Typography";

const columns = [
  {
    name: "archiContactID",
    label: "ARCHI Contact ID",
  },
  {
    name: "firstName",
    label: "First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
  },
  {
    name: "emailAddress",
    label: "E-Mail Address",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
  },
  {
    name: "active",
    label: "Active",
  },
];

const options = {
  filterType: "multiselect",
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
};

const ContactsTable = (props) => {
  const [createContactDialogVisible, setCreateContactDialogVisible] =
    React.useState(false);
  const Contacts = useSelector(selectContactsByFacilityID(props.facilityID));
  const Loaded = useSelector((state) => state.Loaded);

  useEffectOnce(() => {
    Loaded.Contact === false && props.dispatch(ContactActions.BatchAdd());
  });

  const data = Contacts.map((contact, i) => {
    return {
      archiContactID: contact.ARCHIContactID,
      firstName: contact.FirstName,
      lastName: contact.LastName,
      emailAddress: contact.EmailAddress,
      phoneNumber:
        contact.PhoneNumber !== undefined && contact.PhoneNumber !== null
          ? `${
              contact.AreaCode !== undefined &&
              contact.AreaCode !== null &&
              `(${contact.AreaCode})`
            } ${contact.PhoneNumber} ${
              contact.Extension !== undefined &&
              contact.Extension !== null &&
              `ext. ${contact.Extension}`
            }`
          : "-",
      active: contact.Active ? "Active" : "Inactive",
    };
  });

  return (
    <>
      {createContactDialogVisible && (
        <CreateAndLinkContactDialog
          {...props}
          dialogVisible={createContactDialogVisible}
          setDialogVisible={() => {
            setCreateContactDialogVisible(false);
          }}
        />
      )}
      <div className={"row pt-4"}>
        <div className={"col d-flex"}>
          <Typography variant={"h4"}>Contacts</Typography>
        </div>
      </div>
      <div className={"row pt-1"}>
        <div className={"col d-flex"}>
          <Button
            size={"small"}
            variant={"outlined"}
            color={"primary"}
            onClick={() => setCreateContactDialogVisible(true)}
          >
            New Contact
          </Button>
        </div>
      </div>
      <div className={"row pt-2"}>
        <div className={"col"}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </div>
      </div>
    </>
  );
};

export default ContactsTable;
