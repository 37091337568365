import React from "react";
import { Field, FormSpy } from "react-final-form";
import { emptyObject } from "src/utilities/objectUtilities";

const SideEffect = ({ name = "" }) => (
  <FormSpy>
    {({ form }) => (
      <Field name={`${name}Checkbox`} subscription={{ value: true }}>
        {({ input: { value: checkboxValue } }) => {
          if (checkboxValue && !form.getFieldState(name)?.value)
            form.change(name, emptyObject("RNComments")[name]);
          return <></>;
        }}
      </Field>
    )}
  </FormSpy>
);

export default SideEffect;
