import React, { useContext, useMemo } from "react";
import _ from "lodash";
import { recordReviewData } from "src/components/pages/MeetingManagement/RecordReview";
import TextCompare from "src/components/pages/MeetingManagement/RecordReview/TextCompare";
import { Field } from "react-final-form";
import { Switches } from "mui-rff";
import Condition from "src/components/pages/RNTools/Edit/Comments/CommentSections/BoxWrapper/Condition";
import { makeStyles } from "@mui/styles";
import blue from "@mui/material/colors/blue";
import Box from "@mui/material/Box";

const ReviewCommentsSection = () => {
  return (
    <>
      <TextBox label={"Peer Review Comments"} name={"PeerReviewComments"} />

      <Condition when={`ProviderRecommendationsSwitch`} is={false}>
        <TextBox
          label={"Provider Recommendations"}
          name={"ProviderRecommendations"}
        />
      </Condition>
      <ComparisonSwitch
        name={`ProviderRecommendationsSwitch`}
        label={
          "There were no provider recommendations identified in review of this record"
        }
      />

      <Condition when={`FacilityRecommendationsSwitch`} is={false}>
        <TextBox
          label={"Facility Recommendations"}
          name={"FacilityRecommendations"}
        />
      </Condition>
      <ComparisonSwitch
        name={`FacilityRecommendationsSwitch`}
        label={
          "There were no facility recommendations identified in review of this record"
        }
      />

      <TextBox label={"Teach Backs"} name={"TeachBacks"} />

      <Condition when={`LiteratureReviewSwitch`} is={false}>
        <TextBox label={"Literature Review"} name={"LiteratureReview"} />
      </Condition>
      <ComparisonSwitch
        name={`LiteratureReviewSwitch`}
        label={
          "There were no literature review recommendations identified in review of this record"
        }
      />
    </>
  );
};

const TextBox = ({ label, name }) => {
  const apiResponse = useContext(recordReviewData);
  const oValue = useMemo(
    () => _.get(apiResponse, `OriginalData.RNComments.${name}`, ""),
    [apiResponse]
  );
  const fValue = useMemo(
    () => _.get(apiResponse, `FacilitatorData.RNComments.${name}`, ""),
    [apiResponse]
  );
  return (
    <TextCompare name={name} label={label} fValue={fValue} oValue={oValue} />
  );
};

const ComparisonSwitch = ({ name, label }) => {
  const classes = useStyles();
  const apiResponse = useContext(recordReviewData);
  const oValue = useMemo(
    () => _.get(apiResponse, `OriginalData.RNComments.${name}`, ""),
    [apiResponse]
  );
  return (
    <Box className={classes.checkbox}>
      <Field name={name}>
        {({ input }) => {
          const isValueDiff = input.value !== oValue;
          return (
            <Switches
              name={name}
              data={{
                label: (
                  <span
                    className={
                      isValueDiff
                        ? input.value
                          ? classes.facilitatorValue
                          : classes.originalValue
                        : ""
                    }
                  >
                    {label}
                  </span>
                ),
                value: false,
              }}
            />
          );
        }}
      </Field>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  originalValue: {
    textDecoration: "line-through",
    color: blue[500],
  },
  facilitatorValue: {
    color: blue[700],
  },
  checkbox: {
    width: "100%",
    display: "inline-grid",
    justifyContent: "center",
  },
}));

export default ReviewCommentsSection;
