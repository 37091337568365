import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificLegibilityIssuesByProviderTypeChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Legibility Issues by Provider Type"}
        xAxisLabel={"Issues Found"}
        yAxisLabel={"Number of Records"}
        figureText={
          "Facility-Specific: Records with Legibility Issues by Provider Type"
        }
      />
    </>
  );
};

export default FacilitySpecificLegibilityIssuesByProviderTypeChart;
