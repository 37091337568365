import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityDischargeInstructionsMedicalAbbreviationsChart from "./AllFacilityDischargeInstructionsMedicalAbbreviationsChart";
import FacilitySpecificDischargeInstructionsMedicalAbbreviationsChart from "./FacilitySpecificDischargeInstructionsMedicalAbbreviationsChart";
import TextBegin from "./TextBegin";

const DischargeInstructionsMedicalAbbreviations = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, [
    "dischargeInstructionsAbbreviations",
  ]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, [
      "dischargeInstructionsAbbreviations",
    ]);

  return (
    <>
      <TextBegin {...props} />
      {chartDataAllFacilities !== false && (
        <AllFacilityDischargeInstructionsMedicalAbbreviationsChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Medical Abbreviations"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificDischargeInstructionsMedicalAbbreviationsChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Medical Abbreviations"}
        rows={chartData.rows}
      />
    </>
  );
};

export default DischargeInstructionsMedicalAbbreviations;
