import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { RecordActions } from "src/redux/reducers/Record";
import { ContactActions } from "src/redux/reducers/PersonnelManagement/Contact";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { MidlevelActions } from "src/redux/reducers/PersonnelManagement/Midlevel";
import { ProviderActions } from "src/redux/reducers/PersonnelManagement/Provider";
import { UserActions } from "src/redux/reducers/PersonnelManagement/User";
import { MeetingActions } from "src/redux/reducers/Meeting";
import { selectRecordByID } from "src/redux/selectors";
import Header from "./Header";
import EditTrackingDialog from "src/components/pages/MRTSRecords/EditTrackingDialog";
import PageCard from "src/components/individual/PageCard";
import RNTabs from "./RNTabs";

const Edit = (props) => {
  const { recordID } = useParams();
  const Loaded = useSelector((state) => state.Loaded);
  const [isTrackingDialogVisible, setIsTrackingDialogVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const render =
    Loaded.Contact &&
    Loaded.Facility &&
    Loaded.Midlevel &&
    Loaded.Provider &&
    Loaded.Record &&
    Loaded.RNTools &&
    Loaded.User &&
    Loaded.Meeting;

  const record = useSelector(selectRecordByID(recordID));

  useEffectOnce(() => {
    Loaded.Contact === false && props.dispatch(ContactActions.BatchAdd());
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
    Loaded.Midlevel === false && props.dispatch(MidlevelActions.BatchAdd());
    Loaded.Provider === false && props.dispatch(ProviderActions.BatchAdd());
    Loaded.Record === false &&
      props.dispatch(RecordActions.BatchAdd(props.roles, props.location));
    Loaded.User === false && props.dispatch(UserActions.BatchAdd());
    Loaded.Meeting === false && props.dispatch(MeetingActions.BatchAdd());
  });

  const Warning = () => {
    const warnOnStatus = [
      "Pending Facilitator Review",
      "Facilitator Review In Progress",
      "Committee Review Requested",
      "Pending Meeting Assignment",
      "Side Review Requested",
    ];
    const review = record.RecordReview;
    return review && warnOnStatus.includes(review.ReviewStatus) ? (
      <div className={"pb-3"}>
        <Alert severity="error">
          WARNING: Pending Review with status —{" "}
          <strong>{review.ReviewStatus}</strong>
          <br />
          Changes here won't be reflected on the final record.
        </Alert>
      </div>
    ) : null;
  };

  return record && render ? (
    <>
      {isTrackingDialogVisible && (
        <EditTrackingDialog
          {...props}
          dialogVisible={isTrackingDialogVisible}
          setDialogVisible={() => {
            setIsTrackingDialogVisible(false);
          }}
          recordID={record.ID}
        />
      )}
      <PageCard
        title={"RN Tools"}
        render={
          <>
            <Warning />
            <Header
              {...props}
              record={record}
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              setEditTrackingDialogVisible={() =>
                setIsTrackingDialogVisible(true)
              }
            />
            <RNTabs {...props} record={record} />
          </>
        }
      />
    </>
  ) : (
    <CircularProgress size={100} sx={{ padding: 100, margin: 1000 }} />
  );
};

export default Edit;
