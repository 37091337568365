import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import React from "react";

const Buttons = ({ onMerge, onClose }) => (
  <Grid container spacing={2} flexDirection={"row-reverse"}>
    <Grid item>
      <Button color={"error"} variant={"contained"} onClick={onClose}>
        Close
      </Button>
    </Grid>
    <Grid item>
      <Button color={"warning"} variant={"contained"} onClick={onMerge}>
        Merge
      </Button>
    </Grid>
  </Grid>
);

export default Buttons;
