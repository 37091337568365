const C = {
  facilityPersonnel: "FacilityPersonnel",
  facilityAdmin: "FacilityAdmin",
  facilitator: "Facilitator",
  registeredNurse: "RNAdmin",
  archiAdministrator: "Administrator",
  superAdmin: "SuperAdmin",
};

export const canCreateMeetings = (roles) => {
  return (
    registeredNurse(roles) || archiAdministrator(roles) || superAdmin(roles)
  );
};

export const facilityPersonnel = (roles) => {
  if (roles.current !== undefined && roles.current !== null) {
    if (roles.current.includes(C.facilityPersonnel)) {
      return true;
    }
  }
  return false;
};

export const facilityAdmin = (roles) => {
  if (roles.current !== undefined && roles.current !== null) {
    if (roles.current.includes(C.facilityAdmin)) {
      return true;
    }
  }
  return false;
};

export const facilityUser = (roles) =>
  facilityAdmin(roles) || facilityPersonnel(roles);

export const adminUser = (roles) =>
  registeredNurse(roles) || archiAdministrator(roles) || superAdmin(roles);

export const facilitator = (roles) => {
  if (roles !== undefined && roles !== null) {
    if (roles.current !== undefined && roles.current !== null) {
      if (roles.current.includes(C.facilitator)) {
        return true;
      }
    }
  }
  return false;
};

export const registeredNurse = (roles) => {
  if (roles.current !== undefined && roles.current !== null) {
    if (roles.current.includes(C.registeredNurse)) {
      return true;
    }
  }
  return false;
};

export const archiAdministrator = (roles) => {
  if (roles.current !== undefined && roles.current !== null) {
    if (roles.current.includes(C.archiAdministrator)) {
      return true;
    }
  }
  return false;
};

const superAdmin = (roles) => {
  if (roles.current !== undefined && roles.current !== null) {
    if (roles.current.includes(C.superAdmin)) {
      return true;
    }
  }
  return false;
};

export const canAccessFacilities = (roles) => {
  return (
    registeredNurse(roles) || archiAdministrator(roles) || superAdmin(roles)
  );
};

export const canAccessContacts = (roles) => {
  return (
    facilityPersonnel(roles) ||
    facilityAdmin(roles) ||
    registeredNurse(roles) ||
    archiAdministrator(roles) ||
    superAdmin(roles)
  );
};

export const canAccessProviders = (roles) => {
  return (
    facilityPersonnel(roles) ||
    facilityAdmin(roles) ||
    registeredNurse(roles) ||
    archiAdministrator(roles) ||
    superAdmin(roles)
  );
};

export const canAccessMidlevels = (roles) => {
  return (
    facilityPersonnel(roles) ||
    facilityAdmin(roles) ||
    registeredNurse(roles) ||
    archiAdministrator(roles) ||
    superAdmin(roles)
  );
};

export const canAccessUsers = (roles) => {
  return (
    facilityAdmin(roles) ||
    registeredNurse(roles) ||
    archiAdministrator(roles) ||
    superAdmin(roles)
  );
};

export const canAccessPersonnel = (roles) => {
  return (
    facilityPersonnel(roles) ||
    facilityAdmin(roles) ||
    registeredNurse(roles) ||
    archiAdministrator(roles) ||
    superAdmin(roles)
  );
};

export const canAccessAdministration = (roles) => {
  return (
    registeredNurse(roles) || archiAdministrator(roles) || superAdmin(roles)
  );
};

export const canAccessReports = (roles) => {
  return (
    facilityAdmin(roles) ||
    registeredNurse(roles) ||
    archiAdministrator(roles) ||
    superAdmin(roles)
  );
};

export const canViewMRTSPDF = (roles) => {
  return (
    facilityPersonnel(roles) ||
    facilityAdmin(roles) ||
    registeredNurse(roles) ||
    archiAdministrator(roles) ||
    superAdmin(roles)
  );
};

export const canViewFaceSheet = (roles) => {
  return (
    facilityPersonnel(roles) ||
    facilityAdmin(roles) ||
    registeredNurse(roles) ||
    archiAdministrator(roles) ||
    superAdmin(roles)
  );
};

export const canViewFinalReport = (roles) => {
  return (
    facilityAdmin(roles) ||
    registeredNurse(roles) ||
    archiAdministrator(roles) ||
    superAdmin(roles)
  );
};

export const canAddContact = (roles) => {
  return (
    facilityPersonnel(roles) ||
    facilityAdmin(roles) ||
    registeredNurse(roles) ||
    archiAdministrator(roles) ||
    superAdmin(roles)
  );
};

export const canAddProvider = (roles) => {
  return (
    facilityPersonnel(roles) ||
    facilityAdmin(roles) ||
    registeredNurse(roles) ||
    archiAdministrator(roles) ||
    superAdmin(roles)
  );
};

export const canAddMidlevel = (roles) => {
  return (
    facilityPersonnel(roles) ||
    facilityAdmin(roles) ||
    registeredNurse(roles) ||
    archiAdministrator(roles) ||
    superAdmin(roles)
  );
};

export const canCreateFacility = (roles) => {
  return (
    registeredNurse(roles) || archiAdministrator(roles) || superAdmin(roles)
  );
};

export const canCreateUser = (roles) => {
  return (
    facilityAdmin(roles) ||
    registeredNurse(roles) ||
    archiAdministrator(roles) ||
    superAdmin(roles)
  );
};
