import * as yup from "yup";

const baseValidationSchema = yup.object({
  firstName: yup
    .string()
    .label("first name")
    .max(255)
    .min(2)
    .nullable()
    .required()
    .trim()
    .matches(/^[_A-z- .']*$/, {
      excludeEmptyString: true,
      message: "no numbers, spaces, or symbols allowed",
    }),
  lastName: yup
    .string()
    .label("last name")
    .max(255)
    .min(2)
    .nullable()
    .required()
    .trim()
    .matches(/^[_A-z- .']*$/, {
      excludeEmptyString: true,
      message: "no numbers, spaces, or symbols allowed",
    }),
  email: yup.string().email().max(255).nullable().lowercase().trim(),
  active: yup.boolean().required(),
});

const phoneValidationSchema = yup.object({
  areaCode: yup
    .number()
    .label("area code")
    .typeError("must be a number")
    .integer()
    .min(100)
    .lessThan(1000)
    .required(),
  phoneNumber: yup
    .string()
    .label("phone number")
    .typeError("must be a number")
    .required()
    .min(8)
    .max(8)
    .matches(/^[0-9-]*$/, {
      excludeEmptyString: true,
      message: " Only numbers and dash allowed",
    }),
  extension: yup
    .number()
    .typeError("numbers only")
    .lessThan(1000000000000)
    .integer()
    .positive()
    .nullable(),
});

export const midlevelProviderValidationSchema = baseValidationSchema.concat(
  yup.object({
    degree: yup.string().max(255).required().nullable(),
    facilityID: yup.number().nullable(),
  })
);

export const contactValidationSchema = baseValidationSchema
  .concat(phoneValidationSchema)
  .concat(
    yup.object({
      facilityID: yup.number().typeError("facility is required").required(),
    })
  );

export const userValidationSchema = baseValidationSchema.concat(
  yup.object({
    username: yup.string().typeError("username is required").required().trim(),
    email: yup
      .string()
      .typeError("email is required")
      .email()
      .max(255)
      .required()
      .lowercase()
      .trim(),
    permissionID: yup
      .number()
      .typeError("permission level is required")
      .required(),
    facilityID: yup.number().nullable(),
  })
);

export default baseValidationSchema;
