import React from "react";
import PropTypes from "prop-types";
import RenderEachItem from "../RenderEachItem";
import MuiCheckbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Field, FormSpy } from "react-final-form";

const Checkbox = ({ name, label, valueDependsOn, items, itemsDependOn }) => {
  return (
    <>
      {valueDependsOn ? (
        <DependentCheckbox valueDependsOn={valueDependsOn} label={label} />
      ) : (
        <IndependentCheckbox name={name} label={label} />
      )}
      <RenderEachItem items={items} itemsDependOn={itemsDependOn} indent />
    </>
  );
};

const IndependentCheckbox = ({ name, label }) => (
  <div className={"d-flex text-left"}>
    <Field name={name}>
      {({ input }) => (
        <FormControlLabel
          key={`Checkbox_FormControl_${name}`}
          control={
            <FormSpy subscription={{ form: true }}>
              {({ form }) => (
                <MuiCheckbox
                  key={`Checkbox_${name}`}
                  onChange={(event) =>
                    form.change(
                      input.name,
                      JSON.stringify(event.target.checked)
                    )
                  }
                  checked={input.value === "true"}
                  color="primary"
                />
              )}
            </FormSpy>
          }
          label={label}
        />
      )}
    </Field>
  </div>
);
const DependentCheckbox = ({ label, valueDependsOn }) => {
  return (
    <div className={"d-flex text-left"}>
      <FormSpy>
        {({ values }) => {
          const isTrue = valueDependsOn.reduce(
            (acc, { name, is }) => (is === values[name] ? acc + 1 : acc),
            0
          );
          return (
            <FormControlLabel
              key={`Checkbox_FormControl_${label}`}
              control={
                <MuiCheckbox
                  key={`Checkbox_${label}`}
                  checked={isTrue}
                  color="primary"
                />
              }
              disabled={true}
              label={label}
            />
          );
        }}
      </FormSpy>
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  name: PropTypes.string,
  items: PropTypes.array,
  itemsVisible: PropTypes.bool,
};
