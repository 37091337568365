import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityRecordsReviewedChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Records Reviewed"}
        xAxisLabel={"Specialty"}
        yAxisLabel={"Number of Records"}
        figureText={"Records Reviewed by Committee for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilityRecordsReviewedChart;
