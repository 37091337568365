import React from "react";

const ProgramOverview = () => {
  return (
    <div className={"section-page"} style={{ pageBreakBefore: "always" }}>
      <div className={"section-report-title"}>
        <h4>Program Overview</h4>
      </div>
      <div className={"text-left"}>
        <p>
          In late 2003, The A&M Rural and Community
          Health Institute (ARCHI) developed a unique program to address a
          special need of rural Healthcare Facilities - Physician Peer Review.
          The Rural Physician Peer Review Program (RPPR)© is an "internal" peer
          review program facilitated by ARCHI. Using e-technology and conference
          calling, physicians are brought together across the state to discuss
          patient care. The program has grown from an initial two facilities
          in 2003 to 60+ facilities in 2022. Additionally, ARCHI developed a
          "hybrid" program to allow physicians to have direct review of the
          medical records in lieu of participating in virtual meetings. The
          participating facilities may choose either path of the program.
        </p>
        <p>
          While physician peer review is difficult in any venue, it is
          particularly challenging in rural settings. Rural facilities are
          challenged to conduct effective, unbiased peer review due to their
          limited medical staff. Many times the rural facility has only one or
          two physicians per specialty who are either in practice together or
          are competitors. When there is a practice problem or a perceived
          problem, conducting a non-biased review is almost impossible. In
          addition to the issues identified, peer review has also traditionally
          been seen as punitive.
        </p>
        <p>
          Compounding the issues of peer review for rural healthcare facilities
          is the demand for patient safety and quality of care. There is
          increased focus on these issues along with increasing regulatory
          requirements to monitor and evaluate the quality and outcomes of
          patient care. The Joint Commission (TJC) 2007 Medical Staff Standards
          require facilities to continuously monitor physician performance
          through the Focused Professional Practice Evaluation (FPPE)/ Ongoing
          Professional Practice Evaluation (OPPE) in the areas of patient care,
          medical knowledge, practice-based learning and improvement,
          interpersonal and communication skills, systems-based practice, and
          professionalism. RPPR© provides the facilities a venue to help
          facilities meet this requirement, in part, of their overall physician
          evaluation.
        </p>
        <p>
          RPPR© is a program developed to not only address regulatory
          requirements but also the issues encountered by rural providers,
          utilizing peer review as a venue in which learning can occur.
          Incorporating learning into the peer review process removes bias,
          promotes a "Just Culture" and removes the traditional punitive focus.
          Participating physicians receive Continuing Medical Education (CME)
          credits that are awarded through the Texas A&M Coastal Bend Education
          Center. In 2009, arrangements were made for American Academy of Family
          Physicians (AAFP) members to receive one hour of prescribed credit for
          each Family Medicine peer review session they attended through the
          AAFP.
        </p>
        <p>
          A unique feature of the program includes the ability for physicians to
          participate from any location. Information is provided through a
          HIPAA- compliant, secure web portal. The actual meeting is then
          conducted via a secure conference call.
        </p>
        <p>
          Many times patient outcomes are attributed to a single physician yet
          in reality many factors or variables can and should be attributed to
          the outcome. RPPR© also promotes system learning. When "systems"
          issues are recognized in a peer review session, feedback is provided
          to the facility as opportunities for improvement.
        </p>
        <p>
          Taking a systems approach to peer review also helps physicians and
          facilities share in patient safety and quality issues including but
          not limited to core measures, time-outs, documentation issues,
          medication reconciliation, discharge/transfer orders, health literacy,
          hand-offs, and the use of unapproved abbreviations. Accredited rural
          facilities have used this information to assist in demonstrating their
          compliance with standards and patient safety goals.
        </p>
        <p>
          This report summarizes selected time period RPPR© activities and
          provides your facility- specific data from which you can benchmark
          with your peers. ARCHI thanks you for the opportunity to assist you
          with your quality and patient safety improvement activities by
          participating in RPPR©.
        </p>
      </div>
    </div>
  );
};

export default ProgramOverview;
