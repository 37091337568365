import React from "react";
import { TextField } from "mui-rff";
import { makeStyles } from "@mui/styles";

const TextBox = ({ label, name }) => {
  const classes = useStyles();

  return (
    <TextField
      className={classes.spacing}
      label={label}
      name={name}
      rows={5}
      multiline
    />
  );
};

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginBottom: theme.spacing(5),
  },
}));

export default TextBox;
