import React from "react";
import { useSelector } from "react-redux";
import RecursiveComponent from "./RecursiveComponents";
import {
  selectMeetingsByMRTSReviewType,
  selectOutcomes,
} from "src/redux/selectors";
import createOutcomeStructure from "src/components/templateStructures/outcomeStructure";

const OutcomeForm = ({ MRTSReviewTypeID }) => {
  const meetings = useSelector(
    selectMeetingsByMRTSReviewType(MRTSReviewTypeID)
  );
  const outcomes = useSelector(selectOutcomes);

  return (
    <RecursiveComponent items={createOutcomeStructure(meetings, outcomes)} />
  );
};

export default OutcomeForm;
