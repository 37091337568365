import React from "react";
import {
  renderBoxRow,
  renderTwoBoxColumn,
} from "../PDFUtilities/RenderPDFFunctions";

const BasicInformation = (props) => {
  const recordData = props.recordData;
  const basicInformation = props.settings[0]
    ? {
        PeerReview: recordData.WorksheetMeetingName,
        RecordNumber: recordData.ARCHIRecordID,
        ReviewReason: recordData.ReviewReasonsList,
        ClinicalQuestion: recordData.ClinicalQuestion,
      }
    : {
        PeerReview: "",
        RecordNumber: "",
        ReasonForReview: "",
        ClinicalQuestion: "",
      };

  return (
    <>
      {renderTwoBoxColumn(
        "Peer Review",
        basicInformation.PeerReview,
        "Record Number",
        basicInformation.RecordNumber
      )}
      {renderBoxRow("Reason for review", basicInformation.ReviewReason)}
      {renderBoxRow(
        "Clinical Question/Comments",
        basicInformation.ClinicalQuestion
      )}
    </>
  );
};

export default BasicInformation;
