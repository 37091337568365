import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const checklistQuestionSlice = createSlice({
  name: "checklistQuestion",
  initialState,
  reducers: {
    batchAdd: (_state, action) => {
      return [...action.payload.checklistOption];
    },
  },
});

const { batchAdd } = checklistQuestionSlice.actions;

const BatchAdd = (content) => async (dispatch) => {
  dispatch(batchAdd(content));
};

export const ChecklistQuestionActions = {
  BatchAdd,
};

export default checklistQuestionSlice.reducer;
