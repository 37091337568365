import React from "react";

const TextBegin = (props) => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>Discharge Instructions - Teach Backs</h4>
      </div>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              One model for patient materials, developed by the National Patient
              Safety Foundation, is called Ask Me 3:
            </p>
            <br />
            <ol className={"text-left"}>
              <li>
                What is my main problem? (i.e., why was I in the hospital?)
              </li>
              <li>
                What do I need to do? (i.e., how do I manage at home, and what
                should I do if I run into problems?)
              </li>
              <li>Why is it important for me to do this?</li>
            </ol>
            <br />
            <p className={"text-left"}>
              Discharge information, both written and verbal, should be reviewed
              with the patient/family caregivers with an emphasis on assessing
              and ensuring comprehension. Teach back is a technique by which the
              provider asks the patient or caregiver to explain the recently
              taught concept in the patient's own words. This technique permits
              the provider to identify and correct any misunderstandings in real
              time, with the intent of preventing adverse events related to
              inadequate comprehension of discharge information. A copy in the
              record provides documentation on the content and details of what
              the patient received.
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextBegin;
