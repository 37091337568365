import React from "react";
import { Image, View } from "@react-pdf/renderer";
import { styles } from "../../PDFUtilities/PDFStyles";

const Header = () => {
  return (
    <View style={styles.pdfHeader}>
      <Image src={"/images/ARCHI-HorizontalLogo-07.2023.png"} />
    </View>
  );
};

export default Header;
