import * as yup from "yup";

const validationSchema = yup.object({
  recordStatus: yup.number().label("record status").required().nullable(),
  mrtsReviewType: yup.number().label("MRTS review type").required().nullable(),
  reviewMonth: yup.number().label("review month").required().nullable(),
  reviewYear: yup
    .number()
    .label("review year")
    .min(2000)
    .max(3000)
    .required()
    .nullable(),
  comments: yup.string().nullable().max(2000),
});

export default validationSchema;
