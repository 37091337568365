const mutators = {
  clearAll: (args, state, utils) => {
    utils.changeValue(state, "reviewType", () => null);
    utils.changeValue(state, "committeeID", () => null);
    utils.changeValue(state, "subcommittee", () => null);
    utils.changeValue(state, "meetingDate", () => null);
  },
  clearSubcommittee: (args, state, utils) => {
    utils.changeValue(state, "subcommittee", () => null);
  },
};

export default mutators;
