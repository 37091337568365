import { store } from "src/index";
import { format } from "date-fns";
import getUTCDate from "src/utilities/getUTCDate";
import { emptyObject } from "src/utilities/objectUtilities";
import isNullish from "src/utilities/isNullish";
import C from "src/utilities/constants";
import { isEmpty } from "lodash";

export const getConstantInfo = (Name) => {
  return store.getState()[Name];
};

export const formatPDFDate = (dateString) => {
  if (dateString.includes("Z")) {
    dateString = dateString.slice(0, -1);
  }
  return format(new Date(dateString), "MM/dd/yyyy");
};

export const formatPDFDateTime = (dateString) => {
  if (dateString.includes("Z")) {
    dateString = dateString.slice(0, -1);
  }
  return format(new Date(dateString), "MM/dd/yyyy HH:mm");
};

export const formatPatientAge = (age, ageIncrementID) => {
  let patientAge = "> 89 Years";

  if (ageIncrementID !== C.AGE_INCREMENT_89_PLUS) {
    patientAge =
      age +
      " " +
      getConstantInfo("AgeIncrement").filter((a) => a.ID === ageIncrementID)[0]
        .Name;
  }

  return patientAge;
};

export const formatPDFStayLength = (time) => {
  let days = Math.floor(time / (60 * 24));
  let hours = Math.floor((time % (60 * 24)) / 60);
  let minutes = Math.floor((time % (60 * 24)) % 60);

  let dayText = days === 1 ? `${days} day` : `${days} days`;
  let hourText = hours === 1 ? `${hours} hour` : `${hours} hours`;
  let minutesText = minutes === 1 ? `${minutes} minute` : `${minutes} minutes`;

  if (days > 0) {
    return hours === 0 ? dayText : `${dayText} ${hourText}`;
  } else if (hours > 0) {
    return minutes === 0 ? hourText : `${hourText} ${minutesText}`;
  } else {
    return minutesText;
  }
};

export const formatReviewReasons = (ReviewReasons) => {
  let ReviewReasonData = [];
  const ReviewReasonList = getConstantInfo("ReviewReason");

  ReviewReasons.forEach((reason) => {
    ReviewReasonList.filter((list) => reason.ID === list.ID).forEach((list) => {
      ReviewReasonData.push({
        ID: list.ID,
        Description: reason.ID === 16 ? "Other: " : list.Reason,
        Other: reason.ID === 16 ? reason.recordReviewReason.OtherReason : null,
      });
    });
  });

  return ReviewReasonData;
};

const formatProvider = (provider) => {
  if (isNullish(provider)) {
    return "";
  }

  return (
    `${isNullish(provider.FirstName) ? "" : provider.FirstName}` +
    " " +
    `${isNullish(provider.LastName) ? "" : provider.LastName}` +
    " (" +
    `${isNullish(provider.Degree) ? "" : provider.Degree}` +
    ") - " +
    `${isNullish(provider.ARCHIProviderID) ? "" : provider.ARCHIProviderID}`
  );
};

export const formatRecordPDFData = (content) => {
  const PDFData = emptyObject("RecordPDF");
  PDFData.Version = content.Version;

  // IDs
  PDFData.ARCHIRecordID = content.ARCHIRecordID;
  PDFData.ARCHIFacilityID = content.Facility.ARCHIFacilityID;
  PDFData.FacilityRecordID = content.OriginalFacilityRecordID;
  PDFData.ARCHIProviderID = content.Provider.ARCHIProviderID;
  PDFData.ARCHIMidlevelID = "";
  if (
    "Midlevel" in content &&
    content.Midlevel &&
    typeof content.Midlevel !== "undefined" &&
    "ARCHIProviderID" in content.Midlevel
  ) {
    PDFData.ARCHIMidlevelID = content.Midlevel.ARCHIProviderID;
  }
  PDFData.CommitteeID = content.CommitteeID;

  // Record Info
  PDFData.RecordDate = content.SubmissionDate
    ? formatPDFDate(content.SubmissionDate)
    : "Pending";
  PDFData.RecordComment = content.RecordComment;
  PDFData.RecordCreatedAt = formatPDFDateTime(content.createdAt);
  PDFData.ReportSentToFacilityDate =
    content.RecordTracking && content.RecordTracking.DateReportSentToFacility
      ? formatPDFDate(content.RecordTracking.DateReportSentToFacility)
      : "Pending";

  // Patient Info
  PDFData.PatientGender = content.PatientGender === true ? "Female" : "Male";
  PDFData.PatientAge = formatPatientAge(
    content.PatientAge,
    content.AgeIncrementID
  );
  PDFData.PatientSource =
    content.PatientSource.Name === "Other"
      ? "Other - " + content.PatientSourceOther
      : content.PatientSource.Name;
  PDFData.ArrivalDateTime = formatPDFDateTime(content.ArrivalDate);
  PDFData.DischargeDateTime = formatPDFDateTime(content.DischargeDate);
  PDFData.StayLength = formatPDFStayLength(content.StayLength);

  // Diagnosis
  PDFData.RecordDiagnosis = content.RecordDiagnosis;
  PDFData.PrimaryDiagnosis = !isEmpty(content.RecordDiagnosis)
    ? content.RecordDiagnosis[0].CodeAndDescription
    : "";

  // Facility Info
  PDFData.FacilityName = content.Facility.Name;
  PDFData.FacilityFullName = content.Facility.FullName;
  PDFData.FacilityCity = content.Facility.City;
  PDFData.FacilityContactName = isNullish(content.Contact)
    ? ""
    : `${isNullish(content.Contact.FirstName) ? "" : content.Contact.FirstName
    }` +
    " " +
    `${isNullish(content.Contact.LastName) ? "" : content.Contact.LastName}`;

  // Other
  PDFData.CommitteeName = content.Committee.Name;
  PDFData.Committe = content.Committe;
  PDFData.ReferenceMaterial = content.ReferenceMaterial;
  PDFData.ReviewReasons = content.ReviewReason
    ? formatReviewReasons(content.ReviewReason)
    : "";
  PDFData.ReviewReasonsList = PDFData.ReviewReasons.reduce(
    (acc, curr, index, Array) => {
      return (
        acc +
        curr.Description +
        (curr.Other !== null ? curr.Other : "") +
        (Array.length !== index + 1 ? ", " : "")
      );
    },
    ""
  );

  PDFData.ReferenceMaterialList = getConstantInfo("ReferenceMaterial");
  PDFData.MeetingName = content.Meeting
    ? content.Meeting.MeetingName
    : "Pending";
  PDFData.WorksheetMeetingName = content.Meeting
    ? content.Meeting.MeetingName
    : "Pending";
  PDFData.Worksheet = content.Worksheet;
  PDFData.RNOutcomes = content.RNOutcomes;
  PDFData.RNComments = content.RNComments;
  PDFData.HistoryPreSurgery = content.HistoryPreSurgery === true ? "Yes" : "No";
  PDFData.RecordAbbreviation = content.RecordAbbreviation;
  PDFData.Signature = content.Signature;
  PDFData.ClinicalQuestion = content.RecordComment.filter(
    (element) => element.TypeID === C.COMMENT_TYPE_ID_COMMITTEE_QUESTION
  )[0].Description;

  PDFData.Provider = formatProvider(content.Provider);

  PDFData.Midlevel = formatProvider(content.Midlevel);

  content.RecordComment.forEach((element) => {
    if (element.TypeID === C.COMMENT_TYPE_ID_REFERENCE_MATERIAL) {
      return (PDFData.ReferenceMaterialDescription = element.Description);
    }
    if (element.TypeID === C.COMMENT_TYPE_ID_ADDITIONAL_INFORMATION) {
      return (PDFData.AdditionalInformation = element.Description);
    }
    if (element.TypeID === C.COMMENT_TYPE_ID_COMMITTEE_QUESTION) {
      return (PDFData.CommitteeQuestion = element.Description);
    }
  });

  return PDFData;
};
