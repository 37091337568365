import React from "react";

const UngroupedProviderRecordsReviewedByCommittee = (props) => {
  const provider = [];
  const labels = [];
  const data = [];
  const rows = [];
  props.Report.Data[props.reportKey].forEach((value) => {
    if (value.archiProviderId !== undefined) {
      provider.push(value.archiProviderId);
    } else if (value.archiProviderName !== undefined) {
      provider.push(value.archiProviderName);
    } else {
      provider.push(value.archiProviderLabel);
    }
    labels.push(`${value.mrtsReviewType} - ${value.committee}`);
    data.push(value.total);
  });
  const sum = data.reduce((a, b) => a + b, 0);
  labels.forEach((label, i) => {
    if (data[i] === 0) return;
    rows.push(
      <tr key={`report-table-row-${label}-${i}`}>
        <td class={"pl-5"} style={{textAlign: "left"}} >{provider[i]}</td>
        <td>{label}</td>
        <td>{data[i]}</td>
        <td>{Math.round(100 * ((data[i] / sum) * 100)) / 100}%</td>
      </tr>
    );
  });
  return (
    <table
      className={"table table-bordered table-striped table-hover table-sm"}
    >
      <thead>
        <tr>
          <th scope={"col"}>
            <b>Provider</b>
          </th>
          <th scope={"col"}>
            <b>{props.heading}</b>
          </th>
          <th scope={"col"}>
            <b>Total</b>
          </th>
          <th scope={"col"}>
            <b>Percentage</b>
          </th>
        </tr>
      </thead>
      <tbody>{rows !== undefined && rows}</tbody>
    </table>
  );
};

export default UngroupedProviderRecordsReviewedByCommittee;
