import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form } from "react-final-form";
import validateFinalForm from "src/utilities/validateFinalForm";
import * as yup from "yup";
import { useSelector } from "react-redux";
import {
  selectContentByID,
  selectActiveUserByPermissionID,
} from "src/redux/selectors";
import { MenuItem } from "@mui/material";
import { Select } from "mui-rff";
import { MeetingActions } from "src/redux/reducers/Meeting";

const validationSchema = yup.object({
  facilitatorID: yup.number().required().nullable(),
});

const mutators = {
  clearAll: (args, state, utils) => {
    utils.changeValue(state, "facilitatorID", () => null);
  },
};

const AssignFacilitatorDialog = (props) => {
  const Facilitators = useSelector(selectActiveUserByPermissionID(4));
  const Meeting = useSelector(selectContentByID("Meeting", props.meetingID));
  const render = Facilitators !== undefined && Meeting !== undefined;

  const initialValues = {
    facilitatorID: Meeting.FacilitatorID,
  };

  const assignFacilitator = (values) => {
    props.dispatch(
      MeetingActions.AddFacilitator({
        MeetingID: props.meetingID,
        FacilitatorID: values.facilitatorID,
      })
    );
  };

  return (
    <>
      {render && (
        <Form
          onSubmit={assignFacilitator}
          initialValues={initialValues}
          validate={validateFinalForm(validationSchema)}
          mutators={mutators}
          render={({ form, submitting, pristine, values }) => (
            <>
              <form>
                <Dialog
                  fullWidth
                  maxWidth={"sm"}
                  open={props.dialogVisible}
                  onClose={() => {
                    props.setDialogVisible();
                    form.mutators.clearAll();
                  }}
                >
                  <DialogTitle>
                    {Meeting.MeetingName} <br /> Assign Facilitator
                  </DialogTitle>
                  <DialogContent className={"py-2"}>
                    <Select
                      name={"facilitatorID"}
                      label={"Facilitator"}
                      labelId="assign-facilitator-facilitator-select-label"
                      id="assign-facilitator-facilitator-select"
                      variant={"outlined"}
                      disabled={submitting}
                      displayEmpty
                      required
                    >
                      <MenuItem value={null}>Select a Facilitator</MenuItem>
                      {Facilitators.map((option) => (
                        <MenuItem
                          key={`assign-facilitator-${option.FirstName}-${option.LastName}-${option.ID}`}
                          value={option.ID}
                        >
                          {`${option.FirstName} ${option.LastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      disabled={submitting}
                      onClick={() => {
                        props.setDialogVisible();
                        form.mutators.clearAll();
                      }}
                      color={"secondary"}
                      variant={"contained"}
                    >
                      Close
                    </Button>
                    <Button
                      disabled={
                        values.facilitatorID === null || submitting || pristine
                      }
                      color={"primary"}
                      variant={"contained"}
                      onClick={() => {
                        assignFacilitator(values);
                        props.setDialogVisible();
                        form.mutators.clearAll();
                      }}
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </>
          )}
        />
      )}
    </>
  );
};

export default AssignFacilitatorDialog;
