import React, { useContext } from "react";
import PropTypes from "prop-types";
import RenderEachItem from "../RenderEachItem";
import MuiCheckbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Field, FormSpy } from "react-final-form";
import { emptyObject } from "src/utilities/objectUtilities";
import { recordReviewData } from "src/components/pages/MeetingManagement/RecordReview";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import blue from "@mui/material/colors/blue";

const Checkbox = ({ name, label, valueDependsOn, items, itemsDependOn }) => {
  return (
    <>
      {valueDependsOn ? (
        <DependentCheckbox valueDependsOn={valueDependsOn} label={label} />
      ) : (
        <IndependentCheckbox name={name} label={label} />
      )}
      <RenderEachItem items={items} itemsDependOn={itemsDependOn} indent />
    </>
  );
};

const IndependentCheckbox = ({ name, label }) => {
  const classes = useStyles();
  const defaultChecklist = emptyObject("RNChecklist");
  const apiResponse = useContext(recordReviewData);
  const oChecklist = _.get(apiResponse, `OriginalData.Checklist`, []);
  const findData = oChecklist.find((oValue) => oValue.Name === name);
  let oValue;
  if (!findData) {
    const defaultData = defaultChecklist.find((elem) => elem.Name === name);
    oValue = defaultData.OptionValue;
  } else {
    oValue = findData.Value;
  }
  // const { Value: oValue } =
  //   oChecklist.find((oValue) => oValue.Name === name) ??
  //   defaultChecklist.find((elem) => elem.name === name);
  return (
    <div className={"d-flex text-left"}>
      <Field name={name}>
        {({ input }) => {
          const isValueDiff = input.value !== oValue;
          return (
            <FormControlLabel
              key={`Checkbox_FormControl_${name}`}
              control={
                <FormSpy subscription={{ form: true }}>
                  {({ form }) => (
                    <MuiCheckbox
                      key={`Checkbox_${name}`}
                      onChange={(event) =>
                        form.change(
                          input.name,
                          JSON.stringify(event.target.checked)
                        )
                      }
                      checked={input.value === "true"}
                      color={"primary"}
                    />
                  )}
                </FormSpy>
              }
              label={
                <span
                  className={
                    isValueDiff
                      ? input.value === "true"
                        ? classes.facilitatorValue
                        : classes.originalValue
                      : ""
                  }
                >
                  {label}
                </span>
              }
            />
          );
        }}
      </Field>
    </div>
  );
};

const DependentCheckbox = ({ label, valueDependsOn }) => {
  return (
    <div className={"d-flex text-left"}>
      <FormSpy>
        {({ values }) => {
          const isTrue = valueDependsOn.reduce(
            (acc, { name, is }) => (is === values[name] ? acc + 1 : acc),
            0
          );
          return (
            <FormControlLabel
              key={`Checkbox_FormControl_${label}`}
              control={
                <MuiCheckbox
                  key={`Checkbox_${label}`}
                  checked={isTrue}
                  color="primary"
                />
              }
              disabled={true}
              label={label}
            />
          );
        }}
      </FormSpy>
    </div>
  );
};

export default Checkbox;

const useStyles = makeStyles(() => ({
  originalValue: {
    textDecoration: "line-through",
    color: blue[500],
  },
  facilitatorValue: {
    color: blue[700],
  },
}));

Checkbox.propTypes = {
  name: PropTypes.string,
  items: PropTypes.array,
  itemsVisible: PropTypes.bool,
};
