import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityMedicalManagementChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Medical Management"}
        xAxisLabel={"Medical Management"}
        yAxisLabel={"Number of Records"}
        figureText={"Medical Management for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilityMedicalManagementChart;
