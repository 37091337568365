import * as React from "react";
//ui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form } from "react-final-form";
import { MenuItem } from "@mui/material";
import { Select, TextField, Checkboxes } from "mui-rff";
//data
import { facilityUser } from "src/utilities/rbacFunctions";
import { ProviderActions } from "src/redux/reducers/PersonnelManagement/Provider";
import { degrees } from "./initialValues";
import { useSelector } from "react-redux";
import { selectContentByID } from "src/redux/selectors";
//validation
import { midlevelProviderValidationSchema as validationSchema } from "../validationSchema";
import { makeValidate } from "mui-rff";

const validateForm = makeValidate(validationSchema);

const UpdateProviderDialog = (props) => {
  const isFacilityUser = facilityUser(props.roles);
  const Provider = useSelector(selectContentByID("Provider", props.providerID));
  const render = Provider !== undefined;

  const initialValues = {
    firstName: Provider.FirstName,
    lastName: Provider.LastName,
    email: Provider.EmailAddress,
    degree: Provider.Degree,
    active: Provider.Active,
  };

  if (isFacilityUser) {
    initialValues.unlink = false;
  }

  const updateProvider = (values) => {
    const castValues = validationSchema.cast(values);
    const payload = {
      ID: props.providerID,
      FirstName: castValues.firstName,
      LastName: castValues.lastName,
      EmailAddress: castValues.email,
      Degree: castValues.degree,
      Active: castValues.active,
    };

    if (isFacilityUser) {
      payload.Unlink = castValues.unlink;
    }

    props.dispatch(ProviderActions.Update(payload));

    if (payload.Unlink) {
      props.dispatch(
        ProviderActions.RemoveFromFacility({
          FacilityID: props.facilityID,
          ProviderID: props.providerID,
        })
      );
    }

    props.setDialogVisible();
  };

  return (
    <>
      {render && (
        <Form
          onSubmit={updateProvider}
          initialValues={initialValues}
          validate={validateForm}
          render={({ handleSubmit }) => (
            <>
              <form>
                <Dialog
                  fullWidth
                  maxWidth={"sm"}
                  open={props.dialogVisible}
                  onClose={() => {
                    props.setDialogVisible();
                  }}
                >
                  <DialogTitle>Update Provider</DialogTitle>
                  <DialogContent>
                    <div className={"row py-2"}>
                      <div className={"col"}>
                        <TextField
                          label="First Name"
                          name="firstName"
                          variant={"outlined"}
                        />
                      </div>
                      <div className={"col"}>
                        <TextField
                          label="Last Name"
                          name="lastName"
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="E-Mail"
                          name="email"
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <Select
                          name={"degree"}
                          label={"Degree"}
                          labelId="create-provider-degree-select-label"
                          id="create-provider-degree-select"
                          variant={"outlined"}
                        >
                          <MenuItem disabled>Select a Degree</MenuItem>
                          {degrees.map((option) => (
                            <MenuItem
                              key={`create-provider-degree-select-option-${option.name}-${option.value}`}
                              value={option.value}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    {!isFacilityUser && (
                      <div className={"row pt-2"}>
                        <div className={"col"}>
                          <Checkboxes
                            size={"small"}
                            name={"active"}
                            data={{ label: "Active", value: true }}
                            color={"primary"}
                          />
                        </div>
                      </div>
                    )}
                    {isFacilityUser && (
                      <div className={"row pt-2"}>
                        <div className={"col"}>
                          <Checkboxes
                            size={"small"}
                            name={"unlink"}
                            data={{
                              label: "Unlink from Facility",
                              value: true,
                            }}
                            color={"primary"}
                          />
                        </div>
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color={"secondary"}
                      variant={"contained"}
                      onClick={() => {
                        props.setDialogVisible();
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      color={"primary"}
                      variant={"contained"}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </>
          )}
        />
      )}
    </>
  );
};

export default UpdateProviderDialog;
