import * as yup from "yup";
import C from "src/utilities/constants";

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export const submissionDateSchema = yup.object({
  submissionDate: yup
    .date()
    .label("submission date")
    .required()
    .min(
      yup.ref("dischargeDate"),
      "submission date must be later than discharge date"
    )
    .max(tomorrow, "No future dates allowed")
    .nullable(),
});

const validationSchema = yup.object({
  // REVIEW INFOMATION
  arrivalDate: yup
    .date()
    .required()
    .label("arrival date")
    .min(new Date(2000, 0, 1), "no dates before 2000 are allowed")
    .max(
      yup.ref("dischargeDate"),
      "arrival date must be earlier than discharge date"
    )
    .nullable(),
  dischargeDate: yup
    .date()
    .required()
    .label("discharge date")
    .min(
      yup.ref("arrivalDate"),
      "discharge date must be later than arrival date"
    )
    .max(new Date(), "no future dates allowed")
    .nullable(),

  // PATIENT INFORMATION
  patientAge: yup
    .number()
    .label("patient age")
    .required()
    .moreThan(-1)
    .integer()
    .nullable(),
  ageIncrementID: yup.number().label("age increment").required().nullable(),
  patientGender: yup.boolean().label("patient gender").required().nullable(),
  committee: yup
    .object({
      Abbreviation: yup.string().required(),
      Active: yup.number().required(),
      ID: yup.number().required(),
      Name: yup.string().required(),
    })
    .required()
    .nullable(),
  committeeOther: yup
    .string()
    .label("committee other")
    .nullable()
    .when("committee", {
      is: (val) => val.ID === C.COMMITTEE_ID_OTHER,
      then: yup.string().required(),
    }),

  // REVIEW REASONS
  reviewReasons: yup
    .array()
    .of(yup.number())
    .label("review reasons")
    .required()
    .min(1, "review reasons is required"),
  otherReviewReason: yup
    .string()
    .label("other review reason")
    .nullable()
    .when("reviewReasons", {
      is: (val) => val.includes(C.REVIEW_REASONS_ID_OTHER),
      then: yup.string().required().nullable().max(255),
    }),

  // REFERENCE MATERIAL
  referenceMaterialChecklist: yup
    .array()
    .of(yup.number())
    .label("reference material checklist")
    .required()
    .nullable(),
  otherReferenceMaterial: yup
    .string()
    .label("other reference material")
    .nullable()
    .when("referenceMaterialChecklist", {
      is: (val) => val.includes(C.REFERENCE_MATERIAL_ID_OTHER),
      then: yup.string().required().nullable().max(255),
    }),
  additionalInformation: yup
    .string()
    .label("additional information")
    .nullable(),
  committeeQuestion: yup
    .string()
    .label("committee question")
    .required()
    .nullable(),
});

export default validationSchema;
