import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { FormSpy } from "react-final-form";
import Custom from "src/components/individual/Custom";

const FinalizeButtons = () => {
  const classes = useStyles();

  return (
    <FormSpy
      subscription={{
        submitting: true,
        pristine: true,
        form: true,
      }}
    >
      {({ pristine, submitting, form }) => (
        <div className={classes.buttonsContainer}>
          <Custom.SubmitButton
            color="primary"
            onClick={form.submit}
            variant="outlined"
            className={classes.button}
            disabled={submitting || pristine}
          >
            Save
          </Custom.SubmitButton>
          <Button
            color="secondary"
            variant="outlined"
            className={classes.button}
            onClick={form.reset}
            disabled={submitting || pristine}
          >
            Reset
          </Button>
        </div>
      )}
    </FormSpy>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default FinalizeButtons;
