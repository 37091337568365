import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { FormSpy } from "react-final-form";
import useStyles from "../styles";
import { recordReviewData } from "src/components/pages/MeetingManagement/RecordReview";
import {
  canCreateMeetings,
  facilitator as facilitatorRole,
} from "src/utilities/rbacFunctions";
import { globalVariables } from "src/components/App";
import C from "src/utilities/constants";

const Buttons = ({ onSubmitRecordReview }) => {
  const { ReviewStatus, countInvalid } = useContext(recordReviewData);
  const { roles } = useContext(globalVariables);
  const classes = useStyles();
  return (
    <FormSpy subscription={{ submitting: true, pristine: true, values: true }}>
      {({ submitting, pristine, values, form }) => {
        return (
          <Container>
            <p>
              All information utilized in this program will be protected as
              afforded under the Healthcare Quality Improvement Act of 1986
              (HCQIA) and all confidentiality provisions and protections as
              provided in Texas HB 1614, Section 160.010 Texas Occupations Code,
              Medical Peer Review committees, Section 161.033 of the Health and
              Safety Code and identifiable information will be protected as
              afforded by HIPAA of 1996.
            </p>
            <>
              <Button
                color="primary"
                variant="outlined"
                className={classes.button}
                disabled={
                  submitting || pristine || isDisabledCheck(ReviewStatus, roles)
                }
                onClick={() => onSubmitRecordReview(values, "Save")}
              >
                Save
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                className={classes.button}
                disabled={
                  submitting ||
                  isDisabledCheck(ReviewStatus, roles) ||
                  countInvalid > 0
                }
                onClick={() => onSubmitRecordReview(values, "Finalize")}
              >
                Finalize
              </Button>
            </>
            <Button
              color="secondary"
              variant="outlined"
              className={classes.button}
              onClick={form.reset}
              disabled={submitting || pristine}
            >
              Reset
            </Button>
          </Container>
        );
      }}
    </FormSpy>
  );
};

const isDisabledCheck = (reviewStatus, roles) =>
  !(facilitatorRole(roles)
    ? [
        C.REVIEW_STATUS.PENDING_FACILITATOR_REVIEW,
        C.REVIEW_STATUS.FACILITATOR_REVIEW_IN_PROGRESS,
      ].includes(reviewStatus)
    : canCreateMeetings(roles)
    ? [
        C.REVIEW_STATUS.PENDING_RN_APPROVAL,
        C.REVIEW_STATUS.RN_REVIEW_IN_PROGRESS,
      ].includes(reviewStatus)
    : false);

export default Buttons;
