import React, { useEffect } from "react";
import Section from "./Section";
import ReportKeys from "../../ReportKeys";
import checkForKey from "./checkForKey";

import Heading from "./Heading";
import ProgramOverview from "./ProgramOverview";

import AbbreviationUse from "./AbbreviationUse";
import RecordsReviewedByCommittee from "./RecordsReviewedByCommittee";
import ProviderRecordsReviewedByCommittee from "./ProviderRecordsReviewedByCommittee";
import ReasonForReview from "./ReasonForReview";
import ReasonForReviewByProvider from "./ReasonForReviewByProvider";
import PeerReviewOutcomes from "./PeerReviewOutcomes";
import OutcomesByProvider from "./OutcomesByProvider";

import RCHITrendIssues from "./RCHITrendIssues";
import DischargeInstructionsMedicalAbbreviations from "./DischargeInstructionsMedicalAbbreviations";
import DischargeInstructionsMedicalTerminology from "./DischargeInstructionsMedicalTerminology";
import DischargeInstructionsTeachbacks from "./DischargeInstructionsTeachbacks";
import DischargeOrTransferDocumentsProvided from "./DischargeOrTransferDocumentsProvided";
import EvidenceOfMedicationReconciliation from "./EvidenceOfMedicationReconciliation";
import EthicsIssue from "./EthicsIssue";
import LegibilityIssuesByProviderType from "./LegibilityIssuesByProviderType";
import MedicalManagement from "./MedicalManagement";
import OtherPatientSafetyConcern from "./OtherPatientSafetyConcern";
import PatientDisposition from "./PatientDisposition";
import PatientOutcome from "./PatientOutcome";
import SentinelEvent from "./SentinelEvent";
import SystemIssues from "./SystemIssues";
import PossibleImprovements from "./PossibleImprovements";
import PatientAge from "./PatientAge";
import PatientGender from "./PatientGender";
import PatientRace from "./PatientRace";
import LengthOfStay from "./LengthOfStay";

let figureCount = 0;

export const getFigureCount = () => {
  figureCount++;
  return figureCount;
};

const ContentAreaReport = (props) => {
  const chartType = props.values.chartType;

  useEffect(() => {
    figureCount = 0;
  }, []);

  return (
    <div style={{ height: "100%", width: "90%" }}>
      <Heading {...props} />
      {checkForKey(ReportKeys.ProgramOverview, props.values) && (
        <>
          <ProgramOverview />
        </>
      )}
      <Section
        {...props}
        reportKey={ReportKeys.RecordsReviewedByCommittee}
        render={
          <RecordsReviewedByCommittee
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.RecordsReviewedByCommittee}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.ProviderRecordsReviewedByCommittee}
        render={
          <ProviderRecordsReviewedByCommittee
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.ProviderRecordsReviewedByCommittee}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.ReasonForReview}
        render={
          <ReasonForReview
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.ReasonForReview}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.ReasonForReviewByProvider}
        render={
          <ReasonForReviewByProvider
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.ReasonForReviewByProvider}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.PeerReviewOutcomes}
        render={
          <PeerReviewOutcomes
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.PeerReviewOutcomes}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.OutcomesByProvider}
        render={
          <OutcomesByProvider
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.OutcomesByProvider}
          />
        }
      />

      <Section
        {...props}
        reportKey={ReportKeys.RCHITrendIssues}
        render={
          <RCHITrendIssues
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.RCHITrendIssues}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.DischargeInstructionsMedicalAbbreviations}
        render={
          <DischargeInstructionsMedicalAbbreviations
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.DischargeInstructionsMedicalAbbreviations}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.DischargeInstructionsMedicalTerminology}
        render={
          <DischargeInstructionsMedicalTerminology
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.DischargeInstructionsMedicalTerminology}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.DischargeInstructionsTeachbacks}
        render={
          <DischargeInstructionsTeachbacks
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.DischargeInstructionsTeachbacks}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.DischargeOrTransferDocumentsProvided}
        render={
          <DischargeOrTransferDocumentsProvided
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.DischargeOrTransferDocumentsProvided}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.EvidenceOfMedicationReconciliation}
        render={
          <EvidenceOfMedicationReconciliation
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.EvidenceOfMedicationReconciliation}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.EthicsIssue}
        render={
          <EthicsIssue
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.EthicsIssue}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.LegibilityIssuesByProviderType}
        render={
          <LegibilityIssuesByProviderType
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.LegibilityIssuesByProviderType}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.MedicalManagement}
        render={
          <MedicalManagement
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.MedicalManagement}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.OtherPatientSafetyConcern}
        render={
          <OtherPatientSafetyConcern
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.OtherPatientSafetyConcern}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.PatientDisposition}
        render={
          <PatientDisposition
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.PatientDisposition}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.PatientOutcome}
        render={
          <PatientOutcome
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.PatientOutcome}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.SentinelEvent}
        render={
          <SentinelEvent
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.SentinelEvent}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.SystemIssues}
        render={
          <SystemIssues
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.SystemIssues}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.PossibleImprovements}
        render={
          <PossibleImprovements
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.PossibleImprovements}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.PatientAge}
        render={
          <PatientAge
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.PatientAge}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.AbbreviationUse}
        render={
          <AbbreviationUse
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.AbbreviationUse}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.PatientGender}
        render={
          <PatientGender
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.PatientGender}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.PatientRace}
        render={
          <PatientRace
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.PatientRace}
          />
        }
      />
      <Section
        {...props}
        reportKey={ReportKeys.LengthOfStay}
        render={
          <LengthOfStay
            {...props}
            chartType={chartType}
            reportKey={ReportKeys.LengthOfStay}
          />
        }
      />
      <div className={"pt-5 pb-2"}>
        <img src={"/images/DataReportEnd.png"} alt="End of report" />
      </div>
    </div>
  );
};

export default ContentAreaReport;
