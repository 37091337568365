import React from "react";

const TextBegin = (props) => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>Discharge or Transfer Documents Provided</h4>
      </div>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              Patients are at high risk for harm from adverse drug events when
              communicating about medications is not clear. Communicating about
              the medication lists help to reduce the risk of transition-related
              adverse drug events. In 2010, National Patient Safety Goal (NPSG)
              8 required that the facility have a process in place that
              accurately and completely reconcile medications across the
              continuum of care. Furthermore, NPSG goal 8 requires that the
              facility complete and reconcile the list of medications and
              communicate this information to the next provider of service
              through documentation in the discharge and or transfer orders.
              When a patient leaves the hospital to go home, the complete and
              reconciled list of medications is to be provided to the patient's
              primary care provider. As of July 2011, The Joint Commission (TJC)
              has incorporated medication reconciliation into National Patient
              Safety Goal #3, "Improving the safety of using medications." This
              goal requires that facilities "maintain and communicate accurate
              medication information " and "compare the medication information
              the patient brought to the hospital with the medications ordered
              for the patient by the hospital in order to identify and resolve
              discrepancies."
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextBegin;
