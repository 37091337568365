import apiService from "src/utilities/api_service";
import { emptyObject } from "src/utilities/objectUtilities";
import { apiUrl } from "src";
import _ from "lodash";
import { improvementsTitle } from "src/components/templateStructures/outcomeStructure";

const facilitatorRequest = async (
  recordID,
  requestType,
  checklist = [],
  comments = {},
  outcomes
) => {
  const originalPeerReviewNotes = _.get(comments, `PeerReviewNotes`, "");

  const checklistChanges = getChecklistChanges(checklist);
  const commentChanges = getCommentChanges(comments);
  const outcomeChanges = getOutcomeChanges(outcomes);
  const peerReviewNotes = formatChanges(
    originalPeerReviewNotes,
    checklistChanges,
    commentChanges,
    outcomeChanges
  );
  if (requestType)
    await apiService.post(`${apiUrl}recordReview/${requestType}/request`, {
      RecordID: recordID,
      PeerReviewNotes: peerReviewNotes,
      DefaultComments: emptyObject("RNComments"),
    });
};

const getChecklistChanges = (checklist) => {
  const defaultChecklist = emptyObject("RNChecklist");
  return checklist.reduce((acc, curr) => {
    const defaultValue = defaultChecklist.find(
      ({ Name }) => Name === curr.Name
    ).OptionValue;
    return curr.Value === defaultValue
      ? acc
      : acc + "\n    " + curr.Label + ": " + curr.Value;
  }, "");
};

const getCommentChanges = (comments) =>
  Object.entries(emptyObject("RNComments")).reduce(
    (acc, [key, value]) =>
      value === comments[key]
        ? acc
        : acc + "\n    " + key + ": " + comments[key],
    ""
  );

const getOutcomeChanges = (
  { Improvements, OtherImprovement } = { Improvements: 0, OtherImprovement: "" }
) => {
  const boolListOfCheckboxData = intToBinaryString(Improvements)
    .split("")
    .reverse()
    .map((num) => Boolean(Number(num)));

  const flattenedStructure = [];
  const flattenStructure = (items) => {
    items.forEach(({ name, label, bitID, items }) => {
      if (name === "Improvements") {
        flattenedStructure.push({ label, bitID });
      }
      if (items) {
        flattenStructure(items);
      }
    });
  };
  flattenStructure(improvementsTitle().items);

  const listOfChecked = boolListOfCheckboxData.reduce((acc, bool, i) => {
    if (bool) {
      acc.push(flattenedStructure.find(({ bitID }) => bitID === i));
    }
    return acc;
  }, []);

  let textOfSelectedData = listOfChecked.reduce(
    (acc, { label }) => acc + "\n" + label,
    ""
  );

  if (listOfChecked.some(({ label }) => label === "Other"))
    textOfSelectedData = `${textOfSelectedData}: ${OtherImprovement}`;

  return textOfSelectedData;
};

const intToBinaryString = (number = 0) => (number >>> 0).toString(2);

const formatChanges = (
  original,
  checklistChanges,
  commentChanges,
  outcomeChanges
) => {
  return `${original}
    
  Comment Changes: 
    ${commentChanges}
  
  Checklist Changes:${checklistChanges}
  
  Outcome Changes:${outcomeChanges}
  `;
};

export default facilitatorRequest;
