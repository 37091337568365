import React from "react";
import { TextField } from "@mui/material";

const DummyPreview = () => {
  return (
    <div className={"row pt-2"}>
      <div className={"col"}>
        <TextField
          label={"Meeting Name"}
          variant={"outlined"}
          fullWidth
          disabled
        />
      </div>
    </div>
  );
};

export default DummyPreview;
