import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificProviderRecordsReviewedChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Provider Records Reviewed"}
        xAxisLabel={"Specialty"}
        yAxisLabel={"Number of Providers"}
        figureText={
          "Facility Specific: Records reviewed by Committee and Provider"
        }
      />
    </>
  );
};

export default FacilitySpecificProviderRecordsReviewedChart;
