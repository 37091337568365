import { createSlice } from "@reduxjs/toolkit";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { LastCreatedObjectActions } from "src/redux/reducers/LastCreatedObject";
import { LoadedActions } from "src/redux/reducers/Loaded";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";

const initialState = [];

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    batchAdd: (_state, action) => {
      return [...action.payload];
    },
    create: (state, action) => {
      state.push(action.payload);
    },
    update: (state, action) => {
      const targetIndex = state.findIndex(
        (c) => Number(c.ID) === Number(action.payload.ID)
      );
      state[targetIndex] = { ...state[targetIndex], ...action.payload };
    },
  },
});

const { batchAdd, create, update } = contactSlice.actions;

const BatchAdd = () => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(LoadedActions.SetContact("pending"));
    const { data: contacts } = await apiService.get(`${apiUrl}contact`);
    dispatch(batchAdd([...contacts]));
    dispatch(LoadedActions.SetContact(true));
  } catch (error) {
    dispatch(FetchingActions.Failure("Loading Contacts"));
  }
};

const Create = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Contact/create`,
      content
    );
    dispatch(create(contents));
    if (contents.FacilityID !== undefined && contents.FacilityID !== null) {
      dispatch(FacilityActions.AddContact(contents));
    }
    dispatch(LastCreatedObjectActions.Set("AutoSelectContact", contents));
    dispatch(FetchingActions.Success("Creating Contact"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Creating Contact"));
  }
};

const Update = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Contact/update`,
      content
    );
    dispatch(update(contents));
    if (contents.FacilityID !== undefined && contents.FacilityID !== null) {
      dispatch(FacilityActions.AddContact(contents));
    }
    dispatch(FetchingActions.Success("Updating Contact"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Contact"));
  }
};

export const ContactActions = {
  BatchAdd,
  Create,
  Update,
};

export default contactSlice.reducer;
