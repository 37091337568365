import React from "react";
import { Svg, G, Polygon } from "@react-pdf/renderer";

const DiamondIcon = ({ bottom, left }) => {
  return (
    <Svg
      style={{
        width: 8,
        height: 8,
        position: "absolute",
        bottom: bottom,
        left: left,
      }}
    >
      <G transform="scale(1) ">
        <Polygon fill="#dddddd" points=" 0,4 4,8 8,4 4,0" />
      </G>
    </Svg>
  );
};

export default DiamondIcon;
