import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificRecordsReviewedChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Records Reviewed"}
        xAxisLabel={"Specialty"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility Specific: Records Reviewed by Committee"}
      />
    </>
  );
};

export default FacilitySpecificRecordsReviewedChart;
