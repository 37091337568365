import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import {
  selectAvailableFacilitiesByProviderID,
  selectContentByID,
  selectFacilities,
} from "src/redux/selectors";
import { ProviderActions } from "src/redux/reducers/PersonnelManagement/Provider";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const LinkProviderToFacilityDialog = (props) => {
  const classes = useStyles();
  const [selectedFacility, setSelectedFacility] = React.useState(null);
  const Facilities = useSelector(selectFacilities);
  const availableFacilities = useSelector(
    selectAvailableFacilitiesByProviderID(props.providerID)
  );
  const Provider = useSelector(selectContentByID("Provider", props.providerID));

  const defaultProps = {
    options: availableFacilities,
    getOptionLabel: (option) => option.FullName,
  };

  const assignedFacilitiesIDs =
    Provider !== undefined
      ? Provider.Facility.map((facility) => facility.ID)
      : [];

  const rows = Facilities.filter((facility) =>
    assignedFacilitiesIDs.includes(facility.ID)
  )
    .sort((a, b) => {
      const nameA = a.FullName.toUpperCase(); // ignore upper and lowercase
      const nameB = b.FullName.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    })
    .map((f) => ({
      ID: f.ID,
      Name: f.FullName,
    }));

  const noRows = !Array.isArray(rows) || !rows.length;

  const linkToFacility = () => {
    props.dispatch(
      ProviderActions.LinkToFacility({
        FacilityID: selectedFacility,
        ProviderID: props.providerID,
        Active: true,
      })
    );
    setSelectedFacility(null);
  };

  const unlinkFromFacility = (ID) => {
    props.dispatch(
      ProviderActions.RemoveFromFacility({
        FacilityID: ID,
        ProviderID: props.providerID,
      })
    );
    setSelectedFacility(null);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={props.dialogVisible}
      onClose={() => {
        props.setDialogVisible();
      }}
    >
      <DialogTitle>Link to Facility</DialogTitle>
      <DialogContent>
        <div className={"row"}>
          <div className={"col"}>
            <Autocomplete
              {...defaultProps}
              id="add-provider-to-facility-autocomplete"
              autoComplete
              includeInputInList
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setSelectedFacility(newValue.ID);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant={"outlined"}
                  label="Facility"
                />
              )}
            />
          </div>
          <div
            className={"col-1 d-flex align-items-center justify-content-center"}
          >
            <IconButton
              aria-label="delete"
              className={classes.margin}
              color={"primary"}
              onClick={linkToFacility}
              disabled={selectedFacility === null}
              size="large"
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
        <div className={"row pt-2"}>
          <div className={"col"}>
            {!noRows && (
              <Table size="small" aria-label="assigned facilities">
                <TableHead>
                  <TableRow>
                    <TableCell>Facility Name</TableCell>
                    <TableCell align={"right"}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.ID}>
                      <TableCell>{row.Name}</TableCell>
                      <TableCell align={"right"}>
                        <IconButton
                          aria-label="delete"
                          className={classes.margin}
                          color={"secondary"}
                          size={"small"}
                          onClick={() => unlinkFromFacility(row.ID)}
                        >
                          <RemoveIcon fontSize="inherit" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.setDialogVisible();
          }}
          color={"secondary"}
          variant={"contained"}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkProviderToFacilityDialog;
