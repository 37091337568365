import React from "react";

const DynamicTable = (props) => {
  const tableRows = props.tableRows;
  function createTableRows() {
    const tempRows = [];
    tableRows.forEach((slice, i) => {
      let tempRow = [];
      for (const [key, value] of Object.entries(slice)) {
        tempRow.push(<td>{value}</td>);
      }
      tempRows.push(
        <tr key={`report-table-row-${slice.sortdate}-${i}`}>{tempRow}</tr>
      );
    });

    return tempRows;
  }
  function createTableHeaders() {
    const headers = [];
    const firstRow = tableRows[0];
    for (const [key] of Object.entries(firstRow)) {
      headers.push(
        <th scope={"col"}>
          <b>{key}</b>
        </th>
      );
    }

    return (
      <thead>
        <tr>{headers}</tr>
      </thead>
    );
  }
  const tableHeaders = createTableHeaders();
  const tableDataRows = createTableRows();
  return (
    <table
      className={"table table-bordered table-striped table-hover table-sm"}
    >
      {tableHeaders}
      <tbody>{tableDataRows !== undefined && tableDataRows}</tbody>
    </table>
  );
};

export default DynamicTable;
