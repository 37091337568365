const initialValues = {
  facility: null,
  contact: null,
  provider: null,
  midlevel: null,
  submissionDate: null,
  reviewCategoryID: null,
  reviewTypeID: null,
  arrivalDate: null,
  dischargeDate: null,
  facilityMedicalRecordNumber: null,
  patientAge: null,
  ageIncrement: null,
  patientOriginationID: null,
  patientRace: null,
  patientGender: null,
  committee: null,
  committeeOther: null,
  historyAndPhysicalOnChart: false,
  icdCodes: [],
  noCodeAvailable: false,
  icdCodeDescription: null,
  reviewReasons: [],
  otherReviewReason: null,
  referenceMaterialChecklist: [],
  otherReferenceMaterial: null,
  additionalInformation: null,
  committeeQuestion: null,
  recordStatus: null,
  mrtsReviewType: null,
  reviewMonth: null,
  reviewYear: null,
  comments: null,
};

export default initialValues;
