import { createSelector } from "reselect";
import { selectContent } from "./contentSelectors";
import {
  findById,
  findMultipleByUniqueID,
  findActiveMultipleByUniqueID,
} from "./helperFunctions";

const selectFacilities = selectContent("Facility");
const selectMidlevels = selectContent("Midlevel");
const selectProviders = selectContent("Provider");
const selectContacts = selectContent("Contact");
const selectUsers = selectContent("User");

const selectActiveFacilities = () =>
  createSelector(selectFacilities, (facilities = []) =>
    facilities.filter((facility) => facility.Active)
  );

const selectProviderByID = (ID) =>
  createSelector(selectProviders, (providers) => findById(providers, ID));

const selectMidlevelByID = (ID) =>
  createSelector(selectMidlevels, (midlevels) => findById(midlevels, ID));

const selectFacilityByID = (ID) =>
  createSelector(selectFacilities, (facilities) => findById(facilities, ID));

const selectAvailableFacilitiesByMidlevelID = (midlevelID) =>
  createSelector(selectFacilities, (facilities) =>
    facilities.filter(
      (facility) =>
        !facility.Midlevel.some(
          (midlevelReference) =>
            Number(midlevelReference.ID) === Number(midlevelID)
        )
    )
  );

const selectAvailableFacilitiesByProviderID = (providerID) =>
  createSelector(selectFacilities, (facilities) =>
    facilities.filter(
      (facility) =>
        !facility.Provider.some(
          (providerReference) =>
            Number(providerReference.ID) === Number(providerID)
        )
    )
  );

const selectMidlevelsByFacilityID = (facilityID) =>
  createSelector(selectMidlevels, (midlevels) =>
    midlevels.filter((midlevel) =>
      midlevel.Facility.some(
        (facilityReference) =>
          Number(facilityReference.ID) === Number(facilityID)
      )
    )
  );

const selectAvailableMidlevelsByFacilityID = (facilityID) =>
  createSelector(selectMidlevels, (midlevels) =>
    midlevels.filter(
      (midlevel) =>
        !midlevel.Facility.some(
          (facilityReference) =>
            Number(facilityReference.ID) === Number(facilityID)
        )
    )
  );

const selectProvidersByFacilityID = (facilityID) =>
  createSelector(selectProviders, (providers) =>
    providers.filter((provider) =>
      provider.Facility.some(
        (facilityReference) =>
          Number(facilityReference.ID) === Number(facilityID)
      )
    )
  );

const selectAvailableProvidersByFacilityID = (facilityID) =>
  createSelector(selectProviders, (providers) =>
    providers.filter(
      (provider) =>
        !provider.Facility.some(
          (facilityReference) =>
            Number(facilityReference.ID) === Number(facilityID)
        )
    )
  );

const selectContactsByFacilityID = (facilityID) =>
  createSelector(selectContacts, (contacts) =>
    contacts.filter(
      (contact) => Number(contact.FacilityID) === Number(facilityID)
    )
  );

const selectActiveUserByPermissionID = (permissionID) =>
  createSelector(selectUsers, (user) =>
    findActiveMultipleByUniqueID(user, permissionID, "PermissionID")
  );

const selectUserByPermissionID = (permissionID) =>
  createSelector(selectUsers, (user) =>
    findMultipleByUniqueID(user, permissionID, "PermissionID")
  );

const selectUsersByFacilityID = (facilityID) =>
  createSelector(selectUsers, (users) =>
    users.filter((user) => Number(user.FacilityID) === Number(facilityID))
  );

export {
  selectFacilities,
  selectActiveFacilities,
  selectMidlevels,
  selectProviders,
  selectUsers,
  selectContacts,
  selectFacilityByID,
  selectMidlevelByID,
  selectProviderByID,
  selectAvailableFacilitiesByMidlevelID,
  selectAvailableFacilitiesByProviderID,
  selectMidlevelsByFacilityID,
  selectAvailableMidlevelsByFacilityID,
  selectProvidersByFacilityID,
  selectAvailableProvidersByFacilityID,
  selectContactsByFacilityID,
  selectActiveUserByPermissionID,
  selectUserByPermissionID,
  selectUsersByFacilityID,
};
