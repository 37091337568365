import React, { useState } from "react";
import Header from "./Header";
import Panels from "./Panels";

const RNTabs = (props) => {
  const { record } = props;
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [selectedTool, setSelectedTool] = useState("Abbreviations");

  return (
    <>
      <div className={"pt-3 mx-5"}>
        <Header
          record={record}
          selectedTool={selectedTool}
          setSelectedTool={setSelectedTool}
          isFormDirty={isFormDirty}
        />
      </div>
      <div className={"row"}>
        <div className={"col"}>
          <Panels
            {...props}
            record={record}
            selectedTool={selectedTool}
            setSelectedTool={setSelectedTool}
            isFormDirty={isFormDirty}
            setIsFormDirty={setIsFormDirty}
          />
        </div>
      </div>
    </>
  );
};

export default RNTabs;
