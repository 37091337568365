import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import { facilitator } from "src/utilities/rbacFunctions";
import { globalVariables } from "src/components/App";

const ViewDirectReviewComments = ({
  directReviewComments = {},
  peerReviewNotes = "",
}) => {
  const classes = useStyles();
  const { roles } = useContext(globalVariables);
  return (
    <>
      {Object.entries(directReviewComments).map(
        ([key, value]) =>
          value && (
            <>
              <Grid item xs={12}>
                <Typography variant={"h6"}>{cases[key].title}</Typography>
              </Grid>
              <Grid item className={classes.gridItem} xs={12}>
                <DialogContentText>{value}</DialogContentText>
              </Grid>
            </>
          )
      )}
      <Grid
        item
        container
        className={classes.gridContainer}
        hidden={facilitator(roles)}
      >
        <Grid item xs={12}>
          <Typography variant={"h6"}>Peer Review Notes</Typography>
        </Grid>
        <Grid item className={classes.gridItem} xs={12}>
          <DialogContentText
            style={{ whiteSpace: "pre-wrap", textAlign: "left" }}
          >
            {peerReviewNotes}
          </DialogContentText>
        </Grid>
      </Grid>
    </>
  );
};

const cases = {
  facilitatorOne: { title: "Original Facilitator Comments:" },
  facilitatorTwo: { title: "Second Facilitator Comments:" },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    border: "1px solid grey",
    display: "grid",
    marginBottom: 12,
  },
  gridItem: {
    border: "1px solid grey",
    display: "grid",
  },
}));

export default ViewDirectReviewComments;
