import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificDischargeInstructionsMedicalTerminologyChart = (
  props
) => {
  return (
    <>
      <ChartCard
        {...props}
        title={
          "Facility-Specific: Discharge Instructions - Medical Terminology"
        }
        xAxisLabel={"Discharge Instructions - Medical Terminology"}
        yAxisLabel={"Number of Records"}
        figureText={
          "Facility-Specific: Discharge Instructions - Medical Terminology"
        }
      />
    </>
  );
};

export default FacilitySpecificDischargeInstructionsMedicalTerminologyChart;
