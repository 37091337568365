import React from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import {
  canAccessAdministration,
  canAddProvider,
} from "src/utilities/rbacFunctions";
import {
  selectProviders,
  selectProvidersByFacilityID,
} from "src/redux/selectors";
import { ProviderActions } from "src/redux/reducers/PersonnelManagement/Provider";
import CreateProviderDialog from "./CreateProviderDialog";
import UpdateProviderDialog from "./UpdateProviderDialog";
import MergeDialog from "./MergeDialog";
import LinkProviderToFacilityDialog from "./LinkProviderToFacilityDialog";
import TablePageCard from "src/components/individual/TablePageCard";
import ActionsButton from "./ActionsButton";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { globalVariables } from "src/components/App";

const Provider = (props) => {
  const [createProviderDialogVisible, setCreateProviderDialogVisible] =
    React.useState(false);
  const [updateProviderDialogVisible, setUpdateProviderDialogVisible] =
    React.useState(false);
  const [mergeDialogVisible, setMergeDialogVisible] = React.useState(false);
  const [providerID, setProviderID] = React.useState(null);
  const [
    linkProviderToFacilityDialogVisible,
    setLinkProviderToFacilityDialogVisible,
  ] = React.useState(false);
  const { data: userData } = React.useContext(globalVariables);
  const Providers = useSelector(
    userData?.current?.FacilityID
      ? selectProvidersByFacilityID(userData.current.FacilityID)
      : selectProviders
  );

  const Loaded = useSelector((state) => state.Loaded);

  useEffectOnce(() => {
    Loaded.Provider === false && props.dispatch(ProviderActions.BatchAdd());
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
  });

  const data = Providers.map((provider, i) => {
    return {
      archiProviderID: provider.ARCHIProviderID,
      firstName: provider.FirstName,
      lastName: provider.LastName,
      emailAddress: provider.EmailAddress,
      degree: provider.Degree,
      active: provider.Active ? "Active" : "Inactive",
      actions: (
        <ActionsButton
          id={i}
          spawnUpdateProviderDialog={() => {
            setProviderID(provider.ID);
            setUpdateProviderDialogVisible(true);
          }}
          spawnLinkProviderToFacilityDialog={() => {
            setProviderID(provider.ID);
            setLinkProviderToFacilityDialogVisible(true);
          }}
        />
      ),
    };
  });

  return (
    <>
      {createProviderDialogVisible && (
        <CreateProviderDialog
          {...props}
          dialogVisible={createProviderDialogVisible}
          setDialogVisible={() => {
            setCreateProviderDialogVisible(false);
          }}
        />
      )}
      <MergeDialog open={mergeDialogVisible} setOpen={setMergeDialogVisible} />
      {updateProviderDialogVisible && (
        <UpdateProviderDialog
          {...props}
          dialogVisible={updateProviderDialogVisible}
          providerID={providerID}
          facilityID={userData.current.FacilityID}
          setDialogVisible={() => {
            setUpdateProviderDialogVisible(false);
          }}
        />
      )}
      {linkProviderToFacilityDialogVisible && (
        <LinkProviderToFacilityDialog
          {...props}
          dialogVisible={linkProviderToFacilityDialogVisible}
          providerID={providerID}
          setDialogVisible={() => {
            setLinkProviderToFacilityDialogVisible(false);
          }}
        />
      )}
      <TablePageCard
        title={"Providers"}
        table={{ columns, data, options }}
        button={{
          isRendered: canAddProvider(props.roles),
          label: "New Provider",
          handleClick: () => setCreateProviderDialogVisible(true),
        }}
        secondaryButton={{
          isRendered: canAccessAdministration(props.roles),
          label: "Merge Providers",
          handleClick: () => setMergeDialogVisible(true),
        }}
      />
    </>
  );
};

const columns = [
  {
    name: "archiProviderID",
    label: "ARCHI Provider ID",
  },
  {
    name: "firstName",
    label: "First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
  },
  {
    name: "emailAddress",
    label: "E-Mail Address",
  },
  {
    name: "degree",
    label: "Degree",
  },
  {
    name: "active",
    label: "Active",
  },
  {
    name: "actions",
    label: "Actions",
    options: {
      filter: false,
      sort: false,
      download: false,
      print: false,
    },
  },
];

const options = {
  filterType: "multiselect",
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
};

export default Provider;
