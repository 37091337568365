import React from "react";
import checkForKey from "./checkForKey";

const Section = ({ Report, reportKey, render, values }) => {
  return (
    checkForKey(reportKey, values) &&
    Report.Data[reportKey] !== undefined && (
      <div className={"section-page"} style={{ pageBreakBefore: "always" }}>
        {<>{render}</>}
      </div>
    )
  );
};

export default Section;
