const initialValues = {
  archiFacilityId: null,
  dateRange: {
    type: null,
  },
  chartType: null,
  displayOptions: {
    groupBy: null,
    displayType: "nameAndId",
    sections: ["text", "table"],
  },
  programOverviewSelected: [],
  checklistReports: [],
  outcomeReports: [],
  patientTypeReports: [],
  recommendationReports: [],
  template: null,
  templateName: null,
};

export default initialValues;
