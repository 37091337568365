import React from "react";
import AccordionContainer from "./AccordionContainer";

const ReportSelectionSection = (props) => {
  return (
    <>
      <div className={"row"}>
        <div className={"col text-left h4 ml-2"}>Report Selection</div>
      </div>
      <AccordionContainer {...props} />
    </>
  );
};

export default ReportSelectionSection;
