const patientSafetyFindings = (disableFirstQuestion) => ({
  label: "Patient Safety Findings",
  render: { type: "Title" },
  items: [
    surgicalTimeout(disableFirstQuestion),
    medicalReconciliation(),
    dischargeTransferDocs(),
    ethicsIssue(),
  ],
});

const surgicalTimeout = (disableFirstQuestion) => ({
  name: "surgicalTimeout",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "N/A"],
    hide: disableFirstQuestion,
  },
  itemsDependOn: [{ name: "surgicalTimeout", is: "Yes" }],
  items: [shaved(), clipped()],
});

const shaved = () => ({
  name: "shaved",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "N/A"],
  },
  itemsDependOn: [{ name: "shaved", is: "Yes" }],
  items: [shavedComment()],
});

const shavedComment = () => ({
  name: "shavedComment",
  render: {
    type: "TextBox",
    required: true,
    hideDependsOnPDF: [{ name: "shavedComment", is: "" }],
  },
});

const clipped = () => ({
  name: "clipped",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "N/A"],
  },
  itemsDependOn: [{ name: "clipped", is: "Yes" }],
  items: [clippedComment()],
});

const clippedComment = () => ({
  name: "clippedComment",
  render: {
    type: "TextBox",
    required: true,
    hideDependsOnPDF: [{ name: "clippedComment", is: "" }],
  },
});

const medicalReconciliation = () => ({
  name: "medicalReconciliation",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Expired", "Incomplete"],
  },
});

const dischargeTransferDocs = () => ({
  name: "dischargeTransferDocs",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Expired", "AMA", "Admitted"],
  },
  itemsDependOn: [{ name: "dischargeTransferDocs", is: "No" }],
  items: [swingBed()],
});

const swingBed = () => ({
  name: "swingBed",
  render: {
    type: "Checkbox",
    hideDependsOnPDF: [{ name: "swingBed", is: "false" }],
  },
});

const ethicsIssue = () => ({
  name: "ethicsIssue",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown"],
  },
});

export default patientSafetyFindings;
