import React from "react";
// ui
import { makeStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  ButtonGroup,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "react-final-form";
import PersonnelInvolved from "../../ReusableSections/PersonnelInvolved";
import ReviewInformation from "../../ReusableSections/ReviewInformation";
import PatientInformation from "../../ReusableSections/PatientInformation";
import ReviewReasons from "../../ReusableSections/ReviewReasons";
import ReferenceMaterial from "../../ReusableSections/ReferenceMaterial";
import CustomSubmitButton from "src/components/pages/MRTSRecords/MRTSRecordDialog/CustomSubmitButton";

// data
import mappers from "../../mappers";
import mutators from "../../mutators";
import { RecordActions } from "src/redux/reducers/Record";
import { useSelector } from "react-redux";
import {
  selectLoaded,
  selectRecordByID,
  selectContent,
} from "src/redux/selectors";
import {
  selectFacilities,
  selectContacts,
  selectProviders,
  selectMidlevels,
} from "src/redux/selectors/personnelSelectors";
import { useEffectOnce } from "react-use";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { ContactActions } from "src/redux/reducers/PersonnelManagement/Contact";
import { MidlevelActions } from "src/redux/reducers/PersonnelManagement/Midlevel";
import { ProviderActions } from "src/redux/reducers/PersonnelManagement/Provider";
import { CircularProgress } from "@mui/material";
import capSentence from "src/utilities/capSentence";
import getRecordComment from "src/components/pages/MRTSRecords/MRTSRecordDialog/getRecordComment";
import C from "src/utilities/constants";
import {
  convertDateToLocal,
  formatDateTime,
} from "src/utilities/dateFunctions";

//validation
import validationSchema from "../../validationSchema";
import { makeValidate } from "mui-rff";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateUnapprovedFacilityDialog = (props) => {
  const Record = useSelector(selectRecordByID(props.recordID));
  const Facilities = useSelector(selectFacilities);
  const Contacts = useSelector(selectContacts);
  const Providers = useSelector(selectProviders);
  const Midlevels = useSelector(selectMidlevels);
  const Committees = useSelector(selectContent("Committee"));
  const ReferenceMaterials = useSelector(selectContent("ReferenceMaterial"));
  const ReviewReasonOptions = useSelector(selectContent("ReviewReason"));
  const Loaded = useSelector(selectLoaded);
  const classes = useStyles();
  const render =
    Record !== undefined &&
    Facilities !== undefined &&
    Contacts !== undefined &&
    Providers !== undefined &&
    Midlevels !== undefined &&
    Committees !== undefined &&
    ReferenceMaterials !== undefined &&
    ReviewReasonOptions !== undefined &&
    Loaded !== undefined;

  React.useEffect(() => {
    if (props.dialogVisible && Record.Contact === undefined) {
      props.dispatch(RecordActions.LoadData(props.recordID, "ID"));
    }
  }, [props.dialogVisible]);

  const Facility = Facilities.find((f) => f.ID === Record.FacilityID);
  const Contact = Contacts.find((c) => c.ID === Record.ContactID);
  const Provider = Providers.find((p) => p.ID === Record.ProviderID);
  const Midlevel =
    Record.Midlevel
      ? Midlevels.find((m) => m.ID === Record.MidlevelProviderID)
      : null;
  const Committee = Committees.find((c) => c.ID === Record.CommitteeID);

  //Review Reason Other
  let otherReviewReasonInitialValue = null;
  const reviewReasonOtherID = ReviewReasonOptions.find(
    (r) => r.Reason.split(" ")[0] === "Other"
  ).ID;
  const initialReviewReasonIDs =
    Record.ReviewReason !== undefined
      ? Record.ReviewReason.map((r) => r.ID)
      : [];

  if (initialReviewReasonIDs.includes(reviewReasonOtherID)) {
    otherReviewReasonInitialValue = Record.ReviewReason.find(
      (r) => r.ID === reviewReasonOtherID
    ).recordReviewReason.OtherReason;
  }

  //Reference Material Other
  let otherReferenceMaterialInitialValue = null;
  const initialReferenceMaterialIDs =
    Record !== undefined && Record.ReferenceMaterial !== undefined
      ? Record.ReferenceMaterial.map((r) => r.ID)
      : [];
  const referenceMaterialOtherID = ReferenceMaterials.find(
    (r) => r.ReferenceMaterial === "Other"
  ).ID;

  if (initialReferenceMaterialIDs.includes(referenceMaterialOtherID)) {
    otherReferenceMaterialInitialValue = Record.ReferenceMaterial.find(
      (r) => r.ID === referenceMaterialOtherID
    ).recordReferenceMaterial.OtherReference;
  }

  const initialValues = {
    facility: Facility,
    contact: Contact,
    provider: Provider,
    midlevel: Midlevel,
    reviewCategoryID: Record.ReviewCategoryID,
    reviewTypeID: Record.ReviewTypeID,
    arrivalDate: formatDateTime(Record.ArrivalDate),
    dischargeDate: formatDateTime(Record.DischargeDate),
    facilityMedicalRecordNumber: Record.OriginalFacilityRecordID,
    patientAge: Record.PatientAge,
    ageIncrement: Record.AgeIncrementID,
    patientOriginationID: Record.PatientSourceID,
    patientRace: Record.PatientRaceID,
    patientGender: Record.PatientGender,
    committee: Committee,
    committeeOther: Record.CommitteeOtherDesc,
    historyAndPhysicalOnChart: Record.HistoryPreSurgery,
    icdCodes:
      Record !== undefined && Record.RecordDiagnosis !== undefined
        ? Record.RecordDiagnosis.map((d) => {
          return mappers.icdCode(d);
        })
        : [],
    noCodeAvailable: false,
    icdCodeDescription: null,
    reviewReasons:
      Record !== undefined && Record.ReviewReason !== undefined
        ? Record.ReviewReason.map((r) => r.ID)
        : [],
    otherReviewReason: otherReviewReasonInitialValue,
    referenceMaterialChecklist: initialReferenceMaterialIDs,
    otherReferenceMaterial: otherReferenceMaterialInitialValue,
    additionalInformation:
      Record !== undefined && Record.RecordComment !== undefined
        ? getRecordComment(
          Record.RecordComment,
          C.COMMENT_TYPE_ID_ADDITIONAL_INFORMATION
        )
        : "",
    committeeQuestion:
      Record !== undefined && Record.RecordComment !== undefined
        ? getRecordComment(
          Record.RecordComment,
          C.COMMENT_TYPE_ID_COMMITTEE_QUESTION
        )
        : "",
  };

  useEffectOnce(() => {
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
    Loaded.Contact === false && props.dispatch(ContactActions.BatchAdd());
    Loaded.Midlevel === false && props.dispatch(MidlevelActions.BatchAdd());
    Loaded.Provider === false && props.dispatch(ProviderActions.BatchAdd());
  });

  const updateMRTS = (values) => {
    const referenceMaterialOtherID = ReferenceMaterials.find(
      (r) => r.ReferenceMaterial === "Other"
    ).ID;
    props.dispatch(
      RecordActions.UpdateUnapproved({
        FacilityID: values.facility.ID,
        ContactID: values.contact.ID,
        ProviderID: values.provider.ID,
        RecordStatusID: 1,
        OriginalFacilityRecordID: values.facilityMedicalRecordNumber,
        CommitteeID: values.committee.ID,
        CommitteeOtherDesc:
          values.committeeOther !== null ? values.committeeOther : "",
        HistoryPreSurgery: values.historyAndPhysicalOnChart,
        MidlevelProviderID:
          values.midlevel !== null
            ? values.midlevel.ID
            : null,
        PatientSourceID: values.patientOriginationID,
        ReviewCategoryID: values.reviewCategoryID,
        ReviewTypeID: values.reviewTypeID,
        ARCHIRecordID: "",
        PatientAge: values.patientAge,
        AgeIncrementID: values.ageIncrement,
        PatientGender: values.patientGender,
        PatientRaceID: values.patientRace,
        PatientSourceOther: "",
        ArrivalDate: convertDateToLocal(values.arrivalDate),
        DischargeDate: convertDateToLocal(values.dischargeDate),
        ReviewReason: values.reviewReasons.map((r) => {
          return {
            ID: r,
            recordReviewReason: {
              ReviewReasonID: r,
              OtherReason:
                values.otherReviewReason !== null && values.otherReviewReason,
            },
          };
        }),
        ReferenceMaterial: values.referenceMaterialChecklist.map((id) => {
          if (id === referenceMaterialOtherID) {
            return {
              ID: id,
              recordReferenceMaterial: {
                ReferenceMaterialID: id,
                OtherReference: capSentence(values.otherReferenceMaterial),
              },
            };
          } else {
            return {
              ID: id,
              recordReferenceMaterial: {
                ReferenceMaterialID: id,
                OtherReference: null,
              },
            };
          }
        }),
        RecordDiagnosis: values.icdCodes.map((i) => {
          return mappers.recordDiagnosis(i);
        }),
        OtherReason: values.otherReviewReason,
        RecordComment: [
          { TypeID: 2, Description: capSentence(values.additionalInformation) },
          { TypeID: 3, Description: capSentence(values.committeeQuestion) },
        ],
        ApprovalStatus: 1,
        Signature: null,
        ID: props.recordID,
      })
    );
  };

  return (
    <Form
      onSubmit={updateMRTS}
      initialValues={initialValues}
      keepDirtyOnReinitialize={true}
      validate={makeValidate(validationSchema)}
      mutators={mutators}
      subscription={{
        form: false,
        pristine: false,
        submitting: false,
        values: false,
        handleSubmit: true,
      }}
      render={({ handleSubmit }) => (
        <form>
          <Dialog
            fullScreen
            open={props.dialogVisible}
            onClose={props.setDialogVisible}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    props.setDialogVisible();
                  }}
                  aria-label="close"
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Update MRTS
                </Typography>
                <CustomSubmitButton
                  handleSubmit={handleSubmit}
                  label={"Update"}
                />
              </Toolbar>
            </AppBar>
            {render ? (
              <>
                <div className={"container-fluid"}>
                  <div className={"row py-2"}>
                    <div className={"col"}>
                      <ButtonGroup
                        color="primary"
                        aria-label="outlined primary button group"
                      >
                        <Button
                          onClick={() =>
                            document
                              .getElementById("personnel-involved-title")
                              .scrollIntoView()
                          }
                        >
                          Personnel Involved
                        </Button>
                        <Button
                          onClick={() =>
                            document
                              .getElementById("review-information-title")
                              .scrollIntoView()
                          }
                        >
                          Review Information
                        </Button>
                        <Button
                          onClick={() =>
                            document
                              .getElementById("patient-information-title")
                              .scrollIntoView()
                          }
                        >
                          Patient Information
                        </Button>
                        <Button
                          onClick={() =>
                            document
                              .getElementById("review-reasons-title")
                              .scrollIntoView()
                          }
                        >
                          Review Reasons
                        </Button>
                        <Button
                          onClick={() =>
                            document
                              .getElementById("reference-material-title")
                              .scrollIntoView()
                          }
                        >
                          Reference Material
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    maxHeight: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <PersonnelInvolved {...props} classes={classes} />
                  <ReviewInformation {...props} classes={classes} />
                  <PatientInformation {...props} classes={classes} />
                  <ReviewReasons {...props} classes={classes} />
                  <ReferenceMaterial {...props} classes={classes} />
                </div>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={"5rem"} />
              </div>
            )}
          </Dialog>
        </form>
      )}
    />
  );
};

export default UpdateUnapprovedFacilityDialog;
