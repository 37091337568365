import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificPatientDispositionChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Patient Disposition"}
        xAxisLabel={"Patient Disposition"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: Patient Disposition"}
      />
    </>
  );
};

export default FacilitySpecificPatientDispositionChart;
