import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import rnStageTool from "./rnStageTool";
import format from "./formatData";

const saveRecordReview = async (recordID, actionStage, values, apiResponse) => {
  const stage = rnStageTool(apiResponse);
  const updatedChecklist = format.Checklist(values, apiResponse);
  const updatedRNComments = format.Comments(values, apiResponse);
  const updatedRNOutcomes = format.Outcomes(values, apiResponse);
  const updatedDirectReviewValues = format.DirectReviewComments(
    values,
    apiResponse
  );
  const { data: contents } = await apiService.post(
    `${apiUrl}${stage.saveAs}/update`,
    {
      ...apiResponse,
      [stage.saveTo]: {
        Checklist: updatedChecklist,
        RNComments: updatedRNComments,
        RNOutcomes: updatedRNOutcomes,
        TextStages: values.textStages,
      },
      DirectReviewComments: updatedDirectReviewValues,
      RecordID: recordID,
      Action: actionStage,
    }
  );
  return contents;
};
export default saveRecordReview;
