import { sortAlphabetically } from "src/utilities/arrayMethods";

const labelTranslations = {
  abbreviationUsed: "Abbreviation Used",
  EHREntries: "Electronic Health Record (EHR) Entries",
  EHREntryCloning: 'Cloning Documentation ("Cut and Paste")',
  EHREntryDiscrepencies: "Discrepancies in EHR Entries",
  EHREntryFreeTextExpansion: 'Need for "Free Text" Expansion',
  advancedDirectives: "Advanced Directives",
  clinicalDecisionMaking: "Clinical Decision Making",
  documentation: "Documentation",
  documentationInsufficient: "Documentation Insufficient",
  documentationLegibilityIssues: "Documentation Legibility Issues",
  lengthOfStayInappropriate: "Length of Stay Inappropriate",
  lengthOfStayInappropriateBriefStay:
    "Length of Stay Inappropriate - Brief Stay",
  lengthOfStayInappropriateExtendedStay:
    "Length of Stay Inappropriate - Extended Stay",
  medicationReconciliation: "Medication Reconciliation",
  otherPossibleImprovement: "Other",
  therapeuticChoices: "Therapeutic Choices",
  therapeuticChoicesDiagnostics: "Therapeutic Choices - Diagnostics",
  therapeuticChoicesIntervention: "Therapeutic Choices - Intervention",
  therapeuticChoicesMedications: "Therapeutic Choices - Medications",
  lessThanFiveHours: "Less Than Five Hours",
  lessThanOneDay: "Less Than One Day",
  lessThanTwelveHours: "Less Than Twelve Hours",
  lessThanTwoDays: "Less Than Two Days",
  moreThanTwoDays: "More Than Two Days",
  lessThanOneYear: "Less than 1 year",
  betweenOneAndTenYears: "1-10 years",
  betweenElevenAndTwentyYears: "11-20 years",
  betweenTwentyOneAndThirtyYears: "21-30 years",
  betweenThirtyOneAndFortyYears: "31-40 years",
  betweenFortyOneAndFiftyYears: "41-50 years",
  betweenFiftyOneAndSixtyYears: "51-60 years",
  betweenSixtyOneAndSeventyYears: "61-70 years",
  betweenSeventyOneAndEightyYears: "71-80 years",
  betweenEightyOneAndEightyNineYears: "81-89 years",
  greaterThanEightyNineYears: "89+ years",
};

const ChartDataBuilder = (props, reportKey, labelKeys, isAnObject = false) => {
  let labels = [];
  const data = [];
  let rows = [];
  let datasets = [];
  let tableRows = [];
  const sortedValues = [...props.Report.Data[reportKey]].sort((a, b) =>
    sortAlphabetically(a.sortdate, b.sortdate)
  );

  switch (props.values.displayOptions.groupBy) {
    case "monthly":
    case "quarterly":
    case "yearly": {
      if (isAnObject) {
        const labelsSet = new Set();
        const categoriesSet = new Set();
        props.Report.Data[reportKey].forEach((slice) => {
          const tableRow = {};
          const { sortdate, timeframe, total, ...sliceCopy } = slice;
          Object.keys(sliceCopy).forEach((key) => {
            const reportSlice = slice[key];
            const label =
              labelTranslations[key] === undefined
                ? key
                : labelTranslations[key];

            categoriesSet.add(label);

            if (reportSlice > 0) {
              labelsSet.add(timeframe);
              data.push({
                label,
                total: reportSlice,
                sortdate,
                timeframe,
              });
            }

            tableRow[label] = reportSlice;

            rows.push({
              label,
              value: reportSlice,
              sortdate,
              timeframe,
            });
          });
          if (timeframe) {
            tableRow["Timeframe"] = timeframe;
          }
          if (reportKey.includes("AbbreviationUse")) {
            tableRow["Total"] = total;
          }
          tableRows.push(tableRow);
        });

        const intermediateLabels = [...labelsSet].sort();
        const categories = [...categoriesSet];

        labels = intermediateLabels;
        const mat = new Array(categories.length);
        for (let i = 0; i < mat.length; ++i) {
          mat[i] = new Array(intermediateLabels.length);
          mat[i].fill(null);
        }

        props.Report.Data[reportKey].forEach((slice) => {
          const { sortdate, timeframe, total, ...sliceCopy } = slice;
          labelsSet.add(timeframe);
          Object.keys(sliceCopy).forEach((key) => {
            const value = slice[key];

            const label =
              labelTranslations[key] === undefined
                ? key
                : labelTranslations[key];

            const categoryIndex = categories.indexOf(label);
            const timeframeIndex = intermediateLabels.indexOf(timeframe);

            if (timeframeIndex > -1) {
              mat[categoryIndex][timeframeIndex] =
                mat[categoryIndex][timeframeIndex] === null
                  ? value
                  : value + mat[categoryIndex][timeframeIndex];
            }
          });
        });

        // Combines the categories and data (from mat)
        datasets = categories.map((category) => {
          return {
            label: category,
            data: mat[categories.indexOf(category)],
          };
        });
      } else {
        const labelsSet = new Set();
        const categoriesSet = new Set();

        // Build initial data, labels and categories
        sortedValues.forEach((value) => {
          if (value[labelKeys[0]] !== null) {
            data.push(value);
            // Add timeframe to labels
            labelsSet.add(value.timeframe);
          }

          let temp =
            labelKeys.length === 2
              ? `${value[labelKeys[0]]} - ${value[labelKeys[1]]}`
              : value[labelKeys[0]];

          // add temp answer to categories (for datasets)
          categoriesSet.add(temp == null ? "No Answer" : temp);
        });

        const intermediateLabels = [...labelsSet];
        const categories = [...categoriesSet];

        // Initialize values matrix (for datasets)
        const mat = new Array(categories.length);
        for (let i = 0; i < mat.length; ++i) {
          mat[i] = new Array(intermediateLabels.length);
          mat[i].fill(null);
        }

        // Loop over the sorted values again to sum (into mat) by category and timeframe
        // NOTE: this sums the actual (vs initialized) values for datasets
        sortedValues.forEach((value) => {
          let category =
            labelKeys.length === 2
              ? `${value[labelKeys[0]]} - ${value[labelKeys[1]]}`
              : value[labelKeys[0]];

          category = category == null ? "No Answer" : category;

          const categoryIndex = categories.indexOf(category);
          const timeframeIndex = intermediateLabels.indexOf(value.timeframe);

          mat[categoryIndex][timeframeIndex] =
            mat[categoryIndex][timeframeIndex] === null
              ? value.total
              : value.total + mat[categoryIndex][timeframeIndex];
        });

        // Combines the categories and data (from mat)
        datasets = categories.map((category) => {
          return {
            label: category,
            data: mat[categories.indexOf(category)],
          };
        });
        // comes directly from labelsSet (e.g. [...labelsSet])
        labels = intermediateLabels;
        // finally, create the table rows from data
        rows = data.map((value) => {
          return {
            label:
              labelKeys.length === 2
                ? `${value[labelKeys[0]]} - ${value[labelKeys[1]]}`
                : value[labelKeys[0]],
            timeframe: value.timeframe,
            value: value.total,
            archiProviderName: value.archiProviderName,
            archiProviderId: value.archiProviderId,
            archiProviderLabel: value.archiProviderLabel,
          };
        });
      }
      break;
    }
    default: {
      if (isAnObject) {
        Object.keys(sortedValues[0]).forEach((key) => {
          labels.push(
            labelTranslations[key] === null
              ? "No Answer"
              : labelTranslations[key]
          );
          data.push(sortedValues[0][key]);
        });
      } else {
        sortedValues.forEach((value) => {
          let label =
            labelKeys.length === 2
              ? `${value[labelKeys[0]]} - ${value[labelKeys[1]]}`
              : value[labelKeys[0]];

          label = label === null ? "No Answer" : label;

          const index = labels.indexOf(label);
          if (index >= 0) {
            data[index] += value.total;
          } else {
            labels.push(label);
            data.push(value.total);
          }
        });
      }
      break;
    }
  }
  return {
    labels,
    data,
    rows,
    datasets,
    tableRows,
  };
};

export default ChartDataBuilder;
