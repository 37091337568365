import _ from "lodash";

const MeetingID = (apiResponse) => _.get(apiResponse, `MeetingID`, "");
const TextStages = (apiResponse) =>
  _.get(apiResponse, `ApprovalData.TextStages`, []);

const DirectReviewComments = (apiResponse) =>
  _.get(apiResponse, `DirectReviewComments`)
    ? _.get(apiResponse, `DirectReviewComments`)
    : {
        facilitatorTwo: "",
        facilitatorOne: "",
      };
const PeerReviewNotes = (apiResponse) => {
  return _.get(apiResponse, `PeerReviewNotes`)
    ? _.get(apiResponse, `PeerReviewNotes`)
    : "";
};
const SideBarRequested = (apiResponse) =>
  _.get(apiResponse, `SidebarRequested`, false);
const CommitteeReviewRequested = (apiResponse) =>
  _.get(apiResponse, `CommitteeReviewRequested`, false);
const ReviewStatus = (apiResponse) => _.get(apiResponse, `ReviewStatus`, "");

const ChecklistO = (apiResponse) =>
  _.get(apiResponse, `OriginalData.Checklist`, []);
const ChecklistF = (apiResponse) =>
  _.get(apiResponse, `FacilitatorData.Checklist`, []);
const ChecklistA = (apiResponse) =>
  _.get(apiResponse, `ApprovalData.Checklist`, []);

const OutcomesO = (apiResponse) =>
  _.get(apiResponse, `OriginalData.RNOutcomes`, {});
const OutcomesF = (apiResponse) =>
  _.get(apiResponse, `FacilitatorData.RNOutcomes`, {});
const OutcomesA = (apiResponse) =>
  _.get(apiResponse, `ApprovalData.RNOutcomes`, {});

const CommentsO = (apiResponse) =>
  _.get(apiResponse, `OriginalData.RNComments`, {});
const CommentsF = (apiResponse) =>
  _.get(apiResponse, `FacilitatorData.RNComments`, {});
const CommentsA = (apiResponse) =>
  _.get(apiResponse, `ApprovalData.RNComments`, {});

const MRTSReviewTypeID = (apiResponse) =>
  _.get(apiResponse, `OriginalData.MRTSReviewTypeID`, null);

const get = {
  ReviewStatus,
  ChecklistO,
  ChecklistF,
  ChecklistA,
  OutcomesO,
  OutcomesF,
  OutcomesA,
  CommentsO,
  CommentsF,
  CommentsA,
  MeetingID,
  DirectReviewComments,
  PeerReviewNotes,
  SideBarRequested,
  CommitteeReviewRequested,
  TextStages,
  MRTSReviewTypeID,
};

export default get;
