import React from "react";
import { MenuItem } from "@mui/material";
import { Select } from "mui-rff";

const monthOptions = [
  { name: "January", value: 0 },
  { name: "February", value: 1 },
  { name: "March", value: 2 },
  { name: "April", value: 3 },
  { name: "May", value: 4 },
  { name: "June", value: 5 },
  { name: "July", value: 6 },
  { name: "August", value: 7 },
  { name: "September", value: 8 },
  { name: "October", value: 9 },
  { name: "November", value: 10 },
  { name: "December", value: 11 },
];

const MonthlyRange = (props) => {
  return (
    <>
      <div className={"row pt-2 pr-2"}>
        <div className={"col"}>
          <Select
            name={"dateRange.range.startingMonth"}
            label={"Starting Month"}
            labelId="reports-starting-month-select-label"
            id="reports-starting-month-select"
            variant={"outlined"}
            required={props.values.dateRange.type === "monthly"}
            disabled={props.disabled}
            displayEmpty
          >
            <MenuItem value={null}>Select a Starting Month</MenuItem>
            {monthOptions.map((option) => (
              <MenuItem
                key={`reports-starting-month-select-${option.name}-${option.value}`}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={"col"}>
          <Select
            name={"dateRange.range.startingYear"}
            label={"Starting Year"}
            labelId="reports-starting-year-select-label"
            id="reports-starting-year-select"
            variant={"outlined"}
            required={props.values.dateRange.type === "monthly"}
            disabled={props.disabled}
            displayEmpty
          >
            <MenuItem value={null}>Select a Starting Year</MenuItem>
            {props.yearOptions.map((option) => (
              <MenuItem
                key={`reports-starting-year-select-${option.name}-${option.value}`}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={"row pt-2 pr-2"}>
        <div className={"col"}>
          <Select
            name={"dateRange.range.endingMonth"}
            label={"Ending Month"}
            labelId="reports-ending-month-select-label"
            id="reports-ending-month-select"
            variant={"outlined"}
            required={props.values.dateRange.type === "monthly"}
            disabled={props.disabled}
            displayEmpty
          >
            <MenuItem value={null}>Select an Ending Month</MenuItem>
            {monthOptions.map((option) => (
              <MenuItem
                key={`reports-ending-month-select-${option.name}-${option.value}`}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={"col"}>
          <Select
            label={"Ending Year"}
            name={"dateRange.range.endingYear"}
            labelId="reports-ending-year-select-label"
            id="reports-ending-year-select"
            variant={"outlined"}
            required={props.values.dateRange.type === "monthly"}
            disabled={props.disabled}
            displayEmpty
          >
            <MenuItem value={null}>Select an Ending Year</MenuItem>
            {props.yearOptions.map((option) => (
              <MenuItem
                key={`reports-ending-year-select-${option.name}-${option.value}`}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </>
  );
};

export default MonthlyRange;
