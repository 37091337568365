import React from "react";
import { Switches } from "mui-rff";

const Switch = ({ name, label }) => (
  <Switches
    name={`${name}Switch`}
    data={{
      label: label,
      value: false,
    }}
  />
);

export default Switch;
