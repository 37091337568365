import React, { useContext } from "react";
import PropTypes from "prop-types";
import RenderEachItem from "../RenderEachItem";
import MuiCheckbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Field, FormSpy } from "react-final-form";
import { recordReviewData } from "src/components/pages/MeetingManagement/RecordReview";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import blue from "@mui/material/colors/blue";

const Checkbox = ({ name, label, items, itemsDependOn, bitID, render }) => {
  const classes = useStyles();
  const apiResponse = useContext(recordReviewData);
  const { Improvements: oImprovementsValue } = _.get(
    apiResponse,
    `OriginalData.RNOutcomes`,
    0
  );
  const isOriginalChecked = 0 !== (oImprovementsValue & Math.pow(2, bitID));
  return (
    <>
      <div className={"d-flex text-left"}>
        <Field name={name}>
          {({ input: { value: improvementValue } }) => {
            const isChecked = 0 !== (improvementValue & Math.pow(2, bitID));
            const isValueDiff = isOriginalChecked !== isChecked;
            return (
              <FormControlLabel
                key={`Checkbox_FormControl_${name}`}
                control={
                  <FormSpy subscription={{ form: true }}>
                    {({ form }) => {
                      if (
                        items &&
                        items.some((item) => item.bitID !== undefined)
                      ) {
                        const isShouldBe = items.reduce(
                          (acc, item) =>
                            0 !== (improvementValue & Math.pow(2, item.bitID))
                              ? true
                              : acc,
                          false
                        );
                        if (isShouldBe !== isChecked) {
                          isShouldBe
                            ? form.change(name, improvementValue + 2 ** bitID)
                            : form.change(name, improvementValue - 2 ** bitID);
                        }
                      }
                      return (
                        <MuiCheckbox
                          key={`Checkbox_${name}`}
                          onClick={(e) => {
                            if (e.target.type === "checkbox") {
                              form.change(
                                name,
                                e.target.checked
                                  ? improvementValue + 2 ** bitID
                                  : improvementValue - 2 ** bitID
                              );
                            }
                          }}
                          checked={isChecked}
                          color={"primary"}
                          disabled={render.disabled}
                        />
                      );
                    }}
                  </FormSpy>
                }
                label={
                  <span
                    className={
                      isValueDiff
                        ? isChecked
                          ? classes.facilitatorValue
                          : classes.originalValue
                        : ""
                    }
                  >
                    {label}
                  </span>
                }
              />
            );
          }}
        </Field>
      </div>
      <RenderEachItem items={items} itemsDependOn={itemsDependOn} indent />
    </>
  );
};

export default Checkbox;

const useStyles = makeStyles(() => ({
  originalValue: {
    textDecoration: "line-through",
    color: blue[500],
  },
  facilitatorValue: {
    color: blue[700],
  },
}));

Checkbox.propTypes = {
  name: PropTypes.string,
  items: PropTypes.array,
  itemsVisible: PropTypes.bool,
};
