import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form } from "react-final-form";
import validateFinalForm from "src/utilities/validateFinalForm";
import mutators from "./mutators";
import validationSchema from "./validationSchema";
import EditTracking from "../MRTSRecordDialog/ReusableSections/EditTracking";
import { selectRecordByID } from "src/redux/selectors";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { RecordActions } from "src/redux/reducers/Record";
import { RecordTrackingActions } from "src/redux/reducers/RecordTracking";
import Custom from "src/components/individual/Custom";

const validate = validateFinalForm(validationSchema);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const EditTrackingDialog = (props) => {
  const Record = useSelector(selectRecordByID(props.recordID));
  const render = Record !== undefined && Record.RecordTracking !== undefined;
  const classes = useStyles();

  React.useEffect(() => {
    if (props.dialogVisible && !render) {
      props.dispatch(RecordActions.LoadData(props.recordID, "ID"));
    }
  }, [props.dialogVisible, render]);

  const initialValues = {
    recordStatus: Record.RecordStatusID,
    mrtsReviewType: Record.MRTSReviewTypeID,
    reviewMonth:
      Record !== undefined && Record.RecordTracking !== undefined
        ? Record.RecordTracking.ReviewMonth
        : null,
    reviewYear:
      Record !== undefined && Record.RecordTracking !== undefined
        ? Record.RecordTracking.ReviewYear
        : null,
    comments:
      Record !== undefined && Record.RecordTracking !== undefined
        ? Record.RecordTracking.Comments
        : null,
  };

  const editTracking = (values) => {
    props.dispatch(
      RecordTrackingActions.Update({
        Comments: values.comments || "",
        ReviewYear: values.reviewYear,
        ReviewMonth: values.reviewMonth,
        RecordStatusID: values.recordStatus,
        MRTSReviewTypeID: values.mrtsReviewType,
        RecordID: props.recordID,
      })
    );
  };

  return (
    <>
      {render && (
        <Form
          onSubmit={editTracking}
          initialValues={initialValues}
          validate={validate}
          mutators={mutators}
          render={({ form, submitting, values }) => (
            <>
              <form>
                <Dialog
                  fullWidth
                  maxWidth={"sm"}
                  open={props.dialogVisible}
                  onClose={() => {
                    props.setDialogVisible();
                    form.mutators.clearAll();
                  }}
                >
                  <DialogTitle>
                    Edit Tracking - ARCHI Record ID: {Record.ARCHIRecordID}
                  </DialogTitle>
                  <DialogContent>
                    {render ? (
                      <EditTracking
                        {...props}
                        classes={classes}
                        form={form}
                        values={values}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress size={"2.5rem"} />
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color={"secondary"}
                      variant={"contained"}
                      onClick={() => {
                        props.setDialogVisible();
                        form.mutators.clearAll();
                      }}
                      disabled={submitting}
                    >
                      Close
                    </Button>
                    <Custom.SubmitButton
                      color={"primary"}
                      variant={"contained"}
                      onClick={() => {
                        editTracking(values);
                        props.setDialogVisible();
                        form.mutators.clearAll();
                      }}
                      disabled={
                        values.recordStatus === null ||
                        values.mrtsReviewType === null ||
                        values.reviewMonth === null ||
                        values.reviewYear === null ||
                        submitting
                      }
                    >
                      Update
                    </Custom.SubmitButton>
                  </DialogActions>
                </Dialog>
              </form>
            </>
          )}
        />
      )}
    </>
  );
};

export default EditTrackingDialog;
