import React, { useState } from "react";
import GeneratePDF from "src/components/individual/GeneratePDF";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Grid,
} from "@mui/material";

import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import {selectRecordByID} from "src/redux/selectors";

const ViewWorksheetDialog = ({ isDialogVisible, onClose, recordID }) => {
  const record = useSelector(selectRecordByID(recordID))
  const [formValues, setFormValues] = useState({
    isBasicInformation: true,
    isDemographics: true,
    isRecordIndex: true,
  });

  const handleRadio = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <Dialog
      open={isDialogVisible}
      onClose={onClose}
      aria-labelledby="view-worksheet-dialog-title"
      aria-describedby="view-worksheet-dialog-description"
    >
      <DialogTitle>Generate Worksheet PDF - ARCHI Record ID: {record.ARCHIRecordID}</DialogTitle>

      <DialogContent>
        <Grid container direction={"column"} spacing={2}>
          {radioOptions.map(({ name, label }) => (
            <Grid item>
              <CustomRadios
                name={name}
                label={label}
                value={formValues[name]}
                handleRadio={handleRadio}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        {recordID && (
          <GeneratePDF
            recordID={recordID}
            RecordType={"Worksheet"}
            settings={Object.values(formValues)}
          >
            <Button variant={"contained"} color={"primary"}>
              View
            </Button>
          </GeneratePDF>
        )}
      </DialogActions>
    </Dialog>
  );
};

const CustomRadios = ({ name, value, label = "", handleRadio }) => {
  const handleOnChange = (e) => handleRadio(name, e.target.value === "true");
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup value={value} onChange={handleOnChange} row aria-label={name}>
        <FormControlLabel value={true} control={<Radio />} label="Yes" />
        <FormControlLabel value={false} control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
};

const radioOptions = [
  { name: "isBasicInformation", label: "Populate Basic Information?" },
  { name: "isDemographics", label: "Populate Demographic Information?" },
  { name: "isRecordIndex", label: "Populate Record Index Information?" },
];

export default ViewWorksheetDialog;
