import React from "react";
import { useSelector } from "react-redux";
import { Form } from "react-final-form";
import { RecordActions } from "src/redux/reducers/Record";
import { selectCommentByRecordID } from "src/redux/selectors";
import CommentSections from "./CommentSections";
import PeerReviewNotesSection from "./PeerReviewSections";
import Submitter from "../Submitter";

const Comments = (props) => {
  let comments = useSelector(selectCommentByRecordID(props.record.ID));

  const onSubmit = (results) => {
    const newComments = { ...results, RecordID: props.record.ID };
    results.ID
      ? props.dispatch(RecordActions.UpdateComments(newComments))
      : props.dispatch(RecordActions.CreateComments(newComments));
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={comments}
      subscription={{
        pristine: true,
        submitting: true,
        form: true,
        values: true,
        dirty: true,
      }}
      render={({ handleSubmit, pristine, form, submitting, values, dirty }) => {
        if (props.isFormDirty !== dirty) {
          props.setIsFormDirty(dirty);
        }
        return (
          <form onSubmit={handleSubmit}>
            <div className={"container-fluid border border-dark"}>
              <div className={"row"}>
                <PeerReviewNotesSection
                  pristine={pristine}
                  submitting={submitting}
                  form={form}
                />
                <CommentSections
                  reviewID={props.record.RecordTracking.MRTSReviewTypeID}
                />
              </div>
            </div>
            <Submitter values={values} onSubmit={onSubmit} />
          </form>
        );
      }}
    />
  );
};

export default Comments;
