import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ContentName: null,
  Contents: {},
};

const lastCreatedObjectSlice = createSlice({
  name: "lastCreatedObject",
  initialState,
  reducers: {
    set: (state, action) => {
      state.ContentName = action.payload.ContentName;
      state.Contents = action.payload.Contents;
    },
    reset: () => {
      return initialState;
    },
  },
});

const { set, reset } = lastCreatedObjectSlice.actions;

const Set = (contentName, contents) => async (dispatch) => {
  dispatch(set({ ContentName: contentName, Contents: contents }));
};

const Reset = () => async (dispatch) => {
  dispatch(reset());
};

export const LastCreatedObjectActions = {
  Set,
  Reset,
};

export default lastCreatedObjectSlice.reducer;
