const issuesForArchiToTrend = () => ({
  label: "Issues for ARCHI to Trend",
  render: {
    type: "Title",
    hideDependsOnPDF: [
      { name: "trendIdentified", is: "false" },
      { name: "rootCauseAnalysisRecommended", is: "false" },
      { name: "difficultyToObtainMentalHealthServices", is: "false" },
      { name: "handOff", is: "false" },
      { name: "authorityGradient", is: "false" },
      { name: "prematureClosure", is: "false" },
      { name: "trendSystemIssues", is: "false" },
      { name: "documentationIssues", is: "false" },
      { name: "noTrendIdentified", is: "false" },
    ],
  },
  items: [systemInterpersonal()],
});

const systemInterpersonal = () => ({
  label: "System/Interpersonal:",
  render: { type: "SubTitle" },
  items: [trendIdentified(), noTrendIdentified()],
});

const trendIdentified = () => ({
  label: "Trend Identified",
  render: { type: "Checkbox" },
  valueDependsOn: [
    { name: "rootCauseAnalysisRecommended", is: "true" },
    { name: "difficultyToObtainMentalHealthServices", is: "true" },
    { name: "handOff", is: "true" },
    { name: "authorityGradient", is: "true" },
    { name: "prematureClosure", is: "true" },
    { name: "trendSystemIssues", is: "true" },
    { name: "documentationIssues", is: "true" },
  ],
  items: [
    rootCauseAnalysisRecommended(),
    difficultyToObtainMentalHealthServices(),
    handOff(),
    authorityGradient(),
    prematureClosure(),
    trendSystemIssues(),
    documentationIssues(),
  ],
});

const rootCauseAnalysisRecommended = () => ({
  name: "rootCauseAnalysisRecommended",
  render: { type: "Checkbox" },
});

const difficultyToObtainMentalHealthServices = () => ({
  name: "difficultyToObtainMentalHealthServices",
  render: { type: "Checkbox" },
});

const handOff = () => ({
  name: "handOff",
  render: { type: "Checkbox" },
});

const authorityGradient = () => ({
  name: "authorityGradient",
  render: { type: "Checkbox" },
});

const prematureClosure = () => ({
  name: "prematureClosure",
  render: { type: "Checkbox" },
});

const trendSystemIssues = () => ({
  name: "trendSystemIssues",
  render: { type: "Checkbox" },
});

const documentationIssues = () => ({
  name: "documentationIssues",
  render: { type: "Checkbox" },
});

const noTrendIdentified = () => ({
  name: "noTrendIdentified",
  render: { type: "Checkbox" },
});

export default issuesForArchiToTrend;
