import * as React from "react";
import { useEffectOnce } from "react-use";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form } from "react-final-form";
import validateFinalForm from "src/utilities/validateFinalForm";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { selectContentByID } from "src/redux/selectors";
import { TextField, Checkboxes } from "mui-rff";
import { MeetingActions } from "src/redux/reducers/Meeting";

const validationSchema = yup.object({
  cmeRegular: yup.object({
    value: yup.number().required().nullable(),
    trauma: yup.boolean(),
  }),
  cmeEthics: yup.object({
    value: yup.number().required().nullable(),
    trauma: yup.boolean(),
  }),
  totalFacilitatorsPresent: yup.number().required().nullable(),
  participants: yup.number().required().nullable(),
  mrtsRecordsReviewed: yup.number().required().nullable(),
  facilitiesPresent: yup.number().required().nullable(),
  otherPrivatePractice: yup.number().required().nullable(),
});

const mutators = {
  clearAll: (args, state, utils) => {
    utils.changeValue(state, "cmeRegular.value", () => null);
    utils.changeValue(state, "cmeRegular.trauma", () => false);
    utils.changeValue(state, "cmeEthics.value", () => null);
    utils.changeValue(state, "cmeEthics.trauma", () => false);
    utils.changeValue(state, "totalFacilitatorsPresent", () => null);
    utils.changeValue(state, "participants", () => null);
    utils.changeValue(state, "mrtsRecordsReviewed", () => null);
    utils.changeValue(state, "facilitiesPresent", () => null);
    utils.changeValue(state, "otherPrivatePractice", () => null);
  },
};

const CMECreditsDialog = (props) => {
  const Meeting = useSelector(selectContentByID("Meeting", props.meetingID));
  const render = Meeting !== undefined;

  const initialValues = {
    cmeRegular: {
      value: Meeting.CMERegular,
      trauma: Meeting.CMERegularTrauma,
    },
    cmeEthics: {
      value: Meeting.CMEEthics,
      trauma: Meeting.CMEEthicsTrauma,
    },
    totalFacilitatorsPresent: Meeting.TotalFacilitatorsPresent,
    participants: Meeting.NumberPhyAttending,
    mrtsRecordsReviewed: Meeting.TotalRecordsReviewed,
    facilitiesPresent: Meeting.TotalFacilitiesPresent,
    otherPrivatePractice: Meeting.NumberConsulting,
  };

  useEffectOnce(() => {
    props.dispatch(MeetingActions.LoadData(props.meetingID));
  });

  const updateCMECredits = (values) => {
    props.dispatch(
      MeetingActions.UpdateCredits({
        ID: props.meetingID,
        CMERegular: Number(values.cmeRegular.value),
        CMERegularTrauma: values.cmeRegular.trauma,
        CMEEthics: Number(values.cmeEthics.value),
        CMEEthicsTrauma: values.cmeEthics.trauma,
        NumberPhyAttending: Number(values.participants),
        NumberConsulting: Number(values.otherPrivatePractice),
        TotalRecordsReviewed: Number(values.mrtsRecordsReviewed),
        TotalFacilitiesPresent: Number(values.facilitiesPresent),
        TotalFacilitatorsPresent: Number(values.totalFacilitatorsPresent),
      })
    );
  };

  return (
    <>
      {render && (
        <Form
          onSubmit={updateCMECredits}
          initialValues={initialValues}
          validate={validateFinalForm(validationSchema)}
          mutators={mutators}
          render={({ form, submitting, pristine, values }) => (
            <>
              <form>
                <Dialog
                  fullWidth
                  maxWidth={"sm"}
                  open={props.dialogVisible}
                  onClose={() => {
                    props.setDialogVisible();
                    form.mutators.clearAll();
                  }}
                >
                  <DialogTitle>
                    {Meeting.MeetingName} <br /> CME Credit Entry
                  </DialogTitle>
                  <DialogContent className={"py-2"}>
                    <div className={"row"}>
                      <div className={"col"}>
                        <TextField
                          label="CME Regular"
                          name="cmeRegular.value"
                          required
                          type={"number"}
                          variant={"outlined"}
                        />
                      </div>
                      <div className={"col-3 d-flex align-items-center"}>
                        <Checkboxes
                          size={"small"}
                          name={"cmeRegular.trauma"}
                          data={{ label: "Trauma", value: true }}
                          color={"primary"}
                          disabled={submitting}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="CME Ethics"
                          name="cmeEthics.value"
                          required
                          type={"number"}
                          variant={"outlined"}
                        />
                      </div>
                      <div className={"col-3 d-flex align-items-center"}>
                        <Checkboxes
                          size={"small"}
                          name={"cmeEthics.trauma"}
                          data={{ label: "Trauma", value: true }}
                          color={"primary"}
                          disabled={submitting}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="Total Facilitators Present"
                          name="totalFacilitatorsPresent"
                          required
                          type={"number"}
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="Participants"
                          name="participants"
                          required
                          type={"number"}
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="MRTS Records Reviewed"
                          name="mrtsRecordsReviewed"
                          required
                          type={"number"}
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="Facilities Present"
                          name="facilitiesPresent"
                          required
                          type={"number"}
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                    <div className={"row pt-2"}>
                      <div className={"col"}>
                        <TextField
                          label="Other Private Practice"
                          name="otherPrivatePractice"
                          required
                          type={"number"}
                          variant={"outlined"}
                        />
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      disabled={submitting}
                      onClick={() => {
                        props.setDialogVisible();
                        form.mutators.clearAll();
                      }}
                      color={"secondary"}
                      variant={"contained"}
                    >
                      Close
                    </Button>
                    <Button
                      disabled={
                        values.cmeRegular.value === null ||
                        values.cmeEthics.value === null ||
                        values.totalFacilitatorsPresent === null ||
                        values.participants === null ||
                        values.mrtsRecordsReviewed === null ||
                        values.facilitiesPresent === null ||
                        values.otherPrivatePractice === null ||
                        submitting ||
                        pristine
                      }
                      color={"primary"}
                      variant={"contained"}
                      onClick={() => {
                        updateCMECredits(values);
                        props.setDialogVisible();
                        form.mutators.clearAll();
                      }}
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </>
          )}
        />
      )}
    </>
  );
};

export default CMECreditsDialog;
