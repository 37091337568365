import React from "react";
import RenderEachItem from "../RenderEachItem";

const SubTitle = ({ label, items }) => {
  return (
    <>
      <div className={"d-flex text-left"}>{label}</div>
      <RenderEachItem items={items} indent />
    </>
  );
};

export default SubTitle;
