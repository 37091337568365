import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityRecordsReviewedChart from "./AllFacilityRecordsReviewedChart";
import FacilitySpecificRecordsReviewedChart from "./FacilitySpecificRecordsReviewedChart";
import TextBegin from "./TextBegin";

const RecordsReviewedByCommittee = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, [
    "mrtsReviewType",
    "committee",
  ]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, [
      "mrtsReviewType",
      "committee",
    ]);

  return (
    <>
      <TextBegin {...props} />
      {chartDataAllFacilities !== false && (
        <AllFacilityRecordsReviewedChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Committee"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificRecordsReviewedChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Committee"}
        rows={chartData.rows}
      />
    </>
  );
};

export default RecordsReviewedByCommittee;
