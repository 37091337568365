import React from "react";
// ui
import { makeStyles } from "@mui/styles";
import { Form, FormSpy } from "react-final-form";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  ButtonGroup,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonnelInvolved from "../../ReusableSections/PersonnelInvolved";
import ReviewInformationAdmin from "../../ReusableSections/ReviewInformationAdmin";
import PatientInformation from "../../ReusableSections/PatientInformation";
import ReviewReasons from "../../ReusableSections/ReviewReasons";
import ReferenceMaterialAdmin from "../../ReusableSections/ReferenceMaterialAdmin";
import Tracking from "../../ReusableSections/Tracking";
import CustomSubmitButton from "../../CustomSubmitButton";

//data
import mappers from "../../mappers";
import mutators from "../../mutators";
import initialValues from "../initialValues";
import { RecordActions } from "src/redux/reducers/Record";
import { convertDateToLocal } from "src/utilities/dateFunctions";

// validation
import validationSchema, {
  submissionDateSchema,
  trackingRecordSchema,
} from "../../validationSchema";
import { makeValidate } from "mui-rff";

const extendedValidationSchema = validationSchema
  .concat(submissionDateSchema)
  .concat(trackingRecordSchema);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateApprovedAdminDialog = (props) => {
  const classes = useStyles();

  const createMRTS = async (values) => {
    const RecordInfo = {
      FacilityID: values.facility.ID,
      ContactID: values.contact.ID,
      ProviderID: values.provider.ID,
      RecordStatusID: 1,
      FacilityRecordID: values.facilityMedicalRecordNumber,
      CommitteeID: values.committee.ID,
      CommitteeOtherDesc:
        values.committeeOther !== null ? values.committeeOther : "",
      HistoryPreSurgery: values.historyAndPhysicalOnChart,
      MidlevelProviderID:
        values.midlevel !== null
          ? values.midlevel.ID
          : null,
      PatientSourceID: values.patientOriginationID,
      ReviewCategoryID: values.reviewCategoryID,
      ReviewTypeID: values.reviewTypeID,
      ARCHIRecordID: "",
      PatientAge: values.patientAge,
      AgeIncrementID: values.ageIncrement,
      PatientGender: values.patientGender,
      PatientRaceID: values.patientRace,
      PatientSourceOther: "",
      ArrivalDate: convertDateToLocal(values.arrivalDate),
      DischargeDate: convertDateToLocal(values.dischargeDate),
      ReviewReason: values.reviewReasons.map((r) => {
        return {
          ID: r,
          recordReviewReason: {
            ReviewReasonID: r,
            OtherReason: values.otherReviewReason,
          },
        };
      }),
      ReferenceMaterial: values.referenceMaterialChecklist.map((r) => {
        return {
          ID: r,
          recordReferenceMaterial: {
            ReferenceMaterialID: r,
            OtherReference: values.otherReferenceMaterial,
          },
        };
      }),
      RecordDiagnosis: values.icdCodes.map((i) => {
        return mappers.recordDiagnosis(i);
      }),
      OtherReason: values.otherReviewReason,
      RecordComment: [
        { TypeID: 2, Description: values.additionalInformation },
        { TypeID: 3, Description: values.committeeQuestion },
      ],
      ApprovalStatus: 1,
      Signature: null,
      SubmissionDate: values.submissionDate,
    };
    props.dispatch(
      RecordActions.CreateApproved({
        RecordTracking: {
          RecordInfo,
          MRTSReviewTypeID: values.mrtsReviewType,
          RecordStatusID: values.recordStatus,
          ReviewMonth: values.reviewMonth,
          ReviewYear: values.reviewYear,
          Comments: values.comments,
        },
      })
    );
  };

  return (
    <Form
      onSubmit={createMRTS}
      initialValues={initialValues}
      validate={makeValidate(extendedValidationSchema)}
      mutators={mutators}
      subscription={{
        form: false,
        pristine: false,
        submitting: false,
        values: false,
        handleSubmit: true,
      }}
      render={({ handleSubmit }) => (
        <form>
          <Dialog
            fullScreen
            open={props.dialogVisible}
            onClose={props.setDialogVisible}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <FormSpy subscription={{ form: true }}>
                  {({ form }) => (
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        form.mutators.clearAll();
                        props.setDialogVisible();
                      }}
                      aria-label="close"
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </FormSpy>
                <Typography variant="h6" className={classes.title}>
                  Create MRTS
                </Typography>
                <CustomSubmitButton
                  handleSubmit={handleSubmit}
                  label={"Create"}
                />
              </Toolbar>
            </AppBar>
            <div className={"container-fluid"}>
              <div className={"row py-2"}>
                <div className={"col"}>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      onClick={() =>
                        document
                          .getElementById("personnel-involved-title")
                          .scrollIntoView()
                      }
                    >
                      Personnel Involved
                    </Button>
                    <Button
                      onClick={() =>
                        document
                          .getElementById("review-information-title")
                          .scrollIntoView()
                      }
                    >
                      Review Information
                    </Button>
                    <Button
                      onClick={() =>
                        document
                          .getElementById("patient-information-title")
                          .scrollIntoView()
                      }
                    >
                      Patient Information
                    </Button>
                    <Button
                      onClick={() =>
                        document
                          .getElementById("review-reasons-title")
                          .scrollIntoView()
                      }
                    >
                      Review Reasons
                    </Button>
                    <Button
                      onClick={() =>
                        document
                          .getElementById("reference-material-title")
                          .scrollIntoView()
                      }
                    >
                      Reference Material
                    </Button>
                    <Button
                      onClick={() =>
                        document
                          .getElementById("tracking-title")
                          .scrollIntoView()
                      }
                    >
                      Tracking
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
            <div
              style={{
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <PersonnelInvolved {...props} classes={classes} />
              <ReviewInformationAdmin {...props} classes={classes} />
              <PatientInformation {...props} classes={classes} />
              <ReviewReasons {...props} classes={classes} />
              <ReferenceMaterialAdmin {...props} classes={classes} />
              <Tracking {...props} classes={classes} />
            </div>
          </Dialog>
        </form>
      )}
    />
  );
};

export default CreateApprovedAdminDialog;
