import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityPatientGender = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Patient Gender"}
        xAxisLabel={"Patient Gender"}
        yAxisLabel={"Number of Records"}
        figureText={"Records by Patient Gender for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilityPatientGender;
