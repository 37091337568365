import { createSelector } from "reselect";
import { selectContent } from "./contentSelectors";
import { selectRecords } from "./recordSelectors";
import { findById } from "./helperFunctions";

const selectSignature = (state) => state.Signature;

const selectOutcomes = (state) => state.Outcome;

const selectSignatureNameList = createSelector(
  selectContent("Signature"),
  (signatureList) => {
    const signatureObject = {};
    signatureList.forEach(({ ID, Name }) => (signatureObject[ID] = Name));

    return signatureObject;
  }
);

const selectAbbreviationList = createSelector(
  selectContent("Abbreviation"),
  (abbList) => {
    const formattedAbbData = {
      doNotUse: {
        label: "Do Not Use",
        list: [],
        noDataLabel: "",
      },
      additional: {
        label: "Additional Abbreviations",
        list: [],
        noDataLabel: "",
      },
    };
    abbList.forEach(
      ({
        ID,
        Name,
        AbbreviationCategory,
        PreferredTerm,
        AbbreviationProblemDesc,
      }) => {
        if ([9, 18].includes(ID)) {
          formattedAbbData[
            AbbreviationCategory === "Do Not Use" ? "doNotUse" : "additional"
          ].noDataLabel = Name;
        } else {
          formattedAbbData[
            AbbreviationCategory === "Do Not Use" ? "doNotUse" : "additional"
          ].list.push({
            ID,
            Name,
            AbbreviationCategory,
            PreferredTerm,
            Description: AbbreviationProblemDesc,
          });
        }
      }
    );
    return formattedAbbData;
  }
);

const selectAbbreviationByRecordID = (recordID) =>
  createSelector(
    selectRecords,
    (records) => findById(records, recordID).RecordAbbreviation
  );

export {
  selectSignature,
  selectSignatureNameList,
  selectAbbreviationList,
  selectAbbreviationByRecordID,
  selectOutcomes,
};
