import Dropdown from "./Dropdown";
import Title from "./Title";
import Checkbox from "./Checkbox";
import TextBox from "./TextBox";
import Autocomplete from "./Autocomplete";
import "./index.css";

const ListOfComponents = {
  Title,
  Dropdown,
  Checkbox,
  TextBox,
  Autocomplete,
};

export default ListOfComponents;
