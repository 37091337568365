import { createSlice } from "@reduxjs/toolkit";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { LastCreatedObjectActions } from "src/redux/reducers/LastCreatedObject";
import { RecordActions } from "src/redux/reducers/Record";
import { format } from "date-fns";

const initialState = { ModalVisible: false };

const recordTrackingSlice = createSlice({
  name: "recordTracking",
  initialState,
  reducers: {
    load: (state, action) => {
      const { SubmitMethod, RecordInfo, ModalVisible } = action.payload;
      state.SubmitMethod = SubmitMethod;
      state.RecordID = RecordInfo.ID;
      state.RecordStatusID = RecordInfo.RecordStatusID;
      state.MRTSReviewTypeID = RecordInfo.MRTSReviewTypeID;
      state.ARCHIRecordID = RecordInfo.ARCHIRecordID;
      state.Comments = RecordInfo.RecordTracking.Comments;
      state.ReviewMonth = RecordInfo.RecordTracking.ReviewMonth;
      state.ReviewYear = RecordInfo.RecordTracking.ReviewYear;
      state.ModalVisible = ModalVisible;
    },
    approval: (state, action) => {
      const { SubmitMethod, RecordInfo, ModalVisible } = action.payload;
      state.SubmitMethod = SubmitMethod;
      state.RecordInfo = RecordInfo;
      state.RecordID = RecordInfo.ID;
      state.ModalVisible = ModalVisible;
      state.RecordStatusID = 1;
      state.MRTSReviewTypeID = RecordInfo.MRTSReviewTypeID;
    },
    setRecordStatusID: (state, action) => {
      state.RecordStatusID = action.payload;
    },
    setMRTSReviewTypeID: (state, action) => {
      state.MRTSReviewTypeID = action.payload;
    },
    setReviewMonth: (state, action) => {
      state.ReviewMonth = action.payload;
    },
    setReviewYear: (state, action) => {
      state.ReviewYear = action.payload;
    },
    setComment: (state, action) => {
      state.Comments = action.payload;
    },
    update: (state, action) => {
      state.SubmitMethod = "UpdateTracking";
      state.RecordID = action.payload.RecordTracking.RecordID;
      state.RecordStatusID = action.payload.Record.RecordStatusID;
      state.MRTSReviewTypeID = action.payload.Record.MRTSReviewTypeID;
      state.ARCHIRecordID = action.payload.Record.ARCHIRecordID;
      state.Comments = action.payload.RecordTracking.Comments;
      state.ReviewMonth = action.payload.RecordTracking.ReviewMonth;
      state.ReviewYear = action.payload.RecordTracking.ReviewYear;
      state.ModalVisible = true;
    },
    empty: () => {
      return { ...initialState };
    },
  },
});

const {
  load,
  approval,
  setRecordStatusID,
  setMRTSReviewTypeID,
  setReviewMonth,
  setReviewYear,
  setComment,
  update,
  empty,
} = recordTrackingSlice.actions;

const Load = (content) => async (dispatch) => {
  if (!content.RecordInfo.Loaded) {
    content.RecordInfo = await dispatch(
      RecordActions.LoadData(content.RecordInfo.ID, "ID")
    );
  }
  content.RecordInfo && dispatch(load(content));
};

const Approval = (content) => async (dispatch) => {
  dispatch(approval(content));
};

const SetRecordStatusID = (content) => async (dispatch) => {
  dispatch(setRecordStatusID(content));
};

const SetMRTSReviewTypeID = (content) => async (dispatch) => {
  dispatch(setMRTSReviewTypeID(content));
};

const SetReviewMonth = (content) => async (dispatch) => {
  dispatch(setReviewMonth(content));
};

const SetReviewYear = (content) => async (dispatch) => {
  dispatch(setReviewYear(content));
};

const SetComment = (content) => async (dispatch) => {
  dispatch(setComment(content));
};

const Update = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Record/updateTracking`,
      content
    );
    dispatch(update(contents));
    dispatch(RecordActions.UpdateTracking(contents));
    dispatch(LastCreatedObjectActions.Set("RecordApproval", contents));
    dispatch(FetchingActions.Success("Updating Record Tracking"));
  } catch (error) {
    console.error(error);
    dispatch(FetchingActions.Failure("Updating Record Tracking"));
  }
};

const UpdateRecordTrackingState = (content) => async (dispatch) => {
  dispatch(update(content));
};

const Empty = () => async (dispatch) => {
  dispatch(empty());
};

export const RecordTrackingActions = {
  Load,
  Approval,
  SetRecordStatusID,
  SetMRTSReviewTypeID,
  SetReviewMonth,
  SetReviewYear,
  SetComment,
  Update,
  UpdateRecordTrackingState,
  Empty,
};

export default recordTrackingSlice.reducer;
