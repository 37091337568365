import React from "react";
import { useSelector } from "react-redux";
import TablePageCard from "src/components/individual/TablePageCard";
import {
  selectContent,
  selectFacilities,
  selectLoaded,
  selectRecords,
} from "src/redux/selectors";
import { useEffectOnce } from "react-use";
import { RecordActions } from "src/redux/reducers/Record";
import { MeetingActions } from "src/redux/reducers/Meeting";
import ActionsButton from "./ActionsButton";
import CreateMRTSRecordDialog from "./MRTSRecordDialog/CreateMRTSRecordDialog";
import UpdateMRTSRecordDialog from "./MRTSRecordDialog/UpdateMRTSRecordDialog";
import RejectApprovedRecordDialog from "./RejectApprovedRecordDialog";
import EditTrackingDialog from "./EditTrackingDialog";
import {
  facilityAdmin,
  adminUser,
  facilityUser,
} from "src/utilities/rbacFunctions";
import ViewRejectionDialog from "./ViewRejectionDialog";
import { useNavigate } from "react-router-dom";
import ViewWorksheetDialog from "./ViewWorksheetDialog";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { format } from "date-fns";
import { ContactActions } from "src/redux/reducers/PersonnelManagement/Contact";
import { ProviderActions } from "src/redux/reducers/PersonnelManagement/Provider";
import { MidlevelActions } from "src/redux/reducers/PersonnelManagement/Midlevel";
import getUTCDate from "src/utilities/getUTCDate";

const options = {
  filterType: "multiselect",
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
};

const NoDropdownDashboard = (props) => {
  const navigate = useNavigate();
  const facilityView = facilityUser(props.roles);
  let viewLevel = 0;
  if (facilityAdmin(props.roles)) {
    viewLevel = 1;
  } else if (adminUser(props.roles)) {
    viewLevel = 2;
  }
  const [createMRTSRecordDialogVisible, setCreateMRTSRecordDialogVisible] =
    React.useState(false);
  const [updateMRTSRecordDialogVisible, setUpdateMRTSRecordDialogVisible] =
    React.useState(false);
  const [editTrackingDialogVisible, setEditTrackingDialogVisible] =
    React.useState(false);
  const [recordID, setRecordID] = React.useState(null);
  const [approvalStatus, setApprovalStatus] = React.useState(null);
  const [viewRejectionDialogVisible, setViewRejectionDialogVisible] =
    React.useState(false);
  const [viewWorksheetDialogVisible, setViewWorksheetDialogVisible] =
    React.useState(false);
  const [rejectMRTSRecordVisible, setRejectMRTSDialogVisible] =
    React.useState(false);
  const Records = useSelector(selectRecords);
  const ReviewTypes = useSelector(selectContent("ReviewType"));
  const RecordStatuses = useSelector(selectContent("RecordStatus"));
  const Loaded = useSelector(selectLoaded);
  const Facilities = useSelector(selectFacilities);
  const render =
    Loaded.Record &&
    Loaded.Facility &&
    Loaded.Constants &&
    Loaded.Contact &&
    Loaded.Provider &&
    Loaded.Midlevel;
  let data = [];

  const columns = !facilityView
    ? [
        {
          name: "archiRecordID",
          label: "ARCHI Record ID",
        },
        {
          name: "facilityRecordID",
          label: "Facility Record ID",
        },
        {
          name: "facilityName",
          label: "Facility Name",
        },
        {
          name: "reviewType",
          label: "Review Type",
        },
        {
          name: "mrtsReviewType",
          label: "MRTS Review Type",
        },
        {
          name: "submissionDate",
          label: "Submission Date",
        },
        {
          name: "recordStatus",
          label: "Record Status",
        },
        {
          name: "actions",
          label: "Action",
          options: {
            filter: false,
            sort: false,
            download: false,
            print: false,
          },
        },
      ]
    : [
        {
          name: "archiRecordID",
          label: "ARCHI Record ID",
        },
        {
          name: viewLevel < 2 ? "originalFacilityRecordID" : "facilityRecordID",
          label: "Facility Record ID",
        },
        {
          name: "reviewType",
          label: "Review Type",
        },
        {
          name: "mrtsReviewType",
          label: "MRTS Review Type",
        },
        {
          name: "submissionDate",
          label: "Submission Date",
        },
        {
          name: "recordStatus",
          label: "Record Status",
        },
        {
          name: "actions",
          label: "Action",
          options: {
            filter: false,
            sort: false,
            download: false,
            print: false,
          },
        },
      ];

  useEffectOnce(() => {
    Loaded.Record === false &&
      props.dispatch(RecordActions.BatchAdd(props.roles));
    // Don't load Meetings for Facility Personnel
    if (viewLevel !== 0) {
      Loaded.Meeting === false &&
        props.dispatch(MeetingActions.BatchAdd(props.roles));
    }
    Loaded.Facility === false &&
      props.dispatch(FacilityActions.BatchAdd(props.roles));
    Loaded.Contact === false &&
      props.dispatch(ContactActions.BatchAdd(props.roles));
    Loaded.Provider === false &&
      props.dispatch(ProviderActions.BatchAdd(props.roles));
    Loaded.Midlevel === false &&
      props.dispatch(MidlevelActions.BatchAdd(props.roles));
  });

  if (render) {
    if (facilityView) {
      data = Records.map((record, i) => {
        const archiRecordID =
          record.ARCHIRecordID !== null ? record.ARCHIRecordID : "-";
        const reviewType =
          record.ReviewTypeID !== null &&
          ReviewTypes.find((r) => r.ID === record.ReviewTypeID).Name;
        const mrtsReviewType =
          record.MRTSReviewTypeID !== null && record.MRTSReviewTypeID === 1
            ? "Committee"
            : "Direct";
        const recordStatus =
          record.ApprovalStatus === 0
            ? "Rejected"
            : record.ApprovalStatus === 1
            ? "Pending Approval"
            : record.RecordStatusID !== null
            ? RecordStatuses.find((r) => r.ID === record.RecordStatusID).Name
            : "-";
        return {
          archiRecordID,
          facilityRecordID: record.FacilityRecordID,
          originalFacilityRecordID: record.OriginalFacilityRecordID,
          reviewType,
          mrtsReviewType,
          submissionDate: record.SubmissionDate
            ? format(getUTCDate(new Date(record.SubmissionDate)), "MM/dd/yyyy")
            : "-",
          recordStatus,
          actions: (
            <ActionsButton
              {...props}
              approvalStatus={record.ApprovalStatus}
              setApprovalStatus={() => setApprovalStatus(record.ApprovalStatus)}
              viewLevel={viewLevel}
              id={i}
              record={record}
              recordID={record.ID}
              spawnRecordTrackingDialog={() => {
                setRecordID(record.ID);
                setEditTrackingDialogVisible(true);
              }}
              viewWorksheet={() => {
                setViewWorksheetDialogVisible(true);
              }}
              spawnUpdateMRTSRecordDialog={() => {
                setRecordID(record.ID);
                setUpdateMRTSRecordDialogVisible(true);
              }}
              spawnViewRejectionDialog={() => {
                setRecordID(record.ID);
                setViewRejectionDialogVisible(true);
              }}
              spawnRejectMRTSRecordDialog={() => {
                setRecordID(record.ID);
                setRejectMRTSDialogVisible(true);
              }}
            />
          ),
        };
      });
    } else {
      data = Records.map((record, i) => {
        const archiRecordID =
          record.ARCHIRecordID !== null ? record.ARCHIRecordID : "-";
        const reviewType =
          record.ReviewTypeID !== null &&
          ReviewTypes.find((r) => r.ID === record.ReviewTypeID).Name;
        const mrtsReviewType =
          record.MRTSReviewTypeID !== null && record.MRTSReviewTypeID === 1
            ? "Committee"
            : "Direct";
        const facility =
          record.FacilityID !== null &&
          Facilities.find((f) => f.ID === record.FacilityID);
        const recordStatus =
          record.ApprovalStatus === 0
            ? "Rejected"
            : record.ApprovalStatus === 1
            ? "Pending Approval"
            : record.RecordStatusID !== null
            ? RecordStatuses.find((r) => r.ID === record.RecordStatusID).Name
            : "-";
        return {
          archiRecordID,
          facilityRecordID: record.FacilityRecordID,
          facilityName:
            facility !== undefined && facility !== null
              ? facility.FullName
              : "-",
          reviewType,
          mrtsReviewType,
          submissionDate: record.SubmissionDate
            ? format(getUTCDate(new Date(record.SubmissionDate)), "MM/dd/yyyy")
            : "-",
          recordStatus,
          actions: (
            <ActionsButton
              {...props}
              approvalStatus={record.ApprovalStatus}
              setApprovalStatus={() => setApprovalStatus(record.ApprovalStatus)}
              viewLevel={viewLevel}
              id={i}
              record={record}
              recordID={record.ID}
              spawnRecordTrackingDialog={() => {
                setRecordID(record.ID);
                setEditTrackingDialogVisible(true);
              }}
              sendToRNTools={() => {
                navigate(`/RNTools/${record.ID}`);
              }}
              viewWorksheet={() => {
                setRecordID(record.ID);
                setViewWorksheetDialogVisible(true);
              }}
              spawnUpdateMRTSRecordDialog={() => {
                setRecordID(record.ID);
                setUpdateMRTSRecordDialogVisible(true);
              }}
              spawnViewRejectionDialog={() => {
                setRecordID(record.ID);
                setViewRejectionDialogVisible(true);
              }}
              spawnRejectMRTSRecordDialog={() => {
                setRecordID(record.ID);
                setRejectMRTSDialogVisible(true);
              }}
            />
          ),
        };
      });
    }
  }

  return (
    <>
      {render && (
        <>
          {createMRTSRecordDialogVisible && (
            <CreateMRTSRecordDialog
              {...props}
              viewLevel={viewLevel}
              dialogVisible={createMRTSRecordDialogVisible}
              setDialogVisible={() => {
                setCreateMRTSRecordDialogVisible(false);
                setApprovalStatus(null);
              }}
            />
          )}
          {updateMRTSRecordDialogVisible && (
            <UpdateMRTSRecordDialog
              {...props}
              viewLevel={viewLevel}
              approvalStatus={approvalStatus}
              dialogVisible={updateMRTSRecordDialogVisible}
              setDialogVisible={() => {
                setUpdateMRTSRecordDialogVisible(false);
                setRecordID(null);
                setApprovalStatus(null);
              }}
              recordID={recordID}
            />
          )}
          {editTrackingDialogVisible && (
            <EditTrackingDialog
              {...props}
              dialogVisible={editTrackingDialogVisible}
              setDialogVisible={() => {
                setEditTrackingDialogVisible(false);
                setRecordID(null);
                setApprovalStatus(null);
              }}
              recordID={recordID}
            />
          )}
          {viewRejectionDialogVisible && (
            <ViewRejectionDialog
              {...props}
              dialogVisible={viewRejectionDialogVisible}
              setDialogVisible={() => {
                setViewRejectionDialogVisible(false);
                setRecordID(null);
                setApprovalStatus(null);
              }}
              recordID={recordID}
            />
          )}
          {rejectMRTSRecordVisible && (
            <RejectApprovedRecordDialog
              {...props}
              dialogVisible={rejectMRTSRecordVisible}
              setDialogVisible={() => {
                setRejectMRTSDialogVisible(false);
                setRecordID(null);
                setApprovalStatus(null);
              }}
              recordID={recordID}
            />
          )}
          {viewWorksheetDialogVisible && (
            <ViewWorksheetDialog
              isDialogVisible={viewWorksheetDialogVisible}
              onClose={() => {
                setViewWorksheetDialogVisible(false);
                setRecordID(null);
              }}
              recordID={recordID}
            />
          )}
          <TablePageCard
            title={"MRTS Records"}
            table={{ columns, data, options }}
            button={{
              isRendered: true,
              label: "New MRTS",
              handleClick: () => setCreateMRTSRecordDialogVisible(true),
            }}
          />
        </>
      )}
    </>
  );
};

export default NoDropdownDashboard;
