import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificPatientRaceChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Patient Race"}
        xAxisLabel={"Patient Race"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: Records by Patient Race"}
      />
    </>
  );
};

export default FacilitySpecificPatientRaceChart;
