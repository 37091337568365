import React from "react";
import _ from "lodash";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityPossibleImprovementsChart from "./AllFacilityPossibleImprovementsChart";
import FacilitySpecificPossibleImprovementsChart from "./FacilitySpecificPossibleImprovementsChart";
import TextBegin from "./TextBegin";

const PossibleImprovements = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, [], true);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, [], true);

  let filteredChartData, filteredChartDataAllFacilities;

  if(props.values.displayOptions.groupBy){
    filteredChartData = filterChartData(sortChartDataByTime(chartData));
    filteredChartDataAllFacilities = chartDataAllFacilities !== false && filterChartData(sortChartDataByTime(chartDataAllFacilities));
  }else{
    filteredChartData = filterChartData(sortChartData(chartData));
    filteredChartDataAllFacilities = chartDataAllFacilities !== false && filterChartData(sortChartData(chartDataAllFacilities));
  }
    

  return (
    <>
      <TextBegin {...props} />
      {chartDataAllFacilities !== false && (
        <AllFacilityPossibleImprovementsChart
          {...props}
          labels={filteredChartDataAllFacilities.labels}
          data={filteredChartDataAllFacilities.data}
          datasets={filteredChartDataAllFacilities.datasets}
          heading={"Possible Improvement"}
          rows={filteredChartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificPossibleImprovementsChart
        {...props}
        labels={filteredChartData.labels}
        data={filteredChartData.data}
        datasets={filteredChartData.datasets}
        heading={"Possible Improvement"}
        rows={filteredChartData.rows}
      />
    </>
  );
};

export const sortChartData = (chartData) => {
  const result = chartData.data.reduce(function (result, field, index) {
    const labelKey = chartData.labels[index];
    if (labelKey) {
      result[labelKey] = field;
    }
    return result;
  }, {});

  const sorted = Object.entries(result).sort(([, a], [, b]) => b - a);

  const sortedChartData = _.cloneDeep(chartData);

  sortedChartData.labels = sorted.reduce(
    (acc, curVal) => acc.concat(curVal[0]),
    []
  );

  sortedChartData.data = sorted.reduce(
    (acc, curVal) => acc.concat(curVal[1]),
    []
  );

  return sortedChartData;
};


export const sortChartDataByTime = (chartData) => {
  
  const sortedLabels = [];

  chartData.tableRows.forEach(function(currentValue, index, arr) {
    // reset all datasets data to empty arrays
    if (index === 0){      
      this.forEach(function(dataset){
        dataset.data = [];
      });
    }

    // get ordered values from tableRows
    let row = Object.values(currentValue);     

    // add to label list by removing it from the last element of row
    sortedLabels.push(row.pop());

    // iterate through all datasets by adding one value from row to each dataset
    this.forEach(function(arrCurrentValue){
      arrCurrentValue.data.push(row.shift());
    });

  }, chartData.datasets);

  // overwrite labels with sorted labels
  chartData.labels = sortedLabels;

  return chartData;
};


export const filterChartData = (chartData) => {
  if (!chartData) return chartData;

  const indexToDeleteForAllFacilities = findIndexToRemove(chartData.data);
  const filteredLabels = chartData.labels.filter(
    (_, i) => !indexToDeleteForAllFacilities.includes(i)
  );
  const filteredData = chartData.data.filter(
    (_, i) => !indexToDeleteForAllFacilities.includes(i)
  );
  const filteredChartData = _.cloneDeep(chartData);
  filteredChartData.labels = filteredLabels;
  filteredChartData.data = filteredData;

  return filteredChartData;
};

const findIndexToRemove = (array) => {
  const indexes = [];
  array.forEach((elem, i) => {
    if (!elem) indexes.push(i);
  });
  return indexes;
};

export default PossibleImprovements;
