import { StyleSheet, Font } from "@react-pdf/renderer";
import boldFont from "src/resources/Roboto-Bold.ttf";

Font.register({
  family: "Roboto-Bold",
  src: boldFont,
});

Font.registerHyphenationCallback((word) => {
  return [word]; // Return entire word as unique part
});

export const styles = StyleSheet.create({
  page: {
    paddingTop: 10,
    paddingBottom: 60,
    paddingHorizontal: 60,
  },
  uniformPage: {
    padding: 20,
  },
  header: {
    paddingTop: 50,
    alignSelf: "center",
  },
  boxHeader: {
    textAlign: "center",
    borderTop: true,
    borderRight: true,
    borderLeft: true,
    backgroundColor: "lightgrey",
  },
  boxSubTitle: {
    color: "#000",
    textAlign: "center",
    fontSize: 14,
    backgroundColor: "lightgrey",
  },
  boxTitle: {
    fontSize: 20,
    font: "Montserrat",
    backgroundColor: "lightgrey",
  },
  footer: {
    position: "absolute",
    alignSelf: "center",
    bottom: 20,
  },
  title: {
    fontFamily: "Roboto-Bold",
    fontSize: 20,
    textAlign: "center",
    paddingTop: 90,
    paddingBottom: 20,
  },
  subTitle: {
    fontFamily: "Roboto-Bold",
    fontSize: 14,
    color: "#000",
    textAlign: "center",
    paddingTop: 10,
  },
  descriptionText: {
    marginBottom: 0,
    fontSize: 11,
  },
  finalSubTitle: {
    fontFamily: "Roboto-Bold",
    paddingTop: 20,
    fontSize: 14,
    paddingBottom: 10,
    fontWeight: "bold",
  },
  contentRow: {
    flexDirection: "column",
    marginTop: 20,

    marginBottom: 10,
  },
  subContentRow: {
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
  contentColumn: {
    flexDirection: "row",
  },
  halfColumn: {
    width: "50%",
    flexGrow: 1,
  },
  quarterColumn: { width: "25%", flexGrow: 1 },
  boxColumn: {
    flexDirection: "row",
    border: true,
  },
  boxRow: {
    flexDirection: "row",
    borderLeft: true,
    borderRight: true,
    borderTop: true,
  },
  boxHalfColumn: {
    width: "50%",
    border: true,
    flexGrow: 1,
  },
  text: {
    fontSize: 10,
    marginBottom: 4,
  },
  commentText: {
    fontSize: 12,
    marginBottom: 4,
  },
  columnText: {
    fontSize: 9,
    marginBottom: 4,
  },
  columnTextBold: {
    fontSize: 9,
    marginBottom: 4,
    fontFamily: "Roboto-Bold",
  },
  finalFooter: {
    borderTop: true,
    borderColor: "#771C1C",
    borderTopWidth: 5,
    marginTop: 10,
    position: "absolute",
    alignSelf: "center",
    width: "80vw",
    bottom: 10,
  },
  footerText: {
    borderTop: true,
    borderColor: "#771C1C",
    marginTop: 1,
    paddingTop: 2,
    textAlign: "center",
    fontSize: 8,
    color: "grey",
  },
  boxedText: {
    fontSize: 10,
    padding: 2,
  },
  pageNumber: {
    textAlign: "center",
    marginTop: 0,
    fontSize: 8,
    color: "grey",
  },
  finalPage: {
    padding: 40,
  },
  pdfHeader: {
    position: "absolute",
    width: "300",
    marginTop: 10,
    alignSelf: "center",
  },
  watermark: {
    position: "absolute",
    top: 180,
    alignSelf: "center",
    height: "58%",
    width: "125%",
  },
  finalTitle: {
    fontFamily: "Roboto-Bold",
    textAlign: "center",
    fontSize: 20,
    paddingTop: 60,
    paddingBottom: 20,
  },
});
