import React from "react";
import { MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Select } from "mui-rff";
import { ReportTemplatesActions } from "src/redux/reducers/ReportTemplates";

const TemplateSection = (props) => {
  return (
    <>
      <div className={"row pt-2"}>
        <div className={"col"}>
          <Select
            name={"template"}
            label={"Template"}
            labelId="reports-template-select-label"
            id="reports-template-select"
            variant={"outlined"}
            disabled={props.disabled}
            displayEmpty
          >
            <MenuItem value={null}>Select a Template</MenuItem>
            {props.ReportTemplates.map((template) => (
              <MenuItem
                key={`reports-template-select-menu-item-${template.Name}-${template.ID}`}
                onClick={() => {
                  props.form.mutators.setTemplate(
                    template.ChartType,
                    template.DateRange,
                    template.DisplayOptions,
                    template.ProgramOverviewSelected,
                    template.ChecklistReports,
                    template.OutcomeReports,
                    template.PatientTypeReports,
                    template.RecommendationReports
                  );
                }}
                value={template.ID}
              >
                {template.Name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div
          className={"col-2 d-flex align-items-center justify-content-begin"}
        >
          <IconButton
            edge={"start"}
            color={"secondary"}
            onClick={() => {
              props.dispatch(
                ReportTemplatesActions.Remove(props.values.template)
              );
            }}
            disabled={props.disabled}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default TemplateSection;
