import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { Form } from "react-final-form";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Button, Card, CardContent } from "@mui/material";
import Sidebar from "./Sidebar";
import ContentArea from "./ContentArea";
import { ReportActions } from "src/redux/reducers/Report";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { ReportTemplatesActions } from "src/redux/reducers/ReportTemplates";
import {
  selectFacilities,
  selectLoaded,
  selectReport,
  selectReportTemplates,
} from "src/redux/selectors";
import initialValues from "./initialValues";
import validationSchema from "./validationSchema";
import mutators from "./mutators";
import validateFinalForm from "src/utilities/validateFinalForm";
import PageCard from "src/components/individual/PageCard";
import SaveTemplateDialog from "./SaveTemplateDialog";

const validate = validateFinalForm(validationSchema);

const styles = {
  card: {
    maxHeight: "95vh",
  },
  innerSidebar: {
    maxHeight: "75vh",
    overflowX: "hidden",
    overflowY: "auto",
  },
  outerSidebar: {
    maxHeight: "80vh",
  },
  outerContentArea: {
    maxHeight: "80vh",
    overflowX: "hidden",
    overflowY: "auto",
  },
  innerContentArea: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const Reports = (props) => {
  const [saveTemplateDialogVisible, setSaveTemplateDialogVisible] =
    React.useState(false);
  const [showPrintPreview, setShowPrintPreview] = React.useState(false);
  const Loaded = useSelector(selectLoaded);
  const Report = useSelector(selectReport);
  const Facilities = useSelector(selectFacilities);
  const ReportTemplates = useSelector(selectReportTemplates);
  const disabled = Report.Fetched === true && Report.Loading === false;

  const viewReport = (values) => {
    props.dispatch(ReportActions.Fetch(values));
  };

  // Reset report on unmount
  useEffect(() => () => props.dispatch(ReportActions.Reset()), []);

  useEffectOnce(() => {
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
    ReportTemplates.Loaded === false &&
      props.dispatch(ReportTemplatesActions.Load());
  });

  return (
    <>
      <PageCard
        title={"Reports"}
        render={
          <>
            <Form
              onSubmit={viewReport}
              initialValues={initialValues}
              validate={validate}
              mutators={mutators}
              render={({ form, submitting, pristine, values }) => (
                <>
                  <form
                    onSubmit={(event) => {
                      viewReport(values);
                      event.preventDefault();
                    }}
                  >
                    {saveTemplateDialogVisible && (
                      <SaveTemplateDialog
                        {...props}
                        saveTemplateDialogVisible={saveTemplateDialogVisible}
                        setSaveTemplateDialogVisible={
                          setSaveTemplateDialogVisible
                        }
                        values={values}
                      />
                    )}
                    {showPrintPreview ? (
                      <>
                        <ContentArea
                          {...props}
                          Facilities={Facilities}
                          Report={Report}
                          values={values}
                        />
                        <div className={"noPrint"}>
                          <Button
                            className={"mx-1"}
                            variant={"outlined"}
                            size={"small"}
                            color={"primary"}
                            onClick={() => {
                              window.print();
                            }}
                          >
                            Print
                          </Button>
                          <Button
                            className={"mx-1"}
                            variant={"outlined"}
                            size={"small"}
                            color={"primary"}
                            onClick={() => {
                              setShowPrintPreview(false);
                            }}
                          >
                            Return
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={"row"}>
                          <div className={"col"}>
                            <Card style={styles.card}>
                              <CardContent>
                                <div
                                  className={"row"}
                                  style={{ height: "100%" }}
                                >
                                  <div
                                    className={"col"}
                                    style={styles.outerContentArea}
                                  >
                                    <div style={styles.innerContentArea}>
                                      <ContentArea
                                        {...props}
                                        Facilities={Facilities}
                                        Report={Report}
                                        values={values}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={"col-4"}
                                    style={styles.outerSidebar}
                                  >
                                    <div style={styles.innerSidebar}>
                                      <Sidebar
                                        {...props}
                                        Facilities={Facilities}
                                        values={values}
                                        ReportTemplates={ReportTemplates.Active}
                                        form={form}
                                        disabled={disabled}
                                      />
                                    </div>
                                    <div className={"row pt-3"}>
                                      <div
                                        className={
                                          "col d-flex align-items-center justify-content-end"
                                        }
                                      >
                                        <ButtonGroup
                                          size="small"
                                          variant={"outlined"}
                                          aria-label="small outlined button group"
                                        >
                                          <Button
                                            color="primary"
                                            disabled={!Report.Fetched}
                                            onClick={() => {
                                              setShowPrintPreview(true);
                                            }}
                                          >
                                            Print Preview
                                          </Button>
                                          <Button
                                            color="primary"
                                            type={"submit"}
                                            disabled={
                                              Report.Loading ||
                                              pristine ||
                                              disabled
                                            }
                                          >
                                            View Report
                                          </Button>
                                          <Button
                                            color="primary"
                                            onClick={() => {
                                              form.mutators.clearTemplateName();
                                              setSaveTemplateDialogVisible(
                                                true
                                              );
                                            }}
                                            disabled={disabled || pristine}
                                          >
                                            Save Template
                                          </Button>
                                          <Button
                                            color={"secondary"}
                                            onClick={() => {
                                              props.dispatch(
                                                ReportActions.Reset()
                                              );
                                            }}
                                          >
                                            Revise
                                          </Button>
                                          <Button
                                            color={"secondary"}
                                            onClick={() => {
                                              form.mutators.clearAll();
                                            }}
                                            disabled={
                                              Report.Loading ||
                                              pristine ||
                                              disabled
                                            }
                                          >
                                            Clear
                                          </Button>
                                        </ButtonGroup>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          </div>
                        </div>
                      </>
                    )}
                  </form>
                </>
              )}
            />
          </>
        }
      />
    </>
  );
};

export default Reports;
