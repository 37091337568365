import React from "react";
import withStyles from "@mui/styles/withStyles";
import { makeStyles } from "@mui/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Checkboxes } from "mui-rff";
import { Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReportKeys from "../../ReportKeys";

const programOverviewOptions = [
  { label: "Program Overview", value: ReportKeys.ProgramOverview },
];

const outcomeReportsOptions = [
  {
    label: "Records Reviewed by Committee",
    value: ReportKeys.RecordsReviewedByCommittee,
  },
  {
    label: "Provider Records Reviewed",
    value: ReportKeys.ProviderRecordsReviewedByCommittee,
  },
  { label: "Reason for Review", value: ReportKeys.ReasonForReview },
  {
    label: "Reason for Review by Provider",
    value: ReportKeys.ReasonForReviewByProvider,
  },
  { label: "Peer Review Outcomes", value: ReportKeys.PeerReviewOutcomes },
  { label: "Outcomes by Provider", value: ReportKeys.OutcomesByProvider },
];

const checklistReportOptions = [
  { label: "ARCHI Trend Issues", value: ReportKeys.RCHITrendIssues },
  {
    label: "Discharge Instructions - Medical Abbreviations",
    value: ReportKeys.DischargeInstructionsMedicalAbbreviations,
  },
  {
    label: "Discharge Instructions - Medical Terminology",
    value: ReportKeys.DischargeInstructionsMedicalTerminology,
  },
  {
    label: "Discharge Instructions - Teachbacks",
    value: ReportKeys.DischargeInstructionsTeachbacks,
  },
  {
    label: "Discharge or Transfer Documents Provided",
    value: ReportKeys.DischargeOrTransferDocumentsProvided,
  },
  {
    label: "Evidence of Medication Reconciliation",
    value: ReportKeys.EvidenceOfMedicationReconciliation,
  },
  {
    label: "Ethics",
    value: ReportKeys.EthicsIssue,
  },
  {
    label: "Legibility Issues by Provider Type",
    value: ReportKeys.LegibilityIssuesByProviderType,
  },
  { label: "Medical Management", value: ReportKeys.MedicalManagement },
  {
    label: "Other Patient Safety Concern",
    value: ReportKeys.OtherPatientSafetyConcern,
  },
  { label: "Patient Disposition", value: ReportKeys.PatientDisposition },
  { label: "Patient Outcome", value: ReportKeys.PatientOutcome },
  { label: "Sentinel Event", value: ReportKeys.SentinelEvent },
  { label: "System Issues", value: ReportKeys.SystemIssues },
];

const recommendationReportOptions = [
  { label: "Possible Improvements", value: ReportKeys.PossibleImprovements },
  { label: "Abbreviation Use", value: ReportKeys.AbbreviationUse },
];

const patientTypeReportOptions = [
  { label: "Patient Age", value: ReportKeys.PatientAge },
  { label: "Patient Gender", value: ReportKeys.PatientGender },
  { label: "Patient Race", value: ReportKeys.PatientRace },
  { label: "Length of Stay", value: ReportKeys.LengthOfStay },
];

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  label: {
    fontSize: "8px",
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles({
  root: {
    fontSize: ".9rem",
  },
});

const AccordionContainer = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <div className={"row pt-2"}>
        <div
          className={"col d-flex align-items-center justify-content-begin ml-2"}
        >
          <Checkboxes
            size={"small"}
            name={"programOverviewSelected"}
            data={programOverviewOptions}
            color={"primary"}
            disabled={props.disabled}
          />
        </div>
      </div>
      <div className={"row pt-2"}>
        <div className={"col"}>
          <div className={"pr-2 pb-2"}>
            <Accordion
              square
              expanded={expanded === "outcomeReports"}
              onChange={handleChange("outcomeReports")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="outcome-reports-content"
                id="outcome-reports-header"
              >
                <Typography>Outcome Reports</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={"container-fluid"}>
                  <div className={"row"}>
                    <div
                      className={
                        "col d-flex align-items-center justify-content-begin pl-3"
                      }
                    >
                      <Button
                        className={"mx-1"}
                        variant={"outlined"}
                        size={"small"}
                        color={"primary"}
                        onClick={
                          props.form.mutators.selectAllOutcomeReportsOptions
                        }
                        disabled={props.disabled}
                      >
                        Select All
                      </Button>
                      <Button
                        className={"mx-1"}
                        variant={"outlined"}
                        size={"small"}
                        color={"secondary"}
                        onClick={
                          props.form.mutators.unselectAllOutcomeReportsOptions
                        }
                        disabled={props.disabled}
                      >
                        Unselect All
                      </Button>
                    </div>
                  </div>
                  <div className={"row"}>
                    <div
                      className={
                        "col pl-4 d-flex align-items-center justify-content-begin"
                      }
                    >
                      <Checkboxes
                        size={"small"}
                        name={"outcomeReports"}
                        data={outcomeReportsOptions}
                        color={"primary"}
                        disabled={props.disabled}
                        formControlLabelProps={{
                          classes: {
                            label: classes.root,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "checklistReports"}
              onChange={handleChange("checklistReports")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="checklist-reports-content"
                id="checklist-reports-header"
              >
                <Typography>Checklist Reports</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={"container-fluid"}>
                  <div className={"row"}>
                    <div
                      className={
                        "col d-flex align-items-center justify-content-begin pl-3"
                      }
                    >
                      <Button
                        className={"mx-1"}
                        variant={"outlined"}
                        size={"small"}
                        color={"primary"}
                        onClick={
                          props.form.mutators.selectAllChecklistReportsOptions
                        }
                        disabled={props.disabled}
                      >
                        Select All
                      </Button>
                      <Button
                        className={"mx-1"}
                        variant={"outlined"}
                        size={"small"}
                        color={"secondary"}
                        onClick={
                          props.form.mutators.unselectAllChecklistReportsOptions
                        }
                        disabled={props.disabled}
                      >
                        Unselect All
                      </Button>
                    </div>
                  </div>
                  <div className={"row"}>
                    <div
                      className={
                        "col pl-4 d-flex align-items-center justify-content-begin"
                      }
                    >
                      <Checkboxes
                        size={"small"}
                        name={"checklistReports"}
                        data={checklistReportOptions}
                        color={"primary"}
                        disabled={props.disabled}
                        formControlLabelProps={{
                          classes: {
                            label: classes.root,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "recommendationReports"}
              onChange={handleChange("recommendationReports")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="recommendation-reports-content"
                id="recommendation-reports-header"
              >
                <Typography>Recommendation Reports</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={"container-fluid"}>
                  <div className={"row"}>
                    <div
                      className={
                        "col d-flex align-items-center justify-content-begin pl-3"
                      }
                    >
                      <Button
                        className={"mx-1"}
                        variant={"outlined"}
                        size={"small"}
                        color={"primary"}
                        onClick={
                          props.form.mutators
                            .selectAllRecommendationReportsOptions
                        }
                        disabled={props.disabled}
                      >
                        Select All
                      </Button>
                      <Button
                        className={"mx-1"}
                        variant={"outlined"}
                        size={"small"}
                        color={"secondary"}
                        onClick={
                          props.form.mutators
                            .unselectAllRecommendationReportsOptions
                        }
                        disabled={props.disabled}
                      >
                        Unselect All
                      </Button>
                    </div>
                  </div>
                  <div className={"row"}>
                    <div
                      className={
                        "col pl-4 d-flex align-items-center justify-content-begin"
                      }
                    >
                      <Checkboxes
                        size={"small"}
                        name={"recommendationReports"}
                        data={recommendationReportOptions}
                        color={"primary"}
                        disabled={props.disabled}
                        formControlLabelProps={{
                          classes: {
                            label: classes.root,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "patientTypeReports"}
              onChange={handleChange("patientTypeReports")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="patient-type-reports-content"
                id="patient-type-reports-header"
              >
                <Typography>Patient Type Reports</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={"container-fluid"}>
                  <div className={"row"}>
                    <div
                      className={
                        "col d-flex align-items-center justify-content-begin pl-3"
                      }
                    >
                      <Button
                        className={"mx-1"}
                        variant={"outlined"}
                        size={"small"}
                        color={"primary"}
                        onClick={
                          props.form.mutators.selectAllPatientTypeReportsOptions
                        }
                        disabled={props.disabled}
                      >
                        Select All
                      </Button>
                      <Button
                        className={"mx-1"}
                        variant={"outlined"}
                        size={"small"}
                        color={"secondary"}
                        onClick={
                          props.form.mutators
                            .unselectAllPatientTypeReportsOptions
                        }
                        disabled={props.disabled}
                      >
                        Unselect All
                      </Button>
                    </div>
                  </div>
                  <div className={"row"}>
                    <div
                      className={
                        "col pl-4 d-flex align-items-center justify-content-begin"
                      }
                    >
                      <Checkboxes
                        size={"small"}
                        name={"patientTypeReports"}
                        data={patientTypeReportOptions}
                        color={"primary"}
                        disabled={props.disabled}
                        formControlLabelProps={{
                          classes: {
                            label: classes.root,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccordionContainer;
