import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityLegibilityIssuesByProviderTypeChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Legibility Issues by Provider Type"}
        xAxisLabel={"Issues Found"}
        yAxisLabel={"Number of Records"}
        figureText={
          "Records with Legibility Issues by Provider Type for all RPPR© Facilities"
        }
      />
    </>
  );
};

export default AllFacilityLegibilityIssuesByProviderTypeChart;
