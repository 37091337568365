import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import tabData from "./structure";

const Panels = (props) => {
  const { selectedTool, record, isFormDirty, setIsFormDirty } = props;
  return (
    <div className={"container-fluid"} style={{ width: "96%" }}>
      {tabData.map(({ name, Component }) => (
        <TabPanel value={selectedTool} index={name}>
          <Component
            {...props}
            selectedTool={selectedTool}
            record={record}
            isFormDirty={isFormDirty}
            setIsFormDirty={setIsFormDirty}
          />
        </TabPanel>
      ))}
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default Panels;
