export const worksheetTemplate = {
  columns: [
    [
      { label: "Page Range for Part A", name: "PageRangePartA" },
      { label: "Page Range for Part B", name: "PageRangePartB" },
      { label: "Emergency Room Record", name: "EmergencyRoomRecord" },
      { label: "History and Physical", name: "HistoryAndPhysical" },
      { label: "Operative Report", name: "OperativeReport" },
      { label: "Anesthesia", name: "Anesthesia" },
      { label: "Progress Notes", name: "ProgressNotes" },
      { label: "Discharge Summary", name: "DischargeSummary" },
    ],
    [
      { label: "Laboratory Studies", name: "LaboratoryStudies" },
      { label: "Imaging", name: "Imaging" },
      { label: "EKG", name: "EKG" },
      { label: "Strips", name: "Strips" },
      { label: "D/C Instructions", name: "DCInstructions" },
      { label: "Nursing Note Summary (IO, VS)", name: "NursingNoteSummary" },
      { label: "MAR", name: "MAR" },
      { label: "Physician's Orders", name: "PhysicianOrders" },
      { label: "Total Pages", name: "TotalPages" },
    ],
  ],
};

export default worksheetTemplate;
