import React from "react";
import { connect, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import MissedBlindingsForm from "./MissedBlindingsForm";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(2)} 0`,
  },
}));

const Blindings = (props) => {
  const BlindingOptions = useSelector((state) => state.BlindingOptions).filter(
    (b) => Number(b.Active) !== 0
  );
  const { MissedBlindings, dispatch, isFormDirty, setIsFormDirty } = props;
  const RecordID = props.record.ID;
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={4} variant="elevation">
      <MissedBlindingsForm
        RecordID={RecordID}
        MissedBlindings={MissedBlindings}
        blindingOptions={BlindingOptions}
        dispatch={dispatch}
        isFormDirty={isFormDirty}
        setIsFormDirty={setIsFormDirty}
      />
    </Paper>
  );
};

const mapStateToProps = (state, props) => {
  return {
    MissedBlindings: state.Record.find(
      (record) => record.ID === props.record.ID
    ).MissedBlinding,
  };
};

export default connect(mapStateToProps)(Blindings);
