import React from "react";

const TextBegin = (props) => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>Sentinel Event</h4>
      </div>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              A sentinel event is a Patient Safety Event that reaches a patient
              and results in any of the following:
            </p>
            <br />
            <ul className={"text-left"}>
              <li>Death</li>
              <li>Permanent harm</li>
              <li>
                Severe temporary harm and intervention required to sustain life
              </li>
            </ul>
            <br />
            <p className={"text-left"}>
              An event can also be considered sentinel event even if the outcome
              was not death, permanent harm, severe temporary harm and an
              intervention required to sustain life. Such events are called
              "sentinel" because they signal the need for immediate
              investigation and response.
            </p>
            <br />
            <p className={"text-left"}>
              Careful investigation and analysis of Patient Safety Events
              (events not primarily related to the natural course of the
              patient's illness or underlying condition), as well as evaluation
              of corrective actions, is essential to reduce risk and prevent
              patient harm.
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextBegin;
