import React from "react";
import { KeyboardDatePicker } from "mui-rff";

const MeetingDate = (props) => {
  return (
    <div className={"row pt-2"}>
      <div className={"col"}>
        <KeyboardDatePicker
          label={"Meeting Date"}
          variant={"inline"}
          inputVariant={"outlined"}
          name={"meetingDate"}
          format={"MM/dd/yyyy"}
          disabled={props.disabled}
          required
        />
      </div>
    </div>
  );
};

export default MeetingDate;
