import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityMedicalManagementChart from "./AllFacilityMedicalManagementChart";
import FacilitySpecificMedicalManagementChart from "./FacilitySpecificMedicalManagementChart";
import TextBegin from "./TextBegin";

const MedicalManagement = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, [
    "medicalManagement",
  ]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, [
      "medicalManagement",
    ]);

  return (
    <>
      <TextBegin />
      {chartDataAllFacilities !== false && (
        <AllFacilityMedicalManagementChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Medical Management"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificMedicalManagementChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Medical Management"}
        rows={chartData.rows}
      />
    </>
  );
};

export default MedicalManagement;
