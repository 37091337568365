import React from "react";
// data
import { useSelector } from "react-redux";
import {
  selectContacts,
  selectFacilities,
  selectMidlevels,
  selectProviders,
} from "src/redux/selectors/personnelSelectors";
// ui
import { MenuItem, Typography } from "@mui/material";
import { Select } from "mui-rff";
import { Field, FormSpy } from "react-final-form";

const PersonnelInvolved = (props) => {
  const Facilities = useSelector(selectFacilities).filter(
    (f) => f.Active === true || f.Active === 1
  );
  const Contacts = useSelector(selectContacts).filter(
    (c) => c.Active === true || c.Active === 1
  );
  const Providers = useSelector(selectProviders).filter(
    (p) => p.Active === true || p.Active === 1
  );
  const Midlevels = useSelector(selectMidlevels).filter(
    (m) => m.Active === true || m.Active === 1
  );

  return (
    <Field name={"facility"}>
      {({ input }) => (
        <>
          <div className={"row"} id={"personnel-involved-title"}>
            <div className={"col"}>
              <Typography variant="h4" className={props.classes.title}>
                Personnel Involved
              </Typography>
            </div>
          </div>
          <div className={"row pt-2 mx-1"}>
            <div className={"col"}>
              <FormSpy subscription={{ form: true }}>
                {({ form }) => (
                  <Select
                    variant={"outlined"}
                    label={"Facility"}
                    displayEmpty
                    name="facility"
                    required
                  >
                    <MenuItem value={null} disabled>
                      Select a Facility
                    </MenuItem>
                    {Facilities.map((facility) => (
                      <MenuItem
                        value={facility}
                        key={`create-mrts-record-facility-select-${facility.FullName}-${facility.ID}`}
                        onClick={() => {
                          form.mutators.clearSelectedCommittee();
                          form.mutators.clearCommitteeOther();
                          form.mutators.setSelectedContact(null);
                          form.mutators.setSelectedProvider(null);
                          form.mutators.setSelectedMidlevel(null);
                        }}
                      >
                        {facility.FullName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormSpy>
            </div>
            <div className={"col"}>
              <Select
                variant={"outlined"}
                label={"Contact"}
                displayEmpty
                name="contact"
                required
              >
                <MenuItem value={null} disabled>
                  Select a Contact
                </MenuItem>
                {Contacts.filter(
                  (c) => Number(c.FacilityID) === Number(input.value.ID)
                ).map((contact) => (
                  <MenuItem
                    value={contact}
                    key={`create-mrts-record-contact-select-${contact.FirstName}-${contact.LastName}-${contact.ID}`}
                  >
                    {`${contact.FirstName} ${contact.LastName}`}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={"col"}>
              <Select
                variant={"outlined"}
                label={"Provider"}
                displayEmpty
                name="provider"
                required
              >
                <MenuItem value={null} disabled>
                  Select a Provider
                </MenuItem>
                {Providers.filter((p) => {
                  let valid = false;
                  p.Facility.forEach((f) => {
                    if (f.ID === input.value.ID) {
                      valid = true;
                    }
                  });
                  return valid;
                }).map((provider) => (
                  <MenuItem
                    value={provider}
                    key={`create-mrts-record-provider-select-${provider.FirstName}-${provider.LastName}-${provider.ID}`}
                  >
                    {`${provider.FirstName} ${provider.LastName} (${provider.ARCHIProviderID})`}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={"col"}>
              <Select
                variant={"outlined"}
                label={"Midlevel"}
                displayEmpty
                name="midlevel"
              >
                <MenuItem value={null}>Select a Midlevel</MenuItem>
                {Midlevels.filter((m) => {
                  let valid = false;
                  m.Facility.forEach((f) => {
                    if (f.ID === input.value.ID) {
                      valid = true;
                    }
                  });
                  return valid;
                }).map((midlevel) => (
                  <MenuItem
                    value={midlevel}
                    key={`create-mrts-record-midlevel-select-${midlevel.FirstName}-${midlevel.LastName}-${midlevel.ID}`}
                  >
                    {`${midlevel.FirstName} ${midlevel.LastName} (${midlevel.ARCHIProviderID})`}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </>
      )}
    </Field>
  );
};

export default PersonnelInvolved;
