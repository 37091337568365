const rulesPolicyProtocol = (isLegacyVersion) => ({
  label: "Rules/Policy/Protocol",
  render: { type: "Title" },
  items: [rulesApplicable(), policiesSubmitted(isLegacyVersion)],
});

const rulesApplicable = () => ({
  name: "rulesApplicable",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
});

const policiesSubmitted = (isLegacyVersion) => ({
  name: "policiesSubmitted",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No"],
    hide: isLegacyVersion,
  },
  itemsDependOn: [{ name: "policiesSubmitted", is: "Yes" }],
  items: [
    documentationPolicies(),
    treatmentPolicies(),
    transferPolicies(),
    otherPolicies(),
  ],
  version: 2,
});

const documentationPolicies = () => ({
  name: "documentationPolicies",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No"],
  },
});

const treatmentPolicies = () => ({
  name: "treatmentPolicies",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No"],
  },
});

const transferPolicies = () => ({
  name: "transferPolicies",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No"],
  },
});

const otherPolicies = () => ({
  name: "otherPolicies",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No"],
  },
});

export default rulesPolicyProtocol;
