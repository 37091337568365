import Abbreviations from "../Abbreviations";
import Blindings from "../Blindings";
import Checklist from "../Checklist";
import Worksheet from "../Worksheet";
import Comments from "../Comments";
import Outcomes from "../Outcomes";
import Signatures from "../Signatures";
import { emptyObject } from "src/utilities/objectUtilities";

const tabData = [
  {
    name: "Abbreviations",
    record: { name: "RecordAbbreviation", empty: [] },
    emptyObject: { name: "RNAbbreviations" },
    Component: Abbreviations,
  },
  {
    name: "Blindings",
    record: { name: "MissedBlinding", empty: [] },
    emptyObject: { name: "RNBlindings" },
    Component: Blindings,
  },
  {
    name: "Checklist",
    record: {
      name: "Checklist",
      empty: [],
      method: (data = []) =>
        data.every(({ OptionValue }) =>
          ["Pending", "", "false"].includes(OptionValue)
        ),
    },
    emptyObject: { name: "RNChecklist" },
    Component: Checklist,
  },
  {
    name: "Worksheet",
    record: {
      name: "Worksheet",
      empty: null,
      method: (data = emptyObject("Worksheet")) =>
        Object.entries(emptyObject("Worksheet")).reduce(
          (acc, [key, value]) => (data[key] === value ? acc : false),
          true
        ),
    },
    emptyObject: { name: "Worksheet" },
    Component: Worksheet,
  },
  {
    name: "Comments",
    record: {
      name: "RNComments",
      empty: [],
      method: (data = [emptyObject("RNComments")]) =>
        Object.entries(emptyObject("RNComments")).reduce(
          (acc, [key, value]) => (data[0][key] === value ? acc : false),
          true
        ),
    },
    emptyObject: { name: "RNComments" },
    Component: Comments,
  },
  {
    name: "Outcomes",
    record: {
      name: "RNOutcomes",
      empty: null,
      method: (data = []) =>
        Object.entries(emptyObject("RNOutcomes")).reduce(
          (acc, [key, value]) =>
            data[key] === value || !data[key] ? acc : false,
          true
        ),
    },
    emptyObject: { name: "RNOutcomes" },
    Component: Outcomes,
  },
  {
    name: "Signature",
    record: {
      name: "SignatureID",
      empty: null,
      method: (data) => isNaN(data),
    },
    emptyObject: { name: "RNSignature" },
    Component: Signatures,
  },
];

export default tabData;
