import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../PDFUtilities/PDFStyles";
import { RenderSubTitleCard } from "../../PDFUtilities/RenderPDFFunctions";

const Comments = ({ reviewReasons, comments, rnOutcomes, outcomesList }) => {
  let committeeQuestion = "";
  comments.forEach((element) => {
    if (element.TypeID === 3) {
      committeeQuestion = element.Description;
    }
  });

  const peerReviewContent = rnOutcomes
    ? outcomesList.find((outcome) => outcome.ID === rnOutcomes.OutcomeID)
    : "";

  const peerReviewContentName = peerReviewContent
    ? peerReviewContent.Outcome
    : "Pending";

  let outcomeStyle = { ...styles.commentText };
  if (peerReviewContentName === "Pending") {
    outcomeStyle.color = "red";
  }

  return (
    <View>
      <RenderSubTitleCard title={"Reason(s) for Review"}>
        <Text style={styles.commentText}>{reviewReasons}</Text>
      </RenderSubTitleCard>
      <RenderSubTitleCard title={"Clinical Question/Comments"}>
        <Text style={styles.commentText}>{committeeQuestion}</Text>
      </RenderSubTitleCard>
      <RenderSubTitleCard title={"Peer Review Outcome"} wrap={false}>
        <Text style={outcomeStyle}>{peerReviewContentName}</Text>
      </RenderSubTitleCard>
    </View>
  );
};

export default Comments;
