import React from "react";
// data
import { useSelector } from "react-redux";
import { selectContent } from "src/redux/selectors";
// ui
import { Checkboxes, TextField } from "mui-rff";
import { Field } from "react-final-form";
import Typography from "@mui/material/Typography";

const ReferenceMaterial = (props) => {
  const ReferenceMaterials = useSelector(
    selectContent("ReferenceMaterial")
  ).filter((r) => r.Active === true || r.Active === 1);

  const otherReferenceMaterial = ReferenceMaterials.find(
    (r) => r.ReferenceMaterial === "Other"
  );

  return (
    <>
      <div className={"row pt-2"} id={"reference-material-title"}>
        <div className={"col"}>
          <Typography variant="h4" className={props.classes.title}>
            Reference Material
          </Typography>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <Checkboxes
            name="referenceMaterialChecklist"
            data={ReferenceMaterials.map((referenceMaterial) => {
              return {
                label: referenceMaterial.ReferenceMaterial,
                value: referenceMaterial.ID,
              };
            })}
            size={"small"}
          />
        </div>
      </div>
      <Field name={"referenceMaterialChecklist"}>
        {({ input }) => (
          <>
            {otherReferenceMaterial !== undefined &&
              otherReferenceMaterial !== null &&
              input.value.includes(otherReferenceMaterial.ID) && (
                <div className={"row pt-2 mx-1"}>
                  <div className={"col"}>
                    <TextField
                      label={"Describe Other Reference Material"}
                      name={"otherReferenceMaterial"}
                      variant={"outlined"}
                    />
                  </div>
                </div>
              )}
          </>
        )}
      </Field>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <TextField
            label={"Additional Information"}
            name={"additionalInformation"}
            variant={"outlined"}
            multiline
            rows={4}
          />
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <TextField
            label={"Committee Question"}
            name={"committeeQuestion"}
            variant={"outlined"}
            multiline
            rows={4}
            required
          />
        </div>
      </div>
    </>
  );
};

export default ReferenceMaterial;
