import React from "react";
import { Radios as MuiRadios } from "mui-rff";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

const Radios = ({ name, render: { options } }) => {
  const classes = useStyles();

  return (
    <Box className={classes.radio}>
      <MuiRadios
        name={name}
        data={options.map((option) => ({ label: option, value: option }))}
        className={classes.radio}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  radio: {
    textAlign: "left",
  },
}));

export default Radios;
