import React from "react";
import RecursiveComponents from "./listOfComponents";
import { makeStyles } from "@mui/styles";
import { Field } from "react-final-form";

const RenderEachItem = ({ items, itemsDependOn = [], indent }) => {
  return (
    <SubscribeWrapper
      items={items}
      itemsDependOn={itemsDependOn}
      indent={indent}
    />
  );
};

const SubscribeWrapper = ({ itemsDependOn = [], items, indent }) =>
  itemsDependOn.reduce(
    (acc, { name, method }) => (
      <Field name={name}>
        {({ input: { value } }) => (method(value) ? acc : null)}
      </Field>
    ),
    <RenderItems items={items} indent={indent} />
  );

const RenderItems = ({ indent, items }) => {
  const classes = useStyles();

  return (
    <div className={indent && classes.leftIndent}>
      {items &&
        items.map((item) => {
          const RenderComponent = RecursiveComponents[item.render.type];
          return !item.render.hide ? (
            <RenderComponent
              render={item.render}
              label={item.label}
              name={item.name}
              valueDependsOn={item.valueDependsOn}
              value={item.value}
              items={item.items}
              itemsDependOn={item.itemsDependOn}
              itemsVisible={item.itemsVisible}
              options={item.options}
              bitID={item.bitID}
            />
          ) : (
            <></>
          );
        })}
    </div>
  );
};

export default RenderEachItem;

const useStyles = makeStyles((theme) => ({
  leftIndent: {
    marginLeft: theme.spacing(5),
  },
}));
