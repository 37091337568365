import React, { useMemo } from "react";
import { Autocomplete } from "mui-rff";
import { useSelector } from "react-redux";
import { selectFacilities } from "src/redux/selectors";
import { globalVariables } from "src/components/App";
import { facilityUser } from "src/utilities/rbacFunctions";

const SelectFacility = () => {
  const { roles, data } = React.useContext(globalVariables);
  const facilities = useSelector(selectFacilities);
  const facilityOptions = useMemo(
    () =>
      facilities
        .filter(
          ({ ARCHIFacilityID, Active }) =>
            Active &&
            (!facilityUser(roles) ||
              ARCHIFacilityID === data.current.FacilityID)
        )
        .map(({ ID, FullName }) => ({ label: FullName, value: ID })),
    [facilities, data.current.FacilitatorID]
  );

  return (
    <Autocomplete
      name={"FacilityID"}
      label={"Facility"}
      autoComplete
      includeInputInList
      options={facilityOptions}
      getOptionValue={(x) => x.value}
      getOptionLabel={(x) => x.label}
    />
  );
};

export default SelectFacility;
