export const sortAlphabetically = (valA = "", valB = "") => {
  if (valA === undefined || valB === undefined || valA === valB) return 0;
  const nameA = valA.toUpperCase();
  const nameB = valB.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const findSortedIndex = (
  array,
  value,
  order = ["LastName", "FirstName", "ARCHIProviderID"]
) => {
  let low = 0,
    high = array.length;

  const compare = (middle, paramValue, i = 0) => {
    if (middle[order[i]] === paramValue[order[i]])
      return compare(middle, paramValue, i + 1);
    return middle[order[i]] < paramValue[order[i]];
  };

  while (low < high) {
    const mid = (low + high) >>> 1;
    if (compare(array[mid], value)) low = mid + 1;
    else high = mid;
  }
  return low;
};
