import React, { useContext, useEffect } from "react";
import { Field, FormSpy } from "react-final-form";
import { TextField } from "mui-rff";
import Diff from "react-stylable-diff";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
// import Button from "@mui/material/Button";
// import C from "src/utilities/constants";
// import { recordReviewData } from "../RecordReview";
// import _ from "lodash";

const TextCompare = ({ name, label, oValue, fValue }) => {
  // const apiResponse = useContext(recordReviewData);
  // const reviewStatus = _.get(apiResponse, `ReviewStatus`, "");
  const classes = useStyles();
  return (
    <Field name={"textStages"}>
      {({ input: { value: textStages } }) => {
        return (
          <Field name={name}>
            {({ input: { value } }) => {
              // const isRNAdmin = [
              //   C.REVIEW_STATUS.PENDING_RN_APPROVAL,
              //   C.REVIEW_STATUS.RN_REVIEW_IN_PROGRESS,
              // ].includes(reviewStatus);
              // const isApprovalNeeded =
              //   isRNAdmin &&
              //   oValue !== fValue &&
              //   !textStages.some(({ name: textName }) => textName === name);
              // const isRejected =
              //   textStages.some(({ name: textName }) => textName === name) &&
              //   textStages.find(({ name: textName }) => textName === name)
              //     .stage === "rejected";
              // const isDisabled = isRNAdmin && isApprovalNeeded;
              const referenceValue = oValue;
              // isRNAdmin &&
              // !(oValue === fValue || isApprovalNeeded || isRejected)
              //   ? fValue
              //   : oValue;
              const isDifference = value !== referenceValue;
              return (
                <>
                  <TextField
                    className={classes.spacing}
                    label={label}
                    name={name}
                    rows={5}
                    // disabled={isDisabled}
                    multiline
                  />
                  {isDifference && (
                    <Grid container spacing={1} direction="column">
                      <Grid item>
                        <Diff
                          type="words"
                          inputA={referenceValue}
                          inputB={value}
                        />
                      </Grid>
                      {/*<Grid item>*/}
                      {/*  {isApprovalNeeded && (*/}
                      {/*    <ApprovalTextButtons*/}
                      {/*      name={name}*/}
                      {/*      textStages={textStages}*/}
                      {/*      oValue={oValue}*/}
                      {/*      fValue={fValue}*/}
                      {/*      isApprovalNeeded={isApprovalNeeded}*/}
                      {/*    />*/}
                      {/*  )}*/}
                      {/*</Grid>*/}
                    </Grid>
                  )}
                </>
              );
            }}
          </Field>
        );
      }}
    </Field>
  );
};

// const ApprovalTextButtons = ({ name, textStages, oValue, fValue }) => {
//   const { setCountInvalid } = useContext(recordReviewData);
//   useEffect(() => {
//     setCountInvalid((count) => count + 1);
//     return function cleanup() {
//       setCountInvalid((count) => count - 1);
//     };
//   });
//
//   return (
//     <FormSpy>
//       {({ form }) => {
//         return (
//           <Grid item container direction="row" spacing={1} justify={"flex-end"}>
//             <Grid item>
//               <Button
//                 variant="contained"
//                 size={"small"}
//                 color={"primary"}
//                 onClick={() => {
//                   const newTextStages = _.cloneDeep(textStages);
//                   newTextStages.push({ name, stage: "approved" });
//                   form.change("textStages", newTextStages);
//                   form.change(name, fValue);
//                 }}
//               >
//                 Approve
//               </Button>
//             </Grid>
//             <Grid item>
//               <Button
//                 variant="contained"
//                 size={"small"}
//                 color={"secondary"}
//                 onClick={() => {
//                   const newTextStages = _.cloneDeep(textStages);
//                   newTextStages.push({ name, stage: "rejected" });
//                   form.change("textStages", newTextStages);
//                   form.change(name, oValue);
//                 }}
//               >
//                 Reject
//               </Button>
//             </Grid>
//           </Grid>
//         );
//       }}
//     </FormSpy>
//   );
// };

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginBlock: theme.spacing(2),
  },
}));

export default TextCompare;
