import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { LoadedActions } from "src/redux/reducers/Loaded";
import { store } from "src";
import { facilitator as facilitatorRole } from "src/utilities/rbacFunctions";
import { RecordActions } from "src/redux/reducers/Record";
import { MeetingActions } from "src/redux/reducers/Meeting";

const reviewSlice = createSlice({
  name: "review",
  initialState: [],
  reducers: {
    add: (state, action) => {
      let index = state.findIndex((r) => r.ID === action.payload.ID);
      if (index > -1) {
        state[index] = action.payload;
      } else {
        state.push(action.payload);
      }
    },
    batchAdd: (state, action) => {
      const newState = [...state, ...action.payload];
      return _.orderBy(newState, ["ID"], "desc");
    },
    batchReset: (state, action) => {
      return _.orderBy(action.payload, ["ID"], "desc");
    },
  },
});

const { add, batchAdd, batchReset } = reviewSlice.actions;

const Add = (content) => async (dispatch) => {
  dispatch(add(content));
};

const BatchAdd = () => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.get(
      `${apiUrl}facilitatorReview/`
    );
    dispatch(batchAdd(contents));
    dispatch(LoadedActions.SetReview(true));
  } catch (error) {
    dispatch(FetchingActions.Failure("Fetching Reviews"));
  }
};

const BatchReset = () => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.get(
      `${apiUrl}facilitatorReview/`
    );
    dispatch(batchReset(contents));
    dispatch(LoadedActions.SetReview(true));
  } catch (error) {
    dispatch(FetchingActions.Failure("Fetching Reviews"));
  }
};

const BatchAddMeetingDashboardData = (roles) => async (dispatch) => {
  const Loaded = _.get(store.getState(), "Loaded", {
    Meeting: false,
    Record: false,
    Review: false,
  });
  try {
    if ([Loaded.Meeting, Loaded.Record, Loaded.Review].includes(false)) {
      if (facilitatorRole(roles)) {
        const {
          data: { meetings, recordReviews, records },
        } = await apiService.get(
          `${apiUrl}facilitatorReview/getAssociatedData`
        );
        dispatch(MeetingActions.BatchAddFromContent(meetings));
        dispatch(RecordActions.BatchAddFromContent(records));
        dispatch(batchReset(recordReviews));
      } else {
        Loaded.Meeting === false && dispatch(MeetingActions.BatchAdd());
        Loaded.Record === false && dispatch(RecordActions.BatchAdd(roles));
        Loaded.Review === false && dispatch(BatchReset());
      }
      dispatch(LoadedActions.SetReview(true));
    } else if (!facilitatorRole(roles)) {
      // If all data is loaded, reset the meeting data:
      dispatch(MeetingActions.BatchReset());
    }
  } catch (error) {
    dispatch(FetchingActions.Failure("Meeting Dashboard Data Error"));
  }
};

export const ReviewActions = {
  Add,
  BatchAdd,
  BatchReset,
  BatchAddMeetingDashboardData,
};

export default reviewSlice.reducer;
