import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityDischargeInstructionsTeachbacksChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Discharge Instructions - Teach backs"}
        xAxisLabel={"Discharge Instructions - Teach back"}
        yAxisLabel={"Number of Records"}
        figureText={
          "Discharge Instructions Teach backs for all RPPR© Facilities"
        }
      />
    </>
  );
};

export default AllFacilityDischargeInstructionsTeachbacksChart;
