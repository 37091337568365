import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityARCHITrendIssuesChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: ARCHI Trend Issues"}
        xAxisLabel={"ARCHI Trend Issues"}
        yAxisLabel={"Number of Records"}
        figureText={
          "Records reviewed by ARCHI Trend Issues for all RPPR© Facilities"
        }
      />
    </>
  );
};

export default AllFacilityARCHITrendIssuesChart;
