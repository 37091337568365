import React, { useContext } from "react";
import { Button } from "@mui/material";
import * as _ from "lodash";
import { TextField, Radios } from "mui-rff";
import { FormSpy } from "react-final-form";
import { globalVariables } from "src/components/App";
import { recordReviewData } from "src/components/pages/MeetingManagement/RecordReview";
import C from "src/utilities/constants";
import { facilitator as facilitatorRole } from "src/utilities/rbacFunctions";
import ViewDirectReviewComments from "src/components/individual/RecordReview/ViewDirectReviewComments";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import get from "./helperFunctions/getData";

const options = [
  { label: "Side Bar Review", value: "sidebar" },
  { label: "Committee Review", value: "committeeReview" },
];

const FacilitatorRequest = ({ onSubmitRecordReview }) => {
  const apiResponse = useContext(recordReviewData);
  const { roles } = useContext(globalVariables);

  const mrtsReviewTypeID = get.MRTSReviewTypeID(apiResponse);

  const directReviewComments = get.DirectReviewComments(apiResponse);
  const peerReviewNotes = get.PeerReviewNotes(apiResponse);

  return (
    <Grid container>
      <ViewDirectReviewComments
        directReviewComments={directReviewComments}
        peerReviewNotes={peerReviewNotes}
      />
      <Grid item xs={12}>
        {facilitatorRole(roles) && mrtsReviewTypeID === C.DIRECT_REVIEW_ID && (
          <>
            {!(
              apiResponse.SidebarRequested &&
              apiResponse.CommitteeReviewRequested
            ) && (
              <div className={"row"}>
                <div
                  className={
                    "col d-flex align-items-center justify-content-flex-start"
                  }
                >
                  <div className={"pr-3"}>
                    <Typography variant="h6">
                      Request Additional Review
                    </Typography>
                  </div>
                  <FormSpy subscription={{ values: true }}>
                    {({ values, form }) => (
                      <>
                        <div className={"pr-3"}>
                          <Radios
                            name="facilitatorRequest"
                            data={options.filter(
                              ({ value }) =>
                                (value === "sidebar" &&
                                  !apiResponse.SidebarRequested) ||
                                (value === "committeeReview" &&
                                  !apiResponse.CommitteeReviewRequested)
                            )}
                            radioGroupProps={{
                              row: true,
                              style: { marginBottom: "0rem" },
                            }}
                          />
                        </div>
                        <div className={"pr-3"}>
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled={
                              !values.DirectReviewComments ||
                              !values.facilitatorRequest
                            }
                            onClick={() =>
                              onSubmitRecordReview(values, "RequestElevation")
                            }
                          >
                            Submit Request
                          </Button>
                        </div>
                        <div>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                              form.change("facilitatorRequest", null)
                            }
                          >
                            Clear
                          </Button>
                        </div>
                      </>
                    )}
                  </FormSpy>
                </div>
              </div>
            )}
            <div className={"row mb-2"}>
              <div className={"col"}>
                <TextField
                  label={"Direct Review Comments"}
                  name={"DirectReviewComments"}
                  rows={5}
                  multiline
                />
              </div>
            </div>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default FacilitatorRequest;
