import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityEthicsIssueChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Ethics Issue"}
        xAxisLabel={"Ethics Issue"}
        yAxisLabel={"Number of Records"}
        figureText={"Ethics Issue for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilityEthicsIssueChart;
