import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityPatientRaceChart from "./AllFacilityPatientRaceChart";
import FacilitySpecificPatientRaceChart from "./FacilitySpecificPatientRaceChart";
import TextBegin from "./TextBegin";

const PatientRace = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, ["patientRace"]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, ["patientRace"]);

  return (
    <>
      <TextBegin />
      {chartDataAllFacilities !== false && (
        <AllFacilityPatientRaceChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Patient Race"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificPatientRaceChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Patient Race"}
        rows={chartData.rows}
      />
    </>
  );
};

export default PatientRace;
