import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { RecordActions } from "src/redux/reducers/Record";
import GeneratePDF from "src/components/individual/GeneratePDF";

const ApprovedAdminButton = (props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex] = React.useState(7);

  const options = [
    "Edit MRTS",
    "RN Tools",
    "View MRTS",
    "View Facesheet",
    "View Final Report",
    "View Worksheet",
    "Reject MRTS",
    "Edit Tracking",
  ];

  const handleSelection = (index) => {
    switch (index) {
      case 0: {
        props.spawnUpdateMRTSRecordDialog();
        break;
      }
      case 1: {
        props.sendToRNTools();
        break;
      }
      case 2: {
        props.viewMRTS();
        break;
      }
      case 3: {
        props.viewFacesheet();
        break;
      }
      case 4: {
        props.viewFinalReport();
        break;
      }
      case 5: {
        props.viewWorksheet();
        break;
      }
      case 6: {
        props.spawnRejectMRTSRecordDialog();
        break;
      }
      default: {
        props.spawnRecordTrackingDialog();
        break;
      }
    }
  };

  const handleClick = () => {
    handleSelection(selectedIndex);
  };

  const handleMenuItemClick = (event, index) => {
    handleSelection(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="left">
      <Grid item xs={12}>
        <ButtonGroup
          variant="contained"
          color="primary"
          size={"small"}
          ref={anchorRef}
          aria-label="split button"
        >
          <Button onClick={handleClick} style={{ width: "130px" }}>
            {options[selectedIndex]}
          </Button>
          <Button
            color="primary"
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={() => {
              props.setApprovalStatus();
              props.dispatch(RecordActions.LoadData(props.recordID, "ID"));
              handleToggle();
            }}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id={`split-button-menu-${props.id}`}>
                    {props.record.Loaded !== undefined &&
                    props.record.Loaded !== null ? (
                      <>
                        {options.slice(0, -1).map((option, index) => {
                          if (option === "View MRTS") {
                            return (
                              <GeneratePDF {...props} RecordType={"MRTS"}>
                                <MenuItem
                                  key={option}
                                  selected={index === selectedIndex}
                                >
                                  {option}
                                </MenuItem>
                              </GeneratePDF>
                            );
                          } else if (option === "View Facesheet") {
                            return (
                              <GeneratePDF {...props} RecordType={"Facesheet"}>
                                <MenuItem
                                  key={option}
                                  selected={index === selectedIndex}
                                >
                                  {option}
                                </MenuItem>
                              </GeneratePDF>
                            );
                          } else if (option === "View Final Report") {
                            return (
                              <GeneratePDF
                                {...props}
                                RecordType={"FinalReport"}
                              >
                                <MenuItem
                                  key={option}
                                  selected={index === selectedIndex}
                                >
                                  {option}
                                </MenuItem>
                              </GeneratePDF>
                            );
                          } else if (option === "View Worksheet") {
                            return (
                              <GeneratePDF
                                {...props}
                                RecordType={"Worksheet"}
                              >
                                <MenuItem
                                  key={option}
                                  selected={index === selectedIndex}
                                >
                                  {option}
                                </MenuItem>
                              </GeneratePDF>
                            );
                          } else {
                            return (
                              <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            );
                          }
                        })}
                      </>
                    ) : (
                      <MenuItem key={"split-button-menu-loading"}>
                        <i
                          className="fas fa-spinner fa-pulse"
                          style={{ color: "dodgerblue" }}
                        />
                        &nbsp; Loading
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default ApprovedAdminButton;
