import Dropdown from "./Dropdown";
import Title from "./Title";
import SubTitle from "./SubTitle";
import Checkbox from "./Checkbox";
import TextBox from "./TextBox";
import Radios from "./Radios";
import InlineRadios from "./InlineRadios";
import "../../../index.css";

const ListOfComponents = {
  Dropdown,
  Title,
  SubTitle,
  Checkbox,
  TextBox,
  Radios,
  InlineRadios,
};

export default ListOfComponents;
