import React from "react";
import { MenuItem } from "@mui/material";
import { Select } from "mui-rff";

const reviewTypes = [
  { name: "Committee Review", value: "CR" },
  { name: "Direct Review", value: "DR" },
];

const ReviewTypeSelect = (props) => {
  return (
    <div className={"row"}>
      <div className={"col"}>
        <Select
          name={"reviewType"}
          label={"Review Type"}
          labelId="create-meeting-review-type-select-label"
          id="create-meeting-review-type-select"
          variant={"outlined"}
          disabled={props.disabled}
          displayEmpty
          required
        >
          <MenuItem
            onClick={props.form.mutators.clearSubcommittee}
            value={null}
          >
            Select a Review Type
          </MenuItem>
          {reviewTypes.map((option) => (
            <MenuItem
              onClick={props.form.mutators.clearSubcommittee}
              key={`create-meeting-review-type-${option.name}-${option.value}`}
              value={option.value}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default ReviewTypeSelect;
