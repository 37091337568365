import React from "react";

const TextBegin = (props) => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>Reason for Review</h4>
      </div>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              State healthcare hospital facility licensing guidelines and
              accrediting bodies require facilities to have a process in place
              to monitor and evaluate the quality of care delivered. The
              information below provides the categories for which participating
              RPPR© facilities have submitted medical records. This graph
              represents the reasons by category that medical records were
              submitted for review. Other (facility-based criteria, etc)
              represents early Medical Record Transmittal Sheet changes as well
              as reasons not specified on the list provided.
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextBegin;
