import C from "src/utilities/constants";
import { RecordActions } from "src/redux/reducers/Record";
import { ReviewActions } from "src/redux/reducers/Review";
import { FetchingActions } from "src/redux/reducers/Fetching";
import get from "./getData";
import finalizeValues from "./finalizeValues";
import saveRecordReview from "./saveRecordReview";
import facilitatorRequest from "./facilitatorRequest";
import format from "./formatData";

const submitRecordReview = async (
  recordID,
  values,
  actionStage,
  apiResponse,
  setApiResponse,
  authenticate,
  authenticatedDispatch,
  navigate
) => {
  try {
    await authenticate();
    const contents = await saveRecordReview(
      recordID,
      actionStage,
      values,
      apiResponse,
      navigate
    );

    const reviewStatus = get.ReviewStatus(apiResponse);
    if (actionStage === "RequestElevation")
      await facilitatorRequest(
        contents.RecordID,
        values.facilitatorRequest,
        format.Checklist(values, apiResponse),
        format.Comments(values, apiResponse),
        format.Outcomes(values, apiResponse)
      );
    else if (isRnFinalizing(actionStage, reviewStatus)) {
      await authenticatedDispatch(
        RecordActions.UpdateRNTools(await finalizeValues(contents))
      );
    }

    setApiResponse(contents);
    authenticatedDispatch(ReviewActions.BatchReset());
    authenticatedDispatch(FetchingActions.Success("Record Review Submission"));

    navigate(`/MeetingManagement/${values.MeetingID}`);
  } catch (error) {
    authenticatedDispatch(FetchingActions.Failure("Record Review Submission"));
  }
};

const isRnFinalizing = (actionStage, reviewStatus) =>
  actionStage === "Finalize" &&
  [
    C.REVIEW_STATUS.RN_REVIEW_IN_PROGRESS,
    C.REVIEW_STATUS.PENDING_RN_APPROVAL,
  ].includes(reviewStatus);

export default submitRecordReview;
