const mappers = {
    recordDiagnosis: (diagnosisCode) => {
        return {
            ID: diagnosisCode.id,
            // Here, we need to check if it's "NEW" or "existing"
            // New: use Code
            // Existing: use ICDCdoe
            ICDCode: diagnosisCode.Code
                ? // NEW
                diagnosisCode.Code
                : diagnosisCode.code
                    ? // UPDATE
                    diagnosisCode.code
                    : diagnosisCode.ICDCode
                        ? // EXISTING
                        diagnosisCode.ICDCode
                        : // NO CODE AVAILABLE
                        "X00.0",
            CodeAndDescription: diagnosisCode.text,
            DiagnosisType: diagnosisCode.primary ? "Primary" : "Secondary",
            Order: diagnosisCode.primary ? 0 : 1,
            NoCodeAvailable: diagnosisCode.noCodeAvailable ? 1 : 0,
        };
    },
    icdCode: (recordDiagnosis) => {
        return {
            id: recordDiagnosis.ID,
            code: recordDiagnosis.ICDCode,
            text: recordDiagnosis.CodeAndDescription,
            primary: Number(recordDiagnosis.Order) === 0,
            noCodeAvailable: recordDiagnosis.NoCodeAvailable
                ? recordDiagnosis.NoCodeAvailable
                : 0,
        };
    },
};

export default mappers;
