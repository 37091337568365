export const CreateRNCommentsDataStructure = (rnComments) => {
  return {
    items: [
      {
        name: "Commendations",
        value: rnComments["Commendations"],
        renderType: "Main",
      },
      {
        name: "Peer Review Comments",
        value: rnComments["PeerReviewComments"],
        renderType: "Main",
      },
      {
        name: "Provider Recommendations",
        value: rnComments["ProviderRecommendations"],
        switch: rnComments["ProviderRecommendationsSwitch"],
        renderType: "Main",
        items: [
          {
            name: "Physician Legibility",
            value: rnComments["PhysicianLegibility"],
            checkbox: rnComments["PhysicianLegibilityCheckbox"],
            renderType: "Sub",
          },
        ],
      },
      {
        name: "Facility Recommendations",
        value: rnComments["FacilityRecommendations"],
        switch: rnComments["FacilityRecommendationsSwitch"],
        renderType: "Main",
        items: [
          {
            name: "Nurse Legibility",
            value: rnComments["NurseLegibility"],
            checkbox: rnComments["NurseLegibilityCheckbox"],
            renderType: "Sub",
          },
          {
            name: "Medical Terminology",
            value: rnComments["MedicalTerminology"],
            checkbox: rnComments["MedicalTerminologyCheckbox"],
            renderType: "Sub",
          },
          {
            name: "Teach Backs",
            value: rnComments["TeachBacks"],
            checkbox: rnComments["TeachBacksCheckbox"],
            renderType: "Sub",
          },
        ],
      },
      {
        name: "Literature Review",
        value: rnComments["LiteratureReview"],
        switch: rnComments["LiteratureReviewSwitch"],
        renderType: "Main",
      },
    ],
  };
};
