import React from "react";

const TextBegin = (props) => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>Discharge Instructions - Medical Terminology</h4>
      </div>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              Health Literacy: the capacity to obtain, process, and understand
              basic health information and services to make appropriate health
              decisions. Many people have trouble understanding information
              about their health. About 9 out of 10 American adults have some
              problems with health literacy. Health literacy is the ability to
              get the health information you need, and to understand it. It is
              also about using the information to make good decisions about your
              health and medical care. Low health literacy can harm a patient's
              health. Healthcare professionals should always seek to use plain,
              nonmedical language when speaking to patients. Words that
              clinician use in their day-to-day conversations with colleagues
              may be unfamiliar to the majority of non-medically trained
              persons. A good approach to adopt is to explain things to patients
              in language called "living room" language, or conversational
              language. Conversational language creates opportunities for
              dialogue between the clinician and patient, rather than limiting
              communication to a monologue by the physician. Discharge
              instructions are frequently written beyond the comprehension of
              patients. Therefore, discharge instructions should use plain
              language at the fifth-grade level or lower.
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextBegin;
