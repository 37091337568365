import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificSentinelEventChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Sentinel Event"}
        xAxisLabel={"Sentinel Event"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: Sentinel Event"}
      />
    </>
  );
};

export default FacilitySpecificSentinelEventChart;
