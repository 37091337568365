import get from "./getData";
import rnStageTool from "./rnStageTool";
import { emptyObject } from "src/utilities/objectUtilities";

const Checklist = (values, apiResponse) => {
  const checklist = rnStageTool(apiResponse).checklist;
  return checklist.map((checklist) => ({
    ...checklist,
    Value: values[checklist.Name],
  }));
};

const Comments = (values, apiResponse) => {
  const comments = rnStageTool(apiResponse).comments;
  Object.keys(emptyObject("RNComments")).forEach(
    (key) => (comments[key] = values[key])
  );
  return comments;
};

const Outcomes = (values, apiResponse) => {
  const outcomes = rnStageTool(apiResponse).outcomes;
  Object.keys(emptyObject("RNOutcomes")).forEach(
    (key) => (outcomes[key] = values[key])
  );
  return outcomes;
};

const DirectReviewComments = (formValues, apiResponse) => {
  const sidebarRequested = get.SideBarRequested(apiResponse);
  const committeeReviewRequest = get.CommitteeReviewRequested(apiResponse);
  const updatedDirectReviewValues = get.DirectReviewComments(apiResponse);
  updatedDirectReviewValues[
    sidebarRequested || committeeReviewRequest
      ? "facilitatorTwo"
      : "facilitatorOne"
  ] = formValues.DirectReviewComments;
  return updatedDirectReviewValues;
};

const format = { Checklist, Comments, Outcomes, DirectReviewComments };

export default format;
