import React, { useState, useEffect } from "react";
import { Form } from "react-final-form";
import _ from "lodash";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import * as Yup from "yup";
import { TextField, makeValidate, Checkboxes, Select } from "mui-rff";
import { MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { RNBlindingsActions } from "src/redux/reducers/RNTools/RNBlindings";
import stateOptions from "src/utilities/stateOptions";
import { FinalizeButtons } from "../Shared";
import Submitter from "../Submitter";

// We define our schema based on the same keys as our form:
const schema = Yup.object().shape({
  NoMissedBlindings: Yup.boolean().required(),
  MissedBlindings: Yup.array().when("NoMissedBlindings", {
    is: false,
    then: Yup.array().of(
      Yup.object().shape({
        RequiredBlindingID: Yup.number().nullable().required("Required"),
        PageNumber: Yup.string().nullable().required("Required"),
        TimesMissed: Yup.number()
          .nullable()
          .typeError("Must be a number")
          .required("Required"),
      })
    ),
    otherwise: Yup.array(),
  }),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  deleteButton: {
    background: "#ff3547",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    margin: theme.spacing(1),
  },
  customInput: {
    maxWidth: 160,
  },
  customInput2: {
    maxWidth: 320,
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  checkBoxContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tableCell: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  selectAndInputContainer: {
    display: "flex",
    alignItems: "center",
  },
  select: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
  input: {
    flex: 2,
    maxWidth: "none",
  },
}));

const tableColumns = [
  {
    id: "Serial No",
    label: "#",
    minWidth: 20,
    align: "center",
  },
  {
    id: "blindingType",
    label: "Blinding Type",
    minWidth: 320,
    align: "left",
  },
  {
    id: "pageNumber",
    label: "Page Number",
    minWidth: 80,
    align: "center",
  },
  {
    id: "timesMissed",
    label: "Times Missed",
    minWidth: 80,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 80,
    align: "center",
  },
];

const CustomInput = (props) => {
  return <TextField size="small" variant="outlined" {...props} />;
};

const MissedBlindingsForm = (props) => {
  const { RecordID, MissedBlindings, blindingOptions, dispatch } = props;
  const [initialValues, setInitialValues] = useState({});
  const classes = useStyles();

  useEffect(() => {
    const stateMissedBlindings = MissedBlindings
      ? _.cloneDeep(MissedBlindings)
      : [];

    setInitialValues({
      RecordID,
      MissedBlindings: stateMissedBlindings.filter(
        (item) => item.RequiredBlindingID !== 38
      ),
      NoMissedBlindings: stateMissedBlindings.some(
        (item) => item.RequiredBlindingID === 38
      ),
    });
  }, [RecordID, MissedBlindings]);

  const onSubmit = async (results) => {
    await dispatch(RNBlindingsActions.Save(results));
  };

  return (
    <Form
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      validate={makeValidate(schema)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, form, values, dirty }) => {
        if (props.isFormDirty !== dirty) {
          props.setIsFormDirty(dirty);
        }
        const { NoMissedBlindings: NoMissedBlindingsFormValue } = values;
        return (
          <form onSubmit={handleSubmit}>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {tableColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="MissedBlindings">
                      {({ fields }) => {
                        const fieldsValue = fields.value;
                        return (
                          <>
                            {fields.map((name, index) => {
                              const fieldValue = fieldsValue[index];
                              const shouldShowOtherCell =
                                fieldValue.RequiredBlindingID === 32 ||
                                fieldValue.RequiredBlindingID === 37 ||
                                fieldValue.RequiredBlindingID === 30;
                              const shouldShowLocationCell =
                                fieldValue.RequiredBlindingID === 43;
                              const shouldShowStateCell =
                                fieldValue.RequiredBlindingID === 42;
                              return (
                                <TableRow hover key={index}>
                                  <TableCell
                                    className={classes.tableCell}
                                    align={tableColumns[0].align}
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align={tableColumns[1].align}
                                  >
                                    <div
                                      className={
                                        classes.selectAndInputContainer
                                      }
                                    >
                                      <Select
                                        formControlProps={{
                                          fullWidth: false,
                                          classes: {
                                            root: classes.select,
                                          },
                                        }}
                                        disabled={NoMissedBlindingsFormValue}
                                        size="small"
                                        name={`${name}.RequiredBlindingID`}
                                      >
                                        <MenuItem value="" disabled>
                                          Select Missed blinding
                                        </MenuItem>
                                        {blindingOptions.map((b) => (
                                          <MenuItem key={b.ID} value={b.ID}>
                                            {b.Name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      {shouldShowOtherCell && (
                                        <CustomInput
                                          className={classes.input}
                                          variant="outlined"
                                          fullWidth={true}
                                          disabled={NoMissedBlindingsFormValue}
                                          name={`${name}.OtherBlinding`}
                                          placeholder="Other Blinding"
                                        />
                                      )}
                                      {shouldShowLocationCell && (
                                        <CustomInput
                                          className={classes.input}
                                          variant="outlined"
                                          fullWidth={true}
                                          disabled={NoMissedBlindingsFormValue}
                                          name={`${name}.OtherBlinding`}
                                          placeholder="Location"
                                        />
                                      )}
                                      {shouldShowStateCell && (
                                        <div className={classes.select}>
                                          <Select
                                            size={"small"}
                                            name={`${name}.OtherBlinding`}
                                            labelId="missed-blinding-state-select-label"
                                            id="missed-blinding-state-select"
                                            variant={"outlined"}
                                            displayEmpty
                                          >
                                            <MenuItem value={null}>
                                              Select a State
                                            </MenuItem>
                                            {stateOptions.map((option) => (
                                              <MenuItem
                                                key={`missed-blinding-state-select-option-${option.name}-${option.value}`}
                                                value={option.name}
                                              >
                                                {option.name}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </div>
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align={tableColumns[2].align}
                                  >
                                    <CustomInput
                                      disabled={NoMissedBlindingsFormValue}
                                      className={classes.customInput2}
                                      name={`${name}.PageNumber`}
                                      placeholder="Page Number(s)"
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align={tableColumns[3].align}
                                  >
                                    <CustomInput
                                      disabled={NoMissedBlindingsFormValue}
                                      className={classes.customInput}
                                      name={`${name}.TimesMissed`}
                                      placeholder="Times Missed"
                                      type={"number"}
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align={tableColumns[4].align}
                                  >
                                    <IconButton
                                      disabled={NoMissedBlindingsFormValue}
                                      color="secondary"
                                      aria-label="delete"
                                      onClick={() => {
                                        fields.remove(index);
                                      }}
                                      size="large"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                            <TableRow hover>
                              <TableCell colSpan={5}>
                                <div className={classes.addButtonContainer}>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                      fields.push({
                                        RequiredBlindingID: null,
                                        TimesMissed: null,
                                        PageNumber: null,
                                        OtherBlinding: "",
                                        RecordID,
                                      });

                                      form.change("NoMissedBlindings", false);
                                    }}
                                  >
                                    <i className={"fas fa-plus pr-1"} />
                                    Add Missed Blinding
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      }}
                    </FieldArray>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={classes.checkBoxContainer}>
                <Checkboxes
                  name="NoMissedBlindings"
                  data={{
                    label: "No Missed Blindings",
                  }}
                  color="primary"
                />
              </div>
              <FinalizeButtons />
            </Paper>
            <Submitter values={values} onSubmit={onSubmit} />
          </form>
        );
      }}
    </Form>
  );
};

export default MissedBlindingsForm;
