import React from "react";

const TextBegin = () => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>Patient Race</h4>
      </div>
    </>
  );
};

export default TextBegin;
