import React from "react";
// data
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { selectContent } from "src/redux/selectors";
// ui
import { Checkboxes, TextField } from "mui-rff";
import { Field } from "react-final-form";
import Typography from "@mui/material/Typography";

const ReviewReasons = (props) => {
  const ReviewReasons = useSelector(selectContent("ReviewReason")).filter(
    (r) => r.Active === true || r.Active === 1
  );
  const otherReviewReason = ReviewReasons.find(
    (r) => r.Reason.split(" ")[0] === "Other"
  );
  const reviewReasonOptions = isEmpty(ReviewReasons)
    ? []
    : ReviewReasons.filter((r) => r.Reason.split(" ")[0] !== "Other");
  reviewReasonOptions.push(otherReviewReason);

  return (
    <>
      <div className={"row pt-2"} id={"review-reasons-title"}>
        <div className={"col"}>
          <Typography variant="h4" className={props.classes.title}>
            Review Reasons
          </Typography>
        </div>
      </div>
      <div className={"row pt-2 mx-1"}>
        <div className={"col"}>
          <Checkboxes
            label={"Review Reasons Checklist"}
            required
            name="reviewReasons"
            data={
              reviewReasonOptions[0]
                ? reviewReasonOptions.map((reviewReason) => {
                    return {
                      label: reviewReason.Reason,
                      value: reviewReason.ID,
                    };
                  })
                : []
            }
            size={"small"}
          />
        </div>
      </div>
      <Field name={"reviewReasons"}>
        {({ input }) => (
          <>
            {otherReviewReason !== undefined &&
              otherReviewReason !== null &&
              input.value.includes(otherReviewReason.ID) && (
                <div className={"col mx-1"}>
                  <TextField
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    label={"Other Review Reason"}
                    name={"otherReviewReason"}
                    variant={"outlined"}
                  />
                </div>
              )}
          </>
        )}
      </Field>
    </>
  );
};

export default ReviewReasons;
