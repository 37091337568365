import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilitySentinelEventChart from "./AllFacilitySentinelEventChart";
import FacilitySpecificSentinelEventChart from "./FacilitySpecificSentinelEventChart";
import TextBegin from "./TextBegin";

const SentinelEvent = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, ["sentinelEvent"]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, [
      "sentinelEvent",
    ]);

  return (
    <>
      <TextBegin {...props} />
      {chartDataAllFacilities !== false && (
        <AllFacilitySentinelEventChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Sentinel Event"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificSentinelEventChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Sentinel Event"}
        rows={chartData.rows}
      />
    </>
  );
};

export default SentinelEvent;
