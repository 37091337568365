import React from "react";
import { CreateRNCommentsDataStructure } from "./rnCommentsDataStructure";
import RenderRNComments from "./RenderRNCommentsComponents";
import { styles } from "../../PDFUtilities/PDFStyles";
import { View, Text } from "@react-pdf/renderer";

const RNComments = ({ rnComments }) => {
  const rnCommentsDataStructure = rnComments[0]
    ? CreateRNCommentsDataStructure(rnComments[0])
    : undefined;

  return rnCommentsDataStructure ? (
    <RenderRNComments
      renderType="Paragraphs"
      items={rnCommentsDataStructure.items}
    />
  ) : (
    <View style={{ flexDirection: "row" }} wrap={false}>
      <View style={{ flexGrow: 1, width: "80%" }}>
        <Text style={styles.finalSubTitle}>RNComments:</Text>
      </View>
      <View style={{ flexGrow: 1, width: "20%", paddingTop: 25 }}>
        <Text
          style={[
            styles.text,
            {
              textAlign: "right",
              marginRight: 40,
              color: "red",
            },
          ]}
        >
          Pending
        </Text>
      </View>
    </View>
  );
};

export default RNComments;
