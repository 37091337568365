import React from "react";

const TextBegin = (props) => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>Provider Records Reviewed</h4>
      </div>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              Physicians participate in committees based on their respective
              specialties. Figure 2 shows the distribution of records assigned
              to committees by specialty. Emergency and Family Medicine are the
              most frequently conducted peer review committee meetings. The
              number of meetings is determined by the number of records received
              per specialty. Reviews that fall outside of peer review specialty
              committees are listed as "Other" or "Direct Review."
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextBegin;
