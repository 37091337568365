import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificMedicalManagementChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Medical Management"}
        xAxisLabel={"Medical Management"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: Medical Management"}
      />
    </>
  );
};

export default FacilitySpecificMedicalManagementChart;
