import React, { useContext } from "react";
import { Select } from "mui-rff";
import _ from "lodash";
import { recordReviewData } from "src/components/pages/MeetingManagement/RecordReview";
import RenderEachItem from "../RenderEachItem";
import { Field } from "react-final-form";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

const Dropdown = ({
  name,
  label,
  items,
  itemsDependOn,
  render: { options = [] },
}) => {
  const apiResponse = useContext(recordReviewData);
  const oChecklist = _.get(apiResponse, `OriginalData.Checklist`, []);
  const { Value: oValue } = oChecklist.find((oValue) => oValue.Name === name);
  const classes = useStyles();
  return (
    <>
      <div className={"row pb-2"}>
        <div className={"col d-flex text-left my-auto"}>{label}</div>
        <div className={"col-2 d-flex justify-content-end"}>
          <Field name={name}>
            {({ input: { value } }) => {
              return (
                <>
                  {oValue !== value && (
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label={"Original"}
                      defaultValue={oValue}
                      disabled
                      inputProps={{
                        className: classes.input,
                      }}
                    />
                  )}
                </>
              );
            }}
          </Field>
        </div>
        <div className={"col-3 d-flex justify-content-end"}>
          <Select
            name={name}
            data={options.map((option) => ({
              label: option === "Pending" ? "Choose Your Option" : option,
              value: option,
            }))}
            variant={"outlined"}
            className={classes.textLeft}
          />
        </div>
      </div>
      <RenderEachItem items={items} itemsDependOn={itemsDependOn} indent />
    </>
  );
};

const useStyles = makeStyles(() => ({
  textLeft: {
    textAlign: "left",
  },
  box: {
    bgcolor: "background.paper",
    borderColor: "text.primary",
    m: 1,
    border: 1,
    style: { width: "5rem", height: "2rem" },
  },
  input: {
    color: "red",
  },
}));

export default Dropdown;
