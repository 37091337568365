import { format } from "date-fns";

export const convertDateToLocal = (dateString) => {
  return format(dateString, "Pp");
};

export const formatDate = (dateString = null) => {
  if (dateString === null) {
    return dateString;
  }

  if (dateString.includes("Z")) {
    dateString = dateString.slice(0, -1);
  }

  const date = new Date(dateString);
  return date > new Date(2000, 0, 1) ? format(date, "MM/dd/yyyy") : null;
};

export const formatDateTime = (dateString = null) => {
  if (dateString === null) {
    return dateString;
  }

  if (dateString.includes("Z")) {
    dateString = dateString.slice(0, -1);
  }

  return new Date(dateString);
};
