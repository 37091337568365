import React from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { useParams } from "react-router-dom";
import PageCard from "src/components/individual/PageCard";
import { ContactActions } from "src/redux/reducers/PersonnelManagement/Contact";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { MidlevelActions } from "src/redux/reducers/PersonnelManagement/Midlevel";
import { ProviderActions } from "src/redux/reducers/PersonnelManagement/Provider";
import { UserActions } from "src/redux/reducers/PersonnelManagement/User";
import { selectFacilityByID, selectLoaded } from "src/redux/selectors";
import ContactsTable from "./ContactsTable";
import MidlevelsTable from "./MidlevelsTable";
import ProvidersTable from "./ProvidersTable";
import UsersTable from "./UsersTable";

const FacilityLinks = (props) => {
  const { facilityID } = useParams();
  const Facility = useSelector(selectFacilityByID(facilityID));
  const Loaded = useSelector(selectLoaded);

  useEffectOnce(() => {
    Loaded.Facility === false && props.dispatch(FacilityActions.BatchAdd());
    Loaded.Contact === false && props.dispatch(ContactActions.BatchAdd());
    Loaded.Provider === false && props.dispatch(ProviderActions.BatchAdd());
    Loaded.Midlevel === false && props.dispatch(MidlevelActions.BatchAdd());
    Loaded.User === false && props.dispatch(UserActions.BatchAdd());
  });

  const render =
    Loaded.Facility &&
    Loaded.Contact &&
    Loaded.Provider &&
    Loaded.Midlevel &&
    Loaded.User &&
    Facility !== undefined &&
    Object.keys(Facility).length > 0;

  return (
    <PageCard
      title={render ? Facility.FullName : "Facility Links"}
      render={
        <>
          {render && (
            <>
              <UsersTable facilityID={facilityID} {...props} />
              <ContactsTable facilityID={facilityID} {...props} />
              <ProvidersTable facilityID={facilityID} {...props} />
              <MidlevelsTable facilityID={facilityID} {...props} />
            </>
          )}
        </>
      }
    />
  );
};

export default FacilityLinks;
