import { createSelector } from "reselect";
import _ from "lodash";
import { selectContent } from "./contentSelectors";
import {
  selectProviders,
  selectFacilities,
  selectContacts,
  selectMidlevels,
} from "src/redux/selectors/personnelSelectors";
import { selectMeetings } from "src/redux/selectors/meetingSelectors";
import { findById, findMultipleByUniqueID } from "./helperFunctions";
import { emptyObject, getContentById } from "src/utilities/objectUtilities";
import { selectSignature } from "src/redux/selectors/index";

const selectRecords = selectContent("Record");

const selectRecordByID = (ID) =>
  createSelector(selectRecords, (records) => findById(records, ID));

const selectRecordsByFacilityID = (facilityID) =>
  createSelector(selectRecords, (records) =>
    records.filter((records) => records.FacilityID === facilityID)
  );

const selectRecordInformationByID = (ID) =>
  createSelector(
    selectRecordByID(ID),
    selectFacilities,
    selectContacts,
    selectProviders,
    selectMidlevels,
    selectContent("Committee"),
    selectContent("PatientSource"),
    selectMeetings,
    selectSignature,
    (
      record,
      facilities,
      contacts,
      providers,
      midlevels,
      committees,
      patientSources,
      meetings,
      signatures
    ) => {
      if (!ID) return emptyObject("Record");
      const fields = [
        { name: "Facility", content: facilities },
        { name: "Contact", content: contacts },
        { name: "Provider", content: providers },
        { name: "Midlevel", content: midlevels },
        { name: "Committee", content: committees },
        { name: "PatientSource", content: patientSources },
        { name: "Meeting", content: meetings },
        { name: "Signature", content: signatures },
      ];
      const hydratedRecord = _.cloneDeep(record);
      fields.forEach((elemObj) => {
        if (elemObj.name === "Midlevel") {
          if (record.Midlevel !== undefined) {
            if (!hydratedRecord.hasOwnProperty(elemObj.name)) {
              hydratedRecord[elemObj.name] = getContentById(
                elemObj.content,
                record[`${elemObj.name}ID`]
              );
            }
          }
        } else if (elemObj.name === "Provider") {
          if (!hydratedRecord.hasOwnProperty(elemObj.name)) {
            hydratedRecord[elemObj.name] = getContentById(
              elemObj.content,
              record[`${elemObj.name}ID`]
            );
          }
        } else {
          hydratedRecord[elemObj.name] = getContentById(
            elemObj.content,
            record[`${elemObj.name}ID`]
          );
        }
      });
      return hydratedRecord;
    }
  );

const selectRecordsAssignableToMeeting = createSelector(
  selectRecords,
  (records) =>
    records.filter((record) => record.ApprovalStatus === 2 && !record.MeetingID)
);

const selectRecordsApprovedByFacilityID = (FacilityID) =>
  createSelector(selectRecords, (records) =>
    records.filter(
      (record) =>
        record.ApprovalStatus === 2 && record.FacilityID === FacilityID
    )
  );

const selectRecordsByMeetingID = (MeetingID) =>
  createSelector(selectRecords, (records) =>
    findMultipleByUniqueID(records, MeetingID, "MeetingID")
  );

const selectRecordsByFilter = ({
  ApprovalStatusID,
  FacilityID,
  RecordStatusID,
  StartDate,
  EndDate,
}) =>
  createSelector(selectRecords, (record) =>
    record.filter(
      (record) =>
        (ApprovalStatusID === 3 ||
          ApprovalStatusID === record.ApprovalStatus) &&
        (FacilityID === null || FacilityID === record.FacilityID) &&
        (RecordStatusID === 0 || RecordStatusID === record.RecordStatusID) &&
        (!record.SubmissionDate ||
          (StartDate.setHours(0, 0, 0, 0) <=
            new Date(_.cloneDeep(record.SubmissionDate)) &&
            new Date(_.cloneDeep(record.SubmissionDate)) <=
            EndDate.setHours(23, 59, 59, 0)))
    )
  );

export {
  selectRecords,
  selectRecordByID,
  selectRecordsByFacilityID,
  selectRecordInformationByID,
  selectRecordsAssignableToMeeting,
  selectRecordsApprovedByFacilityID,
  selectRecordsByMeetingID,
  selectRecordsByFilter,
};
