import red from "@mui/material/colors/red";
import blue from "@mui/material/colors/blue";
import { makeStyles } from "@mui/styles";

const primaryRed = red[500],
  primaryBlue = blue["A700"];

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  header: {
    fontSize: 36,
    fontWeight: "bold",
  },
  recordDetailsList: {
    display: "flex",
    flexWrap: "wrap",
    fontSize: 16,
    listStyle: "none",
    padding: 0,
    marginTop: theme.spacing(4),
    "& li": {
      flexBasis: "480px",
      textAlign: "left",
    },
  },
  listKey: {
    marginRight: theme.spacing(1),
  },
  listValue: {
    fontWeight: "bold",
  },
  checkBoxSection: {
    display: "flex",
    flexDirection: "column",
  },
  checkBoxSubSection: {
    width: 480,
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(2),
  },
  differenceContainer: {
    position: "relative",
    marginBottom: theme.spacing(2),
  },
  modifyIconLabel: {
    background: theme.palette.primary.main,
    borderRadius: "50%",
    padding: 4,
    color: "#fff",
  },
  disabledEditButton: {
    opacity: 0.6,
  },
  modifyIcon: {
    position: "absolute",
    right: -16,
    top: "calc(50% - 16px)",
    zIndex: 1,
  },
  radioGroup: {
    display: "flex",
    flexWrap: "wrap",
  },
  radioGroupFormGroup: {
    marginLeft: `${theme.spacing(2)}px`,
  },
  sectionHeading: {
    fontSize: 18,
    fontWeight: "bold",
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
    textAlign: "left",
  },
  formElementDescription: {
    minWidth: 400,
    width: 400,
    textAlign: "left",
    margin: `auto ${theme.spacing(2)}px`,
  },
  strikedText: {
    textDecoration: "line-through",
  },
  redText: {
    color: primaryRed,
  },
  blueText: {
    color: primaryBlue,
  },
  subSection: {
    marginLeft: theme.spacing(4),
    flexBasis: "100%",
    textAlign: "left",
  },
  formElementContainer: {
    textAlign: "left",
  },
  sectionSubHeading: {
    textAlign: "left",
    fontSize: 18,
  },
  input: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: 360,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default useStyles;
