import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { RecordActions } from "src/redux/reducers/Record";

const Save = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const response = await apiService.post(`${apiUrl}blinding/save`, content);
    dispatch(
      RecordActions.SaveMissedBlindings({
        RecordID: content.RecordID,
        MissedBlinding: response.data,
      })
    );
    dispatch(FetchingActions.Success("Updating Missed Blinding"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Missed Blinding"));
  }
};

export const RNBlindingsActions = {
  Save,
};
