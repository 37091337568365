import React from "react";
import { adminUser } from "src/utilities/rbacFunctions";
import CreateUserAdminDialog from "./CreateUserAdminDialog";
import CreateUserFacilityDialog from "./CreateUserFacilityDialog";

const CreateUserDialog = (props) => {
  if (adminUser(props.roles)) {
    return <CreateUserAdminDialog {...props} />;
  } else {
    return <CreateUserFacilityDialog {...props} />;
  }
};

export default CreateUserDialog;
