import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificLengthOfStayChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Length of Stay"}
        xAxisLabel={"Length of Stay"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: Records by Length of Stay per Record"}
      />
    </>
  );
};

export default FacilitySpecificLengthOfStayChart;
