import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityPeerReviewOutcomesChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Peer Review Outcomes"}
        xAxisLabel={"Outcomes"}
        yAxisLabel={"Number of Records"}
        figureText={"Records by Peer Review Outcome for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilityPeerReviewOutcomesChart;
