const sentinelEvent = () => ({
  label: "Sentinel Event",
  render: { type: "Title" },
  items: [sentinelEventRadios()],
});

const sentinelEventRadios = () => ({
  name: "sentinelEvent",
  render: {
    type: "Radios",
    options: [
      "Medication error(s), resulting in a patient's unanticipated death or major loss of bodily" +
        " function in circumstances unrelated to the natural course of the illness or underlying" +
        " condition of the patient.",
      "A perinatal death unrelated to congenital condition in an infant with a birth weight" +
        " greater than 2,500 grams.",
      "The suicide of a patient in a setting in which the patient received care 24 hours a day.",
      "The abduction of a newborn infant patient from the hospital or the discharge of a newborn" +
        " infant patient from the hospital into the custody of an individual in circumstances in" +
        " which the hospital knew, or in the exercise of ordinary care should have known, that the" +
        " individual did not have legal custody of the infant.",
      "The sexual assault of a patient during treatment or while the patient was on the premises" +
        " of the hospital or facility.",
      "A hemolytic transfusion reaction in a patient resulting from the administration of blood or" +
        " blood products with major blood group incompatibilities.",
      "A surgical procedure on the wrong patient or the wrong body part of a patient.",
      "A foreign object accidentally left in a patient during a procedure.",
      "A patient death or serious disability associated with the use or function of a device " +
        "designed for patient care that is used for functions other than as intended",
      "No sentinel event identified",
    ],
  },
});

export default sentinelEvent;
