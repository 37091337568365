import { createSlice } from "@reduxjs/toolkit";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { FacilityActions } from "src/redux/reducers/PersonnelManagement/Facility";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { LastCreatedObjectActions } from "src/redux/reducers/LastCreatedObject";
import { LoadedActions } from "src/redux/reducers/Loaded";

const initialState = [];

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    batchAdd: (_state, action) => {
      return [...action.payload];
    },
    create: (state, action) => {
      state.push(action.payload);
    },
    update: (state, action) => {
      const targetIndex = state.findIndex(
        (u) => Number(u.ID) === Number(action.payload.ID)
      );
      state[targetIndex] = { ...state[targetIndex], ...action.payload };
    },
  },
});

const { batchAdd, create, update } = userSlice.actions;

const BatchAdd = () => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(LoadedActions.SetUser("pending"));
    const { data: users } = await apiService.get(`${apiUrl}user`);
    dispatch(batchAdd([...users]));
    dispatch(LoadedActions.SetUser(true));
  } catch (error) {
    dispatch(FetchingActions.Failure("Loading User"));
  }
};

const Create = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}User/create`,
      content
    );
    dispatch(create(contents));
    dispatch(LastCreatedObjectActions.Set("User", contents));
    dispatch(FetchingActions.Success("Creating User"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Creating User"));
  }
};

const Update = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}User/update`,
      content
    );
    dispatch(update(contents));
    dispatch(FetchingActions.Success("Updating User"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating User"));
  }
};

export const CreateAndLinkToFacility = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}User/create`,
      content
    );
    dispatch(create(contents));
    dispatch(
      FacilityActions.AddUser({
        FacilityID: parseInt(content.FacilityID),
        UserID: contents.ID,
        Active: 1,
      })
    );
    dispatch(FetchingActions.Success("Creating User"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Creating User"));
  }
};

export const LinkToFacility = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}User/update`,
      content
    );
    dispatch(update(contents));
    dispatch(FacilityActions.AddUser(contents));
    dispatch(
      LastCreatedObjectActions.Set({
        ...contents,
        contentName: "AutoSelectUser",
      })
    );
    dispatch(FetchingActions.Success("Linking User"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Linking User"));
  }
};

export const UserActions = {
  BatchAdd,
  Create,
  Update,
  CreateAndLinkToFacility,
  LinkToFacility,
};

export default userSlice.reducer;
