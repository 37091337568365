import React from "react";
import { TextField } from "mui-rff";
import { Field } from "react-final-form";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)(() => ({
  "& .MuiInputBase-input": {
    fontSize: "1.5em",
  },
}));

const TextBox = ({ name, variant, disabled = false, style = "", rows = 8 }) => (
  <Field name={`${name}Checkbox`}>
    {({ input: { value } }) => (
      <CustomTextField
        name={name}
        multiline
        rows={rows}
        variant="outlined"
        disabled={disabled || (variant === "withCheckbox" && !value)}
        className={style}
      />
    )}
  </Field>
);

export default TextBox;
