import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificSystemIssuesChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: System Issues"}
        xAxisLabel={"System Issues"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: System Issues"}
      />
    </>
  );
};

export default FacilitySpecificSystemIssuesChart;
