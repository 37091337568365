import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { RenderSubTitleCard } from "../../PDFUtilities/RenderPDFFunctions";
import { styles } from "../../PDFUtilities/PDFStyles";

const RenderEachItem = ({ items = [] }) => {
  let totalLength = 0;
  return (
    <>
      {items &&
        items.map((item) => {
          let wrap = false;
          const RenderComponent = RenderRNCommentsComponents[item.renderType];
          const currentItems = item.items;
          const currentValue = item.value ?? "";
          let currentLength = currentValue ? currentValue.length : 0;
          if (currentItems) {
            currentLength = currentItems.reduce(
              (total, currVal) =>
                total + (currVal.value ? currVal.value.length : 0),
              currentLength
            );
          }
          if (totalLength > 2000 || totalLength < 800) {
            wrap = true;
          }
          totalLength += currentLength;
          return (
            <RenderComponent
              name={item.name}
              items={item.items}
              value={item.value}
              switch={item.switch}
              checkbox={item.checkbox}
              wrap={wrap}
            />
          );
        })}
    </>
  );
};

const Paragraphs = ({ items }) => {
  return (
    <View>
      <RenderEachItem items={items} />
    </View>
  );
};

const Main = (props) => {
  if (props.switch || props.items || props.value) {
    return (
      <RenderSubTitleCard title={props.name}>
        {props.switch ? (
          <Text style={styles.commentText}>
            {textWhenSwitchIsTrue[props.name]}
          </Text>
        ) : (
          <Text style={styles.commentText}>{props.value}</Text>
        )}
        <RenderEachItem items={props.items} />
      </RenderSubTitleCard>
    );
  } else {
    return <></>;
  }
};

const textWhenSwitchIsTrue = {
  "Provider Recommendations":
    "There were no provider recommendations identified in review of this record.",
  "Facility Recommendations":
    "There were no facility recommendations identified in review of this record.",
  "Literature Review":
    "In the review of this record, there was no request for a literature search.",
};

const Sub = (props) => {
  return props.checkbox === true ? (
    <Text style={styles.commentText}>{props.value}</Text>
  ) : (
    <></>
  );
};

const RenderRNCommentsComponents = {
  Paragraphs,
  Main,
  Sub,
};

const RenderRNComments = ({ renderType, items }) => {
  const RenderComponent = RenderRNCommentsComponents[renderType];
  return <RenderComponent items={items} />;
};

export default RenderRNComments;
