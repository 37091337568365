import React from "react";
import { MenuItem } from "@mui/material";
import { Select } from "mui-rff";

const subcommittees = [
  { name: "1", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
  { name: "5", value: "5" },
  { name: "6", value: "6" },
  { name: "7", value: "7" },
  { name: "8", value: "8" },
  { name: "9", value: "9" },
  { name: "10", value: "10" },
];

const DRSubcommitteeSelect = (props) => {
  return (
    <div className={"row pt-2"}>
      <div className={"col"}>
        <Select
          name={"dummy"}
          label={"Subcommittee"}
          labelId="create-meeting-dummy-subcommittee-select-label"
          id="create-meeting-dummy-subcommittee-select"
          variant={"outlined"}
          disabled
          displayEmpty
          required={props.values.reviewType === null}
        >
          <MenuItem value={null}>Select a Subcommittee</MenuItem>
          {subcommittees.map((option) => (
            <MenuItem
              key={`create-meeting-dr-subcommittee-${option.name}-${option.value}`}
              value={option.name}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default DRSubcommitteeSelect;
