import React from "react";

const TextBegin = (props) => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>ARCHI Trend Issues</h4>
      </div>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              System thinking is the process of understanding how things
              influence one another within a whole. In organizations, systems
              consist of people, structures, and processes that work together to
              make an organization healthy or unhealthy. Systems can best be
              understood in the context of relationships with each other and
              with other systems, rather than viewing each item in isolation.
            </p>
            <br />
            <p className={"text-left"}>
              Hand offs: The lack of documentation or incomplete documentation
              can always impact patient care should it be assumed by another
              healthcare professional. The importance of thorough and complete
              communication between providers is addressed via "hand offs". The
              most frequently cited category of root causes of sentinel events
              is noted to be ineffective communication.
            </p>
            <br />
            <p className={"text-left"}>
              Premature Closure: An important aspect of diagnostic reasoning is
              the ability to recognize when there is sufficient evidence to
              enable a working diagnosis to be made and thus avoid the
              unnecessary risks and costs of further testing. Premature closure
              is an issue in which the physician fails to consider reasonable
              alternatives after an initial diagnosis is made. It is a common
              cause of delayed diagnosis and/or misdiagnosis. Premature closure
              can be avoided by the open consideration of alternate/appropriate
              differential diagnosis, and use of disciplined clinical
              decision-making.
            </p>
            <br />
            <p className={"text-left"}>
              Authority Gradient: Authority gradient is a patient safety term
              that refers to the balance of decision-making power or the
              steepness of command hierarchy in a given situation. Members of a
              crew or organization with a domineering, overbearing, or
              dictatorial team leader experience a steep authority gradient.
              Expressing concerns, questioning, or even simply clarifying
              instructions would require considerable determination on the part
              of team members who perceive their input as devalued or frankly
              unwelcome. (Patient Safety Network, Glossary, 2005). Authority
              gradient is often addressed in policies and procedures to include
              medical staff bylaws under appropriate conduct, disruptive
              behavior or professional behaviors/expectations. In the event the
              facility feels authority gradient is an issue it is suggested
              appropriate policies be reviewed and followed or if no policy is
              available one should be written.
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextBegin;
