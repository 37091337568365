import React, { useContext } from "react";
// data
import { globalVariables } from "src/components/App";
import { useDebounce } from "react-use";
import { apiUrl } from "src/index";
import apiService from "src/utilities/api_service";
// ui
import { makeStyles } from "@mui/styles";
import { FormSpy, Field } from "react-final-form";
import {
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { NotificationActions } from "src/redux/reducers/Notification";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ICDSearch = (props) => {
  const { authenticate, authenticatedDispatch } = useContext(globalVariables);
  const classes = useStyles();
  const PAGE_LIMIT = 50;
  const ICDVersion = 10;
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const [page, setPage] = React.useState(1);

  const fetchICD = async () => {
    if (searchTerm.length > 1) {
      await authenticate();
      const response = await apiService.get(
        `${apiUrl}icdCodes/search?term=${searchTerm}&page=${page}&page_limit=${PAGE_LIMIT}&version=${ICDVersion}`
      );
      setSearchResults(response.data.result);
    }
  };

  useDebounce(
    () => {
      fetchICD();
    },
    500,
    [searchTerm, page]
  );

  return (
    <>
      <div className={"col"}>
        <TextField
          fullWidth
          label={"Search for ICD Code*"}
          variant={"outlined"}
          value={searchTerm}
          onChange={(e) => {
            setPage(1);
            return setSearchTerm(e.target.value);
          }}
        />
        <FormSpy
          subscription={{
            errors: true,
            touched: true,
          }}
        >
          {({ errors, touched }) => (
            <span style={{ color: "red" }}>
              {touched.icdCodes && errors.icdCodes && errors.icdCodes[0]}
            </span>
          )}
        </FormSpy>
      </div>
      <div className={"col"}>
        <FormSpy subscription={{ form: true }}>
          {({ form }) => (
            <FormControl
              disabled={searchTerm === ""}
              fullWidth
              variant="outlined"
            >
              <InputLabel id="create-mrts-record-add-icd-code-select">
                Click to View Results
              </InputLabel>
              <Select label="Search Results">
                <MenuItem value="">None</MenuItem>
                <Field name={"icdCodes"}>
                  {({ input: { value = [] } }) => (
                    <>
                      {searchResults.map((result) => (
                        <MenuItem
                          key={`create-mrts-record-add-icd-code-select-result-${result.text}`}
                          onClick={() => {
                            if (value.some(({ code }) => code === result.Code)) {
                              authenticatedDispatch(
                                NotificationActions.Open({
                                  Message: `ICD Code already exist`,
                                  Severity: "error",
                                  Time: 5000,
                                })
                              );
                            }
                            form.mutators.addICDCode(result);
                            setSearchTerm("");
                            setSearchResults([]);
                          }}
                          value={result}
                        >
                          {result.text}
                        </MenuItem>
                      ))}
                    </>
                  )}
                </Field>
              </Select>
            </FormControl>
          )}
        </FormSpy>
      </div>
      <div className={"col d-flex align-items-center justify-content-begin"}>
        <IconButton
          aria-label="back"
          className={classes.margin}
          color={"primary"}
          onClick={() => setPage(page - 1)}
          disabled={
            page === 1 || searchResults.length === 0 || searchTerm === ""
          }
          size="large"
        >
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          aria-label="next"
          className={classes.margin}
          color={"primary"}
          onClick={() => setPage(page + 1)}
          disabled={
            page === PAGE_LIMIT ||
            searchResults.length < PAGE_LIMIT ||
            searchResults.length === 0 ||
            searchTerm === ""
          }
          size="large"
        >
          <ArrowForwardIcon fontSize="inherit" />
        </IconButton>
      </div>
    </>
  );
};

export default ICDSearch;
