import React from "react";
import { Page, Document } from "@react-pdf/renderer";
import BasicInformation from "./BasicInformation";
import Demographics from "./Demographics";
import RecordIndex from "./RecordIndex";
import Workspace from "./Workspace";
import { renderBox } from "../PDFUtilities/RenderPDFFunctions";
import Footer from "../PDFUtilities/Footer";
import { styles } from "../PDFUtilities/PDFStyles";

const values = {
  isBasicInformation: true,
  isDemographics: true,
  isRecordIndex: true,
};
const defaultSettings = Object.values(values);

const Worksheet = (props) => {
  return (
    <Document
      title={"Rural Physician Peer Review Worksheet"}
      author={"ARCHI"}
      subject={"Worksheet"}
      keywords={"Peer Review PPR ARCHI "}
      creator={"ARCHI PPR System"}
      producer={"ARCHI"}
      language={"English"}
    >
      <Page size="LETTER" style={styles.uniformPage}>
        {renderBox("Rural Physician Peer Review © Worksheet", "boxTitle")}
        <BasicInformation {...props} settings={defaultSettings} />
        <Demographics {...props} settings={defaultSettings} />
        <RecordIndex {...props} settings={defaultSettings} />
        <Workspace />
        <Footer />
      </Page>
    </Document>
  );
};

export default Worksheet;
