import { emptyObject } from "src/utilities/objectUtilities";
import rnStageTool from "./rnStageTool";
import get from "./getData";

const initiateValues = (initialValues = {}, apiResponse) => {
  const stage = rnStageTool(apiResponse);
  //RNChecklist Info
  initChecklist(initialValues, stage.checklist);
  //RNComments
  initComments(initialValues, stage.comments, get.PeerReviewNotes(apiResponse));
  //RNOutcomes
  initOutcomes(initialValues, stage.outcomes);
  initMeetingID(initialValues, get.MeetingID(apiResponse));
  initTextStages(initialValues, get.TextStages(apiResponse));
  initDirectReviewComments(
    initialValues,
    get.DirectReviewComments(apiResponse),
    get.SideBarRequested(apiResponse),
    get.CommitteeReviewRequested(apiResponse)
  );
  initialValues.facilitatorRequest = null;
};

const initChecklist = (initialValues, checklist) =>
  checklist.forEach(({ Name, Value }) => (initialValues[Name] = Value));

const initComments = (initialValues, comments, peerReviewNotes) => {
  Object.entries(emptyObject("RNComments")).forEach(
    ([key, value]) => (initialValues[key] = value)
  );
  Object.entries(comments).forEach(
    ([key, value]) => (initialValues[key] = value)
  );
  initialValues.PeerReviewNotes = peerReviewNotes;
};

const initOutcomes = (initialValues, outcomes) =>
  Object.entries(
    Object.keys(outcomes).length === 0 ? emptyObject("RNOutcomes") : outcomes
  ).forEach(([key, value]) => (initialValues[key] = value));

const initMeetingID = (initialValues, meetingID) =>
  (initialValues.MeetingID = meetingID);

const initTextStages = (initialValues, textStages) =>
  (initialValues.textStages = textStages);

const initDirectReviewComments = (
  initialValues,
  directReviewComments,
  sidebarRequested,
  committeeReviewRequested
) =>
  (initialValues.DirectReviewComments =
    sidebarRequested || committeeReviewRequested
      ? directReviewComments["facilitatorTwo"]
      : directReviewComments["facilitatorOne"]);

export default initiateValues;
