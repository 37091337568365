const otherDocumentationIssues = (isLegacyVersion) => ({
  label: "Other Documentation Issues",
  render: { type: "Title", hide: isLegacyVersion },
  items: [
    electronicHealthRecord(),
    standardizedIssues(),
    documentationIncongruity(),
  ],
  version: 2,
});

const electronicHealthRecord = () => ({
  name: "electronicHealthRecord",
  render: { type: "InlineRadios", options: ["Yes", "No", "N/A"] },
});

const standardizedIssues = () => ({
  name: "standardizedIssues",
  render: { type: "InlineRadios", options: ["Yes", "No", "N/A"] },
});

const documentationIncongruity = () => ({
  name: "documentationIncongruity",
  render: { type: "InlineRadios", options: ["Yes", "No"] },
});

export default otherDocumentationIssues;
