import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const referenceMaterialSlice = createSlice({
  name: "referenceMaterial",
  initialState,
  reducers: {
    batchAdd: (_state, action) => {
      return [...action.payload.referenceMaterial];
    },
  },
});

const { batchAdd } = referenceMaterialSlice.actions;

const BatchAdd = (content) => async (dispatch) => {
  dispatch(batchAdd(content));
};

export const ReferenceMaterialActions = {
  BatchAdd,
};

export default referenceMaterialSlice.reducer;
