import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form } from "react-final-form";
import ReviewTypeSelect from "./ReviewTypeSelect";
import validateFinalForm from "src/utilities/validateFinalForm";
import validationSchema from "./validationSchema";
import mutators from "./mutators";
import CommitteeSelect from "../CommitteeSelect";
import CRSubcommitteeSelect from "./CRSubcommitteeSelect";
import DRSubcommitteeSelect from "./DRSubcommitteeSelect";
import MeetingDate from "./MeetingDate";
import CRPreview from "./CRPreview";
import DRPreview from "./DRPreview";
import DummyPreview from "./DummyPreview";
import DummySubcommitteeSelect from "./DummySubcommitteeSelect";
import { MeetingActions } from "src/redux/reducers/Meeting";
import { useSelector } from "react-redux";
import { format, isValid } from "date-fns";
import { selectContent } from "src/redux/selectors";

const CreateMeetingDialog = (props) => {
  const Committees = useSelector(selectContent("Committee"));
  const initialValues = {
    reviewType: null,
    committeeID: null,
    subcommittee: null,
    meetingDate: null,
    dummy: null,
  };

  const createMeeting = (values) => {
    const Committee = Committees.find(
      (committee) => committee.ID === values.committeeID
    );
    props.dispatch(
      MeetingActions.Add({
        MeetingDate: values.meetingDate,
        MeetingName:
          values.reviewType === "CR"
            ? `${Committee !== undefined ? Committee.Abbreviation : ""}-${
                values.subcommittee !== null ? values.subcommittee : ""
              } ${
                isValid(values.meetingDate)
                  ? format(values.meetingDate, "MM.dd.yyyy")
                  : ""
              }`
            : `${
                isValid(values.meetingDate)
                  ? format(values.meetingDate, "MMM yyyy").toUpperCase()
                  : ""
              } ${
                Committee !== undefined ? Committee.Abbreviation : ""
              } Group ${
                values.subcommittee !== null ? values.subcommittee : ""
              }`,
        CommitteeID: values.committeeID,
      })
    );
  };

  return (
    <Form
      onSubmit={createMeeting}
      initialValues={initialValues}
      validate={validateFinalForm(validationSchema)}
      mutators={mutators}
      render={({ form, submitting, pristine, values }) => (
        <>
          <form>
            <Dialog
              fullWidth
              maxWidth={"sm"}
              open={props.dialogVisible}
              onClose={() => {
                props.setDialogVisible();
                form.mutators.clearAll();
              }}
            >
              <DialogTitle>Create Meeting</DialogTitle>
              <DialogContent className={"py-2"}>
                <ReviewTypeSelect form={form} disabled={submitting} />
                <CommitteeSelect disabled={submitting} />
                {values.reviewType === null && (
                  <DummySubcommitteeSelect values={values} />
                )}
                {values.reviewType === "CR" && (
                  <CRSubcommitteeSelect values={values} disabled={submitting} />
                )}
                {values.reviewType === "DR" && (
                  <DRSubcommitteeSelect values={values} disabled={submitting} />
                )}
                <MeetingDate disabled={submitting} />
                {values.reviewType === null && <DummyPreview />}
                {values.reviewType === "CR" && <CRPreview values={values} />}
                {values.reviewType === "DR" && <DRPreview values={values} />}
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={submitting}
                  onClick={() => {
                    props.setDialogVisible();
                    form.mutators.clearAll();
                  }}
                  color={"secondary"}
                  variant={"contained"}
                >
                  Close
                </Button>
                <Button
                  disabled={
                    values.reviewType === null ||
                    values.committeeID === null ||
                    values.subcommittee === null ||
                    values.meetingDate === null ||
                    submitting ||
                    pristine
                  }
                  color={"primary"}
                  variant={"contained"}
                  onClick={() => {
                    createMeeting(values);
                    props.setDialogVisible();
                    form.mutators.clearAll();
                  }}
                >
                  Create
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </>
      )}
    />
  );
};

export default CreateMeetingDialog;
