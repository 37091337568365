import ReportKeys from "./ReportKeys";

const mutators = {
  selectAllDisplaySections: (args, state, utils) => {
    utils.changeValue(state, "displayOptions.sections", () => [
      "text",
      "table",
      "allFacilities",
    ]);
  },
  unselectAllDisplaySections: (args, state, utils) => {
    utils.changeValue(state, "displayOptions.sections", () => []);
  },
  selectAllOutcomeReportsOptions: (args, state, utils) => {
    utils.changeValue(state, "outcomeReports", () => [
      ReportKeys.RecordsReviewedByCommittee,
      ReportKeys.ProviderRecordsReviewedByCommittee,
      ReportKeys.ReasonForReview,
      ReportKeys.ReasonForReviewByProvider,
      ReportKeys.OutcomesByProvider,
      ReportKeys.PeerReviewOutcomes,
    ]);
  },
  selectAllChecklistReportsOptions: (args, state, utils) => {
    utils.changeValue(state, "checklistReports", () => [
      ReportKeys.RCHITrendIssues,
      ReportKeys.DischargeInstructionsMedicalAbbreviations,
      ReportKeys.DischargeInstructionsMedicalTerminology,
      ReportKeys.DischargeInstructionsTeachbacks,
      ReportKeys.DischargeOrTransferDocumentsProvided,
      ReportKeys.EthicsIssue,
      ReportKeys.EvidenceOfMedicationReconciliation,
      ReportKeys.LegibilityIssuesByProviderType,
      ReportKeys.MedicalManagement,
      ReportKeys.OtherPatientSafetyConcern,
      ReportKeys.PatientDisposition,
      ReportKeys.PatientOutcome,
      ReportKeys.SentinelEvent,
      ReportKeys.SystemIssues,
    ]);
  },
  selectAllRecommendationReportsOptions: (args, state, utils) => {
    utils.changeValue(state, "recommendationReports", () => [
      ReportKeys.PossibleImprovements,
      ReportKeys.AbbreviationUse,
    ]);
  },
  selectAllPatientTypeReportsOptions: (args, state, utils) => {
    utils.changeValue(state, "patientTypeReports", () => [
      ReportKeys.PatientAge,
      ReportKeys.PatientGender,
      ReportKeys.PatientRace,
      ReportKeys.LengthOfStay,
    ]);
  },
  unselectAllOutcomeReportsOptions: (args, state, utils) => {
    utils.changeValue(state, "outcomeReports", () => []);
  },
  unselectAllChecklistReportsOptions: (args, state, utils) => {
    utils.changeValue(state, "checklistReports", () => []);
  },
  unselectAllRecommendationReportsOptions: (args, state, utils) => {
    utils.changeValue(state, "recommendationReports", () => []);
  },
  unselectAllPatientTypeReportsOptions: (args, state, utils) => {
    utils.changeValue(state, "patientTypeReports", () => []);
  },
  clearGroupBy: (args, state, utils) => {
    utils.changeValue(state, "displayOptions.groupBy", () => null);
  },
  setTemplate: (args, state, utils) => {
    utils.changeValue(state, "chartType", () => args[0]);
    utils.changeValue(state, "dateRange", () => args[1]);
    utils.changeValue(state, "displayOptions", () => args[2]);
    utils.changeValue(state, "programOverviewSelected", () =>
      args[3] === true ? ["ProgramOverview"] : []
    );
    utils.changeValue(state, "checklistReports", () => args[4]);
    utils.changeValue(state, "outcomeReports", () => args[5]);
    utils.changeValue(state, "patientTypeReports", () => args[6]);
    utils.changeValue(state, "recommendationReports", () => args[7]);
  },
  clearTemplateName: (args, state, utils) => {
    utils.changeValue(state, "templateName", () => null);
  },
  clearAll: (args, state, utils) => {
    utils.changeValue(state, "archiFacilityId", () => null);
    utils.changeValue(state, "dateRange", () => {
      return {
        type: null,
      };
    });
    utils.changeValue(state, "chartType", () => null);
    utils.changeValue(state, "displayOptions", () => {
      return {
        groupBy: null,
        displayType: "nameAndId",
        sections: [],
      };
    });
    utils.changeValue(state, "reports1", () => []);
    utils.changeValue(state, "reports2", () => []);
    utils.changeValue(state, "templates", () => null);
    utils.changeValue(state, "templateName", () => null);
  },
};

export default mutators;
