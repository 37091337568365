import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import { selectRecordByID } from "src/redux/selectors";
import { RecordActions } from "src/redux/reducers/Record";
import { CircularProgress, TextField } from "@mui/material";

const ViewRejectionDialog = (props) => {
  const Record = useSelector(selectRecordByID(props.recordID));
  const render =
    props.dialogVisible &&
    Record !== undefined &&
    Record.RecordRejection !== null &&
    Record.RecordRejection !== undefined;

  React.useEffect(() => {
    if (
      props.dialogVisible &&
      (Record.RecordRejection === undefined || Record.RecordRejection === null)
    ) {
      props.dispatch(RecordActions.LoadRejectionReason(props.recordID, "ID"));
    }
  }, [props.dialogVisible, props.recordID]);

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={props.dialogVisible}
      onClose={props.setDialogVisible}
      aria-labelledby="view-rejection-dialog-title"
      aria-describedby="view-rejection-dialog-description"
    >
      <DialogTitle id="view-rejection-dialog-title">View Rejection</DialogTitle>
      <DialogContent>
        {render ? (
          <>
            <div className={"row"}>
              <div className={"col"}>
                <TextField
                  fullWidth
                  disabled
                  variant={"outlined"}
                  label={"Rejection Reason"}
                  value={Record.RecordRejection.Reason}
                />
              </div>
            </div>
            <div className={"row pt-2"}>
              <div className={"col"}>
                <TextField
                  fullWidth
                  disabled
                  multiline
                  rows={4}
                  variant={"outlined"}
                  label={"Rejection Comment"}
                  value={Record.RecordRejection.Comments}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={"row"}>
            <div
              className={"col d-flex align-items-center justify-content-center"}
            >
              <CircularProgress size={"2.5rem"} />
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.setDialogVisible}
          variant={"contained"}
          color="primary"
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewRejectionDialog;
