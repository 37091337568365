import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityPossibleImprovementsChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Possible Improvements"}
        xAxisLabel={"Improvement"}
        yAxisLabel={"Number of Records"}
        figureText={"Records by Possible Improvements for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilityPossibleImprovementsChart;
