import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { LastCreatedObjectActions } from "src/redux/reducers/LastCreatedObject";
import { LoadedActions } from "src/redux/reducers/Loaded";

const initialState = [];

const facilitySlice = createSlice({
  name: "facility",
  initialState,
  reducers: {
    batchAdd: (_state, action) => {
      return [...action.payload];
    },
    create: (state, action) => {
      const newFacility = { ...action.payload };
      newFacility.Contact = [];
      newFacility.Provider = [];
      newFacility.Midlevel = [];
      newFacility.User = action.payload.User ? action.payload.User : [];
      state.push(newFacility);
    },
    update: (state, action) => {
      const facilityIndex = state.findIndex(
        ({ ID }) => ID === Number(action.payload.ID)
      );
      state[facilityIndex] = action.payload;
    },
    addContact: (state, action) => {
      const facilityIndex = state.findIndex(
        ({ ID }) => ID === Number(action.payload.FacilityID)
      );
      state[facilityIndex].Contact.push({ ID: action.payload.ID });
    },
    addUser: (state, action) => {
      const facilityIndex = state.findIndex(
        ({ ID }) => ID === Number(action.payload.FacilityID)
      );
      state[facilityIndex].User.push({ ID: action.payload.ID });
    },
    addProvider: (state, action) => {
      const facilityIndex = state.findIndex(
        ({ ID }) => ID === Number(action.payload.FacilityID)
      );
      state[facilityIndex].Provider.push({
        ID: Number(action.payload.ProviderID),
        facilityProvider: Number(action.payload),
      });
    },
    removeProvider: (state, action) => {
      const facilityIndex = state.findIndex(
        ({ ID }) => ID === Number(action.payload.FacilityID)
      );
      state[facilityIndex].Provider = state[facilityIndex].Provider.filter(
        (p) => Number(p.ID) !== Number(action.payload.ProviderID)
      );
    },
    addMidlevel: (state, action) => {
      const facilityIndex = state.findIndex(
        ({ ID }) => ID === Number(action.payload.FacilityID)
      );
      state[facilityIndex].Midlevel.push({
        ID: Number(action.payload.MidlevelID),
        facilityMidlevel: action.payload,
      });
    },
    removeMidlevel: (state, action) => {
      const facilityIndex = state.findIndex(
        ({ ID }) => ID === Number(action.payload.FacilityID)
      );
      state[facilityIndex].Midlevel = state[facilityIndex].Midlevel.filter(
        (m) => Number(m.ID) !== Number(action.payload.MidlevelID)
      );
    },
    mergeProviders: (state, action) => {
      const newState = state.map((facility) => {
        const details = facility.Provider.reduce(
          (acc, curr) => {
            if (curr.ID === action.payload.source) {
              acc = { ...acc, hasSource: true };
            }
            if (curr.ID === action.payload.destination) {
              acc = { ...acc, hasDestination: true };
            }
            return acc;
          },
          {
            hasSource: false,
            hasDestination: false,
          }
        );

        const updatedFacilities = _.cloneDeep(facility);
        if (details.hasSource) {
          updatedFacilities.Provider = facility.Provider.filter(
            (provider) => provider.ID !== action.payload.source
          );
          if (!details.hasDestination) {
            updatedFacilities.Provider.push({
              ID: action.payload.destination,
              facilityProvider: {
                FacilityID: facility.ID,
                ProviderID: action.payload.destination,
                facilityID: facility.ID,
                providerID: action.payload.destination,
                Active: true,
              },
            });
          }
        }
        return updatedFacilities;
      });
      return newState;
    },
    mergeMidlevels: (state, action) => {
      return state.map((facility) => {
        const details = facility.Midlevel.reduce(
          (acc, curr) => {
            if (curr.ID === action.payload.source) {
              acc = { ...acc, hasSource: true };
            }
            if (curr.ID === action.payload.destination) {
              acc = { ...acc, hasDestination: true };
            }
            return acc;
          },
          {
            hasSource: false,
            hasDestination: false,
          }
        );

        const updatedFacilities = _.cloneDeep(facility);
        if (details.hasSource) {
          updatedFacilities.Midlevel = facility.Midlevel.filter(
            (midlevel) => midlevel.ID !== action.payload.source
          );
          if (!details.hasDestination) {
            updatedFacilities.Midlevel.push({
              ID: action.payload.destination,
              facilityMidlevel: {
                FacilityID: facility.ID,
                MidlevelID: action.payload.destination,
                facilityID: facility.ID,
                midlevelID: action.payload.destination,
                Active: true,
              },
            });
          }
        }
        return updatedFacilities;
      });
    },
  },
});

const {
  batchAdd,
  create,
  update,
  addContact,
  addUser,
  addProvider,
  removeProvider,
  addMidlevel,
  removeMidlevel,
  mergeProviders,
  mergeMidlevels,
} = facilitySlice.actions;

const BatchAdd = () => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    dispatch(LoadedActions.SetFacility("pending"));
    const { data: facilities } = await apiService.get(`${apiUrl}facility`);
    dispatch(batchAdd([...facilities]));
    dispatch(LoadedActions.SetFacility(true));
  } catch (error) {
    dispatch(FetchingActions.Failure("Loading Facilities"));
  }
};

const Create = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Facility/create`,
      content
    );
    dispatch(create(contents));
    dispatch(LastCreatedObjectActions.Set("Facility", contents));
    dispatch(FetchingActions.Success("Creating Facility"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Creating Facility"));
  }
};

const Update = (content) => async (dispatch) => {
  try {
    dispatch(FetchingActions.Begin());
    const { data: contents } = await apiService.post(
      `${apiUrl}Facility/update`,
      content
    );
    dispatch(update(contents));
    dispatch(FetchingActions.Success("Updating Facility"));
  } catch (error) {
    dispatch(FetchingActions.Failure("Updating Facility"));
  }
};

const AddContact = (content) => async (dispatch) => {
  dispatch(addContact(content));
};

const AddUser = (content) => async (dispatch) => {
  dispatch(addUser(content));
};

const AddProvider = (content) => async (dispatch) => {
  dispatch(addProvider(content));
};

const RemoveProvider = (content) => async (dispatch) => {
  dispatch(removeProvider(content));
};

const AddMidlevel = (content) => async (dispatch) => {
  dispatch(addMidlevel(content));
};

const RemoveMidlevel = (content) => async (dispatch) => {
  dispatch(removeMidlevel(content));
};

const MergeProviders = (content) => async (dispatch) => {
  dispatch(mergeProviders(content));
};

const MergeMidlevels = (content) => async (dispatch) => {
  dispatch(mergeMidlevels(content));
};

export const FacilityActions = {
  BatchAdd,
  Create,
  Update,
  AddContact,
  AddUser,
  AddProvider,
  RemoveProvider,
  AddMidlevel,
  RemoveMidlevel,
  MergeProviders,
  MergeMidlevels,
};

export default facilitySlice.reducer;
