import { Field } from "react-final-form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";
import PropTypes from "prop-types";

const CustomAutocomplete = ({ name, label, options = [] }) => {
  return (
    <Field name={name}>
      {(props) => {
        const currValue = options.find(
          ({ value }) => value === props.input.value
        ) ?? { label: "", value: null };
        return (
          <Autocomplete
            value={currValue ? currValue.label : ""}
            onChange={(_, labelSelected) => {
              const option = options.find(
                ({ label }) => label === labelSelected
              );
              if (option) props.input.onChange(option.value);
            }}
            onInputChange={(event, newInputValue) => {
              if (newInputValue === "") props.input.onChange(newInputValue);
            }}
            options={options.map(({ label }) => label)}
            id={`${name}-autocomplete-component`}
            variant={"outlined"}
            fullWidth
            disablePortal
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={props.meta.touched && props.meta.error}
                helperText={props.meta.touched && props.meta.error}
              />
            )}
            sx={{ marginTop: 2 }}
          />
        );
      }}
    </Field>
  );
};

CustomAutocomplete.prototype = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ).isRequired,
};

export default CustomAutocomplete;
