import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityProviderRecordsReviewedChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Provider Records Reviewed"}
        xAxisLabel={"Specialty"}
        yAxisLabel={"Number of Providers"}
        figureText={
          "Records reviewed by Committee and Provider for all RPPR© Facilities"
        }
      />
    </>
  );
};

export default AllFacilityProviderRecordsReviewedChart;
