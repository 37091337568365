import { createSlice } from "@reduxjs/toolkit";
import { NotificationActions } from "./Notification";

const initialState = {
  Loading: false,
  Error: null,
  Message: null,
};

const fetchingSlice = createSlice({
  name: "fetching",
  initialState,
  reducers: {
    begin: (state) => {
      state.Loading = true;
      state.Error = null;
      state.Warning = false;
      state.Message = null;
    },
    failure: (state, action) => {
      state.Loading = false;
      state.Error = true;
      state.Warning = false;
      state.Message = action.payload;
    },
    warning: (state, action) => {
      state.Loading = false;
      state.Error = false;
      state.Warning = true;
      state.Message = action.payload;
    },
    success: (state, action) => {
      state.Loading = false;
      state.Error = false;
      state.Warning = false;
      state.Message = action.payload;
    },
    reset: (state) => {
      state.Loading = false;
      state.Error = null;
      state.Warning = false;
      state.Message = null;
    },
  },
});

const { begin, failure, warning, success, reset } = fetchingSlice.actions;

const Begin = () => async (dispatch) => {
  dispatch(begin());
};

const Failure = (message) => async (dispatch) => {
  dispatch(
    NotificationActions.Open({
      Message: message,
      Severity: "error",
    })
  );
  dispatch(failure(message));
};

const Warning = (message) => async (dispatch) => {
  dispatch(
    NotificationActions.Open({
      Message: message,
      Severity: "warning",
    })
  );
  dispatch(warning(message));
};

const Success = (message) => async (dispatch) => {
  dispatch(
    NotificationActions.Open({
      Message: message,
      Severity: "success",
    })
  );
  dispatch(success(message));
};

const Reset = () => async (dispatch) => {
  dispatch(reset());
};

export const FetchingActions = {
  Begin,
  Failure,
  Warning,
  Success,
  Reset,
};

export default fetchingSlice.reducer;
