import React from "react";
import { Text, View } from "@react-pdf/renderer";
import {
  renderBox,
  renderTwoBoxColumn,
} from "../PDFUtilities/RenderPDFFunctions";
import { styles } from "../PDFUtilities/PDFStyles";
import Constants from "src/utilities/constants";

const Demographics = ({ recordData, checklistData = [], settings }) => {
  const demographics = settings[1]
    ? {
        Age: recordData.PatientAge,
        Sex: recordData.PatientGender,
        PrimaryDiagnosis: recordData.PrimaryDiagnosis,
        AdmissionDate: recordData.ArrivalDateTime,
        DischargeDate: recordData.DischargeDateTime,
        LengthOfStay: recordData.StayLength,
        DischargeDisposition: checklistData.find(
          ({ OptionID }) => OptionID === Constants.PATIENT_DISPOSITION_ID
        ).Value,
      }
    : {
        Age: "",
        Sex: "",
        PrimaryDiagnosis: "",
        AdmissionDate: "",
        DischargeDate: "",
        LengthOfStay: "",
        DischargeDisposition: "",
      };
  return (
    <>
      {renderBox("Demographics", "boxSubTitle")}
      <View style={styles.boxRow}>
        <View style={styles.quarterColumn}>
          <View style={{ flexDirection: "row" }}>
            <View style={[styles.halfColumn, { borderRight: true }]}>
              <Text style={styles.boxedText}>Age: {demographics.Age}</Text>
            </View>
            <View style={styles.halfColumn}>
              <Text style={styles.boxedText}>Sex: {demographics.Sex}</Text>
            </View>
          </View>
        </View>
        <View style={{ borderLeft: true, width: "75%", flexGrow: 1 }}>
          <Text style={styles.boxedText}>
            Primary Diagnosis: {demographics.PrimaryDiagnosis}
          </Text>
        </View>
      </View>
      {renderTwoBoxColumn(
        "Arrival Date and Time",
        demographics.AdmissionDate,
        "Discharge Date and Time",
        demographics.DischargeDate
      )}
      {renderTwoBoxColumn(
        "Length Of Stay",
        demographics.LengthOfStay,
        "Discharge Disposition",
        demographics.DischargeDisposition
      )}
    </>
  );
};

export default Demographics;
