import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityReasonForReviewByProviderChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All-Facility: Reason for Review by Provider"}
        xAxisLabel={"Reason"}
        yAxisLabel={"Number of Records"}
        figureText={
          "Records reviewed by Reason for Review and Provider for all RPPR© facilities"
        }
      />
    </>
  );
};

export default AllFacilityReasonForReviewByProviderChart;
