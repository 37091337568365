//data must include an array with Name:string, Value:string, OptionID: int, Label:string

const hydrateStructure = (structure, dbData) => {
  const hydrateQuestion = (templateProperties) => {
    // Value undefined if data.find does not return anything...
    const dbDataFound = dbData.find(
      ({ Name }) => Name === templateProperties.name
    ) || { Value: undefined, OptionID: null, Label: "" };

    return {
      ...templateProperties,
      value: dbDataFound.Value,
      optionID: dbDataFound.OptionID,
      label: dbDataFound.Label,
    };
  };

  const findValueByName = (name) => {
    const { Value } = dbData.find(({ Name }) => Name === name) || {
      Value: undefined,
    };
    return Value;
  };

  const hydrateQuestions = (items) => {
    return items.reduce((acc, curr, i) => {
      acc[i] = curr.hasOwnProperty("name") ? hydrateQuestion(curr) : curr;
      if (
        curr.hasOwnProperty("valueDependsOn") &&
        curr.render.type === "Checkbox"
      ) {
        acc[i].value = curr.valueDependsOn.reduce((acc, { name, is }) => {
          return is === findValueByName(name) ? "true" : acc;
        }, "false");
      }
      if (curr.render.hasOwnProperty("hideDependsOnPDF")) {
        acc[i].render.hidePDF = curr.render.hideDependsOnPDF.every(
          ({ name, is }) => findValueByName(name) === is
        );
      }
      if (curr.hasOwnProperty("itemsDependOn")) {
        acc[i].itemsVisible = curr.itemsDependOn.reduce((acc, { name, is }) => {
          return is === findValueByName(name) ? true : acc;
        }, false);
      }
      if (curr.hasOwnProperty("items")) {
        acc[i].items = hydrateQuestions(curr.items);
      }
      return acc;
    }, []);
  };

  return dbData ? hydrateQuestions(structure) : structure;
};

export default hydrateStructure;
