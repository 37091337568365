import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityEthicsIssueChart from "./AllFacilityEthicsIssueChart";
import FacilitySpecificEthicsIssueChart from "./FacilitySpecificEthicsIssueChart";
import TextBegin from "./TextBegin";

const EthicsIssue = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, ["ethicsIssue"]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, ["ethicsIssue"]);

  return (
    <>
      <TextBegin {...props} />
      {chartDataAllFacilities !== false && (
        <AllFacilityEthicsIssueChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Ethics Issue"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificEthicsIssueChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Ethics Issue"}
        rows={chartData.rows}
      />
    </>
  );
};

export default EthicsIssue;
