import React from "react";
import ChartCard from "../../ChartCard";

const FacilitySpecificOtherPatientSafetyConcernChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"Facility-Specific: Other Patient Safety Concern"}
        xAxisLabel={"Other Patient Safety Concern"}
        yAxisLabel={"Number of Records"}
        figureText={"Facility-Specific: Other Patient Safety Concern"}
      />
    </>
  );
};

export default FacilitySpecificOtherPatientSafetyConcernChart;
