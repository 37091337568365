import React from "react";

const TextBegin = () => {
  return (
    <div className={"section-report-title"}>
      <h4>Length of Stay</h4>
    </div>
  );
};

export default TextBegin;
