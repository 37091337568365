import React, { useContext, useMemo } from "react";
import { recordReviewData } from "src/components/pages/MeetingManagement/RecordReview";
import _ from "lodash";
import hydrateStructure from "src/components/templateStructures/checklistStructure/hydrateStructure";
import createStructure from "src/components/templateStructures/checklistStructure";
import RecursiveComponent from "./RecursiveComponents";
import { useSelector } from "react-redux";
import { selectContent } from "src/redux/selectors";

const RNChecklist = () => {
  const apiResponse = useContext(recordReviewData);
  const committeeID = _.get(apiResponse, `OriginalData.CommitteeID`, null);
  const checklistLookUpData = useSelector(selectContent("ChecklistQuestion"));

  const checklistStructure = useMemo(
    () =>
      checklistLookUpData.length
        ? hydrateStructure(
            createStructure(committeeID),
            checklistLookUpData.map(({ id, name, label }) => ({
              ID: id,
              Name: name,
              Label: label,
            }))
          )
        : [],
    [checklistLookUpData]
  );

  return (
    <>
      {checklistStructure.length && (
        <RecursiveComponent items={checklistStructure} />
      )}
    </>
  );
};

export default RNChecklist;
