const additionalBarriers = (isLegacyVersion) => ({
  label: "Additional Barriers to Care Identified",
  render: { type: "Title", hide: isLegacyVersion },
  items: [
    additionalBarriersToCareIdentified(),
    lackOfFacilityResources(),
    lackOfSocialSupport(),
    addBarrToCareIdOther(),
  ],
  version: 2,
});

const additionalBarriersToCareIdentified = () => ({
  name: "additionalBarriersToCareIdentified",
  render: { type: "InlineRadios", options: ["Yes", "No", "No Track"] },
});

const lackOfFacilityResources = () => ({
  name: "lackOfFacilityResources",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
});

const lackOfSocialSupport = () => ({
  name: "lackOfSocialSupport",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
});

const addBarrToCareIdOther = () => ({
  name: "addBarrToCareIdOther",
  render: { type: "TextBox" },
});

export default additionalBarriers;
