import React from "react";
import DropdownDashboard from "./DropdownDashboard";
import NoDropdownDashboard from "./NoDropdownDashboard";
import {
  facilityAdmin,
  adminUser,
  archiAdministrator,
} from "src/utilities/rbacFunctions";

const MRTSRecords = (props) => {
  let viewLevel = 0;
  if (facilityAdmin(props.roles)) {
    viewLevel = 1;
  } else if (archiAdministrator(props.roles)) {
    viewLevel = 3;
  } else if (adminUser(props.roles)) {
    viewLevel = 2;
  }
  if (viewLevel === 3) {
    return <DropdownDashboard {...props} />;
  } else {
    return <NoDropdownDashboard {...props} />;
  }
};

export default MRTSRecords;
