import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { findObject } from "../Checklist/ChecklistAnalyzingFunctions";
import { styles } from "../../PDFUtilities/PDFStyles";
import {
  RenderFourColumns,
  RenderSubTitleCard,
} from "../../PDFUtilities/RenderPDFFunctions";

const range = (start, end, step = 1) => {
  let output = [];
  if (typeof end === "undefined") {
    end = start;
    start = 0;
  }
  for (let i = start; i <= end; i += step) {
    output.push(i);
  }
  return output;
};

const Abbreviations = ({ abbreviations, abbreviationList, radiology }) => {
  const combineSimilarAbbreviations = (abbreviationListToCombine) => {
    return Object.values(
      abbreviationListToCombine.reduce((obj, item) => {
        if (obj[item.AbbreviationID]) {
          obj[item.AbbreviationID].PractitionerText.push({
            ProviderType: item.ProviderType,
            TimesUsed: item.TimesUsed,
          });
        } else {
          obj[item.AbbreviationID] = {
            ID: item.ID,
            AbbreviationID: item.AbbreviationID,
            PractitionerText: [
              { ProviderType: item.ProviderType, TimesUsed: item.TimesUsed },
            ],
          };
        }
        return obj;
      }, {})
    );
  };

  const officialAbbreviations = combineSimilarAbbreviations(
    abbreviations.filter((abbreviation) => abbreviation.AbbreviationID < 11)
  );

  const additionalAbbreviations = combineSimilarAbbreviations(
    abbreviations.filter((abbreviation) =>
      range(11, 17).includes(abbreviation.AbbreviationID)
    )
  );

  const AbbreviationHeader = ({ title }) => {
    return (
      <View>
        <Text style={[styles.subTitle, { textAlign: "center" }]}>{title}</Text>
        <RenderFourColumns
          firstColumn={"Abbreviation"}
          secondColumn={"Potential Problem"}
          thirdColumn={"Preferred Term"}
          fourthColumn={"Practitioners Using Abbreviation"}
        />
      </View>
    );
  };

  return (
    <View style={{ paddingTop: 20 }}>
      <View wrap={false}>
        <RenderSubTitleCard title={"Quality and Patient Safety"} wrap={false} />

        <Text style={styles.text}>
          A major contributing factor related to medication errors is the use of
          potentially dangerous abbreviations. The following abbreviations
          utilized by your facility are on the "Do Not Use" list developed by
          The Joint Commission.
        </Text>
      </View>
      {!abbreviations.some((element) => element.AbbreviationID === 9) &&
      officialAbbreviations.length !== 0 &&
      !radiology ? (
        <View wrap={false}>
          <AbbreviationHeader title={`Official "Do Not Use List"`} />
          {officialAbbreviations.map((abbreviation, index) => {
            const abbreviationDescription = findObject(
              abbreviationList,
              "ID",
              abbreviation.AbbreviationID
            );
            const lastColumnText = abbreviation.PractitionerText.map(
              (element) => {
                return `${element.ProviderType} (${element.TimesUsed}) `;
              }
            );
            return (
              <RenderFourColumns
                firstColumn={abbreviationDescription.Name}
                secondColumn={abbreviationDescription.AbbreviationProblemDesc}
                thirdColumn={abbreviationDescription.PreferredTerm}
                fourthColumn={lastColumnText}
                lastBox={index === officialAbbreviations.length - 1}
              />
            );
          })}
          <Text style={styles.text}>
            'Applies to all orders and all medication-related documentation that
            is handwritten (including free-text computer entry) or on
            pre-printed forms
          </Text>
          <Text style={styles.text}>
            *Exception: A "trailing zero" may be used only where required to
            demonstrate the level of precision of the value being reported, such
            as for laboratory results, imaging studies that report size of
            lesions, or catheter/tube size. It may not be used in medication
            orders or other medication-related documentation.
          </Text>
        </View>
      ) : (
        <RenderSubTitleCard
          title={'Official "Do Not Use List"'}
          wrap={false}
          align="center"
        >
          <Text style={[styles.text, { textAlign: "center" }]}>
            There were no "Do not use" abbreviations found in the review of this
            record.
          </Text>
        </RenderSubTitleCard>
      )}
      {!abbreviations.some((element) => element.AbbreviationID === 18) &&
      additionalAbbreviations.length !== 0 &&
      !radiology ? (
        <View wrap={false}>
          <AbbreviationHeader
            title={`Additional Abbreviations, Acronyms and Symbols`}
          />
          {additionalAbbreviations.map((abbreviation, index) => {
            const abbreviationDescription = findObject(
              abbreviationList,
              "ID",
              abbreviation.AbbreviationID
            );
            const lastColumnText = abbreviation.PractitionerText.map(
              (element) => {
                return `${element.ProviderType} (${element.TimesUsed}) `;
              }
            );
            return (
              <RenderFourColumns
                firstColumn={abbreviationDescription.Name}
                secondColumn={abbreviationDescription.AbbreviationProblemDesc}
                thirdColumn={abbreviationDescription.PreferredTerm}
                fourthColumn={lastColumnText}
                lastBox={index === additionalAbbreviations.length - 1}
              />
            );
          })}
          <Text style={styles.text}>
            In the event the facility has not adopted or created a "Do Not Use"
            list, it is suggested as a measure to reduce medical errors
          </Text>
        </View>
      ) : (
        <RenderSubTitleCard
          title={"Additional Abbreviations, Acronyms, and Symbols"}
          wrap={false}
          align={"center"}
        >
          <Text style={[styles.text, { textAlign: "center" }]}>
            (For possible future inclusion in the Official "Do Not Use" List)
          </Text>
          <Text style={[styles.text, { textAlign: "center" }]}>
            There were no abbreviations found in the review of this record.
          </Text>
        </RenderSubTitleCard>
      )}
    </View>
  );
};

export default Abbreviations;
