import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

const AdminReviewButtons = (props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex] = React.useState(!props.isFacilitator() ? 4 : 0);

  const disableResetStatus = props.facilitatorID === null;

  const options = !props.isFacilitator()
    ? [
      "Re-Assign Meeting",
      "Re-Assign Facilitator",
      "Reset Review Status",
      "Remove from Meeting",
      "Review",
    ]
    : ["Review"];

  const handleSelection = (index) => {
    if (!props.isFacilitator()) {
      switch (index) {
        case 0: {
          props.spawnReassignMeetingMeetingDialog();
          break;
        }
        case 1: {
          props.spawnAssignFacilitatorDialog();
          break;
        }
        case 2: {
          props.spawnResetReviewStatusDialog();
          break;
        }
        case 3: {
          props.spawnRemoveFromMeetingDialog();
          break;
        }
        case 4:
        default: {
          props.sendToReviewsDashboard();
          break;
        }
      }
    } else {
      props.sendToReviewsDashboard();
    }
  };

  const handleClick = () => {
    handleSelection(selectedIndex);
  };

  const handleMenuItemClick = (event, index) => {
    handleSelection(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="left">
      <Grid item xs={12}>
        <ButtonGroup
          variant="contained"
          color="primary"
          size={"small"}
          ref={anchorRef}
          aria-label="split button"
        >
          <Button onClick={handleClick}>{options[selectedIndex]}</Button>
          {!props.isFacilitator() && (
            <Button
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          )}
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id={`split-button-menu-${props.recordID}`}>
                    {options.slice(0, -1).map((option, index) => {
                      return (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                          disabled={option === "Reset Review Status" && disableResetStatus}
                        >
                          {option}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default AdminReviewButtons;
