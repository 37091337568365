import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { pdf } from "@react-pdf/renderer";
import { pdfjs } from "react-pdf";
import MenuItem from "@mui/material/MenuItem";
import MRTS from "./MRTS";
import Facesheet from "./Facesheet";
import FinalReport from "./FinalReport";
import Worksheet from "./Worksheet";
import { formatRecordPDFData } from "../GeneratePDF/PDFUtilities/FormatPDFDataFunctions";
import {
  selectContent,
  selectOutcomes,
  selectRecordInformationByID,
  selectRNChecklistByRecordID,
} from "src/redux/selectors";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfComponents = {
  MRTS,
  Facesheet,
  FinalReport,
  Worksheet,
};

const GeneratePDF = ({ RecordType, recordID, settings, children }) => {
  const [loading, setLoading] = useState(true);
  const [documentURL, setDocumentURL] = useState("");
  const PdfComponent = PdfComponents[RecordType];

  // data
  const record = useSelector(selectRecordInformationByID(recordID));
  const recordData = formatRecordPDFData(record);
  const abbreviationList = useSelector(selectContent("Abbreviation"));
  const outcomesList = useSelector(selectOutcomes);
  const checklistData = useSelector(selectRNChecklistByRecordID(recordID));

  useEffect(() => {
    const generateBlob = async () => {
      setLoading(true);
      if (recordID) {
        const blob = await pdf(
          <PdfComponent
            recordData={recordData}
            settings={settings}
            abbreviationList={abbreviationList}
            outcomesList={outcomesList}
            checklistData={checklistData}
          />
        ).toBlob();
        setDocumentURL(window.URL.createObjectURL(blob));
      }
      setLoading(false);
    };
    if (record.Loaded) {
      generateBlob();
    } else {
      setLoading(false);
      setDocumentURL(null);
    }
  }, [recordID, settings]);

  if (loading) {
    return (
      <MenuItem key={`generate-pdf-${RecordType}-${recordID}`}>
        <i
          className="fas fa-spinner fa-pulse"
          style={{ color: "dodgerblue" }}
        />
        &nbsp; Loading
      </MenuItem>
    );
  } else {
    return (
      <a
        href={documentURL}
        style={{ color: "black" }}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }
};

export default GeneratePDF;
