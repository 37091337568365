import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import Footer from "./PDFUtilities/Footer";
import {
  renderReviewReasons,
  renderInfo,
  renderHistoryPreSurgery,
  renderDiagnoses,
} from "./PDFUtilities/RenderPDFFunctions";
import { styles } from "./PDFUtilities/PDFStyles";
import Constants from "../../../utilities/constants";

const MRTS = ({ recordData }) => {
  return (
    <Document
      title={"Medical Record Transmittal Sheet"}
      author={"ARCHI"}
      subject={"MRTS"}
      keywords={"Peer Review PPR ARCHI MRTS"}
      creator={"ARCHI PPR System"}
      producer={"ARCHI"}
      language={"English"}
    >
      {recordData !== undefined && (
        <Page size="LETTER" style={styles.page}>
          <View style={styles.pdfHeader}>
            <Image src={"/images/ARCHI-HorizontalLogo-07.2023.png"} />
          </View>

          <Text style={styles.title}>Medical Record Transmittal Sheet</Text>

          {renderInfo("Record Date", recordData.RecordDate)}
          {renderInfo(
            "Facility Name",
            `${recordData.ARCHIFacilityID} - (${recordData.FacilityCity}) ${recordData.FacilityName}`
          )}
          {renderInfo("Facility Contact Name", recordData.FacilityContactName)}
          {renderInfo(
            "Facility Medical Record Number",
            recordData.FacilityRecordID
          )}
          {renderInfo("Provider", `${recordData.Provider}`)}
          {recordData.Midlevel.length !== 0 &&
            renderInfo("Midlevel", `${recordData.Midlevel}`)}
          {renderInfo("ARCHI Record Number", recordData.ARCHIRecordID)}
          {renderInfo("Age of Patient", recordData.PatientAge)}
          {renderInfo("Sex of Patient", recordData.PatientGender)}
          {renderInfo("Patient Source", recordData.PatientSource)}
          {renderInfo("Date and Time of Arrival", recordData.ArrivalDateTime)}
          {renderInfo(
            "Date and Time of Discharge",
            recordData.DischargeDateTime
          )}
          {renderInfo("Length of Stay", recordData.StayLength)}
          {renderInfo("Committee", recordData.CommitteeName)}
          {renderHistoryPreSurgery(recordData, false)}

          <View style={styles.contentRow}>
            <Text style={styles.subTitle}>Diagnosis Information</Text>
          </View>

          {renderDiagnoses(recordData.RecordDiagnosis)}
          {renderReviewReasons(recordData.ReviewReasons)}

          <View style={styles.contentColumn}>
            <View style={{ flex: "1 0 auto" }}>
              <Text style={styles.columnTextBold}>Reference Material:</Text>
            </View>
            <View style={{ flex: "3 2 auto" }}>
              {recordData.ReferenceMaterial.map((reference, index) => {
                recordData.ReferenceMaterialList.map((list) => {
                  if (
                    reference.recordReferenceMaterial.ReferenceMaterialID ===
                    list.ID
                  ) {
                    if (list.ID === Constants.REFERENCE_MATERIAL_ID_OTHER) {
                      return (recordData.ReferenceMaterialInformation =
                        list.ReferenceMaterial +
                        "\n Description - " +
                        reference.recordReferenceMaterial.OtherReference);
                    } else
                      return (recordData.ReferenceMaterialInformation =
                        list.ReferenceMaterial);
                  }
                  return recordData.ReferenceMaterialInformation;
                });
                return (
                  <View key={index} style={{ flexDirection: "column" }}>
                    <Text style={styles.text}>
                      {recordData.ReferenceMaterialInformation}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>

          <View style={styles.contentRow}>
            <Text style={styles.columnTextBold}>
              Reference Material: (policies, procedures, etc.)
            </Text>
            <Text style={[styles.text, styles.subContentRow]}>
              {recordData.ReferenceMaterialDescription}
            </Text>
          </View>

          <View style={styles.contentRow}>
            <Text style={styles.columnTextBold}>
              Is there additional information that you feel would be relevant or
              helpful to the committee?
            </Text>
            <Text style={[styles.text, styles.subContentRow]}>
              {recordData.AdditionalInformation}
            </Text>
          </View>
          <View style={styles.contentRow}>
            <Text style={styles.columnTextBold}>
              What specific questions would you like the peer review committee
              to answer?
            </Text>
            <Text style={[styles.text, styles.subContentRow]}>
              {recordData.CommitteeQuestion}
            </Text>
          </View>

          <Footer />
        </Page>
      )}
    </Document>
  );
};

export default MRTS;
