const humanFactorsCommunication = () => ({
  label: "Human Factors - Communication",
  render: { type: "Title" },
  items: [
    medicationError(),
    handoffIssue(),
    disagreementWithinFacility(),
    legibilityIssues(),
  ],
});

const medicationError = () => ({
  name: "medicationError",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
});
const handoffIssue = () => ({
  name: "handoffIssue",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
});
const disagreementWithinFacility = () => ({
  name: "disagreementWithinFacility",
  render: {
    type: "InlineRadios",
    options: ["Yes", "No", "Unknown", "No Track"],
  },
});
const legibilityIssues = () => ({
  name: "legibilityIssues",
  render: { type: "InlineRadios", options: ["Yes", "No"] },
  itemsDependOn: [{ name: "legibilityIssues", is: "Yes" }],
  items: [AdditionalDetails()],
});

const AdditionalDetails = () => ({
  name: "legibilityIssuesAdditionalDetails",
  render: { type: "TextBox", required: true },
});

export default humanFactorsCommunication;
