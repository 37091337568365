import React from "react";

const TextBegin = (props) => {
  return (
    <>
      <div className={"section-report-title"}>
        <h4>Discharge Instructions - Medical Abbreviations</h4>
      </div>
      {props.values.displayOptions.sections.includes("text") && (
        <>
          <div>
            <p className={"text-left"}>
              According to the National Assessment of Adult Literacy (NAAL),
              released in 2006 by the U.S. Department of Education, 30 million
              adults struggle with basic reading tasks. The NAAL also found that
              only 12 percent of consumers have proficient health literacy
              skills suggesting that nearly nine out of ten adults may lack many
              of the skills necessary to sufficiently manage their health. Low
              health literacy can affect a person's ability to locate health
              care providers and services, fill out health forms, share personal
              health information with providers, manage chronic diseases and
              engage in self-care.
            </p>
            <br />
            <p className={"text-left"}>
              To ensure that the content of written materials is understandable,
              discharge instructions must include words that are well known to
              the patient. Technical words and abbreviations should be avoided
              and the definition of medical words should be provided when they
              are essential.
            </p>
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default TextBegin;
