export const findValue = (array, searchBy, id) => {
  if (array.length === 0) {
    return "Pending";
  }
  const value = array.find((obj) => obj[searchBy] === id);
  if (value === undefined) {
    return null;
  }
  return value.OptionValue;
};

export const findObject = (array, searchBy, id) => {
  const object = array.find((obj) => obj[searchBy] === id);
  if (object === undefined) {
    return null;
  }
  return object;
};
