import { KeyboardDatePicker } from "mui-rff";
import React from "react";

const CustomRange = (props) => {
  return (
    <>
      <div className={"row pt-2 pr-2"}>
        <div className={"col"}>
          <KeyboardDatePicker
            label={"Start Date"}
            variant={"inline"}
            inputVariant={"outlined"}
            minDate={new Date(2000, 0, 1)}
            maxDate={new Date()}
            name={"dateRange.range.startDate"}
            format={"MM/dd/yyyy"}
            required={props.values.dateRange.type === "custom"}
            disabled={props.disabled}
          />
        </div>
      </div>
      <div className={"row pt-2 pr-2"}>
        <div className={"col"}>
          <KeyboardDatePicker
            label={"End Date"}
            variant={"inline"}
            inputVariant={"outlined"}
            minDate={new Date(2000, 0, 1)}
            maxDate={new Date()}
            name={"dateRange.range.endDate"}
            format={"MM/dd/yyyy"}
            required={props.values.dateRange.type === "custom"}
            disabled={props.disabled}
          />
        </div>
      </div>
    </>
  );
};

export default CustomRange;
