import React, { useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { selectContent } from "src/redux/selectors";

const SubmitButton = (props) => {
  const { onClick, disabled } = props;
  const [state, setState] = useState({ isLoading: false });
  const fetching = useSelector(selectContent("Fetching"));

  const changeState = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const newOnClick = () => {
    changeState("isLoading", true);
    onClick();
    setTimeout(() => changeState("isLoading", false), 1000);
  };

  const isLoading = state.isLoading || fetching.Loading;

  const newProps = {
    ...props,
    onClick: newOnClick,
    disabled: isLoading || disabled,
    children: isLoading ? <CircularProgress size={24} /> : props.children,
  };

  return <Button {...newProps} />;
};

export default SubmitButton;
