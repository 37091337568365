import React from "react";
import Switch from "./Switch";
import Checkbox from "./Checkbox";

const VariantView = ({ variant, name, label }) => {
  const Variant = Variants[variant];

  return <>{variant && <Variant name={name} label={label} />}</>;
};

const Variants = { withSwitch: Switch, withCheckbox: Checkbox };

export default VariantView;
