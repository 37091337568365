const mutators = {
  clearAll: (args, state, utils) => {
    utils.changeValue(state, "recordStatus", () => null);
    utils.changeValue(state, "mrtsReviewType", () => null);
    utils.changeValue(state, "reviewMonth", () => null);
    utils.changeValue(state, "reviewYear", () => null);
    utils.changeValue(state, "comments", () => null);
  },
};

export default mutators;
