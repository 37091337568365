import React, { useContext } from "react";

import { apiUrl } from "src";
import apiService from "src/utilities/api_service";
import { globalVariables } from "src/components/App";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Form } from "react-final-form";
import validateFinalForm from "src/utilities/validateFinalForm";
import * as yup from "yup";
import { useSelector } from "react-redux";
import {
  selectContentByID,
  selectActiveUserByPermissionID,
} from "src/redux/selectors";
import { MenuItem } from "@mui/material";
import { Select } from "mui-rff";
import useAuthHook from "src/utilities/useAuthHook";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { MeetingActions } from "src/redux/reducers/Meeting";

const DeleteMeetingDialog = (props) => {
  const { authenticate } = useAuthHook();
  const { authenticatedDispatch } = useContext(globalVariables);
  
  const handleClose = () => {
    props.setDialogVisible();
    return true;
  }

  const removeMeeting = async (
    meetingID,
    authenticatedDispatch,
    handleClose,
    authenticate
  ) => {
    try {
      await authenticate();
      const resp = await apiService.post(`${apiUrl}meeting/delete`, {
        MeetingID: meetingID,
      });
      if (resp.status === 200) {
        authenticatedDispatch(FetchingActions.Success("Action"));
        authenticatedDispatch(MeetingActions.Remove(resp.data));
      } else {
        authenticatedDispatch(FetchingActions.Failure("Remove Meeting"));
      }
    } catch (error) {
      authenticatedDispatch(FetchingActions.Failure("Remove Meeting"));
    } finally {
      handleClose();
    }
  };

  return (
    <>
        <Dialog
            onClose={handleClose}
            open={props.dialogVisible}
            fullWidth={true}
            scroll="paper"
        >
            <DialogTitle>Remove Meeting</DialogTitle>
            <DialogContent dividers>
                <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                        <Alert severity="error">
                            <b>NOTE</b>: cannot remove a meeting if any records are assigned!
                        </Alert>
                        <DialogActions>
                            <Button onClick={handleClose}>
                            Cancel
                            </Button>
                            <Button
                                onClick={() =>
                                    removeMeeting(
                                        props.meetingID,
                                        authenticatedDispatch,
                                        handleClose,
                                        authenticate
                                    )
                                }
                                autoFocus
                                variant="contained"
                                color="error"
                            >
                                Continue
                            </Button>
                        </DialogActions>    
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </>
  );
};

export default DeleteMeetingDialog;
