const medicalManagement = (isLegacyVersion) => ({
  label: "Medical Management: (Opportunities for Improvement Related To)",
  render: { type: "Title", hide: isLegacyVersion },
  items: [
    trauma(),
    antibioticTherapy(),
    analgesicTherapy(),
    electrocardiogram(),
  ],
  version: 2,
});

const trauma = () => ({
  label: "Trauma/Medical:",
  render: { type: "Checkbox" },
  valueDependsOn: [
    { name: "traumaInterventions", is: "true" },
    { name: "traumaMedications", is: "true" },
    { name: "traumaDiagnostics", is: "true" },
    { name: "traumaDisposition", is: "true" },
  ],
  items: [
    traumaInterventions(),
    traumaMedications(),
    traumaDiagnostics(),
    traumaDisposition(),
  ],
});

const traumaInterventions = () => ({
  name: "traumaInterventions",
  render: { type: "Checkbox" },
});
const traumaMedications = () => ({
  name: "traumaMedications",
  render: { type: "Checkbox" },
});
const traumaDiagnostics = () => ({
  name: "traumaDiagnostics",
  render: { type: "Checkbox" },
});
const traumaDisposition = () => ({
  name: "traumaDisposition",
  render: { type: "Checkbox" },
});

const antibioticTherapy = () => ({
  label: "Antibiotic Therapy:",
  render: { type: "Checkbox" },
  valueDependsOn: [
    { name: "antibioticTherapySelection", is: "true" },
    { name: "antibioticTherapyDosage", is: "true" },
  ],
  items: [antibioticTherapySelection(), antibioticTherapyDosage()],
});

const antibioticTherapySelection = () => ({
  name: "antibioticTherapySelection",
  render: { type: "Checkbox" },
});
const antibioticTherapyDosage = () => ({
  name: "antibioticTherapyDosage",
  render: { type: "Checkbox" },
});

const analgesicTherapy = () => ({
  label: "Analgesic Therapy:",
  render: { type: "Checkbox" },
  valueDependsOn: [
    { name: "analgesicTherapySelection", is: "true" },
    { name: "analgesicTherapyDosage", is: "true" },
    { name: "analgesicTherapyLack", is: "true" },
  ],
  items: [
    analgesicTherapySelection(),
    analgesicTherapyDosage(),
    analgesicTherapyLack(),
  ],
});

const analgesicTherapySelection = () => ({
  name: "analgesicTherapySelection",
  render: { type: "Checkbox" },
});
const analgesicTherapyDosage = () => ({
  name: "analgesicTherapyDosage",
  render: { type: "Checkbox" },
});
const analgesicTherapyLack = () => ({
  name: "analgesicTherapyLack",
  render: { type: "Checkbox" },
});

const electrocardiogram = () => ({
  label: "Electrocardiogram Interpretation:",
  render: { type: "Checkbox" },
  valueDependsOn: [
    { name: "electrocardiogramIncorrect", is: "true" },
    { name: "electrocardiogramInadequate", is: "true" },
  ],
  items: [electrocardiogramIncorrect(), electrocardiogramInadequate()],
});

const electrocardiogramIncorrect = () => ({
  name: "electrocardiogramIncorrect",
  render: { type: "Checkbox" },
});
const electrocardiogramInadequate = () => ({
  name: "electrocardiogramInadequate",
  render: { type: "Checkbox" },
});

export default medicalManagement;
