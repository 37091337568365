import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form } from "react-final-form";
import validateFinalForm from "src/utilities/validateFinalForm";
import mutators from "./mutators";
import validationSchema from "./validationSchema";
import RejectApprovedMRTS from "../MRTSRecordDialog/ReusableSections/RejectApprovedMRTS";
import { selectRecordByID } from "src/redux/selectors";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { RecordActions } from "src/redux/reducers/Record";
import Custom from "src/components/individual/Custom";

const validate = validateFinalForm(validationSchema);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const RejectApprovedRecordDialog = (props) => {
  const Record = useSelector(selectRecordByID(props.recordID));
  const classes = useStyles();

  React.useEffect(() => {
    if (props.dialogVisible) {
      props.dispatch(RecordActions.LoadData(props.recordID, "ID"));
    }
  }, [props.dialogVisible]);

  const initialValues = {
    rejectionReason: null,
    rejectionComment: null,
  };

  const rejectApprovedMRTS = (values) => {
    props.dispatch(
      RecordActions.Reject({
        RecordID: props.recordID,
        Comments: values.rejectionComment,
        Reason: values.rejectionReason,
      })
    );
  };

  return (
    <>
      <Form
        onSubmit={rejectApprovedMRTS}
        initialValues={initialValues}
        validate={validate}
        mutators={mutators}
        render={({ form, submitting, values }) => (
          <>
            <form>
              <Dialog
                fullWidth
                maxWidth={"md"}
                open={props.dialogVisible}
                onClose={() => {
                  props.setDialogVisible();
                  form.mutators.clearAll();
                }}
              >
                <DialogTitle>
                  Reject Approved Record - ARCHI Record ID:{" "}
                  {Record.ARCHIRecordID}
                </DialogTitle>
                <DialogContent>
                  <RejectApprovedMRTS
                    {...props}
                    classes={classes}
                    form={form}
                    values={values}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => {
                      props.setDialogVisible();
                      form.mutators.clearAll();
                    }}
                    disabled={submitting}
                  >
                    Close
                  </Button>
                  <Custom.SubmitButton
                    color={"secondary"}
                    variant={"contained"}
                    onClick={() => {
                      rejectApprovedMRTS(values);
                      props.setDialogVisible();
                      form.mutators.clearAll();
                    }}
                    disabled={
                      values.rejectionReason === null ||
                      values.rejectionComment === null ||
                      submitting
                    }
                  >
                    Reject
                  </Custom.SubmitButton>
                </DialogActions>
              </Dialog>
            </form>
          </>
        )}
      />
    </>
  );
};

export default RejectApprovedRecordDialog;
