import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";
import { styles } from "../../PDFUtilities/PDFStyles";

const Signature = ({ signature }) => {
  return (
    <View wrap={false} style={{ paddingVertical: 20 }}>
      {signature ? <Signed name={signature.Name} /> : <NotSigned />}
      <Text style={styles.text}>
        All information utilized in this program will be protected as afforded
        under the Healthcare Quality Improvement Act of 1986 (HCQIA) and all
        confidentiality provisions and protections as provided in Texas HB 1614,
        Section 160.010 Texas Occupations Code, Medical Peer Review committees,
        Section 161.033 of the Health and Safety Code and identifiable
        information will be protected as afforded by HIPAA of 1996.
      </Text>
    </View>
  );
};

const Signed = ({ name }) => (
  <View style={{ paddingVertical: 10, flexDirection: "column", width: "25%" }}>
    <PhysicianSignature />
    <Image
      src={`/images/signatures/${name.replace(/\s/g, "_")}.png`}
      style={{ flexGrow: 1 }}
    />
  </View>
);

const NotSigned = () => (
  <View style={{ paddingVertical: 10, flexDirection: "row" }}>
    <PhysicianSignature />
    <View
      style={{
        flexGrow: 1,
        width: "45%",
        fontSize: 10,
        borderBottom: true,
      }}
    />
    <Text
      style={{
        flexGrow: 1,
        fontSize: 12,
        fontFamily: "Roboto-Bold",
        paddingLeft: 10,
      }}
    >
      Date:
    </Text>
    <View
      style={{
        flexGrow: 1,
        width: "20%",
        fontSize: 10,
        borderBottom: true,
      }}
    />
  </View>
);

const PhysicianSignature = () => (
  <Text style={{ flexGrow: 1, fontSize: 12, fontFamily: "Roboto-Bold" }}>
    Physician Signature:
  </Text>
);

export default Signature;
