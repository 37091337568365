import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import {
  renderDiagnoses,
  renderShortInfo,
  renderReviewReasons,
} from "./PDFUtilities/RenderPDFFunctions";
import Footer from "./PDFUtilities/Footer";
import { styles } from "./PDFUtilities/PDFStyles";
import Constants from "../../../utilities/constants";

const Facesheet = (props) => {
  const recordData = props.recordData;

  return (
    <Document
      title={"Facesheet Report"}
      author={"ARCHI"}
      subject={"Facesheet"}
      keywords={"Peer Review PPR ARCHI "}
      creator={"ARCHI PPR System"}
      producer={"ARCHI"}
      language={"English"}
    >
      <Page size="LETTER" style={styles.page}>
        <View style={styles.pdfHeader}>
          <Image src={"/images/ARCHI-HorizontalLogo-07.2023.png"} />
        </View>

        <Text style={styles.title}>Facesheet Report</Text>

        {renderShortInfo("Peer Review", recordData.CommitteeName)}
        {renderShortInfo("ARCHI Record Number", recordData.ARCHIRecordID)}
        {renderReviewReasons(recordData.ReviewReasons)}
        <View style={styles.contentRow}>
          <Text style={styles.subTitle}>Patient Information</Text>
        </View>
        {renderShortInfo("Age", recordData.PatientAge)}
        {renderShortInfo("Sex", recordData.PatientGender)}
        {renderShortInfo("Arrival Date and Time", recordData.ArrivalDateTime)}
        {renderShortInfo(
          "Discharge Date and Time",
          recordData.DischargeDateTime
        )}
        {renderShortInfo("Patient Source", recordData.PatientSource)}
        {renderShortInfo("Length of Stay", recordData.StayLength)}
        <View style={styles.contentRow}>
          <Text style={styles.subTitle}>Diagnosis Information</Text>
        </View>
        {renderDiagnoses(recordData.RecordDiagnosis)}
        <View style={styles.contentRow}>
          <Text style={styles.subTitle}>Pertinent Clinical Question</Text>
        </View>

        <Text style={styles.text}>
          {recordData.RecordComment &&
            recordData.RecordComment.length > 0 &&
            recordData.RecordComment.find(
              (e) => e.TypeID === Constants.COMMENT_TYPE_ID_COMMITTEE_QUESTION
            ).Description}
        </Text>

        <Footer />
      </Page>
    </Document>
  );
};

export default Facesheet;
