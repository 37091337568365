import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const signatureSlice = createSlice({
  name: "signature",
  initialState,
  reducers: {
    batchAdd: (_state, action) => {
      return [...action.payload.rnSignature];
    },
  },
});

const { batchAdd } = signatureSlice.actions;

const BatchAdd = (content) => async (dispatch) => {
  dispatch(batchAdd(content));
};

export const SignatureActions = {
  BatchAdd,
};

export default signatureSlice.reducer;
