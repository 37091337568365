import React from "react";

const MidlevelTable = ({ title, midlevels, columnName }) => {
  return (
    <div>
      <div>
        <h6>{title}</h6>
      </div>
      <br />
      <table
        className={"table table-bordered table-striped table-hover table-sm"}
      >
        <thead>
          <tr>
            <th scope={"col"}>
              <b>Midlevel-Provider</b>
            </th>
            <th scope={"col"}>
              <b>{columnName}</b>
            </th>
            <th scope={"col"}>
              <b>Total</b>
            </th>
          </tr>
        </thead>
        <tbody>
          {midlevels.map((midlevel, i) => (
            <tr key={`report-table-row-${midlevel["Mid-level Provider"]}-${i}`}>
              <td>{midlevel["Mid-level Provider"]}</td>
              <td>{midlevel[columnName]}</td>
              <td>{midlevel.Total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MidlevelTable;
