import React from "react";
import Header from "./Header";
import Buttons from "./Buttons";

const Layout = ({ children, onSubmitRecordReview, roles }) => {
  return (
    <>
      <Header roles={roles} />
      {children}
      <Buttons onSubmitRecordReview={onSubmitRecordReview} />
    </>
  );
};

export default Layout;
