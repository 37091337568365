import C from "src/utilities/constants";
import get from "./getData";

const rnStage = (apiResponse) => {
  const fChecklist = get.ChecklistF(apiResponse);
  const fComments = get.CommentsF(apiResponse);
  const fOutcomes = get.OutcomesF(apiResponse);
  const aChecklist = get.ChecklistA(apiResponse);
  const aComments = get.CommentsA(apiResponse);
  const aOutcomes = get.OutcomesA(apiResponse);
  const reviewStatus = get.ReviewStatus(apiResponse);
  const stages = {
    [C.REVIEW_STATUS.PENDING_FACILITATOR_REVIEW]: {
      checklist: fChecklist,
      comments: fComments,
      outcomes: fOutcomes,
      saveAs: "facilitatorReview",
      saveTo: "FacilitatorData",
    },
    [C.REVIEW_STATUS.FACILITATOR_REVIEW_IN_PROGRESS]: {
      checklist: fChecklist,
      comments: fComments,
      outcomes: fOutcomes,
      saveAs: "facilitatorReview",
      saveTo: "FacilitatorData",
    },
    [C.REVIEW_STATUS.PENDING_RN_APPROVAL]: {
      checklist: aChecklist,
      comments: aComments,
      outcomes: aOutcomes,
      saveAs: "adminReview",
      saveTo: "ApprovalData",
    },
    [C.REVIEW_STATUS.RN_REVIEW_IN_PROGRESS]: {
      checklist: aChecklist,
      comments: aComments,
      outcomes: aOutcomes,
      saveAs: "adminReview",
      saveTo: "ApprovalData",
    },
    [C.REVIEW_STATUS.APPROVED]: {
      checklist: aChecklist,
      comments: aComments,
      outcomes: aOutcomes,
    },
    "": {
      checklist: [],
      comments: {},
      outcomes: {},
    },
  };
  return stages[reviewStatus];
};

export default rnStage;
