import Contact from "src/redux/reducers/PersonnelManagement/Contact";
import Facility from "src/redux/reducers/PersonnelManagement/Facility";
import Midlevel from "src/redux/reducers/PersonnelManagement/Midlevel";
import Provider from "src/redux/reducers/PersonnelManagement/Provider";
import User from "src/redux/reducers/PersonnelManagement/User";

const PersonnelManagement = {
  Contact,
  Facility,
  Midlevel,
  Provider,
  User,
};

export default PersonnelManagement;
