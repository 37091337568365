import "animate.css/animate.min.css";
import React from "react";
import {
  Button,
  Card,
  Container,
  Avatar,
  Icon,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Constants from "src/utilities/constants";

const loginRoute = process.env.REACT_APP_LOGIN_ROUTE || "";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const themeColor = "#43A048";

const Login = () => {
  const classes = useStyles();

  return (
    <div className={"animate__animated animate__fadeIn container"}>
      <div className={"row"} style={{ height: "25vh" }} />
      <div className={"row"}>
        <div className={"col"} />
        <div className={"col-6 my-auto"}>
          <Card sx={{ minWidth: 275 }}>
            <Container component="main" maxWidth="xs">
              <div className={classes.paper}>
                <Avatar
                  sx={{ bgcolor: themeColor, width: 90, height: 90 }}
                  variant={"rounded"}
                >
                  <Icon
                    baseClassName="fas"
                    className="fa-clinic-medical"
                    sx={{ fontSize: 80 }}
                    style={{ position: "absolute" }}
                  />
                  <Icon
                    baseClassName="fas"
                    className="fa-square"
                    sx={{ fontSize: 50 }}
                    style={{
                      marginLeft: 35,
                      marginTop: 35,
                      position: "absolute",
                    }}
                  />
                  <Icon
                    baseClassName="fas"
                    className="fa-clipboard-list"
                    sx={{ fontSize: 30, color: themeColor }}
                    style={{
                      marginRight: 20,
                      marginTop: 40,
                      position: "absolute",
                    }}
                  />
                  <Icon
                    baseClassName="fas"
                    className="fa-user-md"
                    sx={{ fontSize: 50, color: themeColor }}
                    style={{
                      marginLeft: 50,
                      marginTop: 35,
                      position: "absolute",
                    }}
                  />
                </Avatar>

                <Typography component="h1" variant="h5">
                  Physician Peer Review
                </Typography>
                <div className={"row pt-2"}>
                  <p>
                    By logging into the Physician Peer Review application, you
                    agree to our{" "}
                    <a href="/privacyPolicy.html">Privacy Policy</a> and{" "}
                    <a href="/termsConditions.html">Terms of Services</a>. If
                    you have any questions, do not hesitate to{" "}
                    <a href="/contact.html">contact us</a>.
                  </p>
                  <div style={{ color: "green", textAlign: "right" }}>
                    <p>Version: {Constants.VERSION}</p>
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col"}>
                    <a href={loginRoute}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        type="submit"
                      >
                        Login
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </Container>
          </Card>
        </div>
        <div className={"col"} />
      </div>
    </div>
  );
};

export default Login;
