import React from "react";
import ChartDataBuilder from "../../ChartDataBuilder";
import AllFacilityPatientGenderChart from "./AllFacilityPatientGenderChart";
import FacilitySpecificPatientGenderChart from "./FacilitySpecificPatientGenderChart";
import TextBegin from "./TextBegin";

const PatientGender = (props) => {
  const chartData = ChartDataBuilder(props, props.reportKey, ["patientGender"]);
  const chartDataAllFacilities =
    props.Report.Data[`${props.reportKey}AllFacilities`] !== undefined &&
    ChartDataBuilder(props, `${props.reportKey}AllFacilities`, [
      "patientGender",
    ]);

  return (
    <>
      <TextBegin />
      {chartDataAllFacilities !== false && (
        <AllFacilityPatientGenderChart
          {...props}
          labels={chartDataAllFacilities.labels}
          data={chartDataAllFacilities.data}
          datasets={chartDataAllFacilities.datasets}
          heading={"Patient Gender"}
          rows={chartDataAllFacilities.rows}
        />
      )}
      <FacilitySpecificPatientGenderChart
        {...props}
        labels={chartData.labels}
        data={chartData.data}
        datasets={chartData.datasets}
        heading={"Patient Gender"}
        rows={chartData.rows}
      />
    </>
  );
};

export default PatientGender;
