import React from "react";

const GroupedWithProvider = (props) => {
  const rows = props.rows;
  const sum = rows.reduce((a, b) => a + b.value, 0);
  const formattedRows = [];
  rows.forEach((row, i) => {
    if (row.value === 0) return;
    formattedRows.push(
      <tr key={`report-table-row-${row.label}-${i}`}>
        <>
          {row.archiProviderId !== undefined ? (
            <td>{row.archiProviderId}</td>
          ) : row.archiProviderName !== undefined ? (
            <td>{row.archiProviderName}</td>
          ) : (
            row.archiProviderLabel !== undefined && (
              <td>{row.archiProviderLabel}</td>
            )
          )}
        </>
        <td class={"pl-5"} style={{ textAlign: "left" }}>
          {row.label}
        </td>
        <td>{row.timeframe}</td>
        <td>{row.value}</td>
        <td>{Math.round(100 * ((row.value / sum) * 100)) / 100}%</td>
      </tr>
    );
  });
  return (
    <table
      className={"table table-bordered table-striped table-hover table-sm"}
    >
      <thead>
        <tr>
          <th scope={"col"}>
            <b>Provider</b>
          </th>
          <th scope={"col"}>
            <b>{props.heading}</b>
          </th>
          <th scope={"col"}>
            <b>Timeframe</b>
          </th>
          <th scope={"col"}>
            <b>Total</b>
          </th>
          <th scope={"col"}>
            <b>Percentage</b>
          </th>
        </tr>
      </thead>
      <tbody>{formattedRows !== undefined && formattedRows}</tbody>
    </table>
  );
};

export default GroupedWithProvider;
