import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityOtherPatientSafetyConcern = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Other Patient Safety Concern"}
        xAxisLabel={"Other Patient Safety Concern"}
        yAxisLabel={"Number of Records"}
        figureText={"Other Patient Safety Concern for all RPPR© Facilities"}
      />
    </>
  );
};

export default AllFacilityOtherPatientSafetyConcern;
