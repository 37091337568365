const otherPatientSafetyIssues = () => ({
  label: "Other Patient Safety Issues",
  render: { type: "Title" },
  items: [otherPatientSafetyIssuesOfConcern()],
});

const otherPatientSafetyIssuesOfConcern = () => ({
  name: "otherSafetyIssues",
  render: { type: "InlineRadios", options: ["Yes", "No"] },
  itemsDependOn: [{ name: "otherSafetyIssues", is: "Yes" }],
  items: [
    {
      label: "Categories:",
      render: { type: "SubTitle" },
      items: [medical(), psychOrSocial(), otherCheckbox()],
    },
  ],
});

const medical = () => ({
  label: "Medical",
  render: { type: "Checkbox" },
  valueDependsOn: [
    { name: "othMedCommunication", is: "true" },
    { name: "othMedContinuityOfCare", is: "true" },
    { name: "othMedTreatmentRendered", is: "true" },
    { name: "othMedDischargePlanning", is: "true" },
  ],
  items: [
    continuityOfCare(),
    careCommunication(),
    careTreatment(),
    careDischarge(),
  ],
});

const continuityOfCare = () => ({
  name: "othMedContinuityOfCare",
  render: { type: "Checkbox" },
});
const careCommunication = () => ({
  name: "othMedCommunication",
  render: { type: "Checkbox" },
});
const careTreatment = () => ({
  name: "othMedTreatmentRendered",
  render: { type: "Checkbox" },
});
const careDischarge = () => ({
  name: "othMedDischargePlanning",
  render: { type: "Checkbox" },
});

const psychOrSocial = () => ({
  label: "Psych/Social",
  render: { type: "Checkbox" },
  valueDependsOn: [
    { name: "othPsyCommunication", is: "true" },
    { name: "othPsyTreatmentRendered", is: "true" },
    { name: "othPsyDischarge", is: "true" },
  ],
  items: [psyCommunication(), psyTreatment(), psyDischarge()],
});

const psyCommunication = () => ({
  name: "othPsyCommunication",
  render: { type: "Checkbox" },
});
const psyTreatment = () => ({
  name: "othPsyTreatmentRendered",
  render: { type: "Checkbox" },
});
const psyDischarge = () => ({
  name: "othPsyDischarge",
  render: { type: "Checkbox" },
});

const otherCheckbox = () => ({
  name: "otherSafetyIssueOther",
  render: { type: "Checkbox" },
  itemsDependOn: [{ name: "otherSafetyIssueOther", is: "true" }],
  items: [additionalDetails()],
});

const additionalDetails = () => ({
  name: "othSafIssOthAdditionalDetails",
  render: { type: "TextBox", required: true },
});

export default otherPatientSafetyIssues;
