import * as React from "react";
import _ from "lodash";
//ui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Field } from "react-final-form";
import { MenuItem } from "@mui/material";
import { Select, TextField, Checkboxes } from "mui-rff";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
//data
import {
  LinkToFacility,
  ProviderActions,
} from "src/redux/reducers/PersonnelManagement/Provider";
import { degrees } from "./initialValues";
import { useSelector } from "react-redux";
import { selectFacilities, selectProviders } from "src/redux/selectors";
//validation
import { midlevelProviderValidationSchema as validationSchema } from "../validationSchema";
import { makeValidate, showErrorOnBlur } from "mui-rff";
import apiService from "src/utilities/api_service";
import { apiUrl } from "src";
import { globalVariables } from "src/components/App";
import { FetchingActions } from "src/redux/reducers/Fetching";
import { formatName } from "src/utilities/objectUtilities";

const validateForm = makeValidate(validationSchema);

const initialValues = {
  firstName: null,
  lastName: null,
  email: null,
  degree: null,
  active: true,
  facilityID: null,
};

const CreateProviderDialog = (props) => {
  const Facilities = useSelector(selectFacilities);
  const [similarProviders, setSimilarProviders] = React.useState([]);

  const facilityOptions = Facilities.map((facility) => {
    return {
      name: facility.FullName,
      value: facility.ID,
    };
  });

  const createProvider = async (values) => {
    const castValues = validationSchema.cast(values);

    const { data } = await apiService.post(
      `${apiUrl}provider/verify`,
      castValues
    );
    if (data.length > 0 && !_.isEqual(data, similarProviders))
      return setSimilarProviders(data);

    if (castValues.facilityID === null) {
      props.dispatch(
        ProviderActions.Create({
          FirstName: castValues.firstName,
          LastName: castValues.lastName,
          EmailAddress: castValues.email,
          Degree: castValues.degree,
          Active: castValues.active,
        })
      );
    } else {
      props.dispatch(
        ProviderActions.CreateAndLinkToFacility({
          FirstName: castValues.firstName,
          LastName: castValues.lastName,
          EmailAddress: castValues.email,
          Degree: castValues.degree,
          Active: castValues.active,
          FacilityID: castValues.facilityID,
        })
      );
    }
    props.setDialogVisible();
    setSimilarProviders([]);
  };

  return (
    <Form
      onSubmit={createProvider}
      initialValues={initialValues}
      validate={validateForm}
      validateOnBlur
      render={({ handleSubmit }) => (
        <>
          <form>
            <Dialog
              fullWidth
              maxWidth={"sm"}
              open={props.dialogVisible}
              onClose={() => {
                props.setDialogVisible();
              }}
            >
              <DialogTitle>Create Provider</DialogTitle>
              <DialogContent>
                <div className={"row py-2"}>
                  <div className={"col"}>
                    <TextField
                      label="First Name *"
                      name="firstName"
                      variant={"outlined"}
                      showError={showErrorOnBlur}
                    />
                  </div>
                  <div className={"col"}>
                    <TextField
                      label="Last Name *"
                      name="lastName"
                      variant={"outlined"}
                      showError={showErrorOnBlur}
                    />
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col"}>
                    <TextField
                      label="E-Mail"
                      name="email"
                      variant={"outlined"}
                      showError={showErrorOnBlur}
                    />
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col"}>
                    <Select
                      name={"degree"}
                      label={"Degree *"}
                      labelId="create-provider-degree-select-label"
                      id="create-provider-degree-select"
                      variant={"outlined"}
                      showError={showErrorOnBlur}
                    >
                      <MenuItem value={null}>Select a Degree</MenuItem>
                      {degrees.map((option) => (
                        <MenuItem
                          key={`create-provider-degree-select-option-${option.name}-${option.value}`}
                          value={option.value}
                        >
                          {option.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col"}>
                    <Select
                      name={"facilityID"}
                      label={"Facility"}
                      labelId="create-provider-facility-select-label"
                      id="create-provider-facility-select"
                      variant={"outlined"}
                    >
                      <MenuItem value={null}>Select a Facility</MenuItem>
                      {facilityOptions.map((option) => (
                        <MenuItem
                          key={`create-provider-facility-select-option-${option.name}-${option.value}`}
                          value={option.value}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col"}>
                    <Checkboxes
                      size={"small"}
                      name={"active"}
                      data={{ label: "Active", value: true }}
                      color={"primary"}
                    />
                  </div>
                </div>
                <Field name={"facilityID"}>
                  {({ input: { value } }) => (
                    <SimilarProviders
                      similarProviders={similarProviders}
                      FacilityID={value}
                      closeDialog={props.setDialogVisible}
                    />
                  )}
                </Field>
              </DialogContent>
              <DialogActions>
                <Button
                  color={"secondary"}
                  variant={"contained"}
                  onClick={() => {
                    props.setDialogVisible();
                  }}
                >
                  Close
                </Button>
                <Button
                  color={"primary"}
                  variant={"contained"}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Create
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </>
      )}
    />
  );
};

export const SimilarProviders = ({
  similarProviders = [],
  FacilityID = null,
  closeDialog,
}) => {
  const { authenticatedDispatch } = React.useContext(globalVariables);
  const providers = useSelector(selectProviders);

  const handleClick = (provider) => {
    if (!FacilityID) return closeDialog();
    const facilityIDNumber = Number(FacilityID);
    const isLinked = provider.Facility.some(
      (elem) => elem.ID === facilityIDNumber
    );
    const providerFromStore = providers.find(({ ID }) => ID === provider.ID);

    let providerFromDb;
    if (!providerFromStore) {
      providerFromDb = similarProviders.find(({ ID }) => ID === provider.ID);
    }

    isLinked
      ? authenticatedDispatch(FetchingActions.Success("Link"))
      : authenticatedDispatch(
          LinkToFacility({
            FacilityID: facilityIDNumber,
            ProviderID: provider.ID,
            Active: 1,
            BackUpProvider: providerFromDb,
          })
        );
    closeDialog();
  };

  return (
    <Grid
      container
      flexDirection={"column"}
      spacing={1}
      hidden={similarProviders.length === 0}
    >
      <Grid item>
        <Typography variant={"h4"} sx={{ textDecoration: "underline" }}>
          Possible Duplicate Provider List
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={"h5"}>
          Select existing Provider or click button "Create"
        </Typography>
      </Grid>
      <Grid item sx={{ border: 2 }}>
        <List>
          {similarProviders.map((provider) => (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleClick(provider);
                }}
              >
                <Typography>{formatName(provider)}</Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default CreateProviderDialog;
