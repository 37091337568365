import React from "react";
import Box from "@mui/material/Box";
import Custom from "src/components/individual/Custom";

//options must be an array of objects such as this { label: "string", value: int or string }

const RffAutocomplete = ({ name, label, render: { options = [] } }) => {
  return (
    <Section>
      <Custom.Autocomplete label={label} name={name} options={options} />
    </Section>
  );
};

export const Section = ({ children }) => (
  <Box className={"row"} style={{ marginTop: 25, marginBottom: 25 }}>
    <Box className={"col"}>{children}</Box>
  </Box>
);

export default RffAutocomplete;
