import React from "react";
import ChartCard from "../../ChartCard";

const AllFacilityDischargeInstructionsMedicalTerminologyChart = (props) => {
  return (
    <>
      <ChartCard
        {...props}
        title={"All Facility: Discharge Instructions - Medical Terminology"}
        xAxisLabel={"Discharge Instructions - Medical Terminology"}
        yAxisLabel={"Number of Records"}
        figureText={
          "Discharge Instructions - Medical Terminology for all RPPR© Facilities"
        }
      />
    </>
  );
};

export default AllFacilityDischargeInstructionsMedicalTerminologyChart;
