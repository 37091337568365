import React, { useContext } from "react";
import { Field, Form } from "react-final-form";
import {
  makeValidate,
  DatePicker,
  DateTimePicker,
  TextField,
  Select,
} from "mui-rff";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { globalVariables } from "src/components/App";
import getRecordComment from "src/components/pages/MRTSRecords/MRTSRecordDialog/getRecordComment";
import ReferenceMaterial from "../../../MRTSRecords/MRTSRecordDialog/ReusableSections/ReferenceMaterial";
import ReviewReasons from "../../../MRTSRecords/MRTSRecordDialog/ReusableSections/ReviewReasons";
import { RecordActions } from "src/redux/reducers/Record";
import { useSelector } from "react-redux";
import { selectLoaded, selectContent } from "src/redux/selectors";
import { emptyObject } from "src/utilities/objectUtilities";
import worksheetTemplate from "./worksheetTemplate";
import { FinalizeButtons } from "../Shared";
import Submitter from "../Submitter";
import DateFnsUtils from "@date-io/date-fns";
import C from "src/utilities/constants";
import {
  convertDateToLocal,
  formatDateTime,
  formatDate,
} from "src/utilities/dateFunctions";

//validation
import validationSchema, { submissionDateSchema } from "./validationSchema";
const extendedValidationSchema = validationSchema.concat(submissionDateSchema);
const validate = makeValidate(extendedValidationSchema);

const WorksheetForm = ({
  record,
  recordID,
  rnWorksheet,
  isFormDirty,
  setIsFormDirty,
}) => {
  const { authenticatedDispatch } = useContext(globalVariables);
  const AgeIncrements = useSelector(selectContent("AgeIncrement")).filter(
    (a) => a.Active === true || a.Active === 1
  );
  const Committees = useSelector(selectContent("Committee"));
  const ReferenceMaterials = useSelector(selectContent("ReferenceMaterial"));
  const ReviewReasonOptions = useSelector(selectContent("ReviewReason"));
  const PatientGenders = useSelector(selectContent("PatientGender")).filter(
    (p) => p.Active === true || p.Active === 1
  );
  const Loaded = useSelector(selectLoaded);
  const render =
    record !== undefined &&
    AgeIncrements !== undefined &&
    Committees !== undefined &&
    PatientGenders !== undefined &&
    ReferenceMaterials !== undefined &&
    ReviewReasonOptions !== undefined &&
    Loaded !== undefined;

  const saveWorksheet = async (values) => {
    const RecordInfo = {
      RecordID: recordID,
      CommitteeID: values.committee.ID,
      CommitteeOtherDesc:
        values.committeeOther !== null ? values.committeeOther : "",
      PatientAge: values.patientAge,
      AgeIncrementID: values.ageIncrementID,
      PatientGender: values.patientGender,
      SubmissionDate: values.submissionDate,
      ArrivalDate: convertDateToLocal(values.arrivalDate),
      DischargeDate: convertDateToLocal(values.dischargeDate),
      ReviewReason: values.reviewReasons.map((r) => {
        return {
          ID: r,
          recordReviewReason: {
            ReviewReasonID: r,
            OtherReason: values.otherReviewReason,
          },
        };
      }),
      ReferenceMaterial: values.referenceMaterialChecklist.map((r) => {
        return {
          ID: r,
          recordReferenceMaterial: {
            ReferenceMaterialID: r,
            OtherReference: values.otherReferenceMaterial,
          },
        };
      }),
      OtherReason: values.otherReviewReason,
      RecordComment: [
        { TypeID: 2, Description: values.additionalInformation },
        { TypeID: 3, Description: values.committeeQuestion },
      ],
      Worksheet: {
        ...emptyObject("Worksheet"),
        ...values,
        RecordID: recordID,
      },
    };

    authenticatedDispatch(RecordActions.SaveWorksheet(RecordInfo));
  };

  const classes = useStyles();
  const Committee = Committees.find((c) => c.ID === record.CommitteeID);
  const validCommittees = Committees.filter(
    (c) => c.Active === true || c.Active === 1
  );

  //Review Reason Other
  let otherReviewReasonInitialValue = null;
  const initialReviewReasonIDs =
    record.ReviewReason !== undefined
      ? record.ReviewReason.map((r) => r.ID)
      : [];
  const reviewReasonOtherID = ReviewReasonOptions.find(
    (r) => r.Reason.split(" ")[0] === "Other"
  ).ID;

  if (initialReviewReasonIDs.includes(reviewReasonOtherID)) {
    otherReviewReasonInitialValue = record.ReviewReason.find(
      (r) => r.ID === reviewReasonOtherID
    ).recordReviewReason.OtherReason;
  }

  //Reference Material Other
  let otherReferenceMaterialInitialValue = null;
  const initialReferenceMaterialIDs = record.ReferenceMaterial.map((r) => r.ID);
  const referenceMaterialOtherID = ReferenceMaterials.find(
    (r) => r.ReferenceMaterial === "Other"
  ).ID;

  if (initialReferenceMaterialIDs.includes(referenceMaterialOtherID)) {
    otherReferenceMaterialInitialValue = record.ReferenceMaterial.find(
      (r) => r.ID === referenceMaterialOtherID
    ).recordReferenceMaterial.OtherReference;
  }

  const initialValues = {
    ...rnWorksheet,
    archiRecordID: record.ARCHIRecordID,
    reviewCategory: record.ReviewCategoryID === 1 ? "Inpatient" : "Outpatient",
    patientAge: record.PatientAge,
    patientGender: record.PatientGender,
    ageIncrementID: record.AgeIncrementID,
    arrivalDate: formatDateTime(record.ArrivalDate),
    dischargeDate: formatDateTime(record.DischargeDate),
    submissionDate: formatDate(record.SubmissionDate),
    committee: Committee,
    committeeID: Committee,
    committeeOther: record.CommitteeOtherDesc,
    reviewReasons: record.ReviewReason.map((r) => r.ID),
    otherReviewReason: otherReviewReasonInitialValue,
    referenceMaterialChecklist: initialReferenceMaterialIDs,
    otherReferenceMaterial: otherReferenceMaterialInitialValue,
    additionalInformation: getRecordComment(
      record.RecordComment,
      C.COMMENT_TYPE_ID_ADDITIONAL_INFORMATION
    ),
    committeeQuestion: getRecordComment(
      record.RecordComment,
      C.COMMENT_TYPE_ID_COMMITTEE_QUESTION
    ),
  };

  return (
    <>
      {render && (
        <Form
          onSubmit={saveWorksheet}
          initialValues={initialValues}
          keepDirtyOnReinitialize={true}
          subscription={{
            form: false,
            pristine: false,
            submitting: false,
            values: true,
            handleSubmit: true,
          }}
          validate={validate}
        >
          {({ handleSubmit, values, dirty }) => {
            if (isFormDirty !== dirty) {
              setIsFormDirty(dirty);
            }
            return (
              <form onSubmit={handleSubmit}>
                <div className={classes.root}>
                  <h1>Record Information</h1>
                  <Grid container>
                    <div className="col">
                      <div className={classes.paper}>
                        <DatePicker
                          className={classes.paper}
                          dateFunsUtils={DateFnsUtils}
                          name={"submissionDate"}
                          label={"Facility Submission Date (MM/DD/YYYY)"}
                          variant={"inline"}
                          inputVariant={"outlined"}
                          disableFuture={true}
                        />
                      </div>
                      <Select
                        className={classes.select}
                        variant={"outlined"}
                        label={"Committee"}
                        displayEmpty
                        name={"committee"}
                        required
                      >
                        <MenuItem>Select a Committee</MenuItem>
                        {validCommittees.map((committee) => (
                          <MenuItem
                            value={committee}
                            key={`create-mrts-record-committee-select-${committee.Name}-${committee.ID}`}
                          >
                            {`${committee.Abbreviation} - ${committee.Name}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="col">
                      <TextField
                        disabled
                        label={"Review Category"}
                        name={"reviewCategory"}
                        variant={"outlined"}
                        className={classes.paper}
                      />
                      <Field name={"committee"}>
                        {({ input }) => {
                          return (
                            input.value.ID === 35 && (
                              <TextField
                                fullWidth
                                inputProps={{ maxLength: 255 }}
                                label={"Describe Other Committee"}
                                name={"committeeOther"}
                                variant={"outlined"}
                                className={classes.paper}
                              />
                            )
                          );
                        }}
                      </Field>
                    </div>
                  </Grid>
                  <div
                    style={{
                      maxHeight: "100%",
                      overflowY: "auto",
                      overflowX: "hidden",
                      textAlign: "left",
                    }}
                  >
                    <ReviewReasons classes={classes} />
                  </div>
                  <h2>Patient Information</h2>
                  <Grid container>
                    <div className="col">
                      <TextField
                        label={"Patient Age"}
                        name={"patientAge"}
                        variant={"outlined"}
                        className={classes.paper}
                      />
                      <Select
                        className={classes.select}
                        variant={"outlined"}
                        label={"Patient Gender"}
                        displayEmpty
                        name={"patientGender"}
                        required
                      >
                        <MenuItem value={null} disabled>
                          Select a Gender
                        </MenuItem>
                        {PatientGenders.map((patientGender) => (
                          <MenuItem
                            value={patientGender.ID}
                            key={`create-mrts-record-patient-gender-select-${patientGender.Name}-${patientGender.ID}`}
                          >
                            {patientGender.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="col">
                      <Select
                        className={classes.select}
                        variant={"outlined"}
                        label={"Age Increment"}
                        displayEmpty
                        name={"ageIncrementID"}
                        required
                      >
                        <MenuItem disabled>Select an Age Increment</MenuItem>
                        {AgeIncrements.map((ageIncrement) => (
                          <MenuItem
                            value={ageIncrement.ID}
                            key={`create-mrts-record-age-increment-select-${ageIncrement.Name}-${ageIncrement.ID}`}
                          >
                            {ageIncrement.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                  <h3>Arrival Date and Time</h3>
                  <Grid container>
                    <div className="col">
                      <div className={classes.paper}>
                        <DateTimePicker
                          className={classes.paper}
                          dateFunsUtils={DateFnsUtils}
                          name={"arrivalDate"}
                          label={"Arrival Date and Time (MM/DD/YYYY HH:MM)"}
                          variant={"inline"}
                          inputVariant={"outlined"}
                          ampm={false}
                          disableFuture={true}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className={classes.paper}>
                        <DateTimePicker
                          className={classes.paper}
                          dateFunsUtils={DateFnsUtils}
                          name={"dischargeDate"}
                          label={"Discharge Date and Time (MM/DD/YYYY HH:MM)"}
                          variant={"inline"}
                          inputVariant={"outlined"}
                          ampm={false}
                          disableFuture={true}
                          required={true}
                        />
                      </div>
                    </div>
                  </Grid>
                  <h1>Record Index Information</h1>
                  <Grid container>
                    {worksheetTemplate.columns.map((col) => (
                      <div className="col">
                        {col.map(({ label, name }) => (
                          <TextField
                            label={label}
                            name={name}
                            variant={"outlined"}
                            className={classes.paper}
                          />
                        ))}
                      </div>
                    ))}
                  </Grid>
                  <h1>Record Questions/Comments</h1>
                  <div
                    style={{
                      maxHeight: "100%",
                      overflowY: "auto",
                      overflowX: "hidden",
                      textAlign: "left",
                    }}
                  >
                    <ReferenceMaterial classes={classes} />
                  </div>
                  <FinalizeButtons />
                </div>
                <Submitter values={values} onSubmit={saveWorksheet} />
              </form>
            );
          }}
        </Form>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: theme.spacing(0.75),
  },
  title: {
    paddingLeft: theme.spacing(2.0),
    fontSize: `1.25rem`,
  },
  select: {
    margin: theme.spacing(0.75),
    textAlign: "left",
  },
}));

export default WorksheetForm;
