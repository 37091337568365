import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-final-form";
import {
  selectAbbreviationList,
  selectAbbreviationByRecordID,
} from "src/redux/selectors";
import { RecordActions } from "src/redux/reducers/Record";
import { emptyObject } from "src/utilities/objectUtilities";
import AbbreviationsTable from "./AbbreviationsTable";
import { FinalizeButtons } from "../Shared";
import Submitter from "../Submitter";
import C from "../../../../../utilities/constants";

let abbreviationData = emptyObject("RNAbbreviations");

export default function Abbreviations(props) {
  const { doNotUse, additional } = useSelector(selectAbbreviationList);
  let recordAbbreviations = useSelector(
    selectAbbreviationByRecordID(props.record.ID)
  );

  if (recordAbbreviations === undefined) {
    recordAbbreviations = [];
  }

  useMemo(() => {
    abbreviationData = emptyObject("RNAbbreviations");
    recordAbbreviations.forEach(
      ({ AbbreviationID, ProviderType, TimesUsed }) => {
        abbreviationData[`Abbreviation_${AbbreviationID}`][ProviderType] =
          ProviderType !== "Switch" ? JSON.stringify(TimesUsed) : true;
      }
    );
  }, [props.record.ID, recordAbbreviations]);

  const onSubmit = (values) => {
    const abbValuesToSave = [];
    for (const [abbKey, objPropertyKey] of Object.entries(values)) {
      for (const [propertyKey, timesUsed] of Object.entries(objPropertyKey)) {
        if (Number(timesUsed)) {
          abbValuesToSave.push({
            RecordID: props.record.ID,
            AbbreviationID: Number(abbKey.match(/\d+/g)[0]),
            ProviderType: propertyKey,
            TimesUsed: Number(timesUsed),
          });
        }
      }
    }
    props.dispatch(
      RecordActions.SaveAbbreviations({
        Values: abbValuesToSave,
        RecordID: props.record.ID,
      })
    );
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={abbreviationData}
      subscription={{ values: true, submitting: true, dirty: true }}
    >
      {({ handleSubmit, values, form, dirty }) => {
        if (props.isFormDirty !== dirty) {
          props.setIsFormDirty(dirty);
        }
        return (
          <form id={"abbreviations-form"} onSubmit={handleSubmit}>
            <AbbreviationsTable
              lookUpConstants={doNotUse}
              switchID={C.ABBREVIATION_ID_NO_ABBREVIATIONS}
              form={form}
            />
            <AbbreviationsTable
              lookUpConstants={additional}
              switchID={C.ABBREVIATION_ID_NO_ADDITIONAL_ABBREVIATIONS}
              form={form}
            />
            <FinalizeButtons />
            <Submitter values={values} onSubmit={onSubmit} />
          </form>
        );
      }}
    </Form>
  );
}
